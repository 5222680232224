import { Color } from "@src/constants";
import styled from "styled-components/native";
import { StyleSheet } from "react-native";
import MediaStyle from "react-native-media-query";

export const PlanRecommendListContainer = styled.View`
  width: 100%;
  height: calc(100% - 100px);
  padding-left: 20px;
  padding-right: 20px;
  background-color: ${Color.GREY};
`;

export const PlanRecommendListHeader = styled.View`
  margin: 30px 0;
`;

export const PlanRecommendListContents = styled.View`
  position: relative;
  background-image: linear-gradient(to bottom, #6432e9, #825bec);
  padding: 21px 16px 20px 16px;
  border-radius: 24px;
`;

export const PlanRecommendListPrice = styled.View`
  margin-bottom: 26px;
  flex-direction: row;
  justify-content: space-between;
`;

export const PlanRecommendListItem = styled.View`
  position: relative;
`;

export const PlanRecommendListItemHeader = styled.View`
  margin-bottom: -1px;
  padding: 16px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  background-color: ${Color.WHITE};
`;

export const PlanRecommendListItemHeaderInner = styled.View`
  flex-direction: row;
  align-items: center;
`;

export const Category = styled.View`
  align-items: center;
  justify-content: center;
  height: 20px;
  border-radius: 10px;
  padding: 2px 8px 3px 8px;
  background-color: ${Color.PUPPLE};
`;

export const ListName = styled.View`
  margin-left: 10px;
`;

export const Summary = styled.View`
  margin-top: 10px;
`;

export const AccordionHeader = styled.View`
  position: absolute;
  top: 10px;
  right: 10px;
`;

export const AccordionHeaderExpand = styled.TouchableOpacity`
  width: 24px;
  height: 24px;
`;

export const AccordionDetailContents = styled.View`
  padding: 20px 16px 30px 16px;
  background-color: ${Color.GREY};
`;

export const AccordionHeaderIcon = styled.Image`
  width: 24px;
  height: 24px;
`;

export const AccordionContentsHighlightIcon = styled.Image`
  width: 20px;
  height: 20px;
  margin-right: 4px;
`;

export const InfoContents = styled.View``;

export const SubSummary = styled.View`
  margin-top: 18px;
`;

export const Notice = styled.View`
  flex-direction: row;
  align-items: center;
  margin-bottom: 3px;
`;

export const Info = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
`;

export const AccordionContentsInfo = styled.View`
  flex-direction: row;
  align-items: flex-start;
  margin-top: 6px;
`;

export const Dot = styled.View`
  margin-top: 5px;
  background-color: ${Color.BLACK_TWO};
`;

export const PlanRecommendListItemPrice = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: -1px;
  padding: 13px 16px 22px 16px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  background-color: ${Color.LIGHT_PUPPLE};
`;

export const PlanRecommendMaking = styled.View`
  margin-top: 70px;
`;

export const PlanRecommendMakingBtn = styled.TouchableOpacity`
  background-color: ${Color.WHITE};
`;

export const PlanRecommendBottomNotice = styled.View`
  margin-top: 40px;
`;

export const NoticeHighlight = styled.View`
  position: relative;
  flex-direction: row;
  margin-bottom: 4px;
`;

export const NoticeHighlightIcon = styled.Image`
  width: 20px;
  height: 20px;
  margin-right: 4px;
`;

export const HighlightBg = styled.View`
  width: 30px;
  background-color: ${Color.LIGHT_PUPPLE_SIX};
`;

export const NoticeHighlightText = styled.Text`
  z-index: 1;
  font-size: 14px;
  font-family: "fontB";
  letter-spacing: -0.35px;
`;

export const CommentInfo = styled.View`
  flex-direction: row;
  margin-bottom: 4px;
`;

export const CommentInfoText = styled.Text`
  font-size: 13px;
  font-family: "fontM";
  letter-spacing: -0.33px;
  line-height: 18px;
`;

export const itemStyles = StyleSheet.create({
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  whiteText: {
    color: Color.WHITE,
  },
  puppleText: {
    color: Color.PUPPLE,
  },
  xxsText: {
    fontSize: 10,
    fontWeight: "500",
  },
  xsText: {
    fontSize: 12,
    fontWeight: "500",
  },
  sText: {
    fontSize: 13,
    fontWeight: "500",
  },
  smText: {
    fontSize: 14,
    fontWeight: "500",
  },
  mdText: {
    fontSize: 16,
    fontWeight: "bold",
  },
  lgText: {
    fontSize: 20,
    fontWeight: "bold",
  },
  xlText: {
    fontSize: 24,
    fontWeight: "bold",
  },
  accordion: {
    overflow: "hidden",
  },
  lineHeight: {
    lineHeight: 20,
  },
  pupple: {
    color: Color.PUPPLE,
  },
  bold: {
    fontWeight: "bold",
  },
  sectionContainer: {
    borderRadius: 16,
  },
  section: {
    marginBottom: 16,
  },
  headerSection: {
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
  },
  commentDot: {
    marginTop: 7,
  },
});

export const { ids, styles } = MediaStyle.create({});
