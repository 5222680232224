import React, { ReactElement, useRef, useEffect } from "react";
import { Animated, Easing } from "react-native";
import {
  TootipContentsInner,
  TootipContentsImg,
  TootipContentsTextWrap,
  TootipContentsText,
} from "./styled";
import tooltipIcon from "@assets/icons/icon-tooltip.svg";
import tooltipLargeIcon from "@assets/icons/icon-tooltip-large.svg";

interface TooltipItemProp {
  title?: string;
  small?: boolean;
  large?: boolean;
  xLarge?: boolean;
  xxLarge?: boolean;
  xLargeIcon?: any;
  positionLeft?: any;
  positionRight?: number;
  positionTop?: any;
  positionBottom?: number;
  opacity?: any;
  transform?: boolean;
}

const TootipItem = ({
  title,
  small,
  large,
  xLarge,
  xxLarge,
  xLargeIcon,
  positionLeft,
  positionRight,
  positionTop,
  positionBottom,
  opacity,
  transform,
}: TooltipItemProp): ReactElement => {
  const animation = useRef(new Animated.Value(0)).current;
  const fadeOut = useRef(new Animated.Value(1)).current;

  useEffect(() => {
    Animated.timing(animation, {
      toValue: -10,
      duration: 3000,
      easing: Easing.elastic(3),
      useNativeDriver: true,
    }).start(() => {
      Animated.timing(fadeOut, {
        toValue: 0,
        duration: 500,
        useNativeDriver: true,
      }).start();
    });
  }, []);

  return (
    <Animated.View
      style={{
        transform: [
          {
            translateY: transform ? 0 : animation,
          },
        ],
        position: "absolute",
        left: positionLeft ? positionLeft : "auto",
        right: positionRight ? positionRight : 0,
        top: positionTop ? positionTop : 40,
        marginLeft: large && -100,
        bottom: positionBottom ? positionBottom : 0,
        zIndex: 100,
        height: xLarge ? 58 : 32,
        opacity: opacity ? opacity : fadeOut,
      }}
    >
      <TootipContentsInner>
        <TootipContentsImg
          small={small}
          large={large}
          xLarge={xLarge}
          xxLarge={xxLarge}
          source={
            small
              ? tooltipIcon
              : large
              ? tooltipLargeIcon
              : xLargeIcon || xLargeIcon
          }
        />
        <TootipContentsTextWrap small={small} large={large}>
          <TootipContentsText small={small} large={large} xLarge={xLarge}>
            {title}
          </TootipContentsText>
        </TootipContentsTextWrap>
      </TootipContentsInner>
    </Animated.View>
  );
};

export default TootipItem;
