import React, { useState, useEffect, useRef, useCallback } from "react";
import { ScrollView, View, Dimensions, StatusBar } from "react-native";
import Toast from "react-native-fast-toast";
import { Helmet } from "react-helmet-async";
import { useRoute } from "@react-navigation/native";

import { globalStyles, styles } from "@src/styles/modules/global";
import {
  AccordionList,
  BottomComment,
  BottomFixButton,
  ShareSns,
  SurgeryInfoItem,
} from "@src/components/presentational";
import {
  SurgeryDetailContents,
  SurgeryDetailContentsInner,
  SurgeryDetailSummary,
  SurgeryDetailTopBg,
} from "./styled";
import {
  clipboardCopyFailText,
  clipboardCopyText,
  shareText,
  surgeryDetailHighlight,
} from "@src/screens/sampleDatas";
import { SurgeryService } from "@src/services";
import { SurgeryItem } from "@src/types";
import { Layouts } from "@src/components/container";
import { analytics } from "@src/hooks/analytics";

/**
 * 시술 상세
 */

const SurgeryDetailScreen = () => {
  const { height } = Dimensions.get("window");

  const route = useRoute<any>();
  const saveToastRef = useRef(null);
  const shareToastRef = useRef(null);
  const screenshotRef = useRef<any>(null);

  const [data, setData] = useState<SurgeryItem[]>([]);
  const [title, setTitle] = useState<string>();

  const getSurgeryItem = async (): Promise<void> => {
    try {
      const { data } = await SurgeryService.getSurgeryItem(route.params?.id);
      setData(data);
    } catch (error) {
      console.log(error);
    }
  };

  // const handleSaveButton = useCallback(() => {
  //   if (data[0] && screenshotRef.current) {
  //     const result = htmlToImage
  //       .toPng(screenshotRef.current)
  //       .then(function (blob) {
  //         saveAs(blob, "image.png");
  //       });

  //     if (result && Platform.OS === "web") {
  //       setTimeout(() => {
  //         saveToastRef.current.show(saveIamgeText);
  //       }, 2000);
  //     }
  //   }
  // }, [data]);

  const dataToShare: ShareData = {
    title: shareText,
    url: window.location.href,
  };

  const handleShareButton = useCallback(async () => {
    const result = await ShareSns(dataToShare);

    if (result === "copiedToClipboard") {
      shareToastRef.current.show(clipboardCopyText);
    } else if (result === "failed") {
      shareToastRef.current.show(clipboardCopyFailText);
    }

    analytics("Surgery Detail Share", { surgery_detail_share: title });
  }, [title]);

  const handleToggleButton = () => {
    analytics("Surgery Detail Toggle", { surgery_detail_toggle: title });
  };

  useEffect(() => {
    getSurgeryItem();
  }, []);

  useEffect(() => {
    const text = data?.map((item) => item.title);
    setTitle(text[0]);
  }, [data]);

  return (
    <>
      <StatusBar barStyle="light-content" backgroundColor="#6432e9" />
      <Helmet>
        <title>{`${title} 시술 정보 | 촉촉박사`}</title>
        <meta
          name="description"
          content={`${title} 시술 정보 | 촉촉박사`}
          data-react-helmet="true"
        />
      </Helmet>
      <Layouts backMode transparent titleMode homeMode title={title}>
        <ScrollView
          bounces={false}
          scrollEventThrottle={1}
          contentContainerStyle={[{ flexGrow: 1 }, globalStyles.listPaddingL]}
          showsVerticalScrollIndicator={false}
        >
          <View style={styles.bg} ref={screenshotRef}>
            <SurgeryDetailContents>
              <SurgeryDetailContentsInner>
                <SurgeryDetailTopBg>
                  <SurgeryDetailSummary>
                    <AccordionList
                      data={data}
                      highlightText={surgeryDetailHighlight}
                      toggleMoreInfo={handleToggleButton}
                    />
                  </SurgeryDetailSummary>
                </SurgeryDetailTopBg>
                <SurgeryInfoItem data={data[0]} />
              </SurgeryDetailContentsInner>
            </SurgeryDetailContents>
          </View>
          <BottomComment />
        </ScrollView>
        <BottomFixButton
          // onPressSave={handleSaveButton}
          fullBtnMode
          fullBtnText="공유하기"
          onPressFullBtn={handleShareButton}
        />
      </Layouts>
      <Toast
        ref={shareToastRef}
        duration={1500}
        offset={height / 2}
        style={globalStyles.centerToast}
        textStyle={globalStyles.centerToastText}
      />
      <Toast
        ref={saveToastRef}
        duration={1500}
        offset={height / 2}
        style={globalStyles.centerToast}
        textStyle={globalStyles.centerToastText}
      />
    </>
  );
};

export default SurgeryDetailScreen;
