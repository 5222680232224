import { SkinPlanScreen } from "@src/screens";

/**
 * Skin Plan Configs
 */

export default [
  {
    name: "skinPlan",
    component: SkinPlanScreen,
    options: {
      title: "",
    },
  },
];
