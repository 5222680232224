import env from "../../../../environments";

const ShareKakao = (route, desc, title) => {
  if (window.Kakao) {
    const kakao = window.Kakao;
    if (!kakao.isInitialized()) {
      kakao.init(env.KAKAO_API_KEY);
    }

    kakao.Link.sendDefault({
      objectType: "feed",
      content: {
        title: title,
        description: desc,
        imageUrl: "",
        link: {
          mobileWebUrl: route,
          webUrl: route,
        },
      },
      buttons: [
        {
          title: "피부 MBTI 하러가기",
          link: {
            mobileWebUrl: route,
            webUrl: route,
          },
        },
      ],
    });
  }
};

export default ShareKakao;
