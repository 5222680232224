import React, { useRef } from "react";

import { FileUpload, FileUploadInner, itemStyles, UploadBtn } from "./styled";

type FileAttacherProps = {
  title?: string;
  files: Array<any>;
  attachedFiles?: Array<any>;
  meta: {
    fileCount?: number;
    fileSize?: number; // 파일 용량 제한 (byte 단위 숫자)
    accept: string; // 허용 확장자 (default: image/*)
    acceptCaption?: string; // 확장자 설명
  };
  onChangeFile: (files) => void;
  onChangeAttachedFile?: (files, deleteItem) => void;
  required?: boolean;
  showToast?: any;
};

const FileAttacher = ({ meta, onChangeFile }: FileAttacherProps) => {
  const fileRef = useRef<any>(null);

  const handleAttach = (): void => {
    fileRef.current.click();
  };

  const handleChangeFile = async (e) => {
    const multipleFile = [];

    for (let i = 0; i < e.target.files.length; i++) {
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[i]);
      reader.onload = (readerEvent) => {
        multipleFile.push({
          base64Encode: readerEvent.target?.result,
        });

        onChangeFile(multipleFile);
      };
    }
  };

  return (
    <FileUpload>
      <input
        type="file"
        accept={meta.accept}
        ref={fileRef}
        onChange={(e) => {
          handleChangeFile(e);
          e.target.value = "";
        }}
        hidden
        multiple
      />

      <FileUploadInner>
        <UploadBtn
          onPress={handleAttach}
          activeOpacity={1}
          style={itemStyles.fileInput}
        />
      </FileUploadInner>
    </FileUpload>
  );
};
export default FileAttacher;
