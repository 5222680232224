import { Color } from "@src/constants";
import styled from "styled-components/native";

export const SurgeryNotice = styled.View`
  padding: 24px 20px 14px 20px;
`;

export const SurgeryNoticeTitle = styled.View`
  position: relative;
  flex-direction: row;
  align-items: center;
  margin-bottom: 4px;
`;

export const SurgeryNoticeTitleText = styled.Text`
  font-size: 14px;
  font-family: "fontB";
  letter-spacing: -0.35px;
`;

export const SurgeryNoticeTitleIcon = styled.Image`
  width: 20px;
  height: 20px;
  margin-right: 4px;
`;

export const HighlightBg = styled.View`
  width: 64px;
  background-color: ${Color.LIGHT_PUPPLE_SIX};
`;

export const Dot = styled.View`
  margin-top: 7px;
  background-color: ${Color.BLACK};
`;

export const SurgeryNoticeInfo = styled.View`
  flex-direction: row;
  margin-bottom: 4px;
`;

export const SurgeryNoticeInfoText = styled.Text`
  font-size: 12px;
  font-family: "fontM";
  letter-spacing: -0.3px;
  line-height: 18px;
`;
