import React, { ReactElement } from "react";
import { View, Text, Image, FlatList } from "react-native";
import { useNavigation } from "@react-navigation/native";

import {
  AdminTag,
  Category,
  CategoryText,
  Comment,
  CommentImage,
  CommentText,
  CommunityListContainer,
  CommunityListInner,
  Contents,
  CountText,
  ImgContents,
  Info,
  itemStyles,
  LikeContents,
  LikeImage,
  MoreBtn,
  MoreContents,
  NicknameText,
  Tag,
  Title,
  TitleText,
} from "./styled";
import { Maybe } from "..";
import commentIcon from "@assets/icons/icon-comment-count.png";
import likeIcon from "@assets/icons/icon-like.png";
import downGrayIcon from "@assets/icons/icon-down-gray.svg";
import noticeIcon from "@assets/icons/icon-community-notice.png";
import magazineIcon from "@assets/icons/icon-community-magazine.png";

interface CommunityListProps {
  data?: any;
  onClickMore?: () => void;
  totalCount?: number;
  last?: boolean;
  style?: any;
}

const CommunityList = ({
  data,
  onClickMore,
  totalCount,
  last,
  style,
}: CommunityListProps): ReactElement => {
  const navigation = useNavigation<any>();

  const renderItem = ({ item }) => {
    return (
      <CommunityListContainer>
        <CommunityListInner
          activeOpacity={1}
          onPress={() => navigation.push("communityDetail", { id: item.id })}
        >
          {item.thumbnailUrl && (
            <ImgContents>
              <Image
                source={item.thumbnailUrl}
                resizeMode="cover"
                style={itemStyles.image}
              />
            </ImgContents>
          )}

          <Contents imgMode={item.thumbnailUrl}>
            <AdminTag>
              {item.adminPostingTypes &&
                item.adminPostingTypes.map((item, index) => (
                  <Tag key={index}>
                    {item === "NOTICE" ? (
                      <Image
                        source={noticeIcon}
                        style={itemStyles.noticeIcon}
                      />
                    ) : (
                      <Image
                        source={magazineIcon}
                        style={itemStyles.magazineIcon}
                      />
                    )}
                  </Tag>
                ))}
            </AdminTag>
            {item.adminPostingTypes ? (
              <></>
            ) : (
              <Category>
                <CategoryText>{item.postCategory.title}</CategoryText>
              </Category>
            )}
            <Title>
              <TitleText>{item.title}</TitleText>
            </Title>
            <Comment>
              <CommentImage source={commentIcon} resizeMode="contain" />
              <CommentText>{item.countOfComments}</CommentText>
            </Comment>
            <Info imgMode={item.thumbnailUrl}>
              <View
                style={
                  item.user?.nickname.length > 13 ? itemStyles.nickname : null
                }
              >
                <NicknameText style={[itemStyles.xsText, itemStyles.gray]}>
                  {item.user?.nickname}
                </NicknameText>
              </View>
              <View style={itemStyles.flexRow}>
                <Text style={[itemStyles.xsText, itemStyles.gray2]}>
                  {item.createDate}
                </Text>
                <CountText style={[itemStyles.xsText, itemStyles.gray2]}>
                  조회 {item.countOfView}
                </CountText>
                <LikeContents>
                  <LikeImage source={likeIcon} resizeMode="contain" />
                  <Text style={[itemStyles.xsText, itemStyles.gray2]}>
                    {item.countOfLike}
                  </Text>
                </LikeContents>
              </View>
            </Info>
          </Contents>
        </CommunityListInner>
      </CommunityListContainer>
    );
  };

  return (
    <View style={{ flex: 1, justifyContent: "space-between" }}>
      <FlatList
        data={data}
        renderItem={renderItem}
        showsVerticalScrollIndicator={false}
        keyExtractor={(item, index) => index.toString()}
      />
      <Maybe
        test={data && totalCount > 10 && !last}
        children={
          <MoreContents>
            <MoreBtn onPress={onClickMore} activeOpacity={1}>
              <Text style={itemStyles.moreText}>더보기</Text>
              <Image
                source={downGrayIcon}
                resizeMode="contain"
                style={itemStyles.icon}
              />
            </MoreBtn>
          </MoreContents>
        }
      />
    </View>
  );
};

export default CommunityList;
