/**
 * 피부 MBTI 질문 Datas
 */

export const skinMbtiTest = {
  totalQuestions: 22,
  questions: [
    {
      question:
        "성별을 알려주세요. 보다 정확한\n피부 MBTI 분석을 위해 필요해요.",
      type: "gender",
      correctAnswer: "",
      id: 1,
    },
    {
      question:
        "출생연도를 알려주세요. 보다 정확한\n피부 MBTI 분석을 위해 필요해요.",
      type: "birthYear",
      correctAnswer: "",
      id: 2,
    },
    {
      question:
        "아뿔싸, 지각이다! 급하게 세수만 하고\n아무것도 바르지 못한 채 출근했다.\n점심시간 쯤 내 피부 상태는?",
      lists: [
        {
          list: "아무것도 못 발랐더니 피부가 찢어질 것 같다.",
          value: [
            {
              type1: "D",
              count: 2,
            },
            {
              type2: "O",
              count: 0,
            },
          ],
        },
        {
          list: "아직도 건조하다. 피부가 당긴다.",
          value: [
            {
              type1: "D",
              count: 1,
            },
            {
              type2: "O",
              count: 0,
            },
          ],
        },
        {
          list: "피부가 당기지도, 번들거리지도 않는다.",
          value: [
            {
              type1: "D",
              count: 0,
            },
            {
              type2: "O",
              count: 1,
            },
          ],
        },
        {
          list: "미끌미끌 번들번들해진다.",
          value: [
            {
              type1: "D",
              count: 0,
            },
            {
              type2: "O",
              count: 2,
            },
          ],
        },
      ],
      id: 3,
      correctAnswer: "",
    },
    {
      question:
        "스킨, 로션 등의 기초제품을 쟁일 타임\n이다. 난 보통 [               ]타입에 맞는\n제품을 고른다.",
      lists: [
        {
          list: "건성",
          value: [
            {
              type1: "D",
              count: 2,
            },
            {
              type2: "O",
              count: 0,
            },
          ],
        },
        {
          list: "중성",
          value: [
            {
              type1: "D",
              count: 1,
            },
            {
              type2: "O",
              count: 0,
            },
          ],
        },
        {
          list: "복합성",
          value: [
            {
              type1: "D",
              count: 0,
            },
            {
              type2: "O",
              count: 1,
            },
          ],
        },
        {
          list: "지성",
          value: [
            {
              type1: "D",
              count: 0,
            },
            {
              type2: "O",
              count: 2,
            },
          ],
        },
      ],
      id: 4,
      correctAnswer: "",
    },
    {
      question:
        "모공을 숭배하는 ‘모공국’에서는 얼굴의\n모공이 크고 많을수록 우러러본다고 한\n다. 만약 내가 모공국에 살고 있다면?",
      lists: [
        {
          list: "모공이 안보인다. 돌려줘요 내 국적",
          value: [
            {
              type1: "D",
              count: 2,
            },
            {
              type2: "O",
              count: 0,
            },
          ],
        },
        {
          list: "그냥 저냥 평범한 소시민",
          value: [
            {
              type1: "D",
              count: 1,
            },
            {
              type2: "O",
              count: 0,
            },
          ],
        },
        {
          list: "이마, 코, 볼쪽에 두드러진 내 모공이\n매력포인트!",
          value: [
            {
              type1: "D",
              count: 0,
            },
            {
              type2: "O",
              count: 1,
            },
          ],
        },
        {
          list: "나는야 모공부자. 모공으로 세계정복한다.",
          value: [
            {
              type1: "D",
              count: 0,
            },
            {
              type2: "O",
              count: 2,
            },
          ],
        },
      ],
      id: 5,
      correctAnswer: "",
    },
    {
      question: "평소 내 이마와 코는 [               ]",
      lists: [
        {
          list: "아주 보송보송하다.",
          value: [
            {
              type1: "D",
              count: 2,
            },
            {
              type2: "O",
              count: 0,
            },
          ],
        },
        {
          list: "번들거리는건지 잘 모르겠다.",
          value: [
            {
              type1: "D",
              count: 1,
            },
            {
              type2: "O",
              count: 0,
            },
          ],
        },
        {
          list: "약간 번들거리는 것 같다.",
          value: [
            {
              type1: "D",
              count: 0,
            },
            {
              type2: "O",
              count: 1,
            },
          ],
        },
        {
          list: "유전이 따로 없다. 기름 부자다.",
          value: [
            {
              type1: "D",
              count: 0,
            },
            {
              type2: "O",
              count: 2,
            },
          ],
        },
      ],
      id: 6,
      correctAnswer: "",
    },
    {
      question:
        "오늘은 눈이 아침에 잘 떠졌다.\n여유롭게 세안하고 로션도 발랐다.\n점심시간 쯤 내 피부 상태는?",
      lists: [
        {
          list: "볼에서 각질이 느껴진다.",
          value: [
            {
              type1: "D",
              count: 2,
            },
            {
              type2: "O",
              count: 2,
            },
          ],
        },
        {
          list: "아이 보들보들한 내 피부 💖",
          value: [
            {
              type1: "D",
              count: 1,
            },
            {
              type2: "O",
              count: 1,
            },
          ],
        },
        {
          list: "슬슬 광이나기 시작한다.",
          value: [
            {
              type1: "D",
              count: 0,
            },
            {
              type2: "O",
              count: 0,
            },
          ],
        },
        {
          list: "이미 미끌미끌 번들번들 빤딱빤딱하다.",
          value: [
            {
              type1: "D",
              count: 0,
            },
            {
              type2: "O",
              count: 0,
            },
          ],
        },
      ],
      id: 7,
      correctAnswer: "",
    },
    {
      question: "촉촉한 피부의 숙적, 여드름!\n난 여드름이 [               ]",
      lists: [
        {
          list: "없다. 진짜다.",
          value: [
            {
              type1: "S",
              count: 0,
            },
            {
              type2: "R",
              count: 2,
            },
          ],
        },
        {
          list: "어쩌다 가~~끔 하나씩 생긴다.",
          value: [
            {
              type1: "S",
              count: 0,
            },
            {
              type2: "R",
              count: 1,
            },
          ],
        },
        {
          list: "한 달에 하나 정도는 매번 생기는 것 같다.",
          value: [
            {
              type1: "S",
              count: 1,
            },
            {
              type2: "R",
              count: 0,
            },
          ],
        },
        {
          list: "한 주에 하나씩 생긴다. 여드름이 곧 나다.",
          value: [
            {
              type1: "S",
              count: 2,
            },
            {
              type2: "R",
              count: 0,
            },
          ],
        },
      ],
      id: 8,
      correctAnswer: "",
    },
    {
      question:
        "난 아토피, 습진, 접촉성 피부염 같은\n피부 질환과 삶을 함께하고 있다.",
      lists: [
        {
          list: "없다. 내 피부는 결백하다.",
          value: [
            {
              type1: "S",
              count: 0,
            },
            {
              type2: "R",
              count: 2,
            },
          ],
        },
        {
          list: "지금은 아니지만 예전엔 있었다.",
          value: [
            {
              type1: "S",
              count: 0,
            },
            {
              type2: "R",
              count: 1,
            },
          ],
        },
        {
          list: "약간 신경쓰이는 정도이다.",
          value: [
            {
              type1: "S",
              count: 1,
            },
            {
              type2: "R",
              count: 0,
            },
          ],
        },
        {
          list: "심각하다. 스트레스가 이만저만이 아니다.",
          value: [
            {
              type1: "S",
              count: 2,
            },
            {
              type2: "R",
              count: 0,
            },
          ],
        },
      ],
      id: 9,
      correctAnswer: "",
    },
    {
      question:
        "친구가 생일선물로 목걸이를 줬다. 하지\n만 금, 은, 써지컬스틸 재질이 아니다…",
      lists: [
        {
          list: "상관없다. 어떤 트러블도 생기지 않는다.",
          value: [
            {
              type1: "S",
              count: 0,
            },
            {
              type2: "R",
              count: 2,
            },
          ],
        },
        {
          list: "어쩌다 가끔 트러블이 생기지만 괜찮다.",
          value: [
            {
              type1: "S",
              count: 0,
            },
            {
              type2: "R",
              count: 1,
            },
          ],
        },
        {
          list: "슬프다. 웬만하면 알러지가 올라올거다.",
          value: [
            {
              type1: "S",
              count: 1,
            },
            {
              type2: "R",
              count: 0,
            },
          ],
        },
        {
          list: "착용하면 100% 확률로 봉산탈춤을 출 것이다.",
          value: [
            {
              type1: "S",
              count: 2,
            },
            {
              type2: "R",
              count: 0,
            },
          ],
        },
      ],
      id: 10,
      correctAnswer: "",
    },
    {
      question: "발그레한 볼, 홍조는 나의 트레이드 \n마크이다.",
      lists: [
        {
          list: "없다. 생기를 주기 위한 볼터치는 필수.",
          value: [
            {
              type1: "S",
              count: 0,
            },
            {
              type2: "R",
              count: 2,
            },
          ],
        },
        {
          list: "희미하게나마 있다. 자연스러운 정도",
          value: [
            {
              type1: "S",
              count: 0,
            },
            {
              type2: "R",
              count: 1,
            },
          ],
        },
        {
          list: "살짝 거슬릴만큼의 홍조는 있다.",
          value: [
            {
              type1: "S",
              count: 1,
            },
            {
              type2: "R",
              count: 0,
            },
          ],
        },
        {
          list: "홍조가 심하다. 볼터치가 필요없다.",
          value: [
            {
              type1: "S",
              count: 2,
            },
            {
              type2: "R",
              count: 0,
            },
          ],
        },
      ],
      id: 11,
      correctAnswer: "",
    },
    {
      question:
        "친구가 홈쇼핑에서 충동구매한 기초 제\n품을 나한테 줬다. 이걸 사용하면?",
      lists: [
        {
          list: "아무 문제 없이 잘 사용한다. 아이 좋아",
          value: [
            {
              type1: "S",
              count: 0,
            },
            {
              type2: "R",
              count: 2,
            },
          ],
        },
        {
          list: "가끔 가렵거나 따가운 적이 있다.",
          value: [
            {
              type1: "S",
              count: 0,
            },
            {
              type2: "R",
              count: 1,
            },
          ],
        },
        {
          list: "높은 확률로 피부가 따갑다. 성분 확인은 필수다.",
          value: [
            {
              type1: "S",
              count: 1,
            },
            {
              type2: "R",
              count: 0,
            },
          ],
        },
        {
          list: "매번 문제가 생긴다. 친구야 마음만 받을게…",
          value: [
            {
              type1: "S",
              count: 2,
            },
            {
              type2: "R",
              count: 0,
            },
          ],
        },
      ],
      id: 12,
      correctAnswer: "",
    },
    {
      question:
        "한동안 날 괴롭히던 여드름과 인그로운\n헤어가 사라졌다. 그 자리에는?",
      lists: [
        {
          list: "아무것도 없다. 말끔하다.",
          value: [
            {
              type1: "N",
              count: 2,
            },
            {
              type2: "p",
              count: 0,
            },
          ],
        },
        {
          list: "가끔 흔적이 남는다.",
          value: [
            {
              type1: "N",
              count: 1,
            },
            {
              type2: "P",
              count: 0,
            },
          ],
        },
        {
          list: "대부분 흔적이 남는다.",
          value: [
            {
              type1: "N",
              count: 0,
            },
            {
              type2: "P",
              count: 1,
            },
          ],
        },
        {
          list: "났다 하면 자취를 남긴다.\n다크 스팟으로 가득하다.",
          value: [
            {
              type1: "N",
              count: 0,
            },
            {
              type2: "P",
              count: 2,
            },
          ],
        },
        {
          list: "애초에 여드름이랑 인그로운 헤어가 없다.\n음하하",
          value: [
            {
              type1: "N",
              count: 0,
            },
            {
              type2: "P",
              count: 0,
            },
          ],
        },
      ],
      id: 13,
      correctAnswer: "",
    },
    {
      question:
        "말끔하게 세안을 한 후, 거울을 본다.\n거울 속 내 피부에는 [               ]",
      lists: [
        {
          list: "기미/잡티없는 깨끗한 피부가 빛나고 있다 😎",
          value: [
            {
              type1: "N",
              count: 2,
            },
            {
              type2: "P",
              count: 0,
            },
          ],
        },
        {
          list: "예전엔 보였었는데 지금은 깨끗하다. 🥰🥰",
          value: [
            {
              type1: "N",
              count: 1,
            },
            {
              type2: "P",
              count: 0,
            },
          ],
        },
        {
          list: "응 있어. 기미 그리고 잡티…",
          value: [
            {
              type1: "N",
              count: 0,
            },
            {
              type2: "P",
              count: 1,
            },
          ],
        },
        {
          list: "기미, 잡티가 있는 수준이 아니다.\n엄청 많다… 😂",
          value: [
            {
              type1: "N",
              count: 0,
            },
            {
              type2: "P",
              count: 2,
            },
          ],
        },
      ],
      id: 14,
      correctAnswer: "",
    },
    {
      question:
        "벼르고 있던 동남아 휴양지에 갔다!\n한 여름의 태양볕 아래 모래찜질을 하다\n보면 내 피부는?",
      lists: [
        {
          list: "빨갛게 변하기만 한다.",
          value: [
            {
              type1: "N",
              count: 2,
            },
            {
              type2: "P",
              count: 0,
            },
          ],
        },
        {
          list: "처음엔 빨갛게 변하다가 점점 피부가 탄다.",
          value: [
            {
              type1: "N",
              count: 1,
            },
            {
              type2: "P",
              count: 0,
            },
          ],
        },
        {
          list: "피부가 점점 탄다. 화르륵",
          value: [
            {
              type1: "N",
              count: 0,
            },
            {
              type2: "P",
              count: 1,
            },
          ],
        },
        {
          list: "응 아무리 피부가 타봐야 티도 안남 ㅎ",
          value: [
            {
              type1: "N",
              count: 0,
            },
            {
              type2: "P",
              count: 2,
            },
          ],
        },
      ],
      id: 15,
      correctAnswer: "",
    },
    {
      question:
        "퇴근길 길을 걷다 애교많은 애옹이를 만\n났다. 츄르를 주려고 했는데 갑자기 나를 할퀴어 상처가 났다 ㅠㅠ",
      lists: [
        {
          list: "상처가 났지만 말끔하게 나았다.",
          value: [
            {
              type1: "N",
              count: 2,
            },
            {
              type2: "P",
              count: 0,
            },
          ],
        },
        {
          list: "상처가 아물고 나서 일주일정도는\n어두운 흔적이…",
          value: [
            {
              type1: "N",
              count: 1,
            },
            {
              type2: "P",
              count: 0,
            },
          ],
        },
        {
          list: "2주 이상 흔적이…",
          value: [
            {
              type1: "N",
              count: 0,
            },
            {
              type2: "P",
              count: 1,
            },
          ],
        },
        {
          list: "몇 달이나 흔적이… 😢",
          value: [
            {
              type1: "N",
              count: 0,
            },
            {
              type2: "P",
              count: 2,
            },
          ],
        },
      ],
      id: 16,
      correctAnswer: "",
    },
    {
      question: "내 자연 모발 색은 [               ]이다.",
      lists: [
        {
          list: "아몬드같은 연갈색",
          value: [
            {
              type1: "N",
              count: 2,
            },
            {
              type2: "P",
              count: 0,
            },
          ],
        },
        {
          list: "밀크 초콜릿 같은 갈색",
          value: [
            {
              type1: "N",
              count: 1,
            },
            {
              type2: "P",
              count: 0,
            },
          ],
        },
        {
          list: "다크 초콜릿 같은 진한 갈색",
          value: [
            {
              type1: "N",
              count: 0,
            },
            {
              type2: "P",
              count: 1,
            },
          ],
        },
        {
          list: "선명한 흑발, 검은색",
          value: [
            {
              type1: "N",
              count: 0,
            },
            {
              type2: "P",
              count: 2,
            },
          ],
        },
      ],
      id: 17,
      correctAnswer: "",
    },
    {
      question:
        "중요한 발표를 앞두고 표정연습을 한다.\n거울을 볼 때 내 얼굴은?",
      lists: [
        {
          list: "언제나 탱탱하다. 아주 굿",
          value: [
            {
              type1: "W",
              count: 0,
            },
            {
              type2: "T",
              count: 2,
            },
          ],
        },
        {
          list: "웃거나 찡그릴 때 눈가에 주름이 생긴다.",
          value: [
            {
              type1: "W",
              count: 0,
            },
            {
              type2: "T",
              count: 1,
            },
          ],
        },
        {
          list: "시크한 표정에서도 팔자 주름이 살짝 보인다.",
          value: [
            {
              type1: "W",
              count: 1,
            },
            {
              type2: "T",
              count: 0,
            },
          ],
        },
        {
          list: "아무리 무표정을 해봐도 주름이 있다…",
          value: [
            {
              type1: "W",
              count: 2,
            },
            {
              type2: "T",
              count: 0,
            },
          ],
        },
      ],
      id: 18,
      correctAnswer: "",
    },
    {
      question:
        "실내 생활에 익숙한 현대인인 나이지만,\n그래도 하루에 이 정도는 햇빛을 만끽한다!",
      lists: [
        {
          list: "거의 없다. 햇빛에 노출되면 난 녹아버릴거야…!",
          value: [
            {
              type1: "W",
              count: 0,
            },
            {
              type2: "T",
              count: 2,
            },
          ],
        },
        {
          list: "매일 십 여분정도는 햇빛과 함께한다.",
          value: [
            {
              type1: "w",
              count: 0,
            },
            {
              type2: "T",
              count: 1,
            },
          ],
        },
        {
          list: "매일 몇 시간정도는 햇살을 즐긴다.",
          value: [
            {
              type1: "W",
              count: 1,
            },
            {
              type2: "T",
              count: 0,
            },
          ],
        },
        {
          list: "매일 오랫동안 햇빛에 노출된다.",
          value: [
            {
              type1: "W",
              count: 2,
            },
            {
              type2: "T",
              count: 0,
            },
          ],
        },
      ],
      id: 19,
      correctAnswer: "",
    },
    {
      question:
        "매끈한 구릿빛 피부를 위한 피부 태닝.\n나는 태닝을 [               ]",
      lists: [
        {
          list: "해 본적이 단 한번도 없다.",
          value: [
            {
              type1: "w",
              count: 0,
            },
            {
              type2: "t",
              count: 2,
            },
          ],
        },
        {
          list: "1년 이상 반복했다.",
          value: [
            {
              type1: "W",
              count: 0,
            },
            {
              type2: "t",
              count: 1,
            },
          ],
        },
        {
          list: "5년 이상 반복했다.",
          value: [
            {
              type1: "W",
              count: 1,
            },
            {
              type2: "T",
              count: 0,
            },
          ],
        },
        {
          list: "10년 이상 반복했다.",
          value: [
            {
              type1: "W",
              count: 2,
            },
            {
              type2: "T",
              count: 0,
            },
          ],
        },
      ],
      id: 20,
      correctAnswer: "",
    },
    {
      question: "객관적으로 내 피부만 본다면,\n실제 나이에 비해 어떨까?",
      lists: [
        {
          list: "피부는 자신있다. 1~5년 정도는\n젊어보인다. 후후",
          value: [
            {
              type1: "W",
              count: 0,
            },
            {
              type2: "T",
              count: 2,
            },
          ],
        },
        {
          list: "내 나이 그대로 보인다.",
          value: [
            {
              type1: "W",
              count: 0,
            },
            {
              type2: "T",
              count: 1,
            },
          ],
        },
        {
          list: "5년정도 더 나이들어 보인다. 주르륵…",
          value: [
            {
              type1: "W",
              count: 1,
            },
            {
              type2: "T",
              count: 0,
            },
          ],
        },
        {
          list: "이런 잔인한 질문을 하다니…!\n5년 이상 늙어보인다 ㅠㅠ",
          value: [
            {
              type1: "W",
              count: 2,
            },
            {
              type2: "T",
              count: 0,
            },
          ],
        },
      ],
      id: 21,
      correctAnswer: "",
    },
    {
      question:
        "나는 테니스, 낚시, 캠핑, 러닝 등\n집 밖에서의 활동을 [              ]",
      lists: [
        {
          list: "선호하지 않는다. 집콕이 제일 좋다.",
          value: [
            {
              type1: "W",
              count: 0,
            },
            {
              type2: "t",
              count: 2,
            },
          ],
        },
        {
          list: "한 달에 한번쯤은 즐긴다. 가끔이 좋다.",
          value: [
            {
              type1: "W",
              count: 0,
            },
            {
              type2: "T",
              count: 1,
            },
          ],
        },
        {
          list: "일주일에 한번 정도씩 주기적으로 즐긴다.\n햇살좋아 🥰",
          value: [
            {
              type1: "W",
              count: 1,
            },
            {
              type2: "T",
              count: 0,
            },
          ],
        },
        {
          list: "거의 매일 즐긴다! 햇빛 최고!",
          value: [
            {
              type1: "W",
              count: 2,
            },
            {
              type2: "T",
              count: 0,
            },
          ],
        },
      ],
      id: 22,
      correctAnswer: "",
    },
  ],
};

export const SkinMbtiResultType = [
  {
    no: 1,
    type: "ORPT",
    title: "꿀피부!\n근데 이제 잡티를 곁들인",
    typeDesc: "O지성/R저항성/P색소성/T탄력성",
    img: require("@assets/images/mbti/mbti-1.jpg"),
    tag: "#탱글피부 #기미잡티 #번들거림",
    description:
      "유분기가 있지만 탱탱하고 튼튼한 지성 피부예요.\n관리만 잘 해주면 효과를 가장 잘 볼 수 있는 피부 유형이에요.\n색소 침착이 잘 되는 편이에요. 평소에 피지 분비를 잘 조절하고, 자외선 차단에 신경 써주세요.\n이 유형은 자외선 차단제를 바르면 기름지고 번들거린다고 느낄 수 있어요. 사용감이 산뜻한 제품을 사용하는 걸 추천 드려요.",
    recommend: [
      {
        id: 31,
        title: "릴리이드",
        description:
          "릴리이드W는 미백 효과가 뛰어난 스킨부스터예요. 균일하고 맑은 피부톤을 만드는데 효과적이에요.",
      },
      {
        id: 36,
        title: "라라필",
        description:
          "뷰티 기업 로레알이 개발한 필링 시술이에요. 피지 분비를 조절하고 피부톤을 개선해줘요!",
      },
    ],
  },
  {
    no: 2,
    type: "ORNT",
    title: "고개를 돌려 광을 찾아.\n반짝반짝 빛나는",
    typeDesc: "O지성/R저항성/N비색소성/T탄력성",
    img: require("@assets/images/mbti/mbti-2.jpg"),
    tag: "#광채피부 #블랙헤드 #모공조심",
    description:
      "맨 얼굴도 광채가 나는, 매끄럽고 깨끗한 피부예요\n가끔 번들거릴 때가 있어요\n모공과 블랙헤드를 잘 관리하면 좋은 피부를 유지할 수 있을 거예요\n유수분 밸런스를 맞춰주는 스킨케어 제품을 사용하시는 걸 추천 드려요.",
    recommend: [
      {
        id: 32,
        title: "쥬베룩",
        description:
          "모공 타이트닝 효과가 있는 스킨부스터를 추천드려요. 매끄러운 내 피부를 더 돋보이게 해줄 거예요.",
      },
      {
        id: 37,
        title: "아쿠아필",
        description:
          "AHA, BHA 필링 용액으로 모공을 청소한 다음, 보습 용액을 이용해 수분을 공급해줘요. 블랙헤드에 효과적인 시술이죠!",
      },
    ],
  },
  {
    no: 3,
    type: "ORPW",
    title: "팜유 세미나 in 내 얼굴.\n산유국 같은",
    typeDesc: "O지성/R저항성/P색소성/W주름성",
    img: require("@assets/images/mbti/mbti-3.jpg"),
    tag: "#왕모공 #유분과다 #오돌토돌",
    description:
      "유분이 많아요. 민감한 피부는 아니지만, 기미잡티나 잔주름이 잘 생기는 피부예요.\n넓은 모공 때문에 오돌토돌 요철이 부각되어 보이기도 해요.\n올라왔던 피지가 그대로 착색되어 피부 톤이 얼룩덜룩해보이기 쉬워요.\n유분이 적고 수분감이 충분한 스킨케어 제품을 사용하는 걸 추천 드려요.",
    recommend: [
      {
        id: 2,
        title: "포텐자",
        description:
          "고주파 레이저로 피지선을 응고 시켜 피지 분비량을 조절하고 모공 축소에 도움을 줘요. 잔주름 개선 효과도 누릴 수 있어요.",
      },
      {
        id: 28,
        title: "리쥬란힐러",
        description:
          "스킨 부스터의 끝판왕이라 불리는 시술이에요. 모공 축소 효과와 함께 전체적인 피부톤 개선에 도움이 되는 스킨부스터예요.",
      },
    ],
  },
  {
    no: 4,
    type: "ORNW",
    title: "내 피부는 주름 생성\n아티스트",
    typeDesc: "O지성/R저항성/N비색소성/W주름성",
    img: require("@assets/images/mbti/mbti-4.jpg"),
    tag: "#튼튼피부 #여드름 #탄력부족",
    description:
      "유분기가 많고 탄력이 부족하지만 트러블에는 강한, 나름 튼튼한 지성 피부예요\n크림을 바르면 번들번들하고, 유분기를 없애면 각질이 올라와요. 어쩌란 건지 모르겠어요.\n다른 지성 피부타입과 다르게 주름이 잘 생기는 유형이에요.\n평소 충분한 수분 섭취와 빠른 안티에이징 관리가 관건이에요.",
    recommend: [
      {
        id: 15,
        title: "울쎄라",
        description:
          "잔주름 개선과 피부 리프팅 효과로 안티에이징에 효과적인 초음파 리프팅 시술이에요. 남성들에게도 인기 있어요!",
      },
      {
        id: 31,
        title: "릴리이드",
        description:
          "피부 속부터 차오르는 히알루론산 성분의 스킨부스터예요. 수분을 꽉 채워주고 콜라겐 생성을 유도해 잔주름 개선에도 도움을 줘요.",
      },
    ],
  },
  {
    no: 5,
    type: "OSPT",
    title: "트러블 없는 얼굴…\n그게 뭔데 어떻게 하는 건데",
    typeDesc: "O지성/S민감성/P색소성/T탄력성",
    img: require("@assets/images/mbti/mbti-5.jpg"),
    tag: "#트러블무한루프 #색소침착 #쫀득피부",
    description:
      "유분이 많고 민감해요. 피지 분비가 많은데다 색소침착도 잘 되는 피부예요.\n한 번 여드름이 나면 그 자리에 거뭇거뭇하게 색소침착 자국이 남는 경우가 많아요.\n화장품을 조금만 잘못써도 트러블 무한 루프에 갇히게 돼요.\n가뜩이나 민감한 피부를 자극할 수 있어서, 화학적 필링은 추천하지 않아요.",
    recommend: [
      {
        id: 34,
        title: "LDM",
        description:
          "초음파 파장으로 피부 수분을 끌어올려 주고, 트러블이 자주 생기는 피부를 재생 시켜줘요. 피부 자극이 적어 민감성 피부를 가진 분들에게 추천하는 시술이에요!",
      },
      {
        id: 8,
        title: "티타늄 리프팅",
        description:
          "3가지 파장으로 즉각적인 리프팅 효과뿐만 아니라 색소침착, 피부톤 개선 효과도 누릴 수 있어요. 전반적인 피부 고민을 한 번에 치료하고 싶으신 분들께 추천해요!",
      },
    ],
  },
  {
    no: 6,
    type: "OSNT",
    title: "뭔데...\n왜 또 붉어지는 건데? 슈퍼샤이한",
    typeDesc: "O지성/S민감성/N비색소성/T탄력성",
    img: require("@assets/images/mbti/mbti-6.jpg"),
    tag: "#홍조 #민감성 #얼굴열감",
    description:
      "번들거리고 민감해요. 색소침착이 잘 되지 않고 주름도 비교적 덜 생기지만, 여드름이 나기 쉬워요.\n얼굴 열감때문에 홍조가 늘 있어요. 조금만 긴장하거나 더워져도 얼굴이 홍당무처럼 빨개져요.\n유수분 밸런스를 잘 맞춰주지 않으면 겉은 기름지면서 속건조는 심해지는 현상이 나타나요.\n얼굴 열감을 내려줄 수 있는 제품 위주로 사용하시는 걸 추천해요.",
    recommend: [
      {
        id: 46,
        title: "엑셀V레이저",
        description:
          "홍조에 특히 효과적인 레이저 시술이에요. 세 가지 모드의 파장을 이용해 피부 탄력을 되살려주고 피부결 개선에도 도움이 돼요!",
      },
      {
        id: 28,
        title: "리쥬란힐러",
        description:
          "스킨 부스터의 끝판왕이라 불리는 시술이에요. 모공 축소 효과와 함께 전체적인 피부톤 개선에 도움이 되는 시술이에요!",
      },
    ],
  },
  {
    no: 7,
    type: "OSPW",
    title: "내 피부가 말아주는\n트러블 종합세트",
    typeDesc: "O지성/S민감성/P색소성/W주름성",
    img: require("@assets/images/mbti/mbti-7.jpg"),
    tag: "#트러블종합세트 #번들거림 #화장들뜸",
    description:
      "기름지고 민감한데다 주름이나 색소침착까지 잘 생기는 피부예요.\n관리 난이도가 최상인 피부 유형이에요. 조금만 잘못 관리해도 피부가 뒤집혀요.\n나에게 딱 맞는 화장품 찾기가 쉽지 않아서, 화장품유목민이에요.\n자외선 차단은 필수예요! 과한 피지 분비 및 잡티 생성을 막을 수 있도록 자외선 차단제를 꼭 써주세요.",
    recommend: [
      {
        id: 8,
        title: "티타늄 리프팅",
        description:
          "3가지 파장으로 즉각적인 리프팅 효과뿐만 아니라 색소침착, 피부톤 개선 효과도 누릴 수 있어요. 전반적인 피부 고민을 한 번에 치료하고 싶으신 분들께 추천해요!",
      },
      {
        id: 30,
        title: "더마젠",
        description:
          "손상된 피부를 재생시키고 피부 장벽을 보호해주는 스킨부스터예요. 항균 항염 효과가 뛰어나요!",
      },
    ],
  },
  {
    no: 8,
    type: "OSNW",
    title: "우린 이걸 \n수부지라 부르기로 했어요",
    typeDesc: "O지성/S민감성/N비색소성/W주름성",
    img: require("@assets/images/mbti/mbti-8.jpg"),
    tag: "#수부지 #울긋불긋 #속건조",
    description:
      "유분이 많고 민감한 피부예요. 색소침착에는 강하지만, 노화에 따라 주름이 생기기 쉬운 피부 유형이에요.\n수분이 부족하면 유분이 더 올라와서 겉은 기름지지만 속건조가 심해지는 수분부족형지성 피부예요.\n햇빛을 받으면 피부가 금방 울긋불긋해져요.\n저자극 제품들을 위주로 사용해 트러블을 잠재우고, 안티에이징 관리를 해주세요.",
    recommend: [
      {
        id: 43,
        title: "물톡스(PHA)필링",
        description:
          "경우에 따라 사전 상담이 필요하지만, 억지로 각질을 벗기지 않고 자극을 최소화해 민감성 피부에도 부작용이 적은 필링 시술이에요. 항산화 효과도 뛰어나요!",
      },
      {
        id: 15,
        title: "울쎄라",
        description:
          "강력한 리프팅 효과가 오래 지속되는 걸로 유명한 초음파 리프팅 시술이에요. 피부 표면의 손상이 거의 없는 것도 장점이죠.",
      },
    ],
  },
  {
    no: 9,
    type: "DRPT",
    title: "탱글한 내 피부!\nBut 기미잡티 만수르 같은",
    typeDesc: "D건성/R저항성/P색소성/T탄력성",
    img: require("@assets/images/mbti/mbti-9.jpg"),
    tag: "#건조함 #기미잡티 #맨들피부",
    description:
      "튼튼하고 주름이 잘 생기지 않아요. 하지만 색소침착에 약해 잡티가 생기기 쉬운 건성 피부예요.\n건조함과 잡티만 잘 잡아주면 좋은 피부를 오래 유지할 수 있어요.\n여드름은 잘 나지 않는 대신 주근깨가 생기기 쉽고, 관리하지 않으면 색소침착으로 얼굴이 얼룩덜룩해보일 수 있어요.\n자외선 차단제를 꼼꼼히 발라주고, 수분감이 충분한 케어 제품을 사용하시는 걸 추천 드려요!",
    recommend: [
      {
        id: 28,
        title: "리쥬란힐러",
        description:
          "스킨 부스터의 끝판왕이라 불리는 시술이에요. 피부 속부터 수분을 채워주고, 전반적인 피부톤을 개선하는데 도움이 돼요!",
      },
      {
        id: 42,
        title: "피코토닝",
        description:
          "색소침착으로 균일하지 않은 피부톤을 개선하는데 효과적인 레이저 시술이에요. 콜라겐 재생을 유도해 피부 탄력 효과까지 누릴 수 있어요.",
      },
    ],
  },
  {
    no: 10,
    type: "DRNT",
    title: "완벽한 거 축하해요.\n피부 축복이 끝이 없는",
    typeDesc: "D건성/R저항성/N비색소성/T탄력성",
    img: require("@assets/images/mbti/mbti-10.jpg"),
    tag: "#아기피부 #가끔건조 #깐달걀",
    description:
      "축복 받았어요. 모두가 부러워하는 매끄럽고 깨끗한 피부를 가지셨군요!\n환절기나 겨울에 가끔 건조함을 느끼지만, 피부 탄력도 좋고 색소 침착이나 모공, 트러블 고민은 없어요.\n보습만 잘해준다면 큰 문제가 없는 피부예요.\n히알루론산 등 수분감 가득한 기초 제품을 사용하시는 걸 추천 드려요!",
    recommend: [
      {
        id: 33,
        title: "뉴라덤",
        description:
          "내 좋은 피부를 더 오래오래. 피부 수분을 꽉 채워주고 전반적인 탄력도 살려주는 스킨 부스터예요.",
      },
      {
        id: 18,
        title: "올리지오",
        description:
          "요즘 인기 있는 국산 고주파 리프팅 시술이에요. 피부 타이트닝 효과와 함께 피부결과 잔주름 개선에 도움이 돼요!",
      },
    ],
  },
  {
    no: 11,
    type: "DRPW",
    title: "각질도 줍줍\n잡티도 줍줍",
    typeDesc: "D건성/R저항성/P색소성/W주름성",
    img: require("@assets/images/mbti/mbti-11.jpg"),
    tag: "#극건성 #갈라짐 #색소침착",
    description:
      "민감한 피부는 아니지만 건조해서 각질이 잘 생기고, 주름이나 잡티가 생기기 쉬운 유형이에요.\n어릴 때 좋은 피부를 믿고 관리하지 않으면 훅 나빠질 수 있는 피부예요.\n찢어질 것 같은 건조함을 느낄 때가 많아요.\n수분 보충에 늘 신경 쓰고, 기미잡티가 생기지 않도록 자외선 차단제를 꼼꼼히 발라 주세요!",
    recommend: [
      {
        id: 28,
        title: "리쥬란힐러",
        description:
          "스킨 부스터의 끝판왕이라 불리는 시술이에요. 피부 속부터 수분을 채워주고, 전반적인 피부톤을 개선하는데 도움이 돼요!",
      },
      {
        id: 20,
        title: "인모드",
        description:
          "고주파를 이용한 안티에이징 리프팅 시술로 이중턱과 피부 탄력이 고민인 분들께 추천해요. 잔주름과 피부톤 개선에도 도움이 돼요!",
      },
    ],
  },
  {
    no: 12,
    type: "DRNW",
    title: "피부 폼 미쳤다\n(주름만 빼면)",
    typeDesc: "D건성/R저항성/N비색소성/W주름성",
    img: require("@assets/images/mbti/mbti-12.jpg"),
    tag: "#빠른노화 #주름조심 #건조함",
    description:
      "외부 자극에 크게 민감하지 않고, 균일하고 깨끗한 피부톤을 가진 건성 피부예요.\n노화가 시작되기 전까진 약간의 건조함 빼고는 별 문제가 없다고 느낄 거예요.\n나이가 들수록 급격히 피부 탄력이 떨어지는 피부 유형이에요.\n일찍부터 안티에이징 관리를 시작하는 걸 추천드려요!",
    recommend: [
      {
        id: 15,
        title: "울쎄라",
        description:
          "강력한 리프팅 효과가 오래 지속되는 걸로 유명한 초음파 리프팅 시술이에요. 피부 표면의 손상이 거의 없는 것도 장점이죠.",
      },
      {
        id: 33,
        title: "뉴라덤",
        description:
          "내 좋은 피부를 더 오래오래. 피부 수분을 꽉 채워주고 전반적인 탄력도 살려주는 스킨 부스터예요.",
      },
    ],
  },
  {
    no: 13,
    type: "DSPT",
    title: "건성, 트러블, 그리고 잡티",
    typeDesc: "D건성/S민감성/P색소성/T탄력성",
    img: require("@assets/images/mbti/mbti-13.jpg"),
    tag: "#잡티부자 #다크서클 #민감성",
    description:
      "건조하고 예민해요. 주름은 잘 생기지 않는 대신, 색소침착에 취약한 민감성 피부예요.\n피부톤이 균일하지 않아 얼룩덜룩해 보일 수 있고 다크서클도 잘 생겨요.\n외부 자극이나 계절 변화에 민감해서 얼굴이 자주 뒤집혀요.\n약산성 클렌저 등 자극이 덜한 제품 위주로 사용하시는 걸 추천 드려요!",
    recommend: [
      {
        id: 34,
        title: "LDM",
        description:
          "초음파 파장으로 피부 수분을 끌어올려 주고, 트러블이 자주 생기는 피부를 재생 시켜줘요. 피부 자극이 적어 민감성 피부를 가진 분들에게 추천하는 시술이에요!",
      },
      {
        id: 28,
        title: "리쥬란힐러",
        description:
          "스킨 부스터의 끝판왕이라 불리는 시술이에요. 피부 속부터 수분을 채워주고, 전반적인 피부톤을 개선하는데 도움이 돼요!",
      },
    ],
  },
  {
    no: 14,
    type: "DSNT",
    title: "신경 안 써? 비질거야!!\n금쪽이 같은 내 피부",
    typeDesc: "D건성/S민감성/N비색소성/T탄력성",
    img: require("@assets/images/mbti/mbti-14.jpg"),
    tag: "#각질부자 #극건성 #홍조",
    description:
      "수분이 부족하고 민감한 극건성 피부예요. 다행히 주름과 색소침착에는 강한 편이에요.\n따가움, 속당김, 간지러움 등 겪을 수 있는 피부 문제는 다 겪어요.\n 작은 자극에도 피부가 쉽게 붉어져요.\n 보습에 신경 써서 피부 장벽을 세우는 데 집중해야 해요. 물을 자주 섭취하는 습관을 들이는 걸 추천 드려요!",
    recommend: [
      {
        id: 31,
        title: "릴리이드",
        description:
          "피부 속부터 차오르는 히알루론산 성분의 스킨부스터예요. 수분을 꽉 채워주고 콜라겐 생성을 유도해 피부 장벽 세우는 걸 도와줘요.",
      },
      {
        id: 44,
        title: "오투덤",
        description:
          "고농도 산소로 피부 수분 장벽을 세워주고 피부 재생을 돕는 피부 관리예요. 자극적이지 않게 피부 보습 효과를 누릴 수 있어요.",
      },
    ],
  },
  {
    no: 15,
    type: "DSPW",
    title: "육아난이도 최상!\n잠잠한 날 없는 내 피부",
    typeDesc: "D건성/S민감성/P색소성/W주름성",
    img: require("@assets/images/mbti/mbti-15.jpg"),
    tag: "#트러블종합세트 #예민피부 #극건성",
    description:
      "건조하고 민감한데, 주름이나 색소침착까지 잘 생기는 피부예요.\nOSPW와 함께 관리 난이도가 최상인 피부 유형이에요. 조금만 잘못 관리해도 피부가 뒤집혀요.\n유수분 모두 부족해서 어떤 화장품을 써도 내 피부에 딱 맞는다는 느낌이 안 들어요.\n민감성 전용 제품 위주로 사용하고, 피부 자극을 최소화하는데 집중하는 게 중요해요.",
    recommend: [
      {
        id: 28,
        title: "리쥬란힐러",
        description:
          "스킨 부스터의 끝판왕이라 불리는 시술이에요. 피부 속부터 수분을 채워주고, 전반적인 피부톤을 개선하고 피부 윤기를 되찾는데 도움이 돼요!",
      },
      {
        id: 30,
        title: "더마젠",
        description:
          "트러블이 심각한 상태라면 더마젠을 고려해보세요. 손상된 피부를 재생시키고 피부 장벽을 보호해주는 스킨부스터예요. 항균 항염 효과가 뛰어나요!",
      },
    ],
  },
  {
    no: 16,
    type: "DSNW",
    title: "이거 맞아...?\n매일 새로운 트러블, 개복치 같은",
    typeDesc: "D건성/S민감성/N비색소성/W주름성",
    img: require("@assets/images/mbti/mbti-16.jpg"),
    tag: "#극민감성 #가려움 #따가움",
    description:
      "속건조가 심하고 민감해요. 잡티는 별로 없지만 주름이 생기기 쉬운 피부예요.\n피부결이 거칠고, 좁쌀 여드름이나 염증도 잘 생기는 편이에요.\n매일 피부 컨디션이 확 달라진다고 느낄만큼 외부 자극에 민감하게 반응해요.\n유분 또한 부족하므로, 유수분밸런스를 잘 잡아주는 기초 제품 위주로 사용하시는 걸 추천 드려요.",
    recommend: [
      {
        id: 8,
        title: "티타늄",
        description:
          "한 번의 시술로 피부톤 개선, 피부 타이트닝 및 리프팅 효과를 누릴 수 있어 추천 드려요. 적은 시술 횟수로 피부 자극을 줄일 수 있어요.",
      },
      {
        id: 32,
        title: "LDM",
        description:
          "초음파 파장으로 피부 수분을 끌어올려 주고, 트러블이 자주 생기는 피부를 재생 시켜줘요. 피부 자극이 적어 민감성 피부를 가진 분들에게 추천하는 시술이에요!",
      },
    ],
  },
];

export const mbtiImages = [
  require("@assets/images/mbti/mbti-c-1.png"),
  require("@assets/images/mbti/mbti-c-2.png"),
  require("@assets/images/mbti/mbti-c-3.png"),
  require("@assets/images/mbti/mbti-c-4.png"),
  require("@assets/images/mbti/mbti-c-5.png"),
  require("@assets/images/mbti/mbti-c-6.png"),
  require("@assets/images/mbti/mbti-c-7.png"),
  require("@assets/images/mbti/mbti-c-8.png"),
  require("@assets/images/mbti/mbti-c-9.png"),
  require("@assets/images/mbti/mbti-c-10.png"),
  require("@assets/images/mbti/mbti-c-11.png"),
  require("@assets/images/mbti/mbti-c-12.png"),
  require("@assets/images/mbti/mbti-c-13.png"),
  require("@assets/images/mbti/mbti-c-14.png"),
  require("@assets/images/mbti/mbti-c-15.png"),
  require("@assets/images/mbti/mbti-c-16.png"),
];
