import { Color } from "@src/constants";
import styled from "styled-components/native";
import { StyleSheet } from "react-native";
import MediaStyle from "react-native-media-query";

export const ContentsWrap = styled.View`
  height: ${({
    reply,
    img,
    isLoggedIn,
  }: {
    reply: boolean;
    img: boolean;
    isLoggedIn: boolean;
  }) =>
    isLoggedIn
      ? "calc(100% - 120px)"
      : img && reply
      ? "calc(100% - 216px)"
      : "calc(100% - 62px)"};
  background-color: ${Color.WHITE};
`;

export const CommunityListContainer = styled.View`
  padding: 16px 20px 0 20px;
  height: 100%;
  background-color: ${Color.WHITE};
`;

export const CommunityListInner = styled.TouchableOpacity`
  flex-direction: row;
`;

export const HeaderContents = styled.View``;

export const TitleHeader = styled.View`
  padding-bottom: 20px;
  border-bottom-width: 1px;
  border-bottom-color: ${Color.LIGHT_GREY_FIVE};
`;

export const Category = styled.View`
  width: fit-content;
  height: 18px;
  padding: 2px 8px;
  border-radius: 9px;
  background-color: ${Color.LIGHT_PUPPLE};
`;

export const CategoryText = styled.Text`
  font-size: 10px;
  font-family: "fontM";
  color: ${Color.PUPPLE};
`;

export const Title = styled.View`
  margin-top: 5px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const TitleText = styled.Text`
  width: calc(100% - 52px);
  text-overflow: ellipsis;
  max-height: 48px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 16px;
  font-family: "fontM";
  line-height: 24px;
`;

export const NoData = styled.View`
  margin-bottom: 78px;
  align-items: center;
`;

export const NoDataText = styled.Text`
  font-size: 14px;
  line-height: 20px;
  text-align: center;
`;

export const CommentContents = styled.View`
  margin-top: 77px;
`;

export const CommentItems = styled.View`
  margin-top: 30px;
`;

export const CommentItem = styled.View`
  align-items: center;
  margin-bottom: 40px;
  margin-left: ${({ comment }: { comment: boolean }) =>
    comment ? "40px" : ""};
`;

export const CommentImage = styled.Image`
  width: 36px;
  height: 36px;
  position: relative;
`;

export const CommentText = styled.Text`
  line-height: 20px;
`;

export const CommentItemInner = styled.View`
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const CommentWriter = styled.View`
  width: fit-content;
  padding: 2px 7px;
  border-radius: 16px;
  justify-content: center;
  background-color: ${Color.BLACK};
`;

export const CommentNicknameContents = styled.View`
  margin-top: ${({ type }: { type: number }) => (type === 2 ? "-6px" : "")};
  height: 16px;
`;

export const CommentNickname = styled.Text`
  display: ${({ type }: { type: number }) => (type === 2 ? "none" : "")};
`;

export const CommentMore = styled.TouchableOpacity``;

export const Comment = styled.View`
  width: 100%;
  padding-top: 10px;
  padding-left: 40px;
`;

export const CommentInputImg = styled.Image`
  width: 80px;
  height: 80px;
  border-radius: 8px;
`;

export const CommentImgView = styled.View`
  position: relative;
  width: 92px;
  padding: 10px 0 20px 0;
`;

export const ImgDelete = styled.TouchableOpacity`
  position: absolute;
  top: 0;
  right: 0;
`;

export const ImgDeleteIcon = styled.Image`
  width: 24px;
  height: 24px;
`;

export const ReplyContents = styled.View`
  width: 100%;
  padding-left: 40px;
`;

export const ReplyInner = styled.View`
  margin-top: 25px;
`;

export const Reply = styled.View`
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const ReplyComment = styled.View`
  width: 100%;
  padding-top: 10px;
  padding-left: 40px;
`;

export const ReplyBtn = styled.TouchableOpacity`
  margin-top: 10px;
  width: fit-content;
`;

export const ReplyBtnText = styled.Text`
  font-size: 12px;
  font-family: "fontM";
  color: ${Color.LIGHT_GREY_FOUR};
`;

export const Info = styled.View`
  flex-direction: row;
  margin-top: 16px;
  width: 100%;
`;

export const CountText = styled.Text`
  margin: 0 8px;
`;

export const NicknameText = styled.Text`
  margin-bottom: 4px;
`;

export const ImgContents = styled.View`
  height: 108px;
  margin-right: 16px;
`;

export const LikeContents = styled.View`
  margin-top: 40px;
  justify-content: center;
  align-items: center;
`;

export const LikeBtn = styled.TouchableOpacity``;

export const LikeImage = styled.Image`
  margin-bottom: 4px;
  width: 32px;
  height: 32px;
`;

export const LikeCount = styled.Text`
  color: ${({ like }: { like: boolean }) => (like ? Color.PUPPLE : "#c6c6c6")};
`;

export const DetailContents = styled.View`
  padding-top: 24px;
  width: 100%;
`;

export const DetailImage = styled.Image`
  width: 100%;
  border-radius: 16px;
  margin-bottom: 16px;
`;

export const DetailContentsInner = styled.View`
  width: 100%;
  height: 100%;
  margin-bottom: 16px;
`;

export const DetailContentsText = styled.Text`
  line-height: 23px;
  font-size: 16px;
`;

export const BottomCommentContents = styled.View`
  position: fixed;
  bottom: 0;
  min-height: ${({ reply, img }: { reply?: boolean; img }) =>
    reply && !img
      ? "106px"
      : "82px" && img && reply && "216px" && img && !reply && "192px"};
  width: 100%;
  padding: 10px 20px 24px 20px;
  background-color: ${Color.WHITE};
  box-shadow: rgba(100, 50, 233, 0.08) 0 0 16px;
`;

export const BottomCommentInner = styled.View`
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

export const AttachIcon = styled.Image`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

export const BottomCommentBtn = styled.TouchableOpacity`
  margin-left: 8px;
`;

export const BottomCommentBtnText = styled.Text`
  font-size: 16px;
  font-family: "fontM";
  color: ${({ active }: { active: boolean }) =>
    active ? Color.PUPPLE : Color.LIGHT_GREY_FOUR};
`;

export const BottomCommentInput = styled.TextInput`
  width: 100%;
  height: ${({ reply }: { reply: boolean }) => (reply ? "72px" : "48px")};
  margin-left: 10px;
  padding: ${({ reply }: { reply: boolean }) =>
    reply ? "35px 16px 13px 16px" : "11px 16px 13px 16px"};
  border-radius: 24px;
  line-height: 24px;
  font-size: 16px;
  outline-color: transparent;
  outline-width: 0;
  outline-style: none;
  background-color: ${Color.GREY};
`;

export const BottomCommentNickname = styled.Text`
  position: absolute;
  top: 15px;
  left: 50px;
  font-size: 14px;
  font-family: "fontM";
  color: ${Color.PUPPLE};
`;

export const MoreContents = styled.View`
  width: 100%;
  align-items: center;
  height: 56px;
`;

export const MoreBtn = styled.TouchableOpacity`
  width: fit-content;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const GrayBox = styled.View`
  text-align: center;
  background-color: ${Color.GREY};
  padding: 12px 42px 14px 42px;
  border-radius: 8px;
`;

export const GrayBoxLogin = styled.TouchableOpacity``;

export const itemStyles = StyleSheet.create({
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  whiteText: {
    color: Color.WHITE,
  },
  puppleText: {
    color: Color.PUPPLE,
  },
  xxsText: {
    fontSize: 10,
    fontWeight: "500",
  },
  xsText: {
    fontSize: 12,
  },
  sText: {
    fontSize: 13,
    fontWeight: "500",
  },
  smText: {
    fontSize: 14,
    fontWeight: "500",
  },
  mdText: {
    fontSize: 16,
    fontWeight: "500",
  },
  lgText: {
    fontSize: 20,
    fontWeight: "bold",
  },
  xlText: {
    fontSize: 24,
    fontWeight: "bold",
  },
  lineHeight: {
    lineHeight: 20,
  },
  pupple: {
    color: Color.PUPPLE,
  },
  bold: {
    fontFamily: "fontB",
  },
  gray: {
    color: Color.LIGHT_GREY_TWO,
  },
  gray2: {
    color: Color.LIGHT_GREY_FOUR,
  },
  flexRow: {
    flexDirection: "row",
  },
  alignCenter: {
    alignItems: "center",
  },
  image: {
    width: 60,
    height: 60,
  },
  profileImg: {
    width: 36,
    height: 36,
    borderRadius: 50,
  },
  smProfileImg: {
    width: 32,
    height: 32,
    borderRadius: 50,
  },
  nickname: {
    marginLeft: 8,
  },
  icon: {
    width: 24,
    height: 24,
  },
  time: {
    marginTop: 2,
  },
  fileInput: {
    position: "absolute",
    width: 24,
    height: 24,
    top: 13,
    opacity: 0,
    padding: 0,
    margin: -1,
    overflow: "hidden",
  },
  text: {
    fontSize: 14,
    fontFamily: "fontR",
  },
  grayBoxText: {
    fontSize: 12,
    fontFamily: "fontR",
    letterSpacing: -0.3,
    textAlign: "center",
    color: Color.LIGHT_GREY_TWO,
  },
  moreText: {
    fontSize: 14,
    fontWeight: "500",
    color: Color.LIGHT_GREY_FOUR,
  },
  commentImage: {
    width: 160,
    height: 160,
    borderRadius: 8,
    marginBottom: 8,
    objectFit: "cover",
  },
  contentsImage: {
    borderRadius: 16,
    marginBottom: 16,
  },
});

export const { ids, styles } = MediaStyle.create({});
