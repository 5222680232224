import React, { useState } from "react";
import { View, StatusBar, Text, ScrollView, FlatList } from "react-native";
import { Helmet } from "react-helmet-async";
import DraggableFlatList from "react-native-draggable-flatlist";
import { useNavigation } from "@react-navigation/native";

import { Layouts } from "@src/components/container";
import {
  CommentInfo,
  CommentInfoText,
  Dot,
  HighlightBg,
  NoticeHighlight,
  NoticeHighlightIcon,
  NoticeHighlightText,
  PlanMakingListAddBtn,
  PlanMakingListAddBtnContents,
  planStyles,
  SkinPlanContainer,
  SkinPlanEdit,
  SkinPlanEditText,
  SkinPlanMakingComment,
  SkinPlanMakingContents,
  SkinPlanMakingHeader,
} from "../styled";
import { globalStyles } from "@src/styles/modules/global";
import commentIcon from "@assets/icons/icon-comment.png";
import {
  planMakingList,
  planMakingTip,
  planMakingTopComment,
} from "../configs";
import {
  BottomFixButton,
  PlanMakingList,
} from "@src/components/presentational";

/**
 * 내 피부 플랜 만들기
 */

const SkinPlanMakingScreen = () => {
  const navigation = useNavigation<any>();

  const [data, setData] = useState<any>(planMakingList);
  const [order, setOrder] = useState<boolean>(false);

  const renderItemList = (item) => {
    return (
      <PlanMakingList
        data={item}
        orderMode={order}
        listUpdate={() => navigation.push("search")}
      />
    );
  };

  const renderHeader = () => {
    return (
      <SkinPlanMakingHeader>
        <SkinPlanMakingComment>
          <NoticeHighlight>
            <NoticeHighlightIcon source={commentIcon} resizeMode="contain" />
            <HighlightBg style={globalStyles.highlight} />
            <NoticeHighlightText style={globalStyles.fontB}>
              {planMakingTip}
            </NoticeHighlightText>
          </NoticeHighlight>
          {planMakingTopComment.split("\n").map((item, index) => (
            <CommentInfo key={index}>
              <Dot style={[globalStyles.dot, planStyles.commentDot]} />
              <CommentInfoText key={index} style={globalStyles.fontM}>
                {item}
              </CommentInfoText>
            </CommentInfo>
          ))}
        </SkinPlanMakingComment>
      </SkinPlanMakingHeader>
    );
  };

  return (
    <>
      <StatusBar barStyle="light-content" backgroundColor="#6432e9" />
      <Helmet>
        <title>내 피부 플랜 만들기 | 촉촉박사</title>
      </Helmet>
      <Layouts logo hamburgerMode transparent>
        <View style={[planStyles.planHeader, planStyles.header]}>
          <Text style={planStyles.mLText}>내 피부 플랜 만들기</Text>
        </View>
        <ScrollView
          bounces={false}
          scrollEventThrottle={1}
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{ flexGrow: 1 }}
        >
          <SkinPlanContainer>
            {renderHeader()}
            <SkinPlanMakingContents>
              <SkinPlanEdit onPress={() => setOrder(!order)} activeOpacity={1}>
                <SkinPlanEditText orderMode={order}>순서 변경</SkinPlanEditText>
              </SkinPlanEdit>

              {order ? (
                <DraggableFlatList
                  keyExtractor={(item, index) => `key${index}`}
                  data={data}
                  showsVerticalScrollIndicator={false}
                  bounces={false}
                  onDragEnd={({ data }) => setData(data)}
                  renderItem={renderItemList}
                />
              ) : (
                <FlatList
                  keyExtractor={(item, index) => `key${index}`}
                  data={data}
                  renderItem={renderItemList}
                />
              )}

              {!order && data.length < 3 && (
                <PlanMakingListAddBtnContents>
                  <PlanMakingListAddBtn
                    activeOpacity={1}
                    onPress={() => navigation.navigate("search")}
                  >
                    <Text style={[planStyles.mdTextB, planStyles.puppleText]}>
                      시술 추가하기
                    </Text>
                  </PlanMakingListAddBtn>
                </PlanMakingListAddBtnContents>
              )}
            </SkinPlanMakingContents>
          </SkinPlanContainer>
        </ScrollView>
        {order ? (
          <BottomFixButton
            multipleBtnMode
            leftBtnText="취소하기"
            rightBtnText="완료"
          />
        ) : (
          <BottomFixButton fullBtnMode fullBtnText="피부 플랜 저장하기" />
        )}
      </Layouts>
    </>
  );
};

export default SkinPlanMakingScreen;
