import { Color } from "@src/constants";
import styled from "styled-components/native";
import { StyleSheet } from "react-native";
import MediaStyle from "react-native-media-query";

export const Dimmed = styled.View`
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  position: ${({ isOpen }: { isOpen: boolean }) => (isOpen ? "fixed" : "")};
  background-color: ${({ isOpen }: { isOpen: boolean }) =>
    isOpen ? "rgba(0, 0, 0, 0.54)" : ""};
  z-index: ${({ isOpen }: { isOpen: boolean }) => (isOpen ? "1001" : "")};
`;

export const BottomPopupContainer = styled.View`
  position: relative;
  height: 100%;
  min-height: 100%;
  padding: 20px;
`;

export const BottomPopupHeader = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

export const CloseBtn = styled.TouchableHighlight`
  align-items: flex-end;
`;

export const CloseBtnIcon = styled.Image`
  width: 24px;
  height: 24px;
`;

export const HeaderTitleText = styled.Text`
  font-size: 20px;
  font-family: "fontB";
  letter-spacing: -0.5px;
`;

export const HighlightInfoText = styled.Text`
  font-size: 20px;
  font-family: "fontB";
  letter-spacing: -0.5px;
`;

export const BottomPopupContents = styled.View`
  align-items: center;
`;

export const TitleText = styled.Text`
  font-size: 24px;
  font-family: "fontB";
`;

export const InfoText = styled.Text`
  margin: 20px 0;
  font-size: 16px;
  font-family: "fontM";
  letter-spacing: -0.4px;
  line-height: 1.5em;
  text-align: center;
`;

export const HighlightText = styled.Text`
  font-size: 16px;
  font-family: "fontB";
  letter-spacing: -0.4px;
  line-height: 1.5em;
  text-align: center;
`;

export const Icon = styled.Image`
  width: 64px;
  height: 64px;
  margin-top: 30px;
`;

export const TextInput = styled.TextInput`
  width: 100%;
  height: 60px;
  padding: 17px 0 19px 16px;
  font-size: 16px;
  border-radius: 16px;
  border: 1px solid ${Color.LIGHT_GREY_FIVE};
  outline-color: transparent;
  outline-width: 0;
  outline-style: none;
`;

export const BottomBtnContents = styled.View`
  width: 100%;
  padding-top: 39px;
`;

export const BottomBtn = styled.TouchableOpacity`
  width: 100%;
  height: 52px;
  border-radius: 26px;
  align-items: center;
  justify-content: center;
  background-color: ${Color.PUPPLE};
`;

export const BottomBtnText = styled.Text`
  font-size: 16px;
  font-family: "fontM";
  color: ${Color.WHITE};
`;

export const itemStyles = StyleSheet.create({
  image: {
    width: 96,
    height: 96,
    borderRadius: 12,
    marginRight: 12,
  },
  icon: {
    width: 24,
    height: 24,
  },
  title: {
    fontSize: 20,
    color: Color.PUPPLE,
    fontFamily: "fontB",
    lineHeight: 32,
  },
  desc: {
    fontSize: 14,
    fontFamily: "fontM",
    letterSpacing: -0.35,
    lineHeight: 20,
  },
});

export const { ids, styles } = MediaStyle.create({
  popup: {
    position: "absolute",
    width: "100%",
    maxWidth: 480,
    maxHeight: 420,
    minHeight: 349,
    marginInline: "calc(50vw - 240px)",
    backgroundColor: Color.WHITE,
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    bottom: 0,
    transition: "top 0.6s cubic-bezier(0.165, 0.84, 0.44, 1)",
    zIndex: 1001,
    "@media (max-width: 480px)": {
      marginInline: 0,
    },
  },
});
