import React, { useEffect, useRef, useState } from "react";
import { StatusBar, ScrollView, Dimensions } from "react-native";
import { Helmet } from "react-helmet-async";
import { useRecoilState } from "recoil";
import { useRoute } from "@react-navigation/native";
import Toast from "react-native-fast-toast";
import { useNavigation } from "@react-navigation/native";

import { mbtiResultState } from "@src/store";
import { Layouts } from "@src/components/container";
import {
  SkinMbtiContainer,
  SkinMbtiResultContents,
  SkinMbtiResultContentsInner,
} from "../styled";
import {
  BottomPopup,
  Maybe,
  MbtiItem,
  MbtiPopup,
} from "@src/components/presentational";
import { useAuth } from "@src/hooks/auth";
import {
  mbtiPopupBtnText,
  mbtiPopupHighlightInfo,
  mbtiPopupInfo,
  mbtiPopupTitle,
  mbtiSaveText,
} from "@src/screens/sampleDatas";
import { globalStyles } from "@src/styles/modules/global";
import noticeImg from "@assets/images/mbti/mbti-notice-img01.png";
import { useRedirectUrl } from "@src/hooks/redirect";
import { analytics } from "@src/hooks/analytics";

/**
 * 피부 MBTI 결과
 */

const SkinMbtiResultScreen = () => {
  const navigation = useNavigation<any>();
  const route = useRoute<any>();

  const type = route.params?.type;
  const state = Number(route.params?.state);

  const { isLoggedIn } = useAuth();
  const { loginUrl } = useRedirectUrl();

  const { height } = Dimensions.get("window");
  const centerToastRef = useRef(null);

  const [mbtiResult, setMbtiResult] = useRecoilState(mbtiResultState);
  const [isVisibleAllType, setIsVisibleAllType] = useState<boolean>(false);
  const [isVisiblePopup, setIsVisiblePopup] = useState<boolean>(false);

  const handleLogin = () => {
    window.location.href = loginUrl;
  };

  useEffect(() => {
    if (isLoggedIn && state === 1) {
      centerToastRef.current.show(mbtiSaveText);
    } else if (!isLoggedIn && state === 1) {
      setIsVisiblePopup(true);
    }
  }, [isLoggedIn, state]);

  useEffect(() => {
    if (state === 0) {
      setIsVisiblePopup(false);
    }
  }, [state]);

  return (
    <>
      <StatusBar barStyle="light-content" backgroundColor="#6432e9" />
      <Helmet>
        <title>피부 MBTI 결과 | 촉촉박사</title>
        <meta
          name="description"
          content="촉촉한 피부, 나만 모르고 있던 시술 정보가 궁금하다면? | 촉촉박사"
          data-react-helmet="true"
        />
      </Helmet>
      <Layouts logo hamburgerMode>
        <ScrollView
          bounces={false}
          scrollEventThrottle={1}
          contentContainerStyle={{ flexGrow: 1 }}
          showsVerticalScrollIndicator={false}
        >
          <SkinMbtiContainer>
            <SkinMbtiResultContents>
              <SkinMbtiResultContentsInner>
                <MbtiItem
                  type={type ? type : mbtiResult}
                  onClick={() => {
                    analytics("Skin Mbti All Type", "");
                    setIsVisibleAllType(true);
                  }}
                  onClickNext={() =>
                    navigation.push("skinPlanQuestion", { id: 1 })
                  }
                />
              </SkinMbtiResultContentsInner>
            </SkinMbtiResultContents>
          </SkinMbtiContainer>
        </ScrollView>
      </Layouts>
      <Maybe
        test={isVisibleAllType}
        children={
          <MbtiPopup
            isOpen={isVisibleAllType}
            onClose={() => setIsVisibleAllType(false)}
          />
        }
      />
      <Maybe
        test={isVisiblePopup}
        children={
          <BottomPopup
            isOpen={isVisiblePopup}
            title={mbtiPopupTitle}
            info={mbtiPopupInfo}
            highlightInfo={mbtiPopupHighlightInfo}
            icon={noticeImg}
            btnText={mbtiPopupBtnText}
            onClick={handleLogin}
            onClose={() => {
              analytics("Skin Mbti Modal Close", "");
              setIsVisiblePopup(false);
            }}
          />
        }
      />
      <Toast
        ref={centerToastRef}
        offset={height / 2}
        duration={3000}
        style={globalStyles.centerToast}
        textStyle={globalStyles.centerToastText}
      />
    </>
  );
};

export default SkinMbtiResultScreen;
