import React from "react";
import { View, StatusBar, Text, ScrollView } from "react-native";
import { Helmet } from "react-helmet-async";
import { useRoute } from "@react-navigation/native";

import { Layouts } from "@src/components/container";
import { planStyles, SkinPlanContainer, SkinPlanHeader } from "../styled";
import { PlanRecommendList } from "@src/components/presentational";
import { planRecommendComment, planRecommendCommentTitle } from "../configs";
import { SkinPlanResultList } from "@src/store";
import { useRecoilState } from "recoil";

/**
 * 추천 피부 플랜
 */

const SkinPlanRecommendScreen = () => {
  const route = useRoute<any>();
  const id = route.params?.id;

  const [planResultList, setPlanResultList] =
    useRecoilState(SkinPlanResultList);

  const renderContents = () => (
    <PlanRecommendList
      data={planResultList && planResultList[id]}
      highlightText={planRecommendCommentTitle}
      comment={planRecommendComment}
      price={planResultList && planResultList[id].totalPriceForPlanner}
    />
  );

  const renderHeader = () => {
    return (
      <SkinPlanHeader>
        <View style={[planStyles.planHeader, planStyles.whiteBg]}>
          <Text style={planStyles.mLText}>추천 피부 플랜</Text>
        </View>
        <View style={planStyles.planIntro}>
          <Text style={[planStyles.highlightText, planStyles.mmlText]}>
            {planResultList && planResultList[id]?.sortNo === 0
              ? "A"
              : planResultList && planResultList[id]?.sortNo === 1
              ? "B"
              : "C"}{" "}
            플랜
          </Text>
          <Text
            style={[
              planStyles.mmdText,
              planStyles.fontBold,
              planStyles.marginTop8,
            ]}
          >
            {planResultList && planResultList[id].subject}
          </Text>
        </View>
      </SkinPlanHeader>
    );
  };

  return (
    <>
      <StatusBar barStyle="light-content" backgroundColor="#6432e9" />
      <Helmet>
        <title>추천 피부 플랜 | 촉촉박사</title>
      </Helmet>
      <Layouts logo hamburgerMode transparent>
        <ScrollView
          bounces={false}
          scrollEventThrottle={1}
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{ flexGrow: 1 }}
        >
          <SkinPlanContainer>
            <View style={planStyles.header}>{renderHeader()}</View>
            {renderContents()}
          </SkinPlanContainer>
        </ScrollView>
      </Layouts>
    </>
  );
};

export default SkinPlanRecommendScreen;
