import React, { ReactElement, useCallback, useState } from "react";
import { ScrollView, StatusBar, Text, Image, View } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { Helmet } from "react-helmet-async";
import DropdownSelect from "react-native-input-select";
import { Layouts } from "@src/components/container";
import {
  CommentInput,
  MypageCommonContents,
  MypageCommonInfoText,
  MypageCommonTitle,
  MypageCommonTitleText,
  MypageCommonTopContainer,
  styles,
} from "./styled";
import { BottomFixButton } from "@src/components/presentational";
import { withdrawalReasonList } from "../sampleDatas";
import dropdownIcon from "@assets/icons/icon-dropdown.svg";
import { Color } from "@src/constants";
import { useRecoilState } from "recoil";
import {
  withdrawalCauseSelectState,
  withdrawalCauseInputState,
} from "@src/store";

/**
 * 회원 탈퇴
 */

const WithdrawalScreen = (): ReactElement => {
  const navigation = useNavigation<any>();
  const inputLength = 10;

  const [selectedValue, setSelectedValue] = useRecoilState(
    withdrawalCauseSelectState
  );
  const [inputText, setInputText] = useRecoilState(withdrawalCauseInputState);
  const [disabled, setDisabled] = useState<boolean>(true);

  const handleDropdownSelect = useCallback(
    (value) => {
      setDisabled(false);

      if (value === "직접 입력") {
        setDisabled(true);
      }

      setSelectedValue(value);
    },
    [selectedValue]
  );

  const handleChangeInputText = (value) => {
    setInputText(value);

    if (!value) {
      setDisabled(true);
    }

    if (value.length >= inputLength) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  return (
    <>
      <StatusBar barStyle="light-content" backgroundColor="#6432e9" />
      <Helmet>
        <title>회원 탈퇴 | 촉촉박사</title>
        <meta
          name="description"
          content="촉촉한 피부, 나만 모르고 있던 시술 정보가 궁금하다면? | 촉촉박사"
          data-react-helmet="true"
        />
      </Helmet>
      <Layouts logo transparent hamburgerMode>
        <MypageCommonTopContainer>
          <MypageCommonTitle>
            <MypageCommonTitleText>
              촉촉박사를
              <br />
              <Text style={styles.highlight}>탈퇴</Text>하시나요?
            </MypageCommonTitleText>
          </MypageCommonTitle>
          <MypageCommonInfoText>
            탈퇴하시는 이유를 알려주세요.
            <br />
            의견을 수렴하여 보다 나은 서비스를 위해 노력할게요.
          </MypageCommonInfoText>
        </MypageCommonTopContainer>
        <ScrollView
          bounces={false}
          scrollEventThrottle={1}
          contentContainerStyle={{ flexGrow: 1 }}
          showsVerticalScrollIndicator={false}
        >
          <MypageCommonContents>
            <DropdownSelect
              placeholder="선택해 주세요."
              options={withdrawalReasonList}
              optionLabel={"name"}
              optionValue={"name"}
              selectedValue={selectedValue}
              onValueChange={(value) => {
                handleDropdownSelect(value);
              }}
              checkboxStyle={{ display: "none" }}
              dropdownStyle={
                selectedValue !== "직접 입력"
                  ? styles.select
                  : styles.inputSelect
              }
              placeholderStyle={styles.text}
              selectedItemStyle={styles.text}
              dropdownIcon={<Image source={dropdownIcon} style={styles.icon} />}
              listComponentStyles={{
                itemSeparatorStyle: {
                  height: 0,
                },
              }}
            />
            {selectedValue === "직접 입력" && (
              <CommentInput
                value={inputText}
                onChangeText={(value) => handleChangeInputText(value)}
                placeholder="내용을 입력해 주세요."
                maxLength={500}
                placeholderTextColor={Color.LIGHT_GREY_FOUR}
                multiline
              />
            )}
          </MypageCommonContents>
        </ScrollView>
        <View style={styles.bottomBtn}>
          <BottomFixButton
            multipleBtnMode
            leftBtnText="계속 이용하기"
            rightBtnText="다음으로"
            disabled={disabled}
            onPressLeftBtn={() => navigation.navigate("myinfo")}
            onPressRightBtn={() => navigation.navigate("withdrawalConfirm")}
          />
        </View>
      </Layouts>
    </>
  );
};

export default WithdrawalScreen;
