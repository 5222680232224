import { ReactElement, ReactNode } from "react";
import { View } from "react-native";
import { ids, styles } from "@src/styles/modules/global";
import { CommonHeader } from "@src/components/presentational";
import { AuthService } from "@src/services";
import { useRecoilState } from "recoil";
import { SkinPlanResultList } from "@src/store";
import { useRedirectUrl } from "@src/hooks/redirect";

/**
 * Header 관련 컴포넌트
 */

interface LayoutsProps {
  children: ReactElement | ReactNode;
  backMode?: boolean;
  cancelMode?: boolean;
  handleCancel?: () => void;
  transparent?: boolean;
  logo?: boolean;
  hamburgerMode?: boolean;
  mainMode?: boolean;
  titleMode?: boolean;
  title?: string;
  homeMode?: boolean;
  hiddenRight?: boolean;
  rightBtn?: boolean;
  rightText?: string;
  rightBtnIcon?: boolean;
  onClickRightBtn?: () => void;
  loginBtn?: boolean;
  handleLogin?: () => void;
  loginPopup?: () => void;
}

const Layouts = ({
  children,
  backMode,
  cancelMode,
  handleCancel,
  transparent,
  logo,
  hamburgerMode,
  mainMode,
  titleMode,
  title,
  homeMode,
  hiddenRight,
  rightBtn,
  rightText,
  rightBtnIcon,
  onClickRightBtn,
  loginPopup,
}: LayoutsProps) => {
  const [planResultList, setPlanResultList] =
    useRecoilState(SkinPlanResultList);

  const { loginUrl } = useRedirectUrl();

  const logout = async () => {
    try {
      setPlanResultList(null);
      await AuthService.signOut();
      window.location.href = "/";
    } catch (e) {
      console.log(e);
    }
  };

  const handleLogin = () => {
    window.location.href = loginUrl;
  };

  return (
    <View style={styles.container} dataSet={{ media: ids.container }}>
      <CommonHeader
        backMode={backMode}
        cancelMode={cancelMode}
        handleCancel={handleCancel}
        transparent={transparent}
        logo={logo}
        hamburgerMode={hamburgerMode}
        mainMode={mainMode}
        titleMode={titleMode}
        title={title}
        homeMode={homeMode}
        hiddenRight={hiddenRight}
        rightBtn={rightBtn}
        rightText={rightText}
        rightBtnIcon={rightBtnIcon}
        onClickRightBtn={onClickRightBtn}
        handleLogin={handleLogin}
        handleLogout={logout}
        loginPopup={loginPopup}
      />
      <>{children}</>
    </View>
  );
};

export default Layouts;
