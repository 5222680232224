import React, { ReactElement } from "react";
import { CheckBox, CheckBoxIcon, CheckBoxWrap } from "./styled";
import uncheckIcon from "@assets/icons/icon-check-outline.svg";
import checkedIcon from "@assets/icons/icon-check.svg";
import uncheckOutlineIcon from "@assets/icons/icon-check-outline2.svg";
import checkedOutlineIcon from "@assets/icons/icon-check2.svg";
import whiteCheckedIcon from "@assets/icons/icon-check-white.svg";
import { SwitchCase } from "..";

interface CircleCheckboxProps {
  isChecked?: boolean;
  onChangeCheck: (value) => void;
  outline?: boolean;
  white?: boolean;
  disabled?: boolean;
}

const CircleCheckbox = ({
  isChecked,
  onChangeCheck,
  outline,
  white,
  disabled,
}: CircleCheckboxProps): ReactElement => {
  return (
    <CheckBoxWrap
      activeOpacity={1}
      onPress={(e) => (!disabled ? onChangeCheck(!isChecked) : undefined)}
    >
      <CheckBox>
        <SwitchCase
          tests={[
            {
              test: outline,
              component: (
                <CheckBoxIcon
                  source={isChecked ? checkedOutlineIcon : uncheckOutlineIcon}
                />
              ),
            },
            {
              test: white,
              component: <CheckBoxIcon source={whiteCheckedIcon} />,
            },
          ]}
          defaultComponent={
            <CheckBoxIcon source={isChecked ? checkedIcon : uncheckIcon} />
          }
        />
      </CheckBox>
    </CheckBoxWrap>
  );
};

export default CircleCheckbox;
