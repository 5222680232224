import { LoginAsSocial } from "@src/screens";

/**
 * Login Configs
 */

export default [
  {
    name: "redirect",
    component: LoginAsSocial,
    options: {
      title: "",
    },
  },
];
