/**
 * 공통 코드
 */

export enum Color {
  WHITE = "#ffffff",
  WHITE_TWO = "#f4f2fe",
  WHITE_THREE = "#dddddd",
  WHITE_FOUR = "#e6e6e6",
  WHITE_SEVEN = "#efefef",
  WHITE_FIVE = "#f8f8f8",
  WHITE_SIX = "#fafafa",
  WHITE_EIGHT = "#e8e8e8",
  BLACK = "#000000",
  BLACK_TWO = "#282828",
  GREY = "#f8f8fa",
  DARK_GREY = "#aaaaaa",
  LIGHT_GREY = "#dddddd",
  LIGHT_GREY_TWO = "#808080",
  LIGHT_GREY_THREE = "#d6d9df",
  LIGHT_GREY_FOUR = "#c6c6c6",
  LIGHT_GREY_FIVE = "#e2e2e2",
  WARM_GREY = "#777777",
  WARM_GREY_TWO = "#999999",
  WARM_GREY_THREE = "#888",
  GREYISH = "#aaaaaa",
  GREYISH_BROWN = "#444444",
  BLUE = "#44bbcd",
  NAVY = "#200074",
  PUPPLE = "#6432e9",
  PUPPLE_TWO = "#572cc9",
  LIGHT_PUPPLE = "#e5e3fd",
  LIGHT_PUPPLE_TWO = "#6432e9",
  LIGHT_PUPPLE_THREE = "#6432e9",
  LIGHT_PUPPLE_FOUR = "#9c85f7",
  LIGHT_PUPPLE_FIVE = "#f4f2fe",
  LIGHT_PUPPLE_SIX = "#e0d8ff",
  LIGHT_PUPPLE_SEVEN = "#efeefe",
  PINK = "#f9dfe9",
  PINK_TWO = "#ee80a6",
  PINKISHGREY = "#cccccc",
  RED = "#fc5f6b",
  RED_PINK = "#f33568",
  TOMATO = "#e82121",
  TOMATO_TWO = "#e6452f",
  BRIGHT_MAGENTA = "#fe2aff",
  BRIGHT_MAGENTA_TWO = "#fe00ff",
  PURPLISH_BLUE = "#5b30ff",
  PURPLISH_GREY = "#ccc",
  CERULEAN = "#0075db",
  BROWNISH_GREY = "#666666",
  AZUL = "#1F63EC",
  AZUL_BRIGHT_MAGENTA = "#9046f5",
}

export enum Sizes {
  XS = "xs",
  SM = "sm",
  MD = "md",
  LG = "lg",
  XL = "xl",
}

export const HEADER_HEIGHT = 60;
