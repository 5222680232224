import { Color } from "@src/constants";
import styled from "styled-components/native";

export const SurgeryListWrap = styled.View`
  padding: 0 20px;
`;

export const SurgeryListContents = styled.View``;

export const SurgeryList = styled.TouchableOpacity`
  min-height: 200px;
  padding: 16px 16px 23px 16px;
  margin-bottom: 20px;
  border-radius: 24px;
  box-shadow: rgba(100, 50, 233, 0.08) 0 0 16px;
  background-color: ${Color.WHITE};
`;

export const SurgeryListHeader = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const SurgeryTitleWrap = styled.View`
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  max-width: 85%;
`;

export const SurgeryTitle = styled.Text`
  margin-right: 10px;
  padding-bottom: 4px;
  font-family: "fontB";
  font-size: 18px;
  letter-spacing: -0.4px;
`;

export const SurgeryTagWrap = styled.View`
  flex-direction: row;
  flex-wrap: wrap;
  padding-bottom: 2px;
`;

export const SurgeryTag = styled.View`
  padding: 4px 10px;
  margin-right: 4px;
  background-color: ${({ bgColor }: { bgColor: number }) =>
    bgColor === 0 ? Color.PINK : Color.LIGHT_PUPPLE};
  border-radius: 10px;
`;

export const SurgeryTagText = styled.Text`
  font-size: 10px;
  font-family: "fontR";
  color: ${({ bgColor }: { bgColor: number }) =>
    bgColor === 0 ? Color.PINK_TWO : Color.LIGHT_PUPPLE_TWO};
`;

export const SurgeryContents = styled.View``;

export const SurgerySummaryWrap = styled.View`
  margin-top: 16px;
  flex-direction: row;
  justify-content: space-between;
`;

export const SurgerySummary = styled.View`
  width: calc(100% / 3);
  align-items: center;
`;

export const SurgerySummaryTitleText = styled.Text`
  font-size: 10px;
  font-family: "fontR";
  letter-spacing: -0.25px;
`;

export const SurgerySummaryIcon = styled.Image`
  width: 48px;
  height: 48px;
  margin: 10px 0;
`;

export const SurgerySummaryInfoText = styled.Text`
  font-size: 12px;
  font-family: "fontB";
  letter-spacing: -0.3px;
`;

export const SurgeryKeywordWrap = styled.View`
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 4px;
`;

export const SurgeryKeyword = styled.View`
  margin-right: 4px;
`;

export const SurgeryKeywordText = styled.Text`
  font-size: 14px;
  font-family: "fontR";
  letter-spacing: -0.35px;
  color: ${Color.LIGHT_GREY_TWO};
`;
