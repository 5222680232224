import React, { ReactElement } from "react";
import { ToggleButtonContainer, ToggleButtonItemText } from "./styled";
interface ToggleButtonProps {
  isChecked: boolean;
  onChangeCheck: (value) => void;
  text?: string;
}

const ToggleButton = ({
  isChecked,
  onChangeCheck,
  text,
}: ToggleButtonProps): ReactElement => {
  return (
    <ToggleButtonContainer
      activeOpacity={1}
      onPress={() => onChangeCheck(!isChecked)}
      selected={isChecked}
    >
      <ToggleButtonItemText selected={isChecked}>{text}</ToggleButtonItemText>
    </ToggleButtonContainer>
  );
};

export default ToggleButton;
