import styled from "styled-components/native";
import { StyleSheet } from "react-native";
import MediaStyle from "react-native-media-query";
import { Color } from "@src/constants";

export const EditTalkContainer = styled.View`
  height: calc(100% - 64px);
  background-color: ${Color.WHITE};
`;

export const SelectInputWrap = styled.View`
  padding: 20px 0 0 20px;
  margin-bottom: 10px;
`;

export const SelectInputBtn = styled.TouchableOpacity``;

export const SelectHeadInputBtn = styled.TouchableOpacity`
  width: calc(100% - 114px);
  justify-content: space-between;
`;

export const SelectInputBtnText = styled.Text`
  font-size: 14px;
  font-family: "fontM";
`;

export const SelectInputContainer = styled.View`
  flex-direction: row;
  align-items: center;
  padding: 16px 20px;
  background-color: ${Color.GREY};
`;

export const EditorContainer = styled.View`
  margin-top: 24px;
  padding: 0 20px 110px 20px;
`;

export const EditorTitle = styled.TextInput`
  width: 100%;
  padding-bottom: 18px;
  margin-bottom: 24px;
  border-bottom-width: 1px;
  border-bottom-color: ${Color.LIGHT_GREY_FIVE};
  font-size: 16px;
  outline-color: transparent;
  outline-width: 0;
  outline-style: none;
`;

export const EditorTextInputWrap = styled.View`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const EditorTextInput = styled.TextInput`
  width: 100%;
  height: auto;
  overflow-wrap: break-word;
  word-break: break-all;
  white-space: pre-wrap;
  font-size: 16px;
  outline-color: transparent;
  outline-width: 0;
  outline-style: none;
`;

export const EditorImage = styled.Image`
  margin-top: 10px;
  width: 100%;
  height: 100%;
`;

export const DeleteBtn = styled.TouchableOpacity`
  position: absolute;
  top: 10px;
  right: 10px;
`;

export const BottomContents = styled.View`
  position: absolute;
  bottom: 0;
  height: 64px;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  background-color: ${Color.WHITE};
`;

export const BottomLeftBtn = styled.TouchableOpacity`
  flex-direction: row;
  align-items: center;
`;

export const BottomRightBtn = styled.TouchableOpacity`
  flex-direction: row;
  align-items: center;
`;

export const AdminSelectList = styled.View`
  flex-direction: row;
  padding: 24px 20px 20px 20px;
`;

export const AdminSelectListItem = styled.View`
  flex-direction: row;
  align-items: center;
  margin-right: 24px;
`;

export const CheckBoxText = styled.Text`
  margin-left: 4px;
  font-size: 16px;
  font-family: "fontM";
`;

export const itemStyles = StyleSheet.create({
  whiteBg: {
    backgroundColor: Color.WHITE,
  },
  smText: {
    fontSize: 14,
    fontWeight: "500",
  },
  mdText: {
    lineHeight: 23,
    fontSize: 16,
  },
  mdTextB: {
    fontSize: 16,
    fontFamily: "fontB",
  },
  mLText: {
    fontSize: 18,
    fontFamily: "fontB",
  },
  lgText: {
    lineHeight: 33,
    fontSize: 24,
    fontFamily: "fontB",
  },
  alignCenter: {
    alignItems: "center",
  },
  spaceBetween: {
    justifyContent: "space-between",
  },
  puppleText: {
    color: Color.PUPPLE,
  },
  icon: {
    width: 20,
    height: 20,
    marginRight: 4,
  },
  selectInput: {
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: 16,
    paddingRight: 10,
    paddingTop: 10,
    paddingBottom: 12,
    marginRight: 8,
    borderRadius: 24,
    backgroundColor: Color.WHITE,
  },
  image: {
    width: "100%",
    height: "100%",
    borderRadius: 16,
  },
  video: {},
  bottomBtn: {
    position: "relative",
    width: "50%",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
  },
  bar: { borderRightColor: "#e0e0e0", borderRightWidth: 1, height: 16 },
  editItem: {
    position: "relative",
    marginBottom: 16,
  },
  deleteIcon: {
    width: 24,
    height: 24,
  },
});

export const { ids, styles, fullStyles } = MediaStyle.create({});
