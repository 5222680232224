/**
 * All Screens
 */

export const screens = {
  redirect: "redirect",
  surgeryList: "surgeryList",
  surgeryDetail: "surgeryDetail",
  comparison: "comparison",
  skinMbti: "skinMbti",
  skinMbtiDetail: "skinMbtiDetail",
  skinMbtiResult: "skinMbtiResult",
  mypage: "mypage",
  changeNickname: "changeNickname",
  privacyNotice: "privacyNotice",
  privacySetting: "privacySetting",
  myinfo: "myinfo",
  servicePolicy: "servicePolicy",
  privacyPolicy: "privacyPolicy",
  withdrawal: "withdrawal",
  withdrawalConfirm: "withdrawalConfirm",
  inactiveMember: "inactiveMember",
  withdrawalMember: "withdrawalMember",
  skinPlan: "skinPlan",
  mySkinPlan: "mySkinPlan",
  mySkinPlanDetail: "mySkinPlanDetail",
  skinPlanQuestion: "skinPlanQuestion",
  skinPlanRecommend: "skinPlanRecommend",
  rangeRecommend: "rangeRecommend",
  skinPlanMaking: "skinPlanMaking",
  community: "community",
  communityDetail: "communityDetail",
  communityReport: "communityReport",
  profile: "profile",
  myprofile: "myprofile",
  editTalk: "editTalk",
  updateTalk: "updateTalk",
  search: "search",
};
