/* eslint-disable @typescript-eslint/no-explicit-any */
import { SignInRequest, SignInResponse } from "@src/types";
import RestService from "../RestService";
import TokenService from "../TokenService";

/**
 * Auth API
 * @see https://
 */
class AuthService {
  /**
   * 사용자 로그인
   * @param {SignInRequest} credential 계정정보(username, password)
   * @return {Promise<SignInResponse>}
   */
  public login = async (credential: SignInRequest): Promise<any> => {
    const url: string = `/v1/user/me`;

    try {
      const { accessToken, refreshToken }: SignInResponse =
        (await RestService.post(url, credential, false)) as SignInResponse;

      if (accessToken) {
        await TokenService.setAccessToken(accessToken);
      }

      if (refreshToken) {
        await TokenService.setRefreshToken(refreshToken);
      }
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  };

  /**
   * 사용자 로그아웃
   * @return {Promise<any>}
   */
  public signOut = async (): Promise<any> => {
    const url: string = `/auth/token`;

    try {
      await RestService.delete(url);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    } finally {
      TokenService.cleanTokens();
    }
  };

  /**
   * 계정 상세 조회
   * @return {Promise<any>}
   */
  public getUserInfo = async (): Promise<any> => {
    const url: string = `/v1/user/me`;

    try {
      const { data } = await RestService.get(url);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  /**
   * user type에 따른 계정 상세 조회
   * @return {Promise<any>}
   */
  public getUserTypeInfo = async (type): Promise<any> => {
    const url: string = `/v1/user/me?req-type=${type}`;

    try {
      const { data } = await RestService.get(url);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

export default new AuthService();
