import React, { useEffect, useState } from "react";
import { View, StatusBar, Image, SafeAreaView } from "react-native";
import { Helmet } from "react-helmet-async";
import { useNavigation } from "@react-navigation/native";
import { Tabs } from "antd";

import { Layouts } from "@src/components/container";
import {
  itemStyles,
  SelectInputBtn,
  SelectInputBtnText,
  SelectInputWrap,
} from "./styled";
import { useAuth } from "@src/hooks/auth";
import {
  BottomFullButton,
  BottomPopup,
  BottomSelectItem,
  CommunityList,
  Maybe,
} from "@src/components/presentational";
import {
  popupBtn,
  popupIcon,
  popupInfo,
  popupSubInfo,
  popupTitle,
} from "../configs";
import { CommunityService } from "@src/services";
import { IForumPostList, PostCategory } from "@src/types";
import { ids, styles } from "@src/styles/modules/global";
import downIcon from "@assets/icons/icon-arrow-down.svg";
import { useRecoilState } from "recoil";
import { CommunityForumId } from "@src/store";
import { useRedirectUrl } from "@src/hooks/redirect";

/**
 * 커뮤니티 목록 페이지
 */

const CommunityListScreen = () => {
  const navigation = useNavigation<any>();

  const { isLoggedIn } = useAuth();
  const { loginUrl } = useRedirectUrl();

  const [isVisiblePopup, setIsVisiblePopup] = useState<boolean>(false);
  const [isVisibleInfoPopup, setIsVisibleInfoPopup] = useState<boolean>(false);

  const [data, setData] = useState<IForumPostList[]>();
  const [categoryList, setCategoryList] = useState<PostCategory[]>();
  const [categoryTitle, setCategoryTitle] = useState<string>("");
  const [postCategoryId, setPostCategoryId] = useState<number>(0);

  const [forumId, setForumId] = useRecoilState(CommunityForumId);
  const [keyword, setKeyword] = useState<string>("");
  const [offset, setOffset] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [last, setLast] = useState<boolean>(false);

  const renderCateogry = () => {
    return (
      <SelectInputWrap>
        <SelectInputBtn
          onPress={() => setIsVisiblePopup(!isVisiblePopup)}
          activeOpacity={1}
        >
          <SelectInputBtnText>
            {categoryTitle ? categoryTitle : "말머리 선택"}
          </SelectInputBtnText>
          <Image
            source={downIcon}
            resizeMode="contain"
            style={itemStyles.icon}
          />
        </SelectInputBtn>
      </SelectInputWrap>
    );
  };

  const getForumPostList = async (): Promise<void> => {
    try {
      const conditions = {
        forumId,
        postCategoryId,
        keyword,
        offset,
      };

      const data = await CommunityService.getForumPostList(conditions);
      if (offset > 0) {
        setData((prev) => prev.concat(data.content));
      } else {
        setData(data?.content);
      }

      setLast(data?.last);
      setTotalCount(data?.totalElements);
    } catch (error) {
      console.log(error);
    }
  };

  const getForumCategory = async (): Promise<void> => {
    try {
      const conditions = {
        forumId,
      };

      const data = await CommunityService.getForumCategory(conditions);
      setCategoryList(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCategory = (item) => {
    setCategoryTitle(item.title);
    setPostCategoryId(item.id);
  };

  const handleTalkEdit = () => {
    if (!isLoggedIn) {
      setIsVisibleInfoPopup(true);
    } else {
      navigation.push("editTalk");
    }
  };

  const handleChangeTabId = (id) => {
    setForumId(Number(id));
    setPostCategoryId(0);
    setCategoryTitle("");
  };

  const handleOffset = () => {
    setOffset((prev) => prev + 1);
  };

  useEffect(() => {
    getForumPostList();
  }, [forumId, postCategoryId, offset]);

  useEffect(() => {
    getForumCategory();
  }, [forumId]);

  const tabs = [
    {
      key: "1",
      label: "촉촉 토크",
      children: (
        <View
          dataSet={{ media: ids.paddingS }}
          style={[{ flex: 1 }, styles.paddingS]}
        >
          {renderCateogry()}
          <View style={itemStyles.contents}>
            <CommunityList
              data={data}
              onClickMore={handleOffset}
              totalCount={totalCount}
              last={last}
            />
          </View>
        </View>
      ),
    },
    {
      key: "2",
      label: "MBTI 토크",
      children: (
        <View
          dataSet={{ media: ids.paddingS }}
          style={[{ flex: 1 }, styles.paddingS]}
        >
          {renderCateogry()}
          <View style={itemStyles.contents}>
            <CommunityList
              data={data}
              onClickMore={handleOffset}
              totalCount={totalCount}
              last={last}
            />
          </View>
        </View>
      ),
    },
  ];

  return (
    <>
      <StatusBar barStyle="light-content" backgroundColor="#6432e9" />
      <Helmet>
        <title>커뮤니티 | 촉촉박사</title>
      </Helmet>
      <Layouts logo hamburgerMode transparent>
        <SafeAreaView style={{ flex: 1 }}>
          <View style={{ flex: 1 }}>
            <Tabs
              defaultActiveKey={String(forumId)}
              items={tabs}
              onChange={handleChangeTabId}
            />
          </View>
          <BottomFullButton
            leftBtnText="검색하기"
            rightBtnText="토크 쓰기"
            onPressLeftBtn={() =>
              navigation.push("search", { type: "community" })
            }
            onPressRightBtn={() => handleTalkEdit()}
            page="community"
          />
        </SafeAreaView>
      </Layouts>
      <Maybe
        test={isVisiblePopup}
        children={
          <BottomSelectItem
            isOpen={isVisiblePopup}
            data={categoryList}
            onClose={() => setIsVisiblePopup(false)}
            onChangeId={(item) => handleCategory(item)}
          />
        }
      />
      <Maybe
        test={isVisibleInfoPopup}
        children={
          <BottomPopup
            isOpen={isVisibleInfoPopup}
            onClose={() => setIsVisibleInfoPopup(false)}
            title={popupTitle}
            info={popupInfo}
            highlightInfo={popupSubInfo}
            icon={popupIcon}
            btnText={popupBtn}
            onClick={() => (window.location.href = loginUrl)}
          />
        }
      />
    </>
  );
};

export default CommunityListScreen;
