import { atom } from "recoil";

/**
 * 회원 Atoms
 */

// 성별
export const genderState = atom<string>({
  key: "genderState",
  default: "",
});

// 생년
export const birthYearState = atom<string>({
  key: "birthYearState",
  default: "",
});

// 회원탈퇴 사유 직접입력
export const withdrawalCauseInputState = atom<string>({
  key: "withdrawalCauseState",
  default: "",
});

// 회원탈퇴 사유 select
export const withdrawalCauseSelectState = atom<string>({
  key: "withdrawalCauseSelectState",
  default: "",
});
