import { GlobalSearchScreen } from "@src/screens";

/**
 * Search Configs
 */

export default [
  {
    name: "search",
    component: GlobalSearchScreen,
    options: {
      title: "",
    },
  },
];
