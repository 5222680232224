import { globalStyles } from "@src/styles/modules/global";
import React, { ReactElement } from "react";
import {
  CategoryItemWrap,
  CategoryItemInner,
  CategoryItemContents,
  CategoryBtn,
  CategoryText,
} from "./styled";

interface CategoryItemProps {
  categoryItems: any;
}

const CategoryItem = ({ categoryItems }: CategoryItemProps): ReactElement => {
  return (
    <CategoryItemWrap>
      <CategoryItemInner>
        {categoryItems.map((item: any, index: any) => (
          <CategoryItemContents key={index}>
            <CategoryBtn>
              <CategoryText style={globalStyles.fontB}>
                {item.name}
              </CategoryText>
            </CategoryBtn>
          </CategoryItemContents>
        ))}
      </CategoryItemInner>
    </CategoryItemWrap>
  );
};

export default CategoryItem;
