import styled from "styled-components/native";

export const SurgeryDetailContents = styled.View`
  position: relative;
`;

export const SurgeryDetailContentsInner = styled.View``;

export const SurgeryDetailTopBg = styled.View`
  padding: 10px 20px 0 20px;
  background-image: linear-gradient(
    to bottom,
    #fff,
    #fff 51%,
    rgba(255, 255, 255, 0)
  );
`;

export const SurgeryDetailSummary = styled.View`
  margin-bottom: 20px;
`;
