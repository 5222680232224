/**
 * 피부 시술 Datas
 */

export const tabItems = [
  {
    id: 0,
    title: "여드름/모공",
  },
  {
    id: 1,
    title: "리프팅",
  },
  {
    id: 2,
    title: "스킨부스터",
  },
  {
    id: 3,
    title: "스킨케어",
  },
  {
    id: 4,
    title: "필러",
  },
  {
    id: 5,
    title: "보톡스",
  },
  {
    id: 6,
    title: "스킨케어",
  },
  {
    id: 7,
    title: "레이저제모",
  },
];

export const notice =
  "트러블로 예민해지고 손상된 피부를 빠르\n고 효과적으로 회복시키는 시술들이에요.\n 필링, 레이저 등 다양한 종류의 시술들이\n 있어요.";

export const surgeryItems = [
  {
    id: 0,
    title: "올리지오",
    keyword: [
      {
        id: 0,
        text: "요즘인기",
      },
      {
        id: 1,
        text: "가성비갑",
      },
    ],
    summary: [
      {
        id: 0,
        title: "평균가격",
        info: "저렴한 편",
      },
      {
        id: 1,
        title: "지속기간",
        info: "오래가는 편",
      },
      {
        id: 2,
        title: "통증정도",
        info: "아파요",
      },
    ],
    tag: [
      {
        id: 0,
        text: "#가성비갑",
      },
      {
        id: 1,
        text: "#잔주름",
      },
      {
        id: 2,
        text: "#얇은피부",
      },
      {
        id: 3,
        text: "#눈가주름",
      },
    ],
    checked: false,
  },
  {
    id: 1,
    title: "인모드",
    keyword: [
      {
        id: 0,
        text: "입문자추천",
      },
      {
        id: 1,
        text: "남성추천",
      },
    ],
    summary: [
      {
        id: 0,
        title: "평균가격",
        info: "저렴한 편",
      },
      {
        id: 1,
        title: "지속기간",
        info: "오래가는 편",
      },
      {
        id: 2,
        title: "통증정도",
        info: "아파요",
      },
    ],
    tag: [
      {
        id: 0,
        text: "#두꺼운피부",
      },
      {
        id: 1,
        text: "#빠른시술",
      },
    ],
    checked: false,
  },
  {
    id: 2,
    title: "텐써마",
    summary: [
      {
        id: 0,
        title: "평균가격",
        info: "저렴한 편",
      },
      {
        id: 1,
        title: "지속기간",
        info: "오래가는 편",
      },
      {
        id: 2,
        title: "통증정도",
        info: "아파요",
      },
    ],
    tag: [
      {
        id: 0,
        text: "#깊은주름",
      },
      {
        id: 1,
        text: "#팔자주름",
      },
      {
        id: 2,
        text: "#얼굴탄력",
      },
    ],
    checked: false,
  },
  {
    id: 3,
    title: "인모드",
    summary: [
      {
        id: 0,
        title: "평균가격",
        info: "저렴한 편",
      },
      {
        id: 1,
        title: "지속기간",
        info: "오래가는 편",
      },
      {
        id: 2,
        title: "통증정도",
        info: "아파요",
      },
    ],
    tag: [
      {
        id: 0,
        text: "#깊은주름",
      },
      {
        id: 1,
        text: "#팔자주름",
      },
      {
        id: 2,
        text: "#얼굴탄력",
      },
      {
        id: 3,
        text: "#빠른시술",
      },
    ],
    checked: false,
  },
  {
    id: 4,
    title: "인모드",
    summary: [
      {
        id: 0,
        title: "평균가격",
        info: "저렴한 편",
      },
      {
        id: 1,
        title: "지속기간",
        info: "오래가는 편",
      },
      {
        id: 2,
        title: "통증정도",
        info: "아파요",
      },
    ],
    tag: [
      {
        id: 0,
        text: "#깊은주름",
      },
      {
        id: 1,
        text: "#팔자주름",
      },
      {
        id: 2,
        text: "#얼굴탄력",
      },
      {
        id: 3,
        text: "#빠른시술",
      },
    ],
    checked: false,
  },
  {
    id: 5,
    title: "올리지오",
    summary: [
      {
        id: 0,
        title: "평균가격",
        info: "저렴한 편",
      },
      {
        id: 1,
        title: "지속기간",
        info: "오래가는 편",
      },
      {
        id: 2,
        title: "통증정도",
        info: "아파요",
      },
    ],
    tag: [
      {
        id: 0,
        text: "#깊은주름",
      },
      {
        id: 1,
        text: "#팔자주름",
      },
      {
        id: 2,
        text: "#얼굴탄력",
      },
      {
        id: 3,
        text: "#빠른시술",
      },
    ],
    checked: false,
  },
];

export const searchPlaceholder = "궁금한 시술을 검색해 보세요.";

export const planSearchPlaceholder = "시술명으로 검색해 주세요";

export const communitySearchPlaceholder = "검색어를 입력하세요";

export const comparisonInfoText =
  "궁금한 시술을 비교 분석해 보세요! (최대 3개)";

export const comparisonBtnText = "시술 비교 해보기";

export const surgeryDetailTag = "#레이저 #적은통증 #빠른효과";

export const surgeryDetailHighlight = "이런 분들께 추천드려요!";

export const surgeryDetailComment = [
  {
    text: "통증이 무서워서 리프팅 시술이 꺼려지는 분",
  },
  {
    text: "피부톤 개선과 피부 탄력 강화를 한 번에 해결하고 싶은 분",
  },
  {
    text: "얼굴 탄력이 없어 고민이신 분",
  },
];

export const surgeryNoData = "찾으시는 시술이 없어요.";

export const surgeryDetailSummary =
  "세계 최초로 3가지 파장(755nm, 810nm, 1065nm)의 레이저가 동시에 조사되어 강력한 리프팅 효과를 자랑해요. 한 번의 시술로 피부 타이트닝, 리프팅, 피부톤 개선의 효과를 한 번에 누릴 수 있어요. 다운타임이 없어 바로 일상생활이 가능해요!";

export const surgeryDetailInfo = [
  {
    title: "시술 단위",
    info: "라인타입 300-400샷 / 펜타입 6000-8000샷",
  },
  {
    title: "권장 횟수",
    info: "2~4주 간격 2~5회",
  },
  {
    title: "지속기간",
    info: "1개월 / 6~12개월",
  },
  {
    title: "효과 나타남",
    info: "1~2개월 후",
  },
  {
    title: "통증 정도",
    info: "많이 아픔",
  },
  {
    title: "다운타임",
    info: "3~5일",
  },
  {
    title: "평균 가격",
    subTitle: "전국 기준",
    price: [
      {
        title: "최저값",
        num: "330,000",
      },
      {
        title: "중간값",
        num: "450,000",
      },
      {
        title: "최고값",
        num: "1,196,000",
      },
    ],
    graph: "",
  },
  {
    title: "추천 조합",
    recommend: true,
    recommendFirst: "올리지오",
    recommendSecond: "타이탄 리프팅",
    summary:
      "고주파 올리지오리프팅으로 전체적인 얼굴 탄력을 올려주고, 고민 되는 부위만 집중적으로 초음파 타이탄리프팅으로 개선해 얼굴이 더욱 갸름해지는 효과를 누릴 수 있어요.",
  },
];

export const bottomCommentTitle = "알려드려요!";

export const bottomComment =
  "권장횟수, 효과 보임, 지속기간, 다운타임, 통증 정도, 시술 단위, 추천조합은 다수의 피부과 전문의 소견 및 실제 사용자 후기를 기반으로 작성된 단순 참고용 정보입니다. 의학적 진단이 아니므로, 실제 시술 결정 시 반드시 해당 의료기관과 사전 상담을 진행하시기 바랍니다. \n평균 가격은 시술 단위 당 평균 가격이며, 촉촉박사에서 수집한 의료기관들의 대외 홍보 가격을 기준으로 작성되었습니다.";

export const tableData = {
  data: [
    {
      title: "리프테라",
      price: {
        id: 0,
        icon: "",
        text: "450,000원",
      },
      count: "2~4주 간격 \n2~5회",
      effect: {
        id: 0,
        icon: "",
        text: "1~2개월 후",
      },
      time: {
        id: 0,
        icon: "",
        text: "없음",
      },
      ache: {
        id: 0,
        icon: "",
        text: "많이 아픔",
      },
      unit: "라인타입 \n300-400샷 / \n펜타입 \n6000-8000샷",
    },
    {
      title: "인모드 \nFX/FORMA",
      price: {
        id: 0,
        icon: "",
        text: "450,000원",
      },
      count: "2~4주 간격 \n2~5회",
      effect: {
        id: 0,
        icon: "",
        text: "1~2개월 후",
      },
      time: {
        id: 0,
        icon: "",
        text: "없음",
      },
      ache: {
        id: 0,
        icon: "",
        text: "많이 아픔",
      },
      unit: "부위 별 시술",
    },
    {
      title: "슈링크 유니버스",
      price: {
        id: 0,
        icon: "",
        text: "450,000원",
      },
      count: "2~4주 간격 \n2~5회",
      effect: {
        id: 0,
        icon: "",
        text: "1~2개월 후",
      },
      time: {
        id: 0,
        icon: "",
        text: "없음",
      },
      ache: {
        id: 0,
        icon: "",
        text: "많이 아픔",
      },
      unit: "얼굴전체 \n300-600샷",
    },
  ],
};

export const popupTitle = "시술 상세 보기";

export const popupInfo =
  "시술 상세 보기 설명 내용입니다.시술 상세 보기 설명 내용입니다. 시술 상세 보기 설명 내용입니다.";

export const popupButton = "피부시술비교 시작하기";

export const popupClostBtn = "다시 보지 않기";

export const popupImage = [
  {
    url: require("@assets/images/img-modal01.png"),
    title: "카테고리별 시술 찾기",
    info: "그동안 궁금했던 피부 시술 정보들을 찾아보세요. \n시술명으로 검색도 가능해요!",
  },
  {
    url: require("@assets/images/img-modal02.png"),
    title: "시술 비교 하기",
    info: "체크박스를 선택하고 맨 아래 ‘시술 비교해 보기’ 버튼을 \n누르면 여러 시술들을 비교해 보실 수 있어요.",
  },
  {
    url: require("@assets/images/img-modal03.png"),
    title: "시술 상세 보기",
    info: "리스트에서 관심 있는 시술을 눌러 보세요! \n더 상세한 시술 정보와 추천 시술 조합도 알려 드려요.",
  },
];

export const comparisonTitle = "시술 비교";

export const clipboardCopyText = "링크가 클립보드에 복사 되었습니다.";

export const clipboardCopyFailText = "공유하기가 지원되지 않는 환경입니다.";

export const saveIamgeText = "이미지가 저장 되었습니다.";

export const shareText = "촉촉박사에서 궁금했던 시술 정보를 확인해 보세요!";

export const surgeryMaxSelectedText =
  "비교할 시술은 한 번에 최대 3개까지만 \n 선택하실 수 있어요!";

export const analyticsId = "6b781e73e8fa4deb3bf55da79d2dc8fb";

export const metaContentText =
  "촉촉한 피부, 나만 모르고 있던 시술 정보가 궁금하다면? | 촉촉박사";

export const commonMenuList = [
  {
    name: "피부시술비교",
    url: "surgeryList",
    type: 0,
    icon: require("@assets/icons/icon-hot.svg"),
  },
  {
    name: "피부 MBTI",
    url: "skinMbti",
    type: 0,
    icon: require("@assets/icons/icon-new.svg"),
  },
  {
    name: "피부 플래너",
    state: 0,
    subMenu: [
      {
        name: "피부 플랜 만들기",
        url: "skinPlan",
      },
      {
        name: "내 피부 플랜",
        url: "mySkinPlan",
        type: 0,
      },
    ],
  },
  {
    name: "커뮤니티",
    url: "community",
    type: 0,
  },
  {
    name: "내 정보 관리",
    url: "mypage",
  },
  {
    name: "로그아웃",
    value: "logout",
  },
];

export const snsList = [
  {
    icon: require("@assets/icons/icon-sns01.png"),
    link: "https://www.youtube.com/@Dr.moistTV",
  },
  {
    icon: require("@assets/icons/icon-sns02.png"),
    link: "https://www.instagram.com/drmoist.official/",
  },
  {
    icon: require("@assets/icons/icon-sns03.png"),
    link: "https://twitter.com/drmoist_kr",
  },
];

export const termsList = [
  {
    name: "서비스 이용약관",
    url: "servicePolicy",
  },
  {
    name: "개인정보 처리방침",
    url: "privacyPolicy",
  },
];

export const mypageMenuList = [
  {
    name: "내 피부 MBTI",
    url: "skinMbti",
  },
  {
    name: "내 커뮤니티 프로필",
    url: "myprofile",
  },
  {
    name: "닉네임 변경",
    url: "changeNickname",
  },
  {
    name: "개인정보 보관 기간 설정",
    url: "privacyNotice",
  },
  {
    name: "내 정보 확인",
    url: "myinfo",
  },
];

export const changeNicknameInput = "변경할 닉네임을 입력하세요.";

export const nicknameSaveText = "닉네임이 잘 저장되었어요!";

export const privacySettingItems = [
  {
    id: "ONE_YEAR",
    label: "1년",
    value: "ONE_YEAR",
  },
  {
    id: "THREE_YEAR",
    label: "3년",
    value: "THREE_YEAR",
  },
  {
    id: "FIVE_YEAR",
    label: "5년",
    value: "FIVE_YEAR",
  },
  {
    id: "UNTIL_DROP",
    label: "계정을 탈퇴할 때까지",
    value: "UNTIL_DROP",
  },
];

export const privacySettingSaveText = "개인정보 보관 기간이 설정되었어요!";

export const myinfoList = [
  {
    name: "연동 SNS",
    icon: require("@assets/icons/icon-kakao.svg"),
  },
  {
    name: "SNS 계정",
    type: "account",
  },
  {
    name: "서비스 이용약관",
    url: "servicePolicy",
  },
  {
    name: "개인정보 처리방침",
    url: "privacyPolicy",
  },
  {
    name: "회원 탈퇴",
    url: "withdrawal",
  },
];

export const withdrawalReasonList = [
  {
    name: "자주 사용하지 않아요.",
    value: 1,
  },
  {
    name: "개인정보 유출이 우려돼요.",
    value: 2,
  },
  {
    name: "쓸만한 기능이 없어요.",
    value: 3,
  },
  {
    name: "콘텐츠가 부족해요.",
    value: 4,
  },
  {
    name: "이용하기가 불편해요.",
    value: 5,
  },
  {
    name: "오류가 너무 많아요.",
    value: 6,
  },
  {
    name: "광고가 너무 많아요.",
    value: 7,
  },
  {
    name: "직접 입력",
    value: 8,
  },
];

export const withdrawalText =
  "그동안 즐거웠어요…\n다시 만나게 될 날을 기다릴게요.";

export const withdrawalCancelText =
  "믿고 있었다구~!\n다시 돌아오신 것을 환영해요!";

export const mbtiLinkCopyText = "링크가 복사되었어요!\n친구에게 공유해 보세요.";

export const mbtiShareText = "촉촉박사에서 피부 MBTI 테스트를 해보세요!";

export const mbtiResultShareText =
  "촉촉박사에서 피부 MBTI 테스트 결과를 확인해 보세요!";

export const mbtiSaveText =
  "내 피부 MBTI에 저장되었어요!\n내 정보 관리에서 확인할 수 있어요.";

export const mbtiPopupTitle = "잠깐!";

export const mbtiPopupInfo =
  "결과 확인하기 전, 로그인을 추천해요!\n언제든 내 피부 MBTI를 확인할 수 있어요.";

export const mbtiPopupHighlightInfo =
  "촉촉박사 AI가 피부 MBTI에 맞는\n서비스를 추천해 줄 거예요!";

export const mbtiPopupBtnText = "1초 만에 로그인 하기";

export const mypageMbtiPopupTitle = "앗, 이럴수가!";

export const mypageMbtiPopupInfo = "아직 피부 MBTI에 참여하지 않으셨네요.";

export const mypageMbtiPopupHighlightInfo =
  "내 피부 MBTI를 확인하고\n촉촉박사 AI의 시술/서비스 추천을 받아보세요.";

export const mypageMbtiPopupBtnText = "피부 MBTI 바로 가기";

export const loginPopupTitle = "로그인이 필요한 서비스 입니다.";

export const loginPopupLeftBtnText = "취소";

export const loginPopupRightBtnText = "로그인";

export const searchKeywordItem = [
  "울쎄라",
  "피코토닝",
  "골드PPT",
  "올리지오",
  "텐쎄라",
  "제네시스 토닝",
];

export const searchTextItem = [
  {
    title: "울쎄라",
  },
  {
    title: "피코토닝",
  },
  {
    title: "골드PPT",
  },
  {
    title: "올리지오",
  },
  {
    title: "텐쎄라",
  },
  {
    title: "제네시스 토닝",
  },
];

export const communityKeywordItem = [
  "최근검색어1",
  "최근검색어2",
  "최근검색어3",
  "최근검색어에요",
];

export const keywordDeletePopupTitle = "최근 검색어를\n모두 삭제 하시겠어요?";

export const keywordToast =
  "올바른 검색어를 입력해 주세요!\n두 글자 이상부터 검색할 수 있어요.";

export const communityShareText =
  "링크가 복사 되었어요!\n친구에게 공유해 보세요.";

export const planDeleteToast = "플랜이 삭제 되었어요!";

export const planUpdateToast = "변경 사항이 저장 되었어요!";
