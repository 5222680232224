import RestService from "../RestService";
const PREFIX_URL = `/v1/surgery`;

/**
 * 공통 API
 */

const getCategoryList = async (): Promise<any> => {
  const url = `${PREFIX_URL}/menuItemList`;
  const response = await RestService.get(url);
  return response;
};

export default {
  getCategoryList,
};
