import React, { ReactElement } from "react";
import {
  SurgeryList,
  SurgeryListHeader,
  SurgeryTitleWrap,
  SurgeryTitle,
  SurgeryKeywordWrap,
  SurgeryKeyword,
  SurgeryKeywordText,
  SurgeryContents,
  SurgerySummaryWrap,
  SurgerySummaryTitleText,
  SurgeryTag,
  SurgeryTagText,
  SurgeryListWrap,
  SurgeryTagWrap,
  SurgerySummary,
  SurgerySummaryIcon,
  SurgerySummaryInfoText,
  SurgeryListContents,
} from "./styled";
import { CircleCheckbox } from "@src/components/presentational";

interface SurgeryItemProps {
  onChangeCheck: (value) => void;
  onPressList?: (id) => void;
  title: string;
  keyword: Array<any>;
  price: any;
  duration: any;
  pain: any;
  provenance?: any;
  summary?: string;
  tag: Array<any>;
  checked?: boolean;
}

const SurgeryItem = ({
  onChangeCheck,
  onPressList,
  title,
  keyword,
  price,
  duration,
  pain,
  provenance,
  tag,
  checked,
}: SurgeryItemProps): ReactElement => {
  return (
    <SurgeryListWrap>
      <SurgeryListContents>
        <SurgeryList onPress={onPressList} activeOpacity={1}>
          <SurgeryListHeader>
            <SurgeryTitleWrap>
              <SurgeryTitle>{title}</SurgeryTitle>
              <SurgeryTagWrap>
                {tag?.map((item, index) => (
                  <SurgeryTag key={index} bgColor={index}>
                    <SurgeryTagText bgColor={index}>{item}</SurgeryTagText>
                  </SurgeryTag>
                ))}
              </SurgeryTagWrap>
            </SurgeryTitleWrap>
            <CircleCheckbox
              isChecked={checked}
              onChangeCheck={(value) => onChangeCheck(value)}
            />
          </SurgeryListHeader>
          <SurgeryContents>
            <SurgeryKeywordWrap>
              {keyword?.map((item, index) => (
                <SurgeryKeyword key={index}>
                  <SurgeryKeywordText>{item}</SurgeryKeywordText>
                </SurgeryKeyword>
              ))}
            </SurgeryKeywordWrap>
            <SurgerySummaryWrap>
              {price && (
                <SurgerySummary>
                  <SurgerySummaryTitleText>
                    {price.title}
                  </SurgerySummaryTitleText>
                  <SurgerySummaryIcon source={price.icon} />
                  <SurgerySummaryInfoText>
                    {price.iconCaption}
                  </SurgerySummaryInfoText>
                </SurgerySummary>
              )}
              {duration && (
                <SurgerySummary>
                  <SurgerySummaryTitleText>
                    {duration.title}
                  </SurgerySummaryTitleText>
                  <SurgerySummaryIcon source={duration.icon} />
                  <SurgerySummaryInfoText>
                    {duration.iconCaption}
                  </SurgerySummaryInfoText>
                </SurgerySummary>
              )}
              {pain.val && (
                <SurgerySummary>
                  <SurgerySummaryTitleText>
                    {pain.title}
                  </SurgerySummaryTitleText>
                  <SurgerySummaryIcon source={pain.icon} />
                  <SurgerySummaryInfoText>
                    {pain.iconCaption}
                  </SurgerySummaryInfoText>
                </SurgerySummary>
              )}
              {provenance.val && (
                <SurgerySummary>
                  <SurgerySummaryTitleText>
                    {provenance.title}
                  </SurgerySummaryTitleText>
                  <SurgerySummaryIcon source={provenance.icon} />
                  <SurgerySummaryInfoText>
                    {provenance.iconCaption}
                  </SurgerySummaryInfoText>
                </SurgerySummary>
              )}
            </SurgerySummaryWrap>
          </SurgeryContents>
        </SurgeryList>
      </SurgeryListContents>
    </SurgeryListWrap>
  );
};

export default SurgeryItem;
