import React, { ReactElement, useState } from "react";
import { useNavigation } from "@react-navigation/native";
import { Text } from "react-native";
import { HamburgerMenu, Maybe, SwitchCase } from "..";
import {
  BackBtn,
  BackImage,
  CommonHeaderInner,
  CommonHeaderLogo,
  CommonHeaderLogoBtn,
  CommonHeaderLogoImage,
  CommonHeaderWrap,
  HamburgerBtn,
  HamburgerImage,
  HeaderRightContents,
  HeaderTitle,
  HeaderTitleText,
  HomeBtn,
  HomeImage,
  LoginBtn,
  LoginBtnText,
  MoreImage,
  RightBtn,
  styles,
} from "./styled";
import { globalStyles } from "@src/styles/modules/global";
import { commonMenuList } from "@src/screens/sampleDatas";
import { useAuth } from "@src/hooks/auth";
import logoImg from "@assets/logo.png";
import logoColorImg from "@assets/logo-color.png";
import backIcon from "@assets/icons/icon-back.svg";
import cancelIcon from "@assets/icons/icon-close-black.svg";
import homeIcon from "@assets/icons/icon-home.svg";
import hamburgerIcon from "@assets/icons/icon-hamburger.svg";
import hamburgerBlackIcon from "@assets/icons/icon-hamburger-black.svg";
import moreIcon from "@assets/icons/icon-more.svg";

interface CommonHeaderProps {
  backMode?: boolean;
  cancelMode?: boolean;
  handleCancel?: () => void;
  transparent?: boolean;
  logo?: boolean;
  hamburgerMode?: boolean;
  mainMode?: boolean;
  titleMode?: boolean;
  title?: string;
  homeMode?: boolean;
  hiddenRight?: boolean;
  rightText?: string;
  rightBtn?: boolean;
  rightBtnIcon?: boolean;
  onClickRightBtn?: () => void;
  handleLogin?: () => void;
  handleLogout?: () => void;
  isVisiblePopup?: boolean;
  loginPopup?: () => void;
}

const CommonHeader = ({
  backMode,
  cancelMode,
  handleCancel,
  transparent,
  logo,
  hamburgerMode,
  mainMode,
  titleMode,
  title,
  homeMode,
  hiddenRight,
  rightText,
  rightBtn,
  rightBtnIcon,
  onClickRightBtn,
  handleLogin,
  handleLogout,
  isVisiblePopup,
  loginPopup,
}: CommonHeaderProps): ReactElement => {
  const navigation = useNavigation<any>();
  const { isLoggedIn } = useAuth();

  const [isBurgerOpen, setIsBurgerOpen] = useState<boolean>(false);

  const handleToggleBurger = () => {
    setIsBurgerOpen(!isBurgerOpen);
  };

  return (
    <>
      <CommonHeaderWrap>
        <CommonHeaderInner bgColor={transparent} hiddenRight={hiddenRight}>
          <Maybe
            test={backMode}
            children={
              <BackBtn
                onPress={() => {
                  navigation.canGoBack()
                    ? navigation.goBack()
                    : navigation.navigate("surgeryList");
                }}
                underlayColor="transparent"
              >
                <BackImage source={backIcon} resizeMode="contain" />
              </BackBtn>
            }
          />
          <Maybe
            test={cancelMode}
            children={
              <BackBtn
                hiddenRight={hiddenRight}
                onPress={handleCancel}
                underlayColor="transparent"
              >
                <BackImage source={cancelIcon} resizeMode="contain" />
              </BackBtn>
            }
          />
          <Maybe
            test={logo}
            children={
              <CommonHeaderLogo>
                <CommonHeaderLogoBtn
                  underlayColor="transparent"
                  onPress={() => navigation.push("surgeryList")}
                >
                  <>
                    {logo && !transparent && (
                      <CommonHeaderLogoImage source={logoImg} />
                    )}
                    {logo && transparent && (
                      <CommonHeaderLogoImage source={logoColorImg} />
                    )}
                  </>
                </CommonHeaderLogoBtn>
              </CommonHeaderLogo>
            }
          />
          <Maybe
            test={titleMode}
            children={
              <HeaderTitle>
                <HeaderTitleText style={globalStyles.fontB}>
                  {title}
                </HeaderTitleText>
              </HeaderTitle>
            }
          />
          <SwitchCase
            tests={[
              {
                test: hamburgerMode,
                component: (
                  <HeaderRightContents>
                    {mainMode && (
                      <HeaderRightContents>
                        <Maybe
                          test={!isLoggedIn}
                          children={
                            <LoginBtn
                              onPress={handleLogin}
                              underlayColor="transparent"
                            >
                              <LoginBtnText>로그인</LoginBtnText>
                            </LoginBtn>
                          }
                        />
                      </HeaderRightContents>
                    )}
                    <HamburgerBtn
                      underlayColor="transparent"
                      onPress={handleToggleBurger}
                    >
                      <HamburgerImage
                        source={
                          !transparent ? hamburgerIcon : hamburgerBlackIcon
                        }
                        resizeMode="contain"
                      />
                    </HamburgerBtn>
                  </HeaderRightContents>
                ),
              },
            ]}
          />
          <Maybe
            test={homeMode}
            children={
              <HomeBtn
                onPress={() => {
                  navigation.navigate("surgeryList");
                }}
                underlayColor="transparent"
              >
                <HomeImage source={homeIcon} resizeMode="contain" />
              </HomeBtn>
            }
          />
          <Maybe
            test={rightBtn}
            children={
              <RightBtn onPress={onClickRightBtn} activeOpacity={1}>
                {rightText && <Text style={styles.text}>{rightText}</Text>}
                {rightBtnIcon && (
                  <MoreImage source={moreIcon} resizeMode="contain" />
                )}
              </RightBtn>
            }
          />
        </CommonHeaderInner>
      </CommonHeaderWrap>
      <HamburgerMenu
        isOpen={isBurgerOpen}
        onClose={() => setIsBurgerOpen(!isBurgerOpen)}
        menuList={commonMenuList}
        handleLogin={handleLogin}
        handleLogout={handleLogout}
        loginPopup={loginPopup}
      />
    </>
  );
};

export default CommonHeader;
