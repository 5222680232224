import React, { ReactElement } from "react";
import { View } from "react-native";

import {
  Dimmed,
  styles,
  ids,
  DialogPopupContents,
  Title,
  BtnContents,
  BtnText,
  SingleBtn,
  itemStyles,
  Info,
  LeftBtn,
  RightBtn,
  Btn,
} from "./styled";
import { SwitchCase } from "..";

interface DialogPopupProps {
  isOpen?: any;
  onClose?: () => void;
  title?: string;
  info?: string;
  subInfo?: string;
  multiple?: any;
  single?: any;
  leftBtnText?: string;
  rightBtnText?: string;
  singleBtnText?: string;
  onClickLeftBtn?: () => void;
  onClickRightBtn?: () => void;
}

const DialogPopup = ({
  isOpen,
  onClose,
  title,
  info,
  multiple,
  leftBtnText,
  rightBtnText,
  singleBtnText,
  onClickLeftBtn,
  onClickRightBtn,
}: DialogPopupProps): ReactElement => {
  return (
    <>
      <Dimmed
        isOpen={isOpen}
        onStartShouldSetResponder={() => true}
        onResponderGrant={() => onClose()}
      />
      <View dataSet={{ media: ids.popup }} style={styles.popup}>
        <DialogPopupContents>
          <Title style={itemStyles.title}>{title}</Title>
          {info && <Info style={itemStyles.info}>{info}</Info>}
          <SwitchCase
            tests={[
              {
                test: multiple,
                component: (
                  <BtnContents>
                    <LeftBtn
                      style={itemStyles.btn}
                      onPress={onClickLeftBtn}
                      activeOpacity={1}
                    >
                      <BtnText style={itemStyles.leftBtnText}>
                        {leftBtnText}
                      </BtnText>
                    </LeftBtn>
                    <RightBtn
                      style={itemStyles.btn}
                      onPress={onClickRightBtn}
                      activeOpacity={1}
                    >
                      <BtnText style={itemStyles.rightBtnText}>
                        {rightBtnText}
                      </BtnText>
                    </RightBtn>
                  </BtnContents>
                ),
              },
            ]}
            defaultComponent={
              <SingleBtn>
                <Btn activeOpacity={1}>
                  <BtnText>{singleBtnText}</BtnText>
                </Btn>
              </SingleBtn>
            }
          />
        </DialogPopupContents>
      </View>
    </>
  );
};

export default DialogPopup;
