import React, { ReactElement, useEffect, useRef, useState } from "react";
import { Dimensions, ScrollView, StatusBar, Text } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { Helmet } from "react-helmet-async";
import Toast from "react-native-fast-toast";
import { useSetRecoilState } from "recoil";
import { Layouts } from "@src/components/container";
import {
  MypageCommonContents,
  MypageCommonInfoText,
  MypageCommonTitle,
  MypageCommonTitleText,
  MypageCommonTopContainer,
  styles,
} from "./styled";
import { BottomFixButton, Radio } from "@src/components/presentational";
import { privacySettingItems, privacySettingSaveText } from "../sampleDatas";
import { globalStyles } from "@src/styles/modules/global";
import { tokenState, simpleUserState } from "@src/store";
import { useAuth } from "@src/hooks/auth";
import { MemberService } from "@src/services";

/**
 * 개인정보 보관 기간 설정
 */

const PrivacySettingScreen = (): ReactElement => {
  const { simpleUser, entireUser } = useAuth();
  const { height } = Dimensions.get("window");

  const setToken = useSetRecoilState(tokenState);
  const setUser = useSetRecoilState(simpleUserState);

  const navigation = useNavigation<any>();
  const centerToastRef = useRef(null);

  const [periodValue, setPeriodValue] = useState<string | any>();

  const handleCancel = () => {
    navigation.navigate("mypage");
  };

  const handleSave = () => {
    updatePrivacySetting();

    if (periodValue) {
      centerToastRef.current.show(privacySettingSaveText);

      setTimeout(() => {
        window.location.href = "mypage";
      }, 1500);
    }
  };

  const handleChangeValue = (value) => {
    setPeriodValue(value);
  };

  const updatePrivacySetting = async () => {
    const conditions = {
      privateInfoPeriod: periodValue,
    };

    try {
      const data = await MemberService.patchUserInfo(conditions);
      setToken(data.accessToken);
      setUser({
        ...simpleUser,
        privateInfoPeriod: periodValue,
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setPeriodValue(entireUser?.privateInfoPeriod);
  }, [entireUser]);

  return (
    <>
      <StatusBar barStyle="light-content" backgroundColor="#6432e9" />
      <Helmet>
        <title>개인정보 보관 기간 설정 | 촉촉박사</title>
        <meta
          name="description"
          content="촉촉한 피부, 나만 모르고 있던 시술 정보가 궁금하다면? | 촉촉박사"
          data-react-helmet="true"
        />
      </Helmet>
      <Layouts logo transparent>
        <MypageCommonTopContainer>
          <MypageCommonTitle>
            <MypageCommonTitleText>
              개인정보 보관 기간 설정
            </MypageCommonTitleText>
          </MypageCommonTitle>
          <MypageCommonInfoText>
            회원님이 아래에서 선택한 기간만큼 개인정보 보관 기간을 설정해요.
            <br />
            <br />
            설정한 기간까지는 계정이 휴면상태로 전환되지 않아요. <br />
            <br />
            <Text style={styles.textGrey}>
              ※ 개인정보 보관 기간 : 휴면 상태로 전환하지 않는 기간
            </Text>
          </MypageCommonInfoText>
        </MypageCommonTopContainer>
        <ScrollView
          bounces={false}
          scrollEventThrottle={1}
          contentContainerStyle={{ flexGrow: 1 }}
          showsVerticalScrollIndicator={false}
        >
          <MypageCommonContents>
            <Radio
              onChangeValue={(value) => handleChangeValue(value)}
              value={periodValue}
              items={privacySettingItems}
            />
          </MypageCommonContents>
        </ScrollView>
        <BottomFixButton
          multipleBtnMode
          leftBtnText="취소하기"
          rightBtnText="설정하기"
          onPressLeftBtn={handleCancel}
          onPressRightBtn={handleSave}
        />
      </Layouts>
      <Toast
        ref={centerToastRef}
        offset={height / 2}
        duration={1000}
        style={globalStyles.centerToast}
        textStyle={globalStyles.centerToastText}
      />
    </>
  );
};

export default PrivacySettingScreen;
