/* eslint-disable @typescript-eslint/no-explicit-any */
import RestService from "../RestService";

/**
 * MbtiAPI
 * @see https://
 */
class MbtiService {
  /**
   * MBTI 저장
   * @return {Promise<any>}
   */
  public patchMbtiResult = async (payload): Promise<any> => {
    const url: string = "/v1/baumann/result";

    try {
      const { data } = await RestService.patch(url, payload);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  /**
   * MBTI test count
   */
  public postMbtiCount = async (): Promise<any> => {
    const url: string = "/v1/stats/baumann/count";

    try {
      await RestService.post(url);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  public getMbtiCount = async (): Promise<any> => {
    const url: string = "/v1/stats/baumann";

    try {
      const { data } = await RestService.get(url);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  /**
   * MBTI 결과 확인
   * @returns {Promise<any>}
   */
  public getMbtiResult = async (): Promise<any> => {
    const url: string = "/v1/baumann/result";

    try {
      const { data } = await RestService.get(url);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

export default new MbtiService();
