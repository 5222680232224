import React from "react";
import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import * as Linking from "expo-linking";
import routesConfigs from "./configs";
import SetMetaTag from "../../setMetaTag";
import { screens } from "./screens";
import { STRG_ACCESS_TOKEN_KEY } from "@src/constants";
import authenticationConfigs from "./configs/AuthenticationConfigs";

const Stack = createNativeStackNavigator();
const prefix = Linking.createURL("/");

const auth = localStorage.getItem(STRG_ACCESS_TOKEN_KEY);

const linking = {
  prefixes: [prefix],
  config: {
    screens: screens,
  },
};

const RootNavigator = () => {
  const options = {
    headerShown: false,
  };

  return (
    <>
      <SetMetaTag />
      <NavigationContainer linking={linking}>
        <Stack.Navigator
          screenOptions={({ route }: any): any => {
            if (route.state) {
              const currentRoute =
                route.state.routes[route.state.routes.length - 1].name;
              routesConfigs.includes(currentRoute);
            }
          }}
          initialRouteName="surgeryList"
        >
          {auth && (
            <>
              {authenticationConfigs.map((config, index) => {
                return (
                  <Stack.Screen
                    key={index}
                    name={config.name}
                    options={options}
                    component={config.component}
                  />
                );
              })}
            </>
          )}
          {routesConfigs.map((config, index) => {
            return (
              <Stack.Screen
                key={index}
                name={config.name}
                options={options}
                component={config.component}
              />
            );
          })}
        </Stack.Navigator>
      </NavigationContainer>
    </>
  );
};

export default RootNavigator;
