import React, { useCallback, useEffect, useState } from "react";
import { FlatList, ScrollView, StatusBar, Text } from "react-native";
import { Helmet } from "react-helmet-async";
import { useNavigation } from "@react-navigation/native";
import { useRecoilState } from "recoil";
import {
  MenuList,
  MenuListItem,
  MenuListItemText,
  MenuMoveIcon,
  MypageContainer,
  MypageInfo,
  MypageInfoIcon,
  MypageInfoIconImg,
  MypageInfoNameText,
  MypageInfoTitleText,
  MypageList,
  styles,
} from "./styled";
import { Layouts } from "@src/components/container";
import {
  mypageMbtiPopupBtnText,
  mypageMbtiPopupHighlightInfo,
  mypageMbtiPopupInfo,
  mypageMbtiPopupTitle,
  mypageMenuList,
} from "../sampleDatas";
import { CommunityService, MbtiService } from "@src/services";
import { ProfileType } from "@src/types";
import { CommunityProfile } from "@src/store";
import { BottomPopup, Maybe } from "@src/components/presentational";
import nextIcon from "@assets/icons/icon-next.svg";
import noticeImg from "@assets/images/mbti/mbti-notice-img02.png";
import profileImg from "@assets/logo-icon-white.svg";

/**
 * 마이페이지
 */

const MypageScreen = () => {
  const navigation = useNavigation<any>();

  const [mbtiResult, setMbtiResult] = useState<string>();
  const [isVisiblePopup, setIsVisiblePopup] = useState<boolean>(false);
  const [myProfileData, setMyProfileData] =
    useRecoilState<ProfileType>(CommunityProfile);

  const getMyProfile = async () => {
    try {
      const data = await CommunityService.getMyProfile();
      setMyProfileData(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickList = useCallback(
    (url) => {
      if (url === "skinMbti" && !mbtiResult) {
        setIsVisiblePopup(true);
      }

      if (url === "skinMbti" && mbtiResult) {
        navigation.push("skinMbtiResult", {
          type: mbtiResult,
          state: 0,
        });
      }

      if (url !== "skinMbti") {
        navigation.push(url);
      }
    },
    [mbtiResult]
  );

  const getMbtiResult = async () => {
    try {
      const data = await MbtiService.getMbtiResult();
      setMbtiResult(data.resultOfBaumannTest);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getMbtiResult();
  }, []);

  useEffect(() => {
    getMyProfile();
  }, []);

  return (
    <>
      <StatusBar barStyle="light-content" backgroundColor="#6432e9" />
      <Helmet>
        <title>마이페이지 | 촉촉박사</title>
        <meta
          name="description"
          content="촉촉한 피부, 나만 모르고 있던 시술 정보가 궁금하다면? | 촉촉박사"
          data-react-helmet="true"
        />
      </Helmet>
      <Layouts logo transparent hamburgerMode>
        <ScrollView
          bounces={false}
          scrollEventThrottle={1}
          contentContainerStyle={{ flexGrow: 1 }}
          showsVerticalScrollIndicator={false}
        >
          <MypageContainer>
            <MypageInfo>
              <MypageInfoIcon>
                <MypageInfoIconImg
                  resizeMode="cover"
                  source={{
                    uri: myProfileData?.profileImageUrl
                      ? myProfileData?.profileImageUrl
                      : profileImg,
                  }}
                />
              </MypageInfoIcon>
              <MypageInfoTitleText>
                안녕하세요, 무엇을 찾으시나요?
              </MypageInfoTitleText>
              <MypageInfoNameText>
                {myProfileData?.nickname}
                <Text style={styles.text}> 님</Text>
              </MypageInfoNameText>
            </MypageInfo>
            <MypageList>
              <FlatList
                data={mypageMenuList}
                renderItem={({ index, item }) => {
                  return (
                    <MenuList
                      onPress={() => handleClickList(item.url)}
                      underlayColor="transparent"
                    >
                      <MenuListItem>
                        <MenuListItemText>{item.name}</MenuListItemText>
                        <MenuMoveIcon resizeMode="contain" source={nextIcon} />
                      </MenuListItem>
                    </MenuList>
                  );
                }}
              />
            </MypageList>
          </MypageContainer>
        </ScrollView>
      </Layouts>
      <Maybe
        test={isVisiblePopup}
        children={
          <BottomPopup
            isOpen={isVisiblePopup}
            title={mypageMbtiPopupTitle}
            info={mypageMbtiPopupInfo}
            highlightInfo={mypageMbtiPopupHighlightInfo}
            icon={noticeImg}
            btnText={mypageMbtiPopupBtnText}
            onClick={() => navigation.push("skinMbti")}
            onClose={() => setIsVisiblePopup(false)}
          />
        }
      />
    </>
  );
};

export default MypageScreen;
