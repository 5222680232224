import React, { useRef, useState } from "react";
import { StatusBar, Text, Dimensions } from "react-native";
import { Helmet } from "react-helmet-async";
import { useNavigation } from "@react-navigation/native";
import Toast from "react-native-fast-toast";

import { Layouts } from "@src/components/container";
import {
  itemStyles,
  ContentsWrap,
  ContentsInner,
  ReportSelectList,
} from "./styled";
import { Radio } from "@src/components/presentational";
import { reportConfirmToast, reportErrorToast, reportItems } from "../configs";
import { globalStyles } from "@src/styles/modules/global";
import { useRecoilState } from "recoil";
import { CommunityCommentId, CommunityPostId } from "@src/store";
import { CommunityService } from "@src/services";

/**
 * 커뮤니티 신고 페이지
 */

const CommunityReportScreen = () => {
  const navigation = useNavigation<any>();
  const reportToastRef = useRef(null);

  const { height } = Dimensions.get("window");

  const [postId, setPostId] = useRecoilState(CommunityPostId);
  const [commentId, setCommentId] = useRecoilState(CommunityCommentId);

  const [reportValue, setReportValue] = useState<string | any>();
  const [reportInputValue, setReportInputValue] = useState<string | any>();
  const [reportConvValue, setReporConvtValue] = useState<string | any>();
  const [inputValue, setInputValue] = useState<string | any>();
  const [showInputText, setShowInputText] = useState<boolean>(false);

  const postCommentReport = async (value) => {
    try {
      await CommunityService.postCommentReport(postId, commentId, value);
    } catch (error) {
      console.log(error);
    }
  };

  const onClickSubmit = () => {
    if (reportConvValue || inputValue) {
      postCommentReport(inputValue ? inputValue : reportConvValue);
      reportToastRef.current.show(reportConfirmToast);

      setTimeout(() => {
        navigation.push("communityDetail", { id: postId });
      }, 3000);
    } else {
      reportToastRef.current.show(reportErrorToast);
    }
  };

  const handleChangeValue = (value) => {
    setReportValue(value);

    if (value === 7) {
      setShowInputText(true);
    } else {
      setShowInputText(false);
      convValue(value);
    }
  };

  const convValue = (value) => {
    switch (value) {
      case 1:
        setReporConvtValue("욕설, 비방, 차별, 혐오를 포함한 게시물");
        break;
      case 2:
        setReporConvtValue("홍보, 영리 목적의 게시물");
        break;
      case 3:
        setReporConvtValue("불법 정보 게시");
        break;
      case 4:
        setReporConvtValue("음란, 청소년 유해 정보");
        break;
      case 5:
        setReporConvtValue("개인 정보 노출, 유포, 거래");
        break;
      case 6:
        setReporConvtValue("도배 및 스팸성 정보");
        break;
    }
  };

  const handleChangeInputValue = (value) => {
    setInputValue(value);
  };

  return (
    <>
      <StatusBar barStyle="light-content" backgroundColor="#6432e9" />
      <Helmet>
        <title>커뮤니티 | 촉촉박사</title>
      </Helmet>
      <Layouts
        transparent
        cancelMode
        handleCancel={() => navigation.push("communityDetail", { id: postId })}
        titleMode
        title="신고하기"
        rightBtn
        rightText="제출"
        onClickRightBtn={onClickSubmit}
      >
        <ContentsWrap>
          <ContentsInner>
            <Text style={itemStyles.mLText}>신고 사유 선택</Text>
            <ReportSelectList>
              <Radio
                onChangeValue={(value) => handleChangeValue(value)}
                value={reportValue}
                items={reportItems}
                input
                inputPlaceholder="사유를 입력해 주세요."
                inputValue={inputValue}
                onChangeInputText={(value) => handleChangeInputValue(value)}
                showInputText={showInputText}
              />
            </ReportSelectList>
          </ContentsInner>
        </ContentsWrap>
      </Layouts>
      <Toast
        ref={reportToastRef}
        offset={height / 2}
        duration={2000}
        style={globalStyles.centerToast}
        textStyle={globalStyles.centerToastText}
      />
    </>
  );
};

export default CommunityReportScreen;
