import React, {
  ReactElement,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react";
import { ScrollView, Dimensions, StatusBar, Platform } from "react-native";
import { useRoute } from "@react-navigation/native";
import Toast from "react-native-fast-toast";
import * as htmlToImage from "html-to-image";
import { Helmet } from "react-helmet-async";
import saveAs from "file-saver";

import {
  BottomComment,
  BottomFixButton,
  SurgeryTable,
  ShareSns,
} from "@src/components/presentational";
import { SurgeryComparisonContents } from "./styled";
import { globalStyles } from "@src/styles/modules/global";
import {
  clipboardCopyFailText,
  clipboardCopyText,
  comparisonTitle,
  saveIamgeText,
  shareText,
} from "@src/screens/sampleDatas";
import { SurgeryService } from "@src/services";
import { SurgeryTableItem } from "@src/types";
import { Layouts } from "@src/components/container";
import { analytics } from "@src/hooks/analytics";

/**
 * 시술 비교
 */

const SurgeryComparisonScreen = ({}): ReactElement => {
  const { height } = Dimensions.get("window");
  const route = useRoute<any>();

  const saveToastRef = useRef(null);
  const shareToastRef = useRef(null);
  const screenshotRef = useRef<any>(null);

  const [data, setData] = useState<SurgeryTableItem>();
  const [surgeryFirstName, setSurgeryFirstName] = useState<string>();
  const [surgerySecondName, setSurgerySecondName] = useState<string>();
  const [surgeryThirdName, setSurgeryThirdName] = useState<string>();

  const getSurgeryComparisonList = async () => {
    const conditions = {
      ids: route.params.id,
    };

    try {
      const { data } = await SurgeryService.getSurgeryItemTable(conditions);
      setData(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSaveButton = useCallback(async () => {
    if (data && screenshotRef.current) {
      const result = htmlToImage
        .toPng(screenshotRef.current)
        .then(function (blob) {
          saveAs(blob, "image.png");
        });

      if (result && Platform.OS === "web") {
        setTimeout(() => {
          saveToastRef.current.show(saveIamgeText);
        }, 2000);
      }
    }
  }, [data]);

  const dataToShare: ShareData = {
    title: shareText,
    url: window.location.href,
  };

  const handleShareButton = useCallback(async () => {
    const result = await ShareSns(dataToShare);

    if (result === "copiedToClipboard") {
      shareToastRef.current.show(clipboardCopyText);
    } else if (result === "failed") {
      shareToastRef.current.show(clipboardCopyFailText);
    }

    analytics("Surgery Comparison Share", {
      surgery_comparison_share: route.params.list,
    });
  }, [route.params]);

  useEffect(() => {
    getSurgeryComparisonList();
  }, [route.params]);

  useEffect(() => {
    const name = data?.title?.valueCell?.map((item) => item);
    if (name) {
      setSurgeryFirstName(name[0]?.val);
      setSurgerySecondName(name[1]?.val);
      if (name[2]) {
        setSurgeryThirdName(name[2]?.val);
      }
    }
  }, [data]);

  return (
    <>
      <StatusBar barStyle="light-content" backgroundColor="#6432e9" />
      <Helmet>
        <title>{`${surgeryFirstName} vs ${surgerySecondName} ${
          surgeryThirdName ? "vs " + surgeryThirdName : ""
        } | 촉촉박사`}</title>
        <meta
          name="description"
          content={`${surgeryFirstName} vs ${surgerySecondName} ${
            surgeryThirdName ? "vs " + surgeryThirdName : ""
          } | 촉촉박사`}
          data-react-helmet="true"
        />
      </Helmet>
      <Layouts backMode transparent titleMode homeMode title={comparisonTitle}>
        <ScrollView
          bounces={false}
          scrollEventThrottle={1}
          contentContainerStyle={[{ flexGrow: 1 }, globalStyles.listPaddingL]}
          showsVerticalScrollIndicator={false}
        >
          <SurgeryComparisonContents ref={screenshotRef}>
            <SurgeryTable data={data} />
          </SurgeryComparisonContents>
          <BottomComment />
        </ScrollView>
        <BottomFixButton
          fullBtnMode
          fullBtnText="공유하기"
          onPressFullBtn={handleShareButton}
        />
      </Layouts>
      <Toast
        ref={shareToastRef}
        offset={height / 2}
        style={globalStyles.centerToast}
        textStyle={globalStyles.centerToastText}
      />
      <Toast
        ref={saveToastRef}
        offset={height / 2}
        style={globalStyles.centerToast}
        textStyle={globalStyles.centerToastText}
      />
    </>
  );
};

export default SurgeryComparisonScreen;
