import React, { ReactElement } from "react";
import {
  Table,
  TableCell,
  TableCellTitle,
  TableCellContents,
  TableCellContentsText,
  TableCellTitleText,
  TableCellTopContents,
  TableCellTopContentsText,
  styles,
  TableContentIcon,
  TableCellContentsSubText,
  TableCellUnitContentsText,
} from "./styled";

interface SurgeryTableProps {
  data: any;
}

const SurgeryTable = ({ data }: SurgeryTableProps): ReactElement => {
  return (
    <Table>
      <TableCell>
        <TableCellTitle>
          <TableCellTitleText>{data?.title?.title}</TableCellTitleText>
        </TableCellTitle>
        {data?.title?.valueCell?.map((value, index) => (
          <TableCellTopContents
            key={index}
            id={index}
            length={data?.title?.valueCell?.length}
          >
            <TableCellTopContentsText>{value.val}</TableCellTopContentsText>
          </TableCellTopContents>
        ))}
      </TableCell>
      <TableCell>
        <TableCellTitle>
          <TableCellTitleText>{data?.averagePrice?.title}</TableCellTitleText>
        </TableCellTitle>
        {data?.averagePrice?.valueCell?.map((value, index) => (
          <TableCellContents
            key={index}
            id={index}
            length={data?.title?.valueCell?.length}
          >
            <TableContentIcon source={value?.icon} />
            <TableCellContentsText>{value.val}</TableCellContentsText>
            <TableCellContentsSubText>
              ({value.iconCaption})
            </TableCellContentsSubText>
          </TableCellContents>
        ))}
      </TableCell>
      {data?.treatUnit && (
        <TableCell>
          <TableCellTitle>
            <TableCellTitleText>{data?.treatUnit?.title}</TableCellTitleText>
          </TableCellTitle>
          {data?.treatUnit?.valueCell?.map((value, index) => (
            <TableCellContents
              key={index}
              id={index}
              length={data?.title?.valueCell?.length}
            >
              <TableCellUnitContentsText>{value.val}</TableCellUnitContentsText>
            </TableCellContents>
          ))}
        </TableCell>
      )}
      {data?.surgerySite && (
        <TableCell>
          <TableCellTitle>
            <TableCellTitleText>{data?.surgerySite?.title}</TableCellTitleText>
          </TableCellTitle>
          {data?.surgerySite?.valueCell?.map((value, index) => (
            <TableCellContents
              key={index}
              id={index}
              length={data?.title?.valueCell?.length}
            >
              <TableCellContentsText>{value.val}</TableCellContentsText>
            </TableCellContents>
          ))}
        </TableCell>
      )}
      {data?.provenance && (
        <TableCell>
          <TableCellTitle style={styles.provenance}>
            <TableCellTitleText>{data?.provenance?.title}</TableCellTitleText>
          </TableCellTitle>
          {data?.provenance?.valueCell?.map((value, index) => (
            <TableCellContents
              key={index}
              id={index}
              length={data?.title?.valueCell?.length}
            >
              <TableContentIcon source={value?.icon} />
              <TableCellContentsText>{value.val}</TableCellContentsText>
            </TableCellContents>
          ))}
        </TableCell>
      )}
      {data?.recommendedNoTimes && (
        <TableCell>
          <TableCellTitle>
            <TableCellTitleText>
              {data?.recommendedNoTimes?.title}
            </TableCellTitleText>
          </TableCellTitle>
          {data?.recommendedNoTimes?.valueCell?.map((value, index) => (
            <TableCellContents
              key={index}
              id={index}
              length={data?.title?.valueCell?.length}
            >
              <TableCellContentsText>{value.val}</TableCellContentsText>
            </TableCellContents>
          ))}
        </TableCell>
      )}
      {data?.threadShape && (
        <TableCell>
          <TableCellTitle>
            <TableCellTitleText>{data?.threadShape?.title}</TableCellTitleText>
          </TableCellTitle>
          {data?.threadShape?.valueCell?.map((value, index) => (
            <TableCellContents
              key={index}
              id={index}
              length={data?.title?.valueCell?.length}
            >
              <TableContentIcon source={value?.icon} />
              <TableCellContentsText>{value.val}</TableCellContentsText>
            </TableCellContents>
          ))}
        </TableCell>
      )}
      {data?.effect && (
        <TableCell>
          <TableCellTitle>
            <TableCellTitleText>{data?.effect?.title}</TableCellTitleText>
          </TableCellTitle>
          {data?.effect?.valueCell?.map((value, index) => (
            <TableCellContents
              key={index}
              id={index}
              length={data?.title?.valueCell?.length}
            >
              <TableCellContentsText>{value.val}</TableCellContentsText>
            </TableCellContents>
          ))}
        </TableCell>
      )}
      {data?.duration && (
        <TableCell>
          <TableCellTitle>
            <TableCellTitleText>{data?.duration?.title}</TableCellTitleText>
          </TableCellTitle>
          {data?.duration?.valueCell?.map((value, index) => (
            <TableCellContents
              key={index}
              id={index}
              length={data?.title?.valueCell?.length}
            >
              <TableContentIcon source={value?.icon} />
              <TableCellContentsText>{value.val}</TableCellContentsText>
            </TableCellContents>
          ))}
        </TableCell>
      )}
      {data?.downtime && (
        <TableCell>
          <TableCellTitle>
            <TableCellTitleText>{data?.downtime?.title}</TableCellTitleText>
          </TableCellTitle>
          {data?.downtime?.valueCell?.map((value, index) => (
            <TableCellContents
              key={index}
              id={index}
              length={data?.title?.valueCell?.length}
            >
              <TableContentIcon source={value?.icon} />
              <TableCellContentsText>{value.val}</TableCellContentsText>
            </TableCellContents>
          ))}
        </TableCell>
      )}
      {data?.levelOfPain && (
        <TableCell>
          <TableCellTitle>
            <TableCellTitleText>{data?.levelOfPain?.title}</TableCellTitleText>
          </TableCellTitle>
          {data?.levelOfPain?.valueCell?.map((value, index) => (
            <TableCellContents
              key={index}
              id={index}
              length={data?.title?.valueCell?.length}
            >
              <TableContentIcon source={value?.icon} />
              <TableCellContentsText>{value.val}</TableCellContentsText>
            </TableCellContents>
          ))}
        </TableCell>
      )}
    </Table>
  );
};

export default SurgeryTable;
