import React, { ReactElement } from "react";
import {
  Dot,
  HighlightBg,
  SurgeryNotice,
  SurgeryNoticeInfo,
  SurgeryNoticeInfoText,
  SurgeryNoticeTitle,
  SurgeryNoticeTitleIcon,
  SurgeryNoticeTitleText,
} from "./styled";
import { bottomComment, bottomCommentTitle } from "@src/screens/sampleDatas";
import commentIcon from "@assets/icons/icon-comment.png";
import { globalStyles } from "@src/styles/modules/global";

const BottomComment = (): ReactElement => {
  return (
    <SurgeryNotice>
      <SurgeryNoticeTitle>
        <SurgeryNoticeTitleIcon source={commentIcon} resizeMode="contain" />
        <HighlightBg style={globalStyles.highlight} />
        <SurgeryNoticeTitleText style={globalStyles.fontB}>
          {bottomCommentTitle}
        </SurgeryNoticeTitleText>
      </SurgeryNoticeTitle>
      {bottomComment.split("\n").map((item, index) => (
        <SurgeryNoticeInfo key={index}>
          <Dot style={globalStyles.dot} />
          <SurgeryNoticeInfoText style={globalStyles.fontM}>
            {item}
          </SurgeryNoticeInfoText>
        </SurgeryNoticeInfo>
      ))}
    </SurgeryNotice>
  );
};

export default BottomComment;
