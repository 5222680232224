import { Color } from "@src/constants";
import styled from "styled-components/native";
import { StyleSheet } from "react-native";

export const RadioButtonItems = styled.View``;

export const RadioButtonItem = styled.View`
  flex-direction: row;
  align-items: center;
  margin-bottom: ${({ last }: { last: any }) => (last ? "" : "24px")};
`;

export const RadioButtonText = styled.Text`
  font-size: 16px;
  font-family: "fontM";
`;

export const SelectInput = styled.TextInput`
  width: 100%;
  height: 160px;
  padding: 20px 16px;
  font-size: 16px;
  border-radius: 16px;
  border: 1px solid ${Color.LIGHT_GREY_FIVE};
  outline-color: transparent;
  outline-width: 0;
  outline-style: none;
`;

export const styles = StyleSheet.create({
  radioStyle: {
    width: "100%",
    alignItems: "flex-start",
  },
});
