import React, { useState, useRef, useEffect, useCallback } from "react";
import { FlatList, ScrollView, Dimensions, StatusBar } from "react-native";
import Toast from "react-native-fast-toast";
import { Helmet } from "react-helmet-async";
import { useIsFocused } from "@react-navigation/native";
import { useNavigation } from "@react-navigation/native";
import { useRecoilState } from "recoil";
import "swiper/css";
import "swiper/css/thumbs";

import {
  SurgeryListContents,
  SearchBarWrap,
  Icon,
  IconImage,
  SearchBar,
  SearchButton,
  SearchInput,
  Comparison,
  ComparisonBtn,
  ComparisonBtnText,
  SearchBarInner,
  SurgeryItemWrap,
  ComparisonWrap,
  ComparisonInfoContents,
  ComparisonInfoContentsText,
  NoData,
  NoDataText,
  ButtonContents,
  DeleteButton,
  DeleteIcon,
} from "./styled";
import {
  Maybe,
  SlidePopup,
  SurgeryItem,
  TabSlider,
  TabSubSlider,
  TooltipItem,
} from "@src/components/presentational";
import {
  globalStyles,
  ToastCloseBtn,
  ToastCloseBtnImage,
} from "@src/styles/modules/global";
import {
  searchPlaceholder,
  comparisonBtnText,
  comparisonInfoText,
  popupButton,
  popupImage,
  surgeryNoData,
  surgeryMaxSelectedText,
  popupClostBtn,
} from "@src/screens/sampleDatas";
import { CommonService, SurgeryService } from "@src/services";
import { ICategory, List } from "@src/types";
import searchIcon from "@assets/icons/icon-search.svg";
import closeIcon from "@assets/icons/icon-close.png";
import cancelIcon from "@assets/icons/icon-cancel.svg";
import { Color } from "@src/constants";
import { guidePopupState, userTypeState } from "@src/store";
import { Layouts } from "@src/components/container";
import { useAuth } from "@src/hooks/auth";
import { analytics } from "@src/hooks/analytics";

/**
 * 시술 목록
 */

const SurgeryListScreen = () => {
  const { isLoggedIn } = useAuth();
  const navigation = useNavigation<any>();

  const isFocused = useIsFocused();
  const { height } = Dimensions.get("window");

  const itemMinCount = 2;
  const itemMaxCount = 3;
  const pageSize = 100;

  const centerToastRef = useRef(null);
  const bottomToastRef = useRef(null);

  const [userType, setUserType] = useRecoilState(userTypeState);

  const [surgeryList, setSurgeryList] = useState<List[]>([]);

  const [category, setCategory] = useState<ICategory[]>([]);
  const [keyword, setKeyword] = useState<string>("");
  const [menuId, setMenuId] = useState<number>(1);
  const [pageNum, setPageNum] = useState<number>(0);

  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [toastId, setToastId] = useState<number>();
  const [itemIdx, setItemIdx] = useState<number>();

  const [isActiveComparison, setIsActiveComparison] = useState<boolean>(false);
  const [isVisibleComparisonInfo, setIsVisibleComparisonInfo] =
    useState<boolean>(true);
  const [isVisiblePopup, setIsVisiblePopup] = useState<boolean>(true);
  const [closePopup, setClosePopup] = useRecoilState(guidePopupState);

  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [noData, setNoData] = useState<boolean>(false);

  const [isLoginPopup, setIsLoginPopup] = useState<boolean>(false);

  const getCategory = async (): Promise<void> => {
    const { data } = await CommonService.getCategoryList();
    setCategory(data);
  };

  const getSurgeryList = async (): Promise<void> => {
    try {
      const conditions = {
        keyword,
        menuId,
        pageNum,
        pageSize,
      };

      const { data } = await SurgeryService.getSurgeryList(conditions);
      setSurgeryList(data?.content);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChecked = (id, idx, checked, text) => {
    const items = [...surgeryList];

    if (checked) {
      items[idx].checked = true;
      setSurgeryList(items);

      const checkedItemId = surgeryList
        .filter((item) => item.checked === true)
        .map((id) => id.id);
      const checkedItemCount = surgeryList.filter(
        (item) => item.checked === true
      ).length;

      handleCheckedMaxCount(checkedItemCount, idx);
      handleVisibleCheckedItem(id, checkedItemCount, text);

      setSelectedItems([...checkedItemId]);
      setIsVisibleComparisonInfo(false);
    } else {
      items[idx].checked = false;
      setSurgeryList(items);

      const checkedItemId = surgeryList
        .filter((item) => item.checked === true)
        .map((id) => id.id);
      const checkedItemCount = surgeryList.filter(
        (item) => item.checked === true
      ).length;

      bottomToastRef.current.hide(id);

      setSelectedItems(checkedItemId);
      handleCheckedMinCount(checkedItemCount);
    }

    setToastId(id);
    setItemIdx(idx);
  };

  const handleVisibleCheckedItem = (id, length, text) => {
    if (length <= itemMaxCount) {
      bottomToastRef.current.show(text, { id });
    }
  };

  const handleCheckedMaxCount = (length, idx) => {
    const items = [...surgeryList];

    switch (true) {
      case length === itemMaxCount:
        setIsActiveComparison(true);
        break;
      case length === itemMinCount:
        setIsActiveComparison(true);
        break;
      case length > itemMaxCount:
        items[idx].checked = false;
        centerToastRef.current.show(surgeryMaxSelectedText);
        break;
      default:
        setIsActiveComparison(false);
    }
  };

  const handleCheckedMinCount = (length) => {
    switch (true) {
      case length === itemMaxCount:
        setIsActiveComparison(true);
        break;
      case length === itemMinCount:
        setIsActiveComparison(true);
        break;
      case length === 0:
        setIsVisibleComparisonInfo(true);

      default:
        setIsActiveComparison(false);
    }
  };

  const handleCloseBottomToast = () => {
    const items = [...surgeryList];
    items[itemIdx].checked = false;
    setSurgeryList(items);

    const checkedItemId = surgeryList
      .filter((item) => item.checked === true)
      .map((id) => id.id);
    const checkedItemCount = surgeryList.filter(
      (item) => item.checked === true
    ).length;

    bottomToastRef.current.hide(toastId);

    setSelectedItems(checkedItemId);
    handleCheckedMinCount(checkedItemCount);
  };

  const handleComparison = useCallback(() => {
    const checkedItem = surgeryList.filter((item) => item.checked === true);

    const id = checkedItem.map((item) => item.id);
    const list = checkedItem.map((item) => item.title).toString();

    navigation.navigate("comparison", {
      id: id,
      list: list,
    });

    analytics("Surgery Comparison List", { surgery_list: list });
  }, [surgeryList]);

  const handleChangeTabId = (id, name) => {
    if (id) {
      surgeryList?.map((item) => (item.checked = false));
    }

    setKeyword("");
    setMenuId(id);
    setSelectedItems([]);
    setIsActiveComparison(false);
    bottomToastRef.current.hideAll();

    analytics("Category Name", { category_name: name });
  };

  const handleSearchValue = () => {
    getSurgeryList();

    if (!keyword || keyword === "" || !surgeryList) {
      setNoData(true);
    } else {
      setNoData(false);
      analytics("Search Keyword", { search_keyword: keyword });
    }

    setSelectedItems([]);
    setIsActiveComparison(false);
    setIsVisibleComparisonInfo(true);

    if (bottomToastRef.current) {
      bottomToastRef.current.hideAll();
    }
  };

  const handleSearchValueClear = () => {
    setKeyword("");
  };

  const handleCloseNoticePopup = () => {
    setIsVisiblePopup(false);
  };

  const handleNotVisiblePopup = () => {
    setClosePopup(false);
    setIsVisiblePopup(false);
  };

  const checkedUserType = () => {
    if (userType) {
      switch (userType) {
        case "dropped":
          navigation.push("withdrawalMember");
          break;
        case "sleeper":
          navigation.push("inactiveMember");
          break;
      }
    }
  };

  const handleLoginPopup = () => {
    if (!isLoggedIn) {
      setIsLoginPopup(true);
    }
  };

  useEffect(() => {
    const items = [...surgeryList];
    const disabledCheckbox = items.map((item) => (item.checked = false));

    return () => {
      setSelectedItems([]);
      setIsActiveComparison(false);
      setIsVisiblePopup(false);
      setIsVisibleComparisonInfo(true);

      if (bottomToastRef.current) {
        bottomToastRef.current.hideAll();
      }
      disabledCheckbox;
    };
  }, [isFocused]);

  useEffect(() => {
    getCategory();
  }, []);

  useEffect(() => {
    checkedUserType();
  }, [userType]);

  useEffect(() => {
    getSurgeryList();
  }, [menuId]);

  useEffect(() => {
    setIsVisiblePopup(true);
    setClosePopup(true);

    if (closePopup === false) {
      setIsVisiblePopup(false);
      setClosePopup(false);
    }

    return () => {
      setIsVisiblePopup(false);
    };
  }, []);

  return (
    <>
      <StatusBar barStyle="light-content" backgroundColor="#6432e9" />
      <Helmet>
        <title>촉촉박사</title>
        <meta
          name="description"
          content="촉촉한 피부, 나만 모르고 있던 시술 정보가 궁금하다면? | 촉촉박사"
          data-react-helmet="true"
        />
      </Helmet>
      <Layouts logo mainMode hamburgerMode>
        <TabSlider
          tabId={menuId}
          onPressTab={handleChangeTabId}
          tabItems={category}
          thumbsSwiper={setThumbsSwiper}
        />
        <ScrollView
          bounces={false}
          scrollEventThrottle={1}
          showsVerticalScrollIndicator={false}
        >
          <TabSubSlider tabItems={category} thumbsSwiper={thumbsSwiper} />
          <SurgeryListContents>
            <SearchBarWrap>
              <SearchBarInner>
                <SearchBar>
                  <SearchInput
                    value={keyword}
                    onChangeText={(value) => setKeyword(value)}
                    onSubmitEditing={handleSearchValue}
                    placeholder={searchPlaceholder}
                    placeholderTextColor={Color.LIGHT_GREY_FOUR}
                  />
                  <ButtonContents>
                    {keyword && (
                      <DeleteButton
                        onPress={handleSearchValueClear}
                        underlayColor="transparent"
                      >
                        <DeleteIcon resizeMode="contain" source={cancelIcon} />
                      </DeleteButton>
                    )}
                    <SearchButton
                      onPress={handleSearchValue}
                      underlayColor="transparent"
                    >
                      <Icon>
                        <IconImage resizeMode="contain" source={searchIcon} />
                      </Icon>
                    </SearchButton>
                  </ButtonContents>
                </SearchBar>
              </SearchBarInner>
            </SearchBarWrap>
            <Maybe
              test={!isVisiblePopup}
              children={<TooltipItem small positionTop={0} title="시술비교" />}
            />
            <SurgeryItemWrap>
              <FlatList
                data={surgeryList}
                keyExtractor={(item, index) => `key${index}`}
                renderItem={({ index, item }) => (
                  <SurgeryItem
                    {...item}
                    title={item.title}
                    keyword={item.keyword}
                    price={item.averagePrice}
                    duration={item.duration}
                    pain={item.levelOfPain}
                    provenance={item.provenance}
                    tag={item.tag}
                    checked={item.checked}
                    onChangeCheck={(checked) => {
                      handleChecked(item.id, index, checked, item.title);
                    }}
                    onPressList={() => {
                      navigation.navigate("surgeryDetail", {
                        id: item.id,
                      }),
                        analytics("Surgery Name", {
                          surgery_name: item.title,
                        });
                    }}
                  />
                )}
              />
              <Maybe
                test={!surgeryList}
                children={
                  <NoData>
                    <NoDataText>{surgeryNoData}</NoDataText>
                  </NoData>
                }
              />
            </SurgeryItemWrap>
          </SurgeryListContents>
        </ScrollView>
        <ComparisonWrap>
          <Maybe
            test={isVisibleComparisonInfo}
            children={
              <ComparisonInfoContents>
                <ComparisonInfoContentsText>
                  {comparisonInfoText}
                </ComparisonInfoContentsText>
              </ComparisonInfoContents>
            }
          />
          <Comparison>
            <ComparisonBtn
              activeOpacity={1}
              isActive={isActiveComparison}
              onPress={isActiveComparison ? handleComparison : undefined}
            >
              <ComparisonBtnText>{comparisonBtnText}</ComparisonBtnText>
            </ComparisonBtn>
          </Comparison>
        </ComparisonWrap>
        <Toast
          ref={bottomToastRef}
          placement="bottom"
          offset={52}
          duration={0}
          icon={
            <ToastCloseBtn onPress={handleCloseBottomToast}>
              <ToastCloseBtnImage source={closeIcon} />
            </ToastCloseBtn>
          }
          style={globalStyles.bottomToast}
          textStyle={globalStyles.bottomToastText}
        />
      </Layouts>
      <Toast
        ref={centerToastRef}
        offset={height / 2}
        duration={800}
        style={globalStyles.centerToast}
        textStyle={globalStyles.centerToastText}
      />
      <Maybe
        test={isVisiblePopup}
        children={
          <SlidePopup
            images={popupImage}
            btnText={popupButton}
            clostBtnText={popupClostBtn}
            onClickCloseBtn={handleNotVisiblePopup}
            onClickBtn={() => {
              handleCloseNoticePopup();
              analytics("Popup", "");
            }}
          />
        }
      />
    </>
  );
};

export default SurgeryListScreen;
