import React, { ReactElement } from "react";
import { ScrollView, View } from "react-native";
import {
  CloseBtn,
  CloseBtnIcon,
  Dimmed,
  styles,
  ids,
  BottomPopupContainer,
  BottomPopupHeader,
  BottomPopupContents,
  TitleText,
  InfoText,
  HighlightText,
  Icon,
  BottomBtn,
  BottomBtnContents,
  BottomBtnText,
  TextInput,
} from "./styled";
import closeIcon from "@assets/icons/icon-close-black.svg";

interface BottomPopupProps {
  isOpen?: any;
  onClose?: () => void;
  title?: string;
  info?: string;
  highlightInfo?: string;
  icon?: any;
  btnText?: string;
  onClick?: () => void;
  input?: any;
  inputValue?: string;
  placeholder?: string;
  handleInputValue?: (value) => void;
}

const BottomPopup = ({
  isOpen,
  onClose,
  title,
  info,
  highlightInfo,
  icon,
  btnText,
  onClick,
  input,
  inputValue,
  placeholder,
  handleInputValue,
}: BottomPopupProps): ReactElement => {
  return (
    <>
      <Dimmed
        isOpen={isOpen}
        onStartShouldSetResponder={() => true}
        onResponderGrant={() => onClose()}
      />
      <View dataSet={{ media: ids.popup }} style={styles.popup}>
        <BottomPopupContainer>
          <BottomPopupHeader>
            <CloseBtn onPress={onClose} underlayColor="transparent">
              <CloseBtnIcon source={closeIcon} resizeMode="contain" />
            </CloseBtn>
          </BottomPopupHeader>
          <BottomPopupContents>
            <TitleText>{title}</TitleText>
            <InfoText>{info}</InfoText>
            <HighlightText>{highlightInfo}</HighlightText>
            {icon && <Icon source={icon} resizeMode="contain" />}
            {input && (
              <TextInput
                value={inputValue}
                placeholder={placeholder}
                placeholderTextColor="#c6c6c6"
                onChangeText={(value) => handleInputValue(value)}
              />
            )}
            <BottomBtnContents>
              <BottomBtn activeOpacity={1} onPress={onClick}>
                <BottomBtnText>{btnText}</BottomBtnText>
              </BottomBtn>
            </BottomBtnContents>
          </BottomPopupContents>
        </BottomPopupContainer>
      </View>
    </>
  );
};

export default BottomPopup;
