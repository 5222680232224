import { atom } from "recoil";
import { TempUser, User } from "@src/types";
import { localStorageEffect } from "..";
import { STRG_ACCESS_TOKEN_KEY, STRG_REFRESH_TOKEN_KEY } from "@src/constants";

/**
 * 회원 관련 State
 */

// token
export const tokenState = atom<string>({
  key: "tokenState",
  default: null as any,
  effects_UNSTABLE: [localStorageEffect(STRG_ACCESS_TOKEN_KEY)],
});

// refresh token
export const refreshTokenState = atom<string>({
  key: "refreshTokenState",
  default: null as any,
  effects_UNSTABLE: [localStorageEffect(STRG_REFRESH_TOKEN_KEY)],
});

export const tempUserState = atom<TempUser>({
  key: "tempUserState",
  default: null as any,
});

// user state
export const userState = atom<User>({
  key: "userState",
  default: null as any,
});

// simple user
export const simpleUserState = atom<User>({
  key: "simpleUserState",
  default: null as any,
});

// entire user
export const entireUserState = atom<User>({
  key: "entireUserState",
  default: null as any,
});

// user type
export const userTypeState = atom<string>({
  key: "userType",
  default: null as any,
});

// 탈퇴 state
export const withdrawalDateState = atom<any>({
  key: "withdrawalDateState",
  default: null as any,
});
