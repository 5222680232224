import styled from "styled-components/native";
import { Color } from "@src/constants";
import { StyleSheet } from "react-native";
import MediaStyle from "react-native-media-query";

export const Dimmed = styled.View`
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  position: ${({ isOpen }: { isOpen: boolean }) => (isOpen ? "fixed" : "")};
  background-color: ${({ isOpen }: { isOpen: boolean }) =>
    isOpen ? "rgba(0, 0, 0, 0.54)" : ""};
  z-index: ${({ isOpen }: { isOpen: boolean }) => (isOpen ? "101" : "")};
`;

export const HamburgerContainer = styled.View`
  position: fixed;
  bottom: 0;
  height: 100%;
  right: ${({ isOpen }: { isOpen: boolean }) => (isOpen ? "0" : "-280px")};
  top: 0;
  transition: right 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  width: 280px;
  background-color: ${Color.WHITE};
  z-index: 1001;
`;

export const HamburgerContents = styled.View`
  position: relative;
  height: 100%;
  min-height: 100%;
  padding: 20px 20px 0 20px;
`;

export const HamburgerContentsHeader = styled.View`
  margin-top: 16px;
  padding-bottom: 34px;
  align-items: center;
  border-bottom-width: 1px;
  border-bottom-color: #e2e2e2;
`;

export const CloseBtn = styled.TouchableHighlight`
  align-items: flex-end;
`;

export const CloseBtnIcon = styled.Image`
  width: 24px;
  height: 24px;
`;

export const HeaderLogo = styled.View`
  margin-bottom: 16px;
`;

export const HeaderLogoIcon = styled.Image`
  width: 60px;
  height: 60px;
  border-radius: 50%;
`;

export const HeaderTitleText = styled.Text`
  font-size: 14px;
  font-family: "fontM";
  letter-spacing: -0.35px;
  color: ${Color.PUPPLE};
`;

export const HeaderInfoText = styled.Text`
  margin-top: 5px;
  font-size: 20px;
  font-family: "fontB";
  letter-spacing: -0.5px;
  color: ${Color.PUPPLE};
`;

export const LoginBtn = styled.TouchableOpacity`
  width: 100%;
  height: 52px;
  margin-top: 24px;
  border-radius: 26px;
  align-items: center;
  justify-content: center;
  background-color: ${Color.PUPPLE};
`;

export const LoginBtnText = styled.Text`
  font-size: 16px;
  font-family: "fontB";
  color: ${Color.WHITE};
`;

export const HamburgerContentsMenu = styled.View`
  height: 100%;
  padding-top: 20px;
`;

export const MenuListContainer = styled.View`
  height: 100%;
  justify-content: center;
`;

export const MenuList = styled.View`
  margin: 16px 0;
`;

export const MenuListContents = styled.TouchableOpacity`
  width: fit-content;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const MenuListItem = styled.View`
  flex-direction: row;
`;

export const Logout = styled.TouchableOpacity`
  width: fit-content;
`;

export const MenuListItemText = styled.Text`
  font-size: 16px;
  font-family: "fontB";
  letter-spacing: -0.4px;
`;

export const MenuListIcon = styled.Image`
  width: 22px;
  height: 8px;
  margin-left: 4px;
`;

export const MenuListMoveIconContents = styled.View``;

export const MenuListDropdownContents = styled.View``;

export const MenuListDropdown = styled.TouchableOpacity`
  position: relative;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const MenuListMoveIcon = styled.Image`
  width: 25px;
  height: 25px;
`;

export const DropdownMenuList = styled.View`
  padding: 16px 0 0 16px;
`;

export const DropdownMenu = styled.TouchableOpacity`
  width: fit-content;
  margin: 16px 0;
  justify-content: center;
`;

export const DropdownMenuText = styled.Text`
  font-family: "fontM";
  font-size: 15px;
`;

export const HamburgerContentsFooter = styled.View`
  flex: 1;
  justify-content: flex-end;
  padding-bottom: 20px;
  margin-top: 140px;
  height: 100px;
`;

export const SnsBtnContainer = styled.View`
  flex-direction: row;
  justify-content: center;
`;

export const SnsBtn = styled.TouchableOpacity`
  flex-direction: row;
  padding: 0 10px;
`;

export const SnsBtnIcon = styled.Image`
  width: 40px;
  height: 40px;
`;

export const TermsContainer = styled.View`
  margin-top: 25px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const TermsBtn = styled.TouchableOpacity`
  height: 10px;
  border-right-width: ${({ index }: { index: number }) =>
    index === 0 ? "1px" : ""};
  border-right-color: ${({ index }: { index: number }) =>
    index === 0 ? Color.LIGHT_GREY_FIVE : ""};
  padding: 0 16px;
  justify-content: center;
`;

export const TermsBtnText = styled.Text`
  font-size: 12px;
  font-family: "fontM";
  color: ${Color.LIGHT_GREY_FOUR};
`;

export const menuStyles = StyleSheet.create({
  text: {
    fontSize: 16,
    fontWeight: "500",
  },
  accordion: {
    overflow: "hidden",
  },
  activeText: {
    color: Color.PUPPLE,
  },
});

export const { ids, styles, fullStyles } = MediaStyle.create({
  menuList: {
    height: "100%",
    justifyContent: "center",
    "@media (max-width: 480px)": {
      height: "auto",
    },
  },
  menuContainer: {},
});
