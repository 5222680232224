import {
  SkinMbtiScreen,
  SkinMbtiDetailScreen,
  SkinMbtiResultScreen,
} from "@src/screens";

/**
 * Skin Mbti Configs
 */

export default [
  {
    name: "skinMbti",
    component: SkinMbtiScreen,
    options: {
      title: "",
    },
  },
  {
    name: "skinMbtiDetail",
    component: SkinMbtiDetailScreen,
    options: {
      title: "",
    },
  },
  {
    name: "skinMbtiResult",
    component: SkinMbtiResultScreen,
    options: {
      title: "",
    },
  },
];
