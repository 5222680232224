import React, { ReactElement, useRef, useState } from "react";
import { Text, Animated } from "react-native";
import { useNavigation } from "@react-navigation/native";
import Accordion from "react-native-collapsible/Accordion";

import {
  itemStyles,
  PlanRecommendListContainer,
  PlanRecommendListContents,
  PlanRecommendListPrice,
  Category,
  ListName,
  PlanRecommendListItemHeader,
  AccordionDetailContents,
  AccordionHeader,
  PlanRecommendListItemHeaderInner,
  Summary,
  InfoContents,
  Info,
  PlanRecommendListItemPrice,
  SubSummary,
  AccordionHeaderIcon,
  AccordionContentsHighlightIcon,
  AccordionContentsInfo,
  Dot,
  Notice,
  PlanRecommendMaking,
  PlanRecommendMakingBtn,
  PlanRecommendBottomNotice,
  NoticeHighlight,
  NoticeHighlightIcon,
  HighlightBg,
  NoticeHighlightText,
  CommentInfo,
  CommentInfoText,
} from "./styled";
import { globalStyles } from "@src/styles/modules/global";
import { TooltipItem } from "..";
import { REGEX_NUMBER_COMMA } from "@src/constants";
import { SkinPlanService } from "@src/services";
import expandDownIcon from "@assets/icons/icon-expand-less.svg";
import expandMoreIcon from "@assets/icons/icon-expand-more.svg";
import noticeIcon from "@assets/icons/icon-notice.png";
import commentIcon from "@assets/icons/icon-comment.png";
import { analytics } from "@src/hooks/analytics";
import { useRecoilState } from "recoil";
import { SkinPlanSelectedList } from "@src/store";

interface PlanRecommendListProps {
  title?: string;
  price?: any;
  data: any;
  highlightText?: string;
  comment?: any;
}

const PlanRecommendList = ({
  title,
  price,
  data,
  highlightText,
  comment,
}: PlanRecommendListProps): ReactElement => {
  const navigation = useNavigation<any>();

  const animatedController = useRef(new Animated.Value(0)).current;

  const [planSelectedList, setPlanSelectedList] =
    useRecoilState(SkinPlanSelectedList);
  const [listData, setListData] = useState<any>(
    data?.plannerRetrievePlanItemList
  );
  const [toggleId, setToggleId] = useState<any>([]);

  const arrowAngle = animatedController.interpolate({
    inputRange: [0, 1],
    outputRange: ["0rad", `${Math.PI}rad`],
  });

  const toggleListItem = (activeSections) => {
    setToggleId(activeSections);
  };

  const submitPlanList = async () => {
    let list = [];

    for (let i = 0; i < listData.length; i++) {
      list.push({
        dermatologyProcedureName: listData[i].dermatologyProcedureName,
        dermatologyProcedureId: listData[i].dermatologyProcedureId,
        sortNo: listData[i].sortNo,
      });
    }

    try {
      const conditions = {
        plannerItemSubmitList: list,
      };

      const { plannerId } = await SkinPlanService.postSkinPlanList(conditions);
      if (plannerId) {
        analytics("Skin Plan Save Recommend List", {
          save_recommend_list: planSelectedList,
        });
        navigation.push("mySkinPlan");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const renderHeader = (item) => {
    return (
      <PlanRecommendListItemHeader>
        <PlanRecommendListItemHeaderInner>
          <Category>
            <Text style={[itemStyles.whiteText, itemStyles.xxsText]}>
              {item.categoryName}
            </Text>
          </Category>
          <ListName>
            <Text style={[itemStyles.puppleText, itemStyles.lgText]}>
              {item.dermatologyProcedureName}
            </Text>
          </ListName>
        </PlanRecommendListItemHeaderInner>

        <AccordionHeader>
          <Animated.View
            style={{
              transform: [{ rotateZ: arrowAngle }],
              width: 24,
              height: 24,
            }}
          >
            <AccordionHeaderIcon
              source={
                toggleId.includes(item.sortNo) ? expandDownIcon : expandMoreIcon
              }
              resizeMode="contain"
            />
          </Animated.View>
        </AccordionHeader>

        <Summary>
          <Text style={[itemStyles.smText, itemStyles.lineHeight]}>
            {item.summary}
          </Text>
        </Summary>
      </PlanRecommendListItemHeader>
    );
  };

  const renderContent = (item) => {
    return (
      <AccordionDetailContents>
        <InfoContents>
          <Info>
            <Text style={itemStyles.xsText}>시술 단위</Text>
            <Text style={itemStyles.smText}>{item.treatUnit}</Text>
          </Info>
          <Info>
            <Text style={itemStyles.xsText}>권장 횟수</Text>
            <Text style={itemStyles.smText}>{item.recommendedNoTimes}</Text>
          </Info>
          <Info>
            <Text style={itemStyles.xsText}>지속 기간</Text>
            <Text style={itemStyles.smText}>{item.duration}</Text>
          </Info>
          <Info>
            <Text style={itemStyles.xsText}>효과 보임</Text>
            <Text style={itemStyles.smText}>{item.effect}</Text>
          </Info>
          <Info>
            <Text style={itemStyles.xsText}>통증 정도</Text>
            <Text style={itemStyles.smText}>{item.levelOfPain}</Text>
          </Info>
          <Info>
            <Text style={itemStyles.xsText}>다운 타임</Text>
            <Text style={itemStyles.smText}>{item.downtime}</Text>
          </Info>
        </InfoContents>
        <SubSummary>
          <Notice>
            <AccordionContentsHighlightIcon
              source={noticeIcon}
              resizeMode="contain"
            />
            <Text style={[itemStyles.smText, itemStyles.bold]}>
              이런 분들께 추천드려요!
            </Text>
          </Notice>
          {item.summary.split("\n").map((item, index) => (
            <AccordionContentsInfo key={index}>
              <Dot style={globalStyles.dot} />
              <Text style={itemStyles.sText}>{item}</Text>
            </AccordionContentsInfo>
          ))}
        </SubSummary>
      </AccordionDetailContents>
    );
  };

  const renderFooter = (item) => {
    return (
      <PlanRecommendListItemPrice>
        <Text style={[itemStyles.smText, itemStyles.bold]}>평균 가격</Text>
        <Text style={[itemStyles.mdText, itemStyles.pupple]}>
          {item.priceForPlanner.toString().replace(REGEX_NUMBER_COMMA, ",")} 원
        </Text>
      </PlanRecommendListItemPrice>
    );
  };

  return (
    <PlanRecommendListContainer>
      <PlanRecommendListContents>
        <PlanRecommendListPrice>
          <Text style={[itemStyles.title, itemStyles.whiteText]}>
            총 예상 금액
          </Text>
          <Text style={[itemStyles.xlText, itemStyles.whiteText]}>
            {price?.toString().replace(REGEX_NUMBER_COMMA, ",")}
            <Text style={[itemStyles.mdText, itemStyles.whiteText]}> 원</Text>
          </Text>
        </PlanRecommendListPrice>

        <Accordion
          activeSections={toggleId}
          sections={listData}
          renderFooter={renderFooter}
          renderHeader={renderHeader}
          renderContent={renderContent}
          onChange={toggleListItem}
          underlayColor="#ffffff"
          sectionContainerStyle={itemStyles.section}
          headerStyle={itemStyles.headerSection}
        />

        <PlanRecommendMaking>
          <TooltipItem
            large
            positionTop="auto"
            positionLeft="50%"
            positionBottom={55}
            opacity={1}
            transform
            title="나만의 피부 플랜을 관리할 수 있어요!"
          />
          <PlanRecommendMakingBtn
            style={globalStyles.fullBtn}
            onPress={submitPlanList}
            activeOpacity={1}
          >
            <Text style={[itemStyles.mdText, itemStyles.puppleText]}>
              내 피부 플랜에 저장하기
            </Text>
          </PlanRecommendMakingBtn>
        </PlanRecommendMaking>
      </PlanRecommendListContents>

      <PlanRecommendBottomNotice>
        <NoticeHighlight>
          <NoticeHighlightIcon source={commentIcon} resizeMode="contain" />
          <HighlightBg style={globalStyles.highlight} />
          <NoticeHighlightText style={globalStyles.fontB}>
            {highlightText}
          </NoticeHighlightText>
        </NoticeHighlight>
        {comment?.split("\n").map((item, index) => (
          <CommentInfo key={index}>
            <Dot style={[globalStyles.dot, itemStyles.commentDot]} />
            <CommentInfoText key={index} style={globalStyles.fontM}>
              {item}
            </CommentInfoText>
          </CommentInfo>
        ))}
      </PlanRecommendBottomNotice>
    </PlanRecommendListContainer>
  );
};

export default PlanRecommendList;
