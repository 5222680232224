import styled from "styled-components/native";
import { StyleSheet } from "react-native";
import { Color } from "@src/constants";

export const BottomFixContentsWrap = styled.View`
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: ${({ editor }: { editor?: boolean }) =>
    editor ? Color.WHITE : Color.PUPPLE};
`;

export const BottomFixContentsInner = styled.View``;

export const BottomFixButtonContents = styled.View`
  width: 100%;
  height: 64px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: ${({ page }: { page?: string }) =>
    page === "community" ? "16px 0 24px 0" : ""};
`;

export const ButtonContents = styled.View`
  width: calc(100% / 2);
  align-items: center;
`;

export const LeftButton = styled.TouchableOpacity`
  flex-direction: row;
`;

export const Bar = styled.View`
  width: 1px;
  height: 16px;
  background-color: #e0e0e0;
`;

export const LeftButtonText = styled.Text`
  margin-left: 4px;
  font-size: ${({ editor }: { editor?: boolean }) =>
    editor ? "14px" : "16px"};
  font-family: "fontM";
  color: ${({ editor }: { editor?: boolean }) =>
    editor ? Color.BLACK_TWO : Color.WHITE};
`;

export const RightButton = styled.TouchableOpacity`
  width: calc(100% / 2);
  flex-direction: row;
`;

export const RightButtonText = styled.Text`
  margin-left: 4px;
  font-size: ${({ editor }: { editor?: boolean }) =>
    editor ? "14px" : "16px"};
  font-family: "fontM";
  color: ${({ editor }: { editor?: boolean }) =>
    editor ? Color.BLACK_TWO : Color.WHITE};
`;

export const styles = StyleSheet.create({
  button: {
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 26,
  },
  btnAlign: {
    alignItems: "center",
    justifyContent: "center",
  },
  icon: {
    width: 24,
    height: 24,
  },
});
