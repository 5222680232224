/**
 * 피부 플랜 Datas
 */

export const skinPlanIntro = [
  {
    title: "3분 컷!\n단 4단계면 완성",
    img: require("@assets/images/plan/plan-intro01.png"),
  },
  {
    title: "나만을 위한\n시술 추천 받기",
    img: require("@assets/images/plan/plan-intro02.png"),
  },
  {
    title: "추천 받은 후엔\n내 피부 플랜 관리",
    img: require("@assets/images/plan/plan-intro03.png"),
  },
  {
    title: "내 플랜은 내 맘대로!\n커스터마이징하기",
    img: require("@assets/images/plan/plan-intro04.png"),
  },
];

export const skinPlanTest = {
  totalQuestions: 4,
  questions: [
    {
      type: "mbtiConfirm",
    },
    {
      title: "어떤 이유로\n피부 시술을 받고 싶으신가요?",
      subTitle: "상황에 가장 알맞는 플랜을 만들어 드릴게요",
      info: "👩🏻 지금 나는",
      questionId: 1,
      list: [
        {
          img: require("@assets/images/plan/plan-question-img01.png"),
          text: "얼굴 관리를 시작해\n보고 싶어요",
          choiceId: 1,
          sortNo: 1,
        },
        {
          img: require("@assets/images/plan/plan-question-img02.png"),
          text: "결혼 준비 중이에요\n",
          choiceId: 2,
          sortNo: 2,
        },
        {
          img: require("@assets/images/plan/plan-question-img03.png"),
          text: "중요한 행사/모임을\n앞두고 있어요",
          choiceId: 3,
          sortNo: 3,
        },
        {
          img: require("@assets/images/plan/plan-question-img04.png"),
          text: "효도 선물을\n해드리고 싶어요",
          choiceId: 4,
          sortNo: 4,
        },
        {
          img: require("@assets/images/plan/plan-question-img05.png"),
          text: "나에게 필요한\n시술을 알고 싶어요",
          choiceId: 5,
          sortNo: 5,
        },
        {
          img: require("@assets/images/plan/plan-question-img06.png"),
          text: "피부 상태가\n엉망이에요",
          choiceId: 6,
          sortNo: 6,
        },
      ],
      type: "imgList",
    },
    {
      title: "좋아요!\n조금 더 자세하게 알려주세요",
      info: "🤔 가장 고민되는 부분은",
      questionId: 2,
      list: [
        {
          text: "피부결",
          choiceId: 7,
          sortNo: 1,
        },
        {
          text: "모공",
          choiceId: 8,
          sortNo: 2,
        },
        {
          text: "이중턱",
          choiceId: 9,
          sortNo: 3,
        },
        {
          text: "블랙헤드",
          choiceId: 10,
          sortNo: 4,
        },
        {
          text: "기미잡티",
          choiceId: 11,
          sortNo: 5,
        },
        {
          text: "피부톤",
          choiceId: 12,
          sortNo: 6,
        },
        {
          text: "주름",
          choiceId: 13,
          sortNo: 7,
        },
        {
          text: "여드름",
          choiceId: 14,
          sortNo: 8,
        },
      ],
      type: "textList",
    },
    {
      title:
        "이제 마지막 단계예요!\n걱정하지 마세요 😉\n내 정보는 외부에 공개되지 않아요",
      info: "🪞 내 얼굴은",
      questionId: 3,
      list: [
        {
          img: require("@assets/images/plan/plan-question-img07.png"),
          text: "살이 없는 편이에요",
          choiceId: 15,
          sortNo: 1,
        },
        {
          img: require("@assets/images/plan/plan-question-img08.png"),
          text: "살이 많은 편이에요",
          choiceId: 16,
          sortNo: 2,
        },
      ],
      info2: "🤕 아픈 걸 잘 참으시는 편인가요?",
      questionId2: 4,
      list2: [
        {
          text: "놉! 아픈 건 못 참아요",
          choiceId: 17,
          sortNo: 1,
        },
        {
          text: "글쎄요? 웬만하면 괜찮을 거예요",
          choiceId: 18,
          sortNo: 2,
        },
        {
          text: "네! 얼굴을 위해서라면 참을 수 있어요",
          choiceId: 19,
          sortNo: 2,
        },
      ],
      type: "imgList2",
    },
    {
      type: "textList",
    },
  ],
};

export const skinPlanPriceRecommendTitle =
  "설정한 예산 내에서 이런 시술들을 받으실 수 있어요!";

export const skinPlanPriceDrRecommendTitle =
  "예산을 조금 더 높이시면 이런 시술 조합도 가능해요!";

export const skinPlanRecommendItem = {
  content: [
    {
      category: "스킨부스터",
      name: "리쥬란힐러",
      summary:
        "여기에는 시술에 대한 짧은 설명이 들어갑니다. 공백 포함 50자 이내로 맞춰보겠습니다.",
      info: [
        {
          title: "시술 단위",
          text: "얼굴 전체 2cc",
        },
        {
          title: "권장 횟수",
          text: "4주 간격 3회",
        },
        {
          title: "지속 기간",
          text: "6-12개월",
        },
        {
          title: "효과 보임",
          text: "2주 후",
        },
        {
          title: "통증 정도",
          text: "많이 아파요",
        },
        {
          title: "다운 타임",
          text: "3-5일",
        },
      ],
      infoSummary:
        "통증이 무서워서 리프팅 시술이 꺼려지는 분\n피부톤 개선과 피부 탄력 강화를 한 번에 해결하고 싶은 분\n얼굴 탄력이 없어 고민이신 분",
      averagePrice: "300,000",
      id: 0,
      open: false,
    },
    {
      category: "리프팅",
      name: "티타늄 리프팅",
      summary:
        "여기에는 시술에 대한 짧은 설명이 들어갑니다. 공백 포함 50자 이내로 맞춰보겠습니다.",
      info: [
        {
          title: "시술 단위",
          text: "얼굴 전체 2cc",
        },
        {
          title: "권장 횟수",
          text: "4주 간격 3회",
        },
        {
          title: "지속 기간",
          text: "6-12개월",
        },
        {
          title: "효과 보임",
          text: "2주 후",
        },
        {
          title: "통증 정도",
          text: "많이 아파요",
        },
        {
          title: "다운 타임",
          text: "3-5일",
        },
      ],
      infoSummary:
        "통증이 무서워서 리프팅 시술이 꺼려지는 분\n피부톤 개선과 피부 탄력 강화를 한 번에 해결하고 싶은 분\n얼굴 탄력이 없어 고민이신 분",
      averagePrice: "300,000",
      id: 1,
      open: false,
    },
    {
      category: "리프팅",
      name: "티타늄 리프팅",
      summary:
        "여기에는 시술에 대한 짧은 설명이 들어갑니다. 공백 포함 50자 이내로 맞춰보겠습니다.",
      info: [
        {
          title: "시술 단위",
          text: "얼굴 전체 2cc",
        },
        {
          title: "권장 횟수",
          text: "4주 간격 3회",
        },
        {
          title: "지속 기간",
          text: "6-12개월",
        },
        {
          title: "효과 보임",
          text: "2주 후",
        },
        {
          title: "통증 정도",
          text: "많이 아파요",
        },
        {
          title: "다운 타임",
          text: "3-5일",
        },
      ],
      infoSummary:
        "통증이 무서워서 리프팅 시술이 꺼려지는 분\n피부톤 개선과 피부 탄력 강화를 한 번에 해결하고 싶은 분\n얼굴 탄력이 없어 고민이신 분",
      averagePrice: "300,000",
      id: 1,
      open: false,
    },
  ],
};

export const rangeItems = [
  {
    id: 0,
    level: "B",
    title: "리쥬란힐러 + 티타늄 리프팅 + LDM",
    price: "900,000",
  },
  {
    id: 1,
    level: "A",
    title: "티타늄 리프팅",
    price: "90,000",
  },
  {
    id: 2,
    level: "C",
    title: "리쥬란힐러 + 티타늄 리프팅 + 헐리우드 토닝",
    price: "1,900,000",
  },
];

export const skinPlanDrRecommendItem = {
  content: [
    {
      category: "스킨부스터",
      name: "리쥬란힐러",
      summary:
        "여기에는 시술에 대한 짧은 설명이 들어갑니다. 공백 포함 50자 이내로 맞춰보겠습니다.",
      info: [
        {
          title: "시술 단위",
          text: "얼굴 전체 2cc",
        },
        {
          title: "권장 횟수",
          text: "4주 간격 3회",
        },
        {
          title: "지속 기간",
          text: "6-12개월",
        },
        {
          title: "효과 보임",
          text: "2주 후",
        },
        {
          title: "통증 정도",
          text: "많이 아파요",
        },
        {
          title: "다운 타임",
          text: "3-5일",
        },
      ],
      infoSummary:
        "통증이 무서워서 리프팅 시술이 꺼려지는 분\n피부톤 개선과 피부 탄력 강화를 한 번에 해결하고 싶은 분\n얼굴 탄력이 없어 고민이신 분",
      averagePrice: "300,000",
      id: 0,
      open: false,
    },
    {
      category: "리프팅",
      name: "티타늄 리프팅",
      summary:
        "여기에는 시술에 대한 짧은 설명이 들어갑니다. 공백 포함 50자 이내로 맞춰보겠습니다.",
      info: [
        {
          title: "시술 단위",
          text: "얼굴 전체 2cc",
        },
        {
          title: "권장 횟수",
          text: "4주 간격 3회",
        },
        {
          title: "지속 기간",
          text: "6-12개월",
        },
        {
          title: "효과 보임",
          text: "2주 후",
        },
        {
          title: "통증 정도",
          text: "많이 아파요",
        },
        {
          title: "다운 타임",
          text: "3-5일",
        },
      ],
      infoSummary:
        "통증이 무서워서 리프팅 시술이 꺼려지는 분\n피부톤 개선과 피부 탄력 강화를 한 번에 해결하고 싶은 분\n얼굴 탄력이 없어 고민이신 분",
      averagePrice: "300,000",
      id: 1,
      open: false,
    },
  ],
};

export const planRecommendCommentTitle = "잠깐!";

export const planRecommendComment =
  "추천된 시술들은 1회 기준 가격으로 산정되었어요! 여러 회차 시술을 받으실 경우, 총예산이 올라가는 점을 참고해 주세요.\n개인의 상태에 따라 시술 불가한 경우가 있을 수 있어요. 실제 시술을 받기 전에는 반드시 전문의와 상담하셔야 해요!";

export const planMakingTip = "피부 플랜 이용 TIP";

export const planMakingTopComment =
  "그때 이 시술받고 광명 찾았는데 이 시술은 가성비가 너무 떨어져! 그동안 받은 피부 시술들! 머릿속으로만 기억하셨나요? \n내 피부 플랜에서 깔끔하게 정리하고 기록해 보세요! 추천받은 시술 조합 또는 내가 원하는 시술 조합으로 피부 플랜을 만들고 저장할 수 있어요. \n시술받은 병원 정보를 저장해요. \n간단한 메모를 남길 수 있어요. \n내 플랜에 대한 별점을 기록해요.";

export const planMakingList = [
  {
    id: 0,
    category: "스킨부스터",
    title: "리쥬란힐러",
  },
  {
    id: 1,
    category: "리프팅",
    title: "티타늄 리프팅",
  },
];

export const myPlanListDetail = [
  {
    category: "스킨부스터",
    title: "리쥬란힐러",
    price: 300000,
    date: "23.11.02",
    memo: "온다리프팅은 병원에서 추천",
    rating: 5,
    type: "proceeding",
    id: 1,
  },
  {
    category: "여드름/모공",
    title: "제네시스 토닝",
    price: 200000,
    date: "23.11.02",
    memo: "온다리프팅은 병원에서 추천",
    rating: 5,
    type: "before",
    status: "incomplete",
    id: 2,
  },
  {
    category: "",
    title: "온다 리프팅",
    price: 350000,
    date: "23.11.02",
    memo: "온다리프팅은 병원에서 추천",
    rating: 5,
    type: "complete",
    id: 3,
  },
];

// export const myPlanList = {
//   list: [
//     {
//       category: "스킨부스터",
//       title: "리쥬란힐러 + 울쎄라 + 온다 리프팅",
//       price: 300000,
//       memo: "온다리프팅은 병원에서 추천 온다리프팅은 병원에서 추천 온다리프팅은 병원에서 추천 온다리프팅은 병원에서 추천",
//       rating: 5,
//       type: "proceeding",
//       id: 1,
//     },
//     {
//       category: "여드름/모공",
//       title: "리쥬란힐러 + 울트라V리프팅 + 온다 리프팅",
//       price: 200000,
//       memo: "온다리프팅은 병원에서 추천",
//       rating: 5,
//       type: "before",
//       status: "incomplete",
//       id: 2,
//     },
//     {
//       category: "",
//       title: "리쥬란힐러 + 울쎄라 + 온다 리프팅",
//       price: 350000,
//       memo: "온다리프팅은 병원에서 추천",
//       rating: 5,
//       type: "complete",
//       id: 3,
//     },
//   ],
// };

export const myPlanList = [
  {
    id: 0,
    category: "진행중",
    title: "리쥬란힐러 + 울쎄라 + 온다 리프팅",
    type: "proceeding",
    summary: [
      {
        id: 0,
        title: "예상 가격",
        info: "1,100,000 원",
        type: "info",
      },
      {
        id: 1,
        title: "실제 가격",
        info: "293,000 원",
        type: "info",
        status: "incomplete",
      },
      {
        id: 2,
        title: "한줄 메모",
        info: "온다리프팅은 병원에서 추천 받아서 했는데 너무 좋아요",
        type: "memo",
      },
      {
        id: 3,
        title: "플랜 평가",
        type: "rating",
      },
    ],
    list: [
      {
        category: "스킨부스터",
        title: "리쥬란힐러",
        summary: [
          {
            title: "평균 가격",
            price: "300,000 원",
            date: "23.11.02",
          },
          {
            title: "실제 가격",
            type: "input",
          },
        ],
        id: 1,
      },
      {
        category: "여드름/모공",
        title: "제네시스 토닝",
        summary: [
          {
            title: "평균 가격",
            price: "300,000 원",
            date: "23.11.02",
          },
          {
            title: "실제 가격",
            type: "input",
          },
        ],
        id: 2,
      },
      {
        category: "",
        title: "온다 리프팅",
        summary: [
          {
            title: "평균 가격",
            price: "300,000 원",
            date: "23.11.02",
          },
          {
            title: "실제 가격",
            type: "input",
          },
        ],
        id: 3,
      },
    ],
  },
  {
    id: 1,
    category: "시작전",
    title: "리쥬란힐러 + 울트라V리프팅 + 제네시스 토닝",
    type: "before",
    summary: [
      {
        id: 0,
        title: "예상 가격",
        info: "1,100,000 원",
        type: "info",
      },
      {
        id: 1,
        title: "실제 가격",
        info: "293,000 원",
        type: "info",
      },
      {
        id: 2,
        title: "한줄 메모",
        info: "온다리프팅은 병원에서 추천 받아서 했는데 너무 좋아요",
      },
      {
        id: 3,
        title: "플랜 평가",
        type: "rating",
      },
    ],
  },
  {
    id: 2,
    category: "완료",
    title: "플래티넘PTT + 울트라V리프팅 + 제네시스 토닝",
    type: "complete",
    summary: [
      {
        id: 0,
        title: "예상 가격",
        info: "1,100,000 원",
        type: "info",
      },
      {
        id: 1,
        title: "실제 가격",
        info: "293,000 원",
        type: "info",
      },
      {
        id: 2,
        title: "한줄 메모",
        info: "온다리프팅은 병원에서 추천 받아서 했는데 너무 좋아요",
        type: "memo",
      },
      {
        id: 3,
        title: "플랜 평가",
        type: "rating",
      },
    ],
  },
];

export const mySkinPlanDetailComment =
  "직접 입력된 시술은 예상 가격 정보를 알 수 없어요.\n예상 가격은 각 시술 선택일 기준 평균 가격의 합으로, 추후 변동될 수 있어요.";

export const popupTitle = "저장하지 않고 돌아가시겠어요?";

export const popupInfo =
  "저장하기 버튼을 누르지 않고 나가시면\n수정한 모든 내용이 사라져요!";

export const deletePopupTitle = "정말 이 플랜을 삭제 하시겠어요?";

export const deletePopupInfo = "삭제된 플랜은 복구할 수 없어요!";
