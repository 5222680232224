import qs from "querystring";
import { ISurgeryListRequest, ISurgeryListResponse } from "@src/types";
import RestService from "../RestService";

const PREFIX_URL = `/v1/surgery`;

/**
 * 피부 시술 API
 */

class SurgeryService {
  /**
   * 피부 시술 아이템
   */
  public getSurgeryItem = async (id): Promise<any> => {
    const url = `${PREFIX_URL}/surgeryItem?ids=${id}`;

    try {
      const response = await RestService.get(url);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  /**
   * 피부 시술 목록
   */
  public getSurgeryList = async ({
    keyword,
    menuId,
    pageNum,
    pageSize,
  }: ISurgeryListRequest): Promise<ISurgeryListResponse> => {
    const url = `${PREFIX_URL}/surgeryItemList?search-keyword=${keyword}&menu-id=${menuId}&page-idx=${pageNum}&page-size=${pageSize}`;

    try {
      const response: ISurgeryListResponse = await RestService.get(url);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  /**
   * 피부 시술 비교 상세
   */
  public getSurgeryItemTable = async (payload): Promise<any> => {
    const url = `${PREFIX_URL}/surgeryItemTable?${qs.stringify(payload)}`;

    try {
      const response = await RestService.get(url);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

export default new SurgeryService();
