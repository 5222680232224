import axios, {
  AxiosRequestConfig,
  AxiosError,
  AxiosInstance,
  AxiosResponse,
} from "axios";
import { STRG_ACCESS_TOKEN_KEY } from "@src/constants";
import { ApiError } from "@src/types";
import env from "../../environments";
import { ERR_EXPIRED_TOKEN } from "@src/constants";
import tokenService from "./TokenService";

interface IRestService {
  get: (url: string, payload?: any, isSecureRequest?: boolean) => Promise<any>;
  post: (url: string, payload?: any, isSecureReqest?: boolean) => Promise<any>;
  put: (url: string, payload?: any, isSecureReqest?: boolean) => Promise<any>;
  patch: (url: string, payload?: any, isSecureReqest?: boolean) => Promise<any>;
  delete: (
    url: string,
    payload?: any,
    isSecureReqest?: boolean
  ) => Promise<any>;
  request: (config: AxiosRequestConfig) => Promise<any>;
}

const axiosConfig = {
  baseURL: env.API_URL,
  validateStatus: (status) => status < 400,
};

let isOnRefreshing: boolean;
let requestQueue: any[];
let api: AxiosInstance;

isOnRefreshing = false;
requestQueue = [];
api = axios.create({
  baseURL: env.API_URL,
  validateStatus: (status: number): boolean => status < 400,
});

const RestService = {
  get: null,
  post: null,
  put: null,
  patch: null,
  delete: null,
  request: null,
};

/**
 * 성공한 요청에 대한 응답 결과 핸들링 함수
 * @param {AxiosResponse | Promise<AxiosResponse>} response 성공 응답 객체
 */
const responseSuccessHandler = (
  response: AxiosResponse
): AxiosResponse | Promise<AxiosResponse> => {
  const { data } = response;
  return data || response;
};

/**
 * 실패한 요청에 대한 응답 결과 핸들링 함수
 * @param {AxiosError} error 에러 응답 객체
 */
const responseErrorHandler = async (error: AxiosError): Promise<any> => {
  const { config, response } = error;

  if (!response) {
    return Promise.reject(error.toJSON());
  }

  if (response.data) {
    const { message } = response.data as ApiError;

    switch (message) {
      case ERR_EXPIRED_TOKEN: {
        try {
          if (isOnRefreshing) {
            return pushRequestToReqestQueue(
              async (token: string): Promise<any> => {
                const resp = await retryRequest(config, token);
                return Promise.resolve(responseSuccessHandler(resp));
              }
            );
          }

          isOnRefreshing = true;

          const token = await tokenService.refresh();
          onRefreshed(token);
          isOnRefreshing = false;

          const resp = await retryRequest(config, token);
          return Promise.resolve(responseSuccessHandler(resp));
        } catch (error) {
          isOnRefreshing = false;
          redirect();
          return Promise.reject(error);
        }
      }
      default: {
        return Promise.reject(response.data);
      }
    }
  } else {
    return Promise.reject(error.toJSON());
  }
};

api.interceptors.response.use(responseSuccessHandler, responseErrorHandler);

const retryRequest = (
  config: AxiosRequestConfig,
  token: string
): Promise<any> => {
  const originRequest = config;

  if (originRequest.headers.Authorization) {
    originRequest.headers.Authorization = `Bearer ${token}`;
  }
  return axios(originRequest);
};

const pushRequestToReqestQueue = (request: any): Promise<any> => {
  requestQueue.push(request);
  return requestQueue[requestQueue.length - 1];
};

const onRefreshed = (token: string): void => {
  [...requestQueue].map((request) => request(token));
  requestQueue = [];
};

const redirect = (): Promise<void> => {
  tokenService.cleanTokens();
  window.location.href = "/surgeryList";
  return Promise.reject();
};

/**
 * 요청 정보 반환 함수
 * @param customConfig 요청정보에 추가적으로 담을 옵션
 */
const getRequestConfig = async (
  isSecureRequest: boolean,
  customConfig?: AxiosRequestConfig,
  data?: AxiosRequestConfig
): Promise<AxiosRequestConfig> => {
  let config: AxiosRequestConfig = {};
  try {
    if (customConfig) {
      config = Object.assign(config, customConfig);
    }

    if (data) {
      config.data = {
        data,
      };
    }

    if (isSecureRequest) {
      const accessToken = localStorage.getItem(STRG_ACCESS_TOKEN_KEY);

      if (accessToken) {
        config.headers = {
          Authorization: `Bearer ${accessToken}`,
        };
      }
    }

    return config;
  } catch (error) {
    return config;
  }
};

/**
 *
 * @param url 요청 보낼 주소
 * @param payload 요청시 담을 데이터(HTTP Body)
 */
RestService.get = async (
  url: string,
  payload: any = null,
  isSecureRequest: boolean = true
): Promise<any> => {
  const config: AxiosRequestConfig = await getRequestConfig(isSecureRequest, {
    data: payload,
  });
  return api.get(url, config);
};

/**
 * POST 요청
 * @param url 요청을 보낼 주소
 * @param payload 요청시 담을 데이터(HTTP Body)
 */
RestService.post = async (
  url: string,
  payload: any = null,
  isSecureRequest: boolean = true
): Promise<any> => {
  const config: AxiosRequestConfig = await getRequestConfig(isSecureRequest);
  return api.post(url, payload, config);
};

/**
 * PUT 요청
 * @param url 요청을 보낼 주소
 * @param payload 요청시 담을 데이터(HTTP Body)
 */
RestService.put = async (
  url: string,
  payload: any = null,
  isSecureRequest: boolean = true
): Promise<any> => {
  const config = await getRequestConfig(isSecureRequest);
  return api.put(url, payload, config);
};

/**
 * PATCH 요청
 * @param url 요청을 보낼 주소
 * @param payload 요청시 담을 데이터(HTTP Body)
 */
RestService.patch = async (
  url: string,
  payload: any = null,
  isSecureRequest: boolean = true
): Promise<any> => {
  const config = await getRequestConfig(isSecureRequest);
  return api.patch(url, payload, config);
};

/**
 * DELETE 요청
 * @param url 요청을 보낼 주소
 * @param isSecureRequest 보안요청 여부
 */
RestService.delete = async (
  url: string,
  data?: any,
  isSecureRequest: boolean = true
): Promise<any> => {
  const config = await getRequestConfig(isSecureRequest, { data: data });
  return api.delete(url, config);
};

/**
 * 파일 핸들링 관련 요청이나, RestService의 기본적인
 * get/post/put/delete 함수가 지원하지 않는 형태의 요청시 사용
 * @param config 요청정보
 */
RestService.request = (config: AxiosRequestConfig): Promise<any> => {
  return api.request(config);
};

export default RestService as IRestService;
