import config from "./src/config.json";

/**
 * Environments
 */

const SHARE_MAP = {
  PROJECT_SYMBOL: "drmoist",
};

const ENV_MAP = {
  development: {
    API_URL: "https://devpi.ai2u.co.kr/nspc",
    REDIRECT_URL: "https://dev.drmoist.co.kr/redirect",
    KAKAO_API_KEY: "d520c910e8f8032c7a7b3819dd3431db",
  },
  production: {
    API_URL: "https://spi.drmoist.co.kr/nspc",
    REDIRECT_URL: "https://drmoist.co.kr/redirect",
    KAKAO_API_KEY: "d520c910e8f8032c7a7b3819dd3431db",
  },
};

export default {
  ...SHARE_MAP,
  ...(ENV_MAP[config.RN_ENV] || ENV_MAP.development),
};
