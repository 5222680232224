import React, { useEffect, useRef, useState } from "react";
import { View, StatusBar, Image, Text, Dimensions } from "react-native";
import { Helmet } from "react-helmet-async";
import Toast from "react-native-fast-toast";
import { useNavigation } from "@react-navigation/native";

import { Layouts } from "@src/components/container";
import {
  ids,
  SkinMbtiBtn,
  SkinMbtiBtnContents,
  SkinMbtiBtnText,
  SkinMbtiContainer,
  SkinMbtiContents,
  SkinMbtiCountText,
  SkinMbtiImg,
  SkinMbtiIntroImage,
  SkinMbtiSnsBtn,
  SkinMbtiSnsContents,
  SkinMbtiTitle,
  SkinMbtiTitleText,
  styles,
} from "../styled";
import mbtiBg from "@assets/images/mbti/mbti-bg.png";
import mbtiLeftBar from "@assets/images/mbti/mbti-left-bar.svg";
import mbtiRightBar from "@assets/images/mbti/mbti-right-bar.svg";
import mbtiIntroImg from "@assets/images/mbti/mbti-intro-img.png";
import mbtiKakaoIcon from "@assets/images/mbti/mbti-sns-kakao.png";
import mbtiShareIcon from "@assets/images/mbti/mbti-sns-share.png";
import { MbtiService } from "@src/services";
import { ShareKakao, ShareSns } from "@src/components/presentational";
import {
  clipboardCopyFailText,
  mbtiLinkCopyText,
  mbtiShareText,
} from "@src/screens/sampleDatas";
import { globalStyles } from "@src/styles/modules/global";
import { useRecoilState } from "recoil";
import { mbtiResultState, resultLoadingState } from "@src/store";
import { analytics } from "@src/hooks/analytics";

/**
 * 피부 MBTI 온보딩
 */

const SkinMbtiScreen = () => {
  const { height } = Dimensions.get("window");
  const navigation = useNavigation<any>();
  const shareToastRef = useRef(null);

  const [mbtiResult, setMbtiResult] = useRecoilState(mbtiResultState);
  const [isLoading, setIsLoading] = useRecoilState(resultLoadingState);

  const [count, setCount] = useState<number>();

  const handleMbtiStart = () => {
    postCount();

    navigation.navigate("skinMbtiDetail", { state: 1 });
  };

  const postCount = async () => {
    try {
      await MbtiService.postMbtiCount();
    } catch (error) {
      console.log(error);
    }
  };

  const getCount = async () => {
    try {
      const data = await MbtiService.getMbtiCount();
      setCount(data.stackedCount);
    } catch (error) {
      console.log(error);
    }
  };

  const dataToShare: ShareData = {
    title: mbtiShareText,
    url: window.location.href,
  };

  const handleShareButton = async () => {
    const result = await ShareSns(dataToShare);

    if (result === "copiedToClipboard") {
      shareToastRef.current.show(mbtiLinkCopyText);
    } else if (result === "failed") {
      shareToastRef.current.show(clipboardCopyFailText);
    }
  };

  useEffect(() => {
    getCount();
    setIsLoading(false);
  }, []);

  return (
    <>
      <StatusBar barStyle="light-content" backgroundColor="#6432e9" />
      <Helmet>
        <title>피부 MBTI | 촉촉박사</title>
        <meta
          name="description"
          content="촉촉한 피부, 나만 모르고 있던 시술 정보가 궁금하다면? | 촉촉박사"
          data-react-helmet="true"
        />
      </Helmet>
      <Layouts logo hamburgerMode>
        <SkinMbtiContainer style={globalStyles.dataList}>
          <SkinMbtiImg resizeMode="cover" source={mbtiBg} />
          <SkinMbtiContents>
            <View dataSet={{ media: ids.header }} style={styles.header}>
              <SkinMbtiTitle>
                <Image
                  source={mbtiLeftBar}
                  resizeMode="contain"
                  dataSet={{ media: ids.bar }}
                  style={styles.bar}
                />
                <SkinMbtiTitleText>[촉촉학개론] 제1장</SkinMbtiTitleText>
                <Image
                  source={mbtiRightBar}
                  resizeMode="contain"
                  dataSet={{ media: ids.bar }}
                  style={styles.bar}
                />
              </SkinMbtiTitle>
              <View
                dataSet={{ media: ids.titleContents }}
                style={styles.titleContents}
              >
                <Text dataSet={{ media: ids.title }} style={styles.title}>
                  내 피부 MBTI 파헤치기
                </Text>
              </View>
              <Text dataSet={{ media: ids.infoText }} style={styles.infoText}>
                레슬리 바우만의 ‘The Skin Type Solution’에서 분류한 16가지의
                피부타입으로 내 피부 타입 알아보기
              </Text>
            </View>
            <SkinMbtiIntroImage style={styles.infoImgContents}>
              <Image
                source={mbtiIntroImg}
                resizeMode="contain"
                dataSet={{ media: ids.infoImg }}
                style={styles.infoImg}
              />
            </SkinMbtiIntroImage>
            <View dataSet={{ media: ids.footer }} style={styles.footer}>
              <SkinMbtiBtnContents>
                <SkinMbtiBtn
                  activeOpacity={1}
                  onPress={() => {
                    handleMbtiStart();
                    setMbtiResult(null);
                    analytics("Skin Mbti Start", "");
                  }}
                >
                  <SkinMbtiBtnText>시작하기</SkinMbtiBtnText>
                  <SkinMbtiCountText>
                    참여한 촉촉박사 {count ?? count}명!
                  </SkinMbtiCountText>
                </SkinMbtiBtn>
              </SkinMbtiBtnContents>
              <SkinMbtiSnsContents>
                <SkinMbtiSnsBtn
                  onPress={() =>
                    ShareKakao(
                      window.location.href,
                      mbtiShareText,
                      "촉촉박사 | 피부 MBTI"
                    )
                  }
                >
                  <Image
                    source={mbtiKakaoIcon}
                    resizeMode="contain"
                    style={styles.snsIcon}
                  />
                </SkinMbtiSnsBtn>
                <SkinMbtiSnsBtn onPress={handleShareButton}>
                  <Image
                    source={mbtiShareIcon}
                    resizeMode="contain"
                    style={styles.snsIcon}
                  />
                </SkinMbtiSnsBtn>
              </SkinMbtiSnsContents>
            </View>
          </SkinMbtiContents>
        </SkinMbtiContainer>
      </Layouts>
      <Toast
        ref={shareToastRef}
        offset={height / 2}
        style={globalStyles.centerToast}
        textStyle={globalStyles.centerToastText}
      />
    </>
  );
};

export default SkinMbtiScreen;
