import React, { ReactElement } from "react";
import { ScrollView, StatusBar, View } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { Helmet } from "react-helmet-async";
import { Layouts } from "@src/components/container";
import {
  MypageCommonBox,
  MypageCommonBoxInfo,
  MypageCommonBoxInner,
  MypageCommonBoxTitle,
  MypageCommonInfoText,
  MypageCommonTitle,
  MypageCommonTitleText,
  MypageCommonTopContainer,
  PolicyContents,
  styles,
} from "./styled";
import { BottomFixButton } from "@src/components/presentational";
import { globalStyles } from "@src/styles/modules/global";

/**
 * 개인정보 처리방침
 */

const PrivacyPolicyScreen = (): ReactElement => {
  const navigation = useNavigation<any>();

  return (
    <>
      <StatusBar barStyle="light-content" backgroundColor="#6432e9" />
      <Helmet>
        <title>개인정보 처리방침 | 촉촉박사</title>
        <meta
          name="description"
          content="촉촉한 피부, 나만 모르고 있던 시술 정보가 궁금하다면? | 촉촉박사"
          data-react-helmet="true"
        />
      </Helmet>
      <Layouts logo transparent hamburgerMode>
        <ScrollView
          bounces={false}
          scrollEventThrottle={1}
          style={{ backgroundColor: "#ffffff" }}
          showsVerticalScrollIndicator={false}
        >
          <MypageCommonTopContainer>
            <MypageCommonTitle>
              <MypageCommonTitleText>개인정보 처리방침</MypageCommonTitleText>
            </MypageCommonTitle>
            <MypageCommonInfoText>
              촉촉박사는 정보통신망 이용 촉진, 개인정보보호법 등의 국내의
              개인정보 보호 법령을 철저히 준수해요.
            </MypageCommonInfoText>
          </MypageCommonTopContainer>
          <PolicyContents>
            <MypageCommonBox>
              <MypageCommonBoxInner>
                <MypageCommonBoxInfo>
                  ㈜에이아이투유(이하 “회사”)은 정보통신망 이용촉진,
                  개인정보보호법 등 정보통신서비스제공자가 준수하여야 할 국내의
                  개인정보 보호 관련 법령을 준수하며, 관련 법령에 의거한 자체
                  개인정보처리방침을 정하여 이용자 권익 보호에 최선을 다하고
                  있습니다. “회사”는 이를 자사 “홈페이지(www.ai2u.co.kr)” 및
                  “어플리케이션(스닥)” (이하 “서비스”)에 공개 및 적용되며 내용은
                  다음과 같습니다.
                </MypageCommonBoxInfo>
                <MypageCommonBoxTitle style={globalStyles.fontB}>
                  제1조 개인정보의 처리 목적 및 항목
                </MypageCommonBoxTitle>
                <MypageCommonBoxInfo>
                  “”회사””는 다음의 목적을 위하여 개인정보를 처리합니다.
                  처리하고 있는 개인정보는 다음 목적 이외의 용도로는 이용되지
                  않으며, 이용 목적이 변경되는 경우에는 별도의 동의를 받는 등
                  필요한 조치를 이행할 예정입니다.
                </MypageCommonBoxInfo>
                <MypageCommonBoxInfo>
                  “”회사””는 다음의 목적을 위하여 개인정보를 처리합니다.
                  처리하고 있는 개인정보는 다음 목적 이외의 용도로는 이용되지
                  않으며, 이용 목적이 변경되는 경우에는 별도의 동의를 받는 등
                  필요한 조치를 이행할 예정입니다.
                </MypageCommonBoxInfo>
              </MypageCommonBoxInner>
            </MypageCommonBox>
          </PolicyContents>
        </ScrollView>
        <View style={styles.bottomBtn}>
          <BottomFixButton
            fullBtnMode
            fullBtnText="확인"
            whiteBg
            onPressFullBtn={() =>
              navigation.canGoBack()
                ? navigation.goBack()
                : navigation.navigate("myinfo")
            }
          />
        </View>
      </Layouts>
    </>
  );
};

export default PrivacyPolicyScreen;
