import styled from "styled-components/native";

export const CategoryItemWrap = styled.View`
  margin-top: 20px;
`;

export const CategoryItemInner = styled.View`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const CategoryItemContents = styled.View`
  align-items: center;
  margin-bottom: 20px;
`;

export const CategoryImage = styled.Image`
  width: 45px;
  height: 45px;
`;

export const CategoryBtn = styled.TouchableOpacity`
  margin: 0 auto;
`;

export const CategoryText = styled.Text`
  text-align: center;
  font-size: 12px;
`;
