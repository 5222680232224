import styled from "styled-components/native";
import { StyleSheet } from "react-native";
import MediaStyle from "react-native-media-query";
import { Color } from "@src/constants";

export const FileUpload = styled.View`
  position: absolute;
  width: 90px;
  height: 20px;
  top: 0;
  left: 0;
`;

export const FileUploadInner = styled.View``;

export const UploadBtn = styled.TouchableOpacity``;

export const itemStyles = StyleSheet.create({
  whiteBg: {
    backgroundColor: Color.WHITE,
  },
  smText: {
    fontSize: 14,
    fontWeight: "500",
  },
  mdText: {
    lineHeight: 23,
    fontSize: 16,
  },
  mdTextB: {
    fontSize: 16,
    fontWeight: "bold",
  },
  mLText: {
    fontSize: 18,
    fontWeight: "bold",
  },
  lgText: {
    lineHeight: 33,
    fontSize: 24,
    fontWeight: "bold",
  },
  alignCenter: {
    alignItems: "center",
  },
  spaceBetween: {
    justifyContent: "space-between",
  },
  puppleText: {
    color: Color.PUPPLE,
  },
  icon: {
    width: 20,
    height: 20,
    marginRight: 4,
  },
  selectInput: {
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: 16,
    paddingRight: 10,
    paddingTop: 10,
    paddingBottom: 12,
    marginRight: 8,
    borderRadius: 24,
    backgroundColor: Color.WHITE,
  },
  image: {
    width: "100%",
    height: "100%",
  },
  bottomBtn: {
    position: "relative",
    width: "50%",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
  },
  fileInput: {
    width: 90,
    height: 20,
  },
});

export const { ids, styles, fullStyles } = MediaStyle.create({});
