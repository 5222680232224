/**
 * 커뮤니티 Sample Datas
 */

export const communityList = [
  {
    category: "시술정보공유",
    title:
      "촉촉토크제목이야촉촉토크제목이야촉촉토크제목이야촉촉토크제목이야촉촉촉촉토크제목이야촉촉토크제목이야촉촉토크제목이야촉촉토크제목이야촉촉",
    commentCount: 0,
    nickname: "닉네임은열두글자입니다만",
    date: "2024.01.01",
    viewCount: 99999,
    likeCount: 99999,
    id: 0,
  },
  {
    category: "시술정보공유",
    title:
      "촉촉토크제목이야촉촉토크제목이야촉촉토크제목이야촉촉토크제목이야촉촉",
    commentCount: 0,
    nickname: "닉네임은열두글자입니다만",
    date: "2024.01.01",
    viewCount: 99999,
    likeCount: 99999,
    id: 1,
  },
  {
    category: "시술정보공유",
    image: require("@assets/images/mbti/mbti-notice-img01.png"),
    title: "촉촉토크제목이야촉촉토크제목이야촉촉",
    commentCount: 0,
    nickname: "닉네임은열두글자입니다만",
    date: "2024.01.01",
    viewCount: 99999,
    likeCount: 99999,
    id: 2,
  },
  {
    category: "시술정보공유",
    image: require("@assets/images/mbti/mbti-notice-img01.png"),
    title: "촉촉토크제목이야촉촉토크제목이야촉촉",
    commentCount: 0,
    nickname: "닉네임은열두글자입니다만",
    date: "2024.01.01",
    viewCount: 99999,
    likeCount: 99999,
    id: 3,
  },
  {
    category: "시술정보공유",
    image: require("@assets/images/mbti/mbti-notice-img01.png"),
    title: "촉촉토크제목이야촉촉토크제목이야촉촉",
    commentCount: 0,
    nickname: "닉네임은열두글자입니다만",
    date: "2024.01.01",
    viewCount: 99999,
    likeCount: 99999,
    id: 4,
  },
  {
    category: "시술정보공유",
    image: require("@assets/images/mbti/mbti-notice-img01.png"),
    title: "촉촉토크제목이야촉촉토크제목이야촉촉",
    commentCount: 0,
    nickname: "닉네임은열두글자입니다만",
    date: "2024.01.01",
    viewCount: 99999,
    likeCount: 99999,
    id: 5,
  },
  {
    category: "시술정보공유",
    image: require("@assets/images/mbti/mbti-notice-img01.png"),
    title: "촉촉토크제목이야촉촉토크제목이야촉촉",
    commentCount: 0,
    nickname: "닉네임은열두글자입니다만",
    date: "2024.01.01",
    viewCount: 99999,
    likeCount: 99999,
    id: 6,
  },
  {
    category: "시술정보공유",
    image: require("@assets/images/mbti/mbti-notice-img01.png"),
    title: "촉촉토크제목이야촉촉토크제목이야촉촉",
    commentCount: 0,
    nickname: "닉네임은열두글자입니다만",
    date: "2024.01.01",
    viewCount: 99999,
    likeCount: 99999,
    id: 7,
  },
  {
    category: "시술정보공유",
    image: require("@assets/images/mbti/mbti-notice-img01.png"),
    title: "촉촉토크제목이야촉촉토크제목이야촉촉",
    commentCount: 0,
    nickname: "닉네임은열두글자입니다만",
    date: "2024.01.01",
    viewCount: 99999,
    likeCount: 99999,
    id: 8,
  },
];

export const communityCategory = [
  {
    title: "말머리 없음",
    id: 0,
    type: "",
  },
  {
    title: "일상 공유",
    id: 1,
    type: "",
  },
  {
    title: "시술 후기",
    id: 2,
    type: "",
  },
  {
    title: "추천 조합",
    id: 3,
    type: "",
  },
  {
    title: "고민 상담",
    id: 4,
    type: "",
  },
  {
    title: "시술 뉴스",
    id: 5,
    type: "",
  },
];

export const talkDetailMoreCategoryMe = [
  {
    title: "토크 수정",
    type: "update",
    id: 0,
  },
  {
    title: "카카오 공유하기",
    type: "kakao",
    id: 1,
  },
  {
    title: "링크 공유하기",
    type: "link",
    id: 2,
  },
  {
    title: "토크 삭제",
    type: "delete",
    id: 3,
  },
];

export const talkDetailMoreCategoryUser = [
  {
    title: "작성자 정보",
    url: "profile",
    id: 0,
  },
  {
    title: "카카오 공유하기",
    type: "kakao",
    id: 1,
  },
  {
    title: "링크 공유하기",
    type: "link",
    id: 2,
  },
  {
    title: "토크 신고",
    url: "communityReport",
    id: 3,
  },
];

export const talkDetailMoreCategoryAnonymous = [
  {
    title: "카카오 공유하기",
    type: "kakao",
    id: 0,
  },
  {
    title: "링크 공유하기",
    type: "link",
    id: 1,
  },
  {
    title: "토크 신고",
    url: "communityReport",
    id: 2,
  },
];

export const replyCategoryMe = [
  {
    title: "댓글 삭제",
    type: "delete",
  },
];

export const replyCategoryUser = [
  {
    title: "작성자 정보",
    url: "profile",
  },
  {
    title: "댓글 신고",
    url: "communityReport",
  },
];

export const replyCategoryAnonymous = [
  {
    title: "댓글 신고",
    url: "communityReport",
  },
];

export const popupTitle = "앗, 이럴수가!";

export const popupInfo =
  "커뮤니티에 토크를 쓰려면\n촉촉박사 로그인이 필요해요.";

export const popupSubInfo =
  "아래 버튼을 눌러보세요!\n아주 빠르고 간단하답니다.";

export const popupIcon = require("@assets/images/img-emoticon02.png");

export const popupBtn = "1초 만에 로그인하기";

export const editTalkCategory = ["촉촉 토크", "MBTI 토크"];

export const youtubePopupTitle = "YouTube 추가";

export const youtubePopupInfo =
  "https://youtu.be/[동영상코드] 형식의\n링크를 입력해 주세요.";

export const youtubePopupBtn = "Youtube 추가하기";

export const youtubeInput = "Youtube 링크를 입력하세요.";

export const cancelPopupTitle = "정말, 되돌아가실 건가요?";

export const cancelPopupInfo =
  "이미 작성한 내용이 있어요!\n작성을 취소하게 되면 작성한 내용은\n모두 사라진답니다.";

export const cancelPopupIcon = require("@assets/images/img-emoticon01.png");

export const cancelPopupBtn = "정말, 작성 취소하기";

export const communityDetailItem = [
  {
    category: "시술정보공유",
    title:
      "촉촉토크제목이야촉촉토크제목이야촉촉토크제목이야촉촉토크제목이야촉촉촉촉토크제목이야촉촉토크제목이야촉촉토크제목이야촉촉토크제목이야촉촉",
    commentCount: 100,
    nickname: "닉네임은열두글자입니다만",
    date: "2024.01.01",
    viewCount: 99999,
    likeCount: 0,
    profile: require("@assets/icons/icon-profile.png"),
    contents: [
      {
        img: require("@assets/images/img-emoticon01.png"),
      },
      {
        img: require("@assets/images/img-modal02.png"),
      },
      {
        img: require("@assets/images/img-modal03.png"),
      },
      {
        text: "토크 쓰기 내용 토크 쓰기 내용 토크 쓰기 내용 토크 쓰기 내용 토크 쓰기 내용 토크 쓰기 내용 토크 쓰기 내용 토크 쓰기 내용 토크 쓰기 내용 ",
      },
    ],
    comment: [
      {
        profile: require("@assets/icons/icon-profile.png"),
        nickname: "닉네임",
        type: "writer",
        time: "16:34",
      },
      {
        profile: require("@assets/icons/icon-profile.png"),
        nickname: "닉네임닉네임",
        type: "writer",
        time: "2023.01.01",
        text: "두번째 댓글입니다. 두번째 댓글입니다. 두번째 댓글입니다. 두번째 댓글입니다.",
        reply: [
          {
            profile: require("@assets/icons/icon-profile.png"),
            nickname: "닉네임닉네임",
            type: "writer",
            time: "2023.01.01",
            text: "두번째 댓글의 답글입니다.",
          },
          {
            profile: require("@assets/icons/icon-profile.png"),
            nickname: "닉네임닉네임",
            type: "writer",
            time: "2023.01.01",
            text: "두번째 댓글의 답글입니다.",
          },
        ],
      },
      {
        profile: require("@assets/icons/icon-profile.png"),
        nickname: "닉네임",
        type: "writer",
        time: "16:34",
        text: "댓글입니다. 댓글입니다. 댓글입니다. 댓글입니다. 댓글입니다. 댓글입니다. 댓글입니다. 댓글입니다. 댓글입니다. 댓글입니다. 댓글입니다. 댓글입니다. 댓글입니다.",
      },
    ],
    id: 0,
  },
];

export const deletePopupTitle = "정말, 삭제하실 건가요?";

export const deletePopupInfo =
  "실수로 눌렀다면 되돌아갈 수 있어요!\n하지만 정말 삭제하고 싶다면\n아래 버튼을 눌러주세요.";

export const deletePopupIcon = require("@assets/images/img-emoticon01.png");

export const deletePopupBtn = "정말,토크 삭제하기";

export const reportItems = [
  {
    label: "욕설, 비방, 차별, 혐오를 포함한 게시물",
    value: "욕설, 비방, 차별, 혐오를 포함한 게시물",
    id: 1,
  },
  {
    label: "홍보, 영리 목적의 게시물",
    value: "홍보, 영리 목적의 게시물",
    id: 2,
  },
  {
    label: "불법 정보 게시",
    value: "불법 정보 게시",
    id: 3,
  },
  {
    label: "음란, 청소년 유해 정보",
    value: "음란, 청소년 유해 정보",
    id: 4,
  },
  {
    label: "개인 정보 노출, 유포, 거래",
    value: "개인 정보 노출, 유포, 거래",
    id: 5,
  },
  {
    label: "도배 및 스팸성 정보",
    value: "도배 및 스팸성 정보",
    id: 6,
  },
  {
    label: "기타 (직접 입력)",
    value: "기타 (직접 입력)",
    type: "input",
    id: 7,
  },
];

export const reportErrorToast = "신고 사유를 선택해 주세요.";

export const reportConfirmToast =
  "신고가 접수되었어요.\n잘 검토하여 처리할게요!";

export const fileCountToastText = "이미지는 최대 3개까지만\n첨부할 수 있어요.";

export const contentsToastText = "제목 또는 내용을 입력해 주세요.";
