import styled from "styled-components/native";
import { Color } from "@src/constants";
import { StyleSheet } from "react-native";

export const MypageContainer = styled.View`
  height: 100%;
  background-color: ${Color.WHITE};
`;

export const MypageInfo = styled.View`
  height: 200px;
  align-items: center;
  justify-content: center;
  background-color: ${Color.PUPPLE};
`;

export const MypageInfoIcon = styled.View`
  padding-bottom: 16px;
`;

export const MypageInfoIconImg = styled.Image`
  width: 60px;
  height: 60px;
  border-radius: 50%;
`;

export const MypageInfoTitleText = styled.Text`
  margin-bottom: 5px;
  font-size: 14px;
  font-family: "fontM";
  letter-spacing: -0.35px;
  color: ${Color.WHITE};
`;

export const MypageInfoNameText = styled.Text`
  font-size: 20px;
  font-family: "fontB";
  letter-spacing: -0.5px;
  color: ${Color.WHITE};
`;

export const MypageList = styled.View`
  margin-top: 10px;
  padding: 0 20px;
`;

export const MyInfoContents = styled.View``;

export const MenuListItem = styled.View`
  height: 56px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const MenuList = styled.TouchableHighlight`
  height: 56px;
  justify-content: center;
  cursor: ${({ link }: { link?: any }) => (link ? "pointer" : "initial")};
`;

export const MenuListItemText = styled.Text`
  font-size: 16px;
  font-family: "fontM";
  letter-spacing: -0.4px;
`;

export const MenuSnsIcon = styled.Image`
  width: 40px;
  height: 40px;
`;

export const MenuMoveIcon = styled.Image`
  width: 24px;
  height: 24px;
`;

export const MypageCommonContainer = styled.View`
  padding: 0 20px;
  height: 100%;
  background-color: ${Color.WHITE};
`;

export const MypageCommonTopContainer = styled.View`
  padding: 0 20px 30px 20px;
  background-color: ${Color.WHITE};
`;

export const MypageCommonTitle = styled.View`
  padding: 40px 0 30px 0;
`;

export const MypageCommonTitleText = styled.Text`
  font-size: 24px;
  font-family: "fontB";
  letter-spacing: -0.6px;
  line-height: 35px;
`;

export const MypageCommonInfoText = styled.Text`
  font-size: 16px;
  letter-spacing: -0.4px;
  line-height: 24px;
`;

export const PolicyContents = styled.View`
  padding: 0 20px;
  background-color: ${Color.WHITE};
`;

export const MypageCommonContents = styled.View`
  padding: 0 20px;
  height: 100%;
  background-color: ${Color.WHITE};
`;

export const MypageCommonBox = styled.View`
  background-color: ${Color.GREY};
  border-radius: 16px;
`;

export const MypageCommonBoxInner = styled.View`
  padding: 20px 16px 0 16px;
`;

export const MypageCommonBoxTitle = styled.Text`
  margin-bottom: 20px;
  font-size: 14px;
  font-family: "fontB";
`;

export const MypageCommonBoxInfo = styled.Text`
  margin-bottom: 20px;
  flex-direction: row;
  font-family: "fontR";
  font-size: 14px;
`;

export const MypageCommonInput = styled.TextInput`
  width: 100%;
  height: 60px;
  padding-left: 16px;
  border-radius: 16px;
  border: ${({ error }: { error?: string }) =>
    error ? `1px solid ${Color.RED}` : `1px solid ${Color.LIGHT_GREY_FIVE}`};
  font-family: "fontM";
  font-size: 16px;
  outline-color: transparent;
  outline-width: 0;
  outline-style: none;
`;

export const CommonErrorMessage = styled.Text`
  margin-top: 8px;
  font-size: 14px;
  color: ${Color.RED};
`;

export const NicknameBtnContents = styled.View``;

export const NicknameBtn = styled.TouchableHighlight`
  margin-top: 20px;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

export const NicknameBtnImg = styled.Image`
  width: 24px;
  height: 24px;
  margin-right: 4px;
`;

export const NicknameBtnText = styled.Text`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-family: "fontB";
  color: ${Color.PUPPLE};
`;

export const CommentInput = styled.TextInput`
  height: 240px;
  margin-top: 8px;
  margin-bottom: 30px;
  padding: 20px 16px;
  border: 1px solid ${Color.PUPPLE};
  border-radius: 16px;
  font-size: 16px;
  outline-color: transparent;
  outline-width: 0;
  outline-style: none;
`;

export const MypageCommonCheck = styled.View`
  margin: 30px 0;
`;

export const MypageCommonCheckbox = styled.TouchableHighlight`
  flex-direction: row;
  align-items: center;
`;

export const MypageCommonCheckText = styled.Text`
  margin-left: 10px;
  font-size: 16px;
`;

export const styles = StyleSheet.create({
  info: {
    marginBottom: 8,
  },
  text: {
    fontSize: 16,
    fontFamily: "fontM",
  },
  infoText: {
    fontSize: 14,
    lineHeight: 20,
    fontFamily: "fontR",
  },
  highlight: {
    color: Color.PUPPLE,
    fontFamily: "fontB",
  },
  textGrey: {
    color: Color.LIGHT_GREY_FOUR,
  },
  select: {
    borderColor: Color.LIGHT_GREY_FIVE,
    borderRadius: 16,
    backgroundColor: Color.WHITE,
    height: 60,
  },
  inputSelect: {
    borderColor: Color.PUPPLE,
    borderRadius: 16,
    backgroundColor: Color.WHITE,
    height: 60,
  },
  icon: {
    width: 24,
    height: 24,
  },
  bottomBtn: {
    height: 96,
    backgroundColor: "#ffffff",
  },
  infoContents: {
    marginTop: 10,
  },
});
