import styled from "styled-components/native";
import { Color } from "@src/constants";
import { StyleSheet } from "react-native";

export const Authontainer = styled.View`
  height: 100%;
  background-color: ${Color.WHITE};
`;

export const AuthCommonContainer = styled.View`
  padding: 0 20px;
  height: 100%;
  background-color: ${Color.WHITE};
`;

export const AuthCommonTitle = styled.View`
  padding: 40px 0 30px 0;
`;

export const AuthCommonTitleText = styled.Text`
  font-size: 24px;
  font-family: "fontB";
  letter-spacing: -0.6px;
  line-height: 35px;
`;

export const AuthCommonInfoText = styled.Text`
  font-size: 16px;
  letter-spacing: -0.4px;
  line-height: 24px;
`;

export const AuthCommonContents = styled.View`
  padding-top: 30px;
`;

export const styles = StyleSheet.create({
  text: {
    fontSize: 16,
    fontWeight: "500",
  },
  infoText: {
    marginBottom: 8,
  },
  highlight: {
    color: Color.PUPPLE,
    fontWeight: "bold",
  },
  textGrey: {
    color: Color.LIGHT_GREY_FOUR,
  },
  select: {
    borderColor: Color.LIGHT_GREY_FIVE,
    borderRadius: 16,
    backgroundColor: Color.WHITE,
    height: 60,
  },
  inputSelect: {
    borderColor: Color.PUPPLE,
    borderRadius: 16,
    backgroundColor: Color.WHITE,
    height: 60,
  },
  icon: {
    width: 24,
    height: 24,
  },
});
