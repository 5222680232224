import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { View, StatusBar, FlatList, Text, Image, Keyboard } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { Helmet } from "react-helmet-async";
import WheelPicker from "react-native-wheel-picker-expo";
import { useRecoilState } from "recoil";
import { useRoute } from "@react-navigation/native";
import Typed from "react-typed";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectCreative } from "swiper";
import "swiper/css";
import "swiper/css/effect-creative";
import "swiper/css/autoplay";

import { Maybe, ProgressBar, SwitchCase } from "@src/components/presentational";
import {
  SkinMbtiAnswerContents,
  SkinMbtiAnswerItem,
  SkinMbtiAnswerText,
  SkinMbtiDateInput,
  SkinMbtiDateInputContents,
  SkinMbtiDatePicker,
  SkinMbtiDatePickerIcon,
  SkinMbtiPickerItem,
  SkinMbtiPickerItemText,
  SkinMbtiQuestionContents,
  SkinMbtiQuestionNumText,
  SkinMbtiQuestionTitle,
  ids,
  SkinMbtiContainer,
  SkinMbtiContents,
  SkinMbtiImg,
  styles,
  Dimmed,
  SkinMbtiPickerNextBtn,
  SkinMbtiNextIcon,
} from "../styled";
import { mbtiImages, skinMbtiTest } from "./configs";
import {
  birthYearState,
  genderState,
  mbtiResultState,
  resultLoadingState,
} from "@src/store";
import { MbtiService } from "@src/services";
import { useAuth } from "@src/hooks/auth";
import { Layouts } from "@src/components/container";
import downIcon from "@assets/icons/icon-down.svg";
import mbtiBg from "@assets/images/mbti/mbti-bg.png";
import nextIcon from "@assets/icons/icon-next-white.svg";
import { analytics } from "@src/hooks/analytics";

/**
 * 피부 MBTI 질문
 */

const SkinMbtiDetailScreen = (): ReactElement => {
  const { isLoggedIn } = useAuth();

  const route = useRoute<any>();
  const state = Number(route.params?.state);
  const navigation = useNavigation<any>();

  const wheelRef = useRef<WheelPicker>(null);

  const [activeQuestionNum, setActiveQuestionNum] = useState<number>(0);
  const [showResult, setShowResult] = useState<boolean>(false);

  const [gender, setGender] = useRecoilState(genderState);
  const [birthYear, setBirthYear] = useRecoilState(birthYearState);
  const [mbtiResult, setMbtiResult] = useRecoilState(mbtiResultState);
  const [isLoading, setIsLoading] = useRecoilState(resultLoadingState);

  const [isCompleted, setIsCompleted] = useState<boolean>(false);
  const [result, setResult] = useState<any>([]);

  const [yearList, setYearList] = useState<any>([]);
  const [yearId, setYearId] = useState<number>();
  const [isPickerOpen, setIsPickerOpen] = useState<boolean>(false);

  const { questions, totalQuestions } = skinMbtiTest;
  const { id, type, lists } = questions[activeQuestionNum];

  const onClickPrev = () => {
    if (activeQuestionNum !== 0) {
      setActiveQuestionNum((prev) => prev - 1);

      const resultValue = result.slice(0, result.length - 1);
      setResult(resultValue);
    } else {
      navigation.push("skinMbti");
    }
  };

  const saveMbtiResult = useCallback(async () => {
    const conditions = {
      gender: gender,
      birthYear: String(birthYear),
      resultOfBaumannTest: mbtiResult,
    };

    try {
      const data = await MbtiService.patchMbtiResult(conditions);
      if (data === "OK") {
        analytics("Skin Mbti Result", { mbti_type: mbtiResult });

        setTimeout(() => {
          setIsCompleted(true);
        }, 4000);

        setTimeout(() => {
          navigation.push("skinMbtiResult", { type: mbtiResult, state: state });
        }, 5500);
      }
    } catch (error) {
      console.log(error);
    }
  }, [mbtiResult]);

  const onClickNext = useCallback(() => {
    if (activeQuestionNum !== totalQuestions - 1) {
      setActiveQuestionNum((prev) => prev + 1);
      setShowResult(false);
    } else {
      analytics("Skin Mbti End", "");
      setActiveQuestionNum(0);
      setShowResult(true);
      setIsLoading(true);
      setIsCompleted(false);
    }
  }, [activeQuestionNum]);

  const handleCountValue = (value) => {
    setResult((prev) => [...prev, { id: id, value: value }]);
  };

  const handleClickDatePicker = () => {
    setIsPickerOpen(!isPickerOpen);
  };

  const handleBirthYearValue = (value, id) => {
    analytics("Birth Year", { birth_year: value });

    setBirthYear(value);
    setYearId(id);
    setIsPickerOpen(false);
  };

  const handleTypeCount = () => {
    // 코드 리팩토링 예정
    const typeD = result.map((item) => {
      if (item.value[0].type1 === "D") {
        return item.value[0].count;
      }
    });

    let sumD = 0;
    typeD.forEach((item) => {
      if (item !== undefined) {
        sumD += item;
      }
    });

    const typeO = result.map((item) => {
      if (item.value[1].type2 === "O") {
        return item.value[1].count;
      }
    });

    let sumO = 0;
    typeO.forEach((item) => {
      if (item !== undefined) {
        sumO += item;
      }
    });

    const typeS = result.map((item) => {
      if (item.value[1].type1 === "S") {
        return item.value[1].count;
      }
    });

    let sumS = 0;
    typeS.forEach((item) => {
      if (item !== undefined) {
        sumS += item;
      }
    });

    const typeR = result.map((item) => {
      if (item.value[1].type2 === "R") {
        return item.value[1].count;
      }
    });

    let sumR = 0;
    typeR.forEach((item) => {
      if (item !== undefined) {
        sumR += item;
      }
    });

    const typeN = result.map((item) => {
      if (item.value[1].type1 === "N") {
        return item.value[1].count;
      }
    });

    let sumN = 0;
    typeN.forEach((item) => {
      if (item !== undefined) {
        sumN += item;
      }
    });

    const typeP = result.map((item) => {
      if (item.value[1].type2 === "P") {
        return item.value[1].count;
      }
    });

    let sumP = 0;
    typeP.forEach((item) => {
      if (item !== undefined) {
        sumP += item;
      }
    });

    const typeW = result.map((item) => {
      if (item.value[1].type1 === "W") {
        return item.value[1].count;
      }
    });

    let sumW = 0;
    typeW.forEach((item) => {
      if (item !== undefined) {
        sumW += item;
      }
    });

    const typeT = result.map((item) => {
      if (item.value[1].type2 === "T") {
        return item.value[1].count;
      }
    });

    let sumT = 0;
    typeT.forEach((item) => {
      if (item !== undefined) {
        sumT += item;
      }
    });

    let type1Result = "";
    if (sumD > sumO) {
      type1Result = "D";
    } else if ((sumD = sumO)) {
      let types = ["D", "O"];
      let randomType = Math.floor(Math.random() * types.length);
      type1Result = types[randomType];
    } else {
      type1Result = "O";
    }

    let type2Result = "";
    if (sumS > sumR) {
      type2Result = "S";
    } else if ((sumD = sumO)) {
      let types = ["S", "R"];
      let randomType = Math.floor(Math.random() * types.length);
      type2Result = types[randomType];
    } else {
      type2Result = "R";
    }

    let type3Result = "";
    if (sumN > sumP) {
      type3Result = "N";
    } else if ((sumN = sumP)) {
      let types = ["N", "P"];
      let randomType = Math.floor(Math.random() * types.length);
      type3Result = types[randomType];
    } else {
      type3Result = "P";
    }

    let type4Result = "";
    if (sumW > sumT) {
      type4Result = "W";
    } else if ((sumW = sumT)) {
      let types = ["W", "T"];
      let randomType = Math.floor(Math.random() * types.length);
      type4Result = types[randomType];
    } else {
      type4Result = "T";
    }

    let str = "";
    const mbtiResult = str.concat(
      type1Result,
      type2Result,
      type3Result,
      type4Result
    );
    setMbtiResult(mbtiResult);
  };

  useEffect(() => {
    handleTypeCount();
  }, [result]);

  useEffect(() => {
    let years = [];
    const currentYear = new Date().getFullYear();
    let startYear = 1901;

    while (startYear <= currentYear) {
      years.push(startYear++);
    }

    setYearList(years);
  }, []);

  useEffect(() => {
    setIsCompleted(false);
  }, []);

  useEffect(() => {
    if (isLoggedIn && showResult) {
      saveMbtiResult();
    } else if (showResult) {
      analytics("Skin Mbti Result", { mbti_type: mbtiResult });

      setTimeout(() => {
        setIsCompleted(true);
      }, 4000);

      setTimeout(() => {
        navigation.push("skinMbtiResult", { type: mbtiResult, state: state });
      }, 5500);
    }
  }, [showResult]);

  const renderDefaultData = ({ index, item }) => {
    return (
      <SkinMbtiAnswerContents>
        <SkinMbtiAnswerItem
          onPress={() => {
            onClickNext();
            handleCountValue(item.value);
          }}
          activeOpacity={1}
        >
          {item.list.split("\n").map((text, index) => (
            <SkinMbtiAnswerText key={index}>{text}</SkinMbtiAnswerText>
          ))}
        </SkinMbtiAnswerItem>
      </SkinMbtiAnswerContents>
    );
  };

  const renderGenderData = () => {
    return (
      <SkinMbtiAnswerContents>
        <SkinMbtiAnswerItem
          activeOpacity={1}
          onPress={() => {
            setGender("female");
            analytics("Gender", { gender: "여성" });
            onClickNext();
          }}
        >
          <SkinMbtiAnswerText>여성</SkinMbtiAnswerText>
        </SkinMbtiAnswerItem>
        <SkinMbtiAnswerItem
          activeOpacity={1}
          onPress={() => {
            setGender("male");
            analytics("Gender", { gender: "남성" });
            onClickNext();
          }}
        >
          <SkinMbtiAnswerText>남성</SkinMbtiAnswerText>
        </SkinMbtiAnswerItem>
      </SkinMbtiAnswerContents>
    );
  };

  const renderYearPicker = () => {
    return (
      <>
        <SkinMbtiDateInputContents
          activeOpacity={1}
          onPress={() => {
            handleClickDatePicker();
            Keyboard.dismiss();
          }}
        >
          <SkinMbtiDateInput
            placeholder="출생연도를 선택해 주세요."
            value={birthYear ? birthYear + "년" : ""}
            caretHidden
          />
          <SkinMbtiDatePickerIcon source={downIcon} resizeMode="contain" />
        </SkinMbtiDateInputContents>
        {isPickerOpen && (
          <SkinMbtiDatePicker>
            <Dimmed
              isOpen={isPickerOpen}
              onStartShouldSetResponder={() => true}
              onResponderGrant={() => handleClickDatePicker()}
            />
            <WheelPicker
              ref={wheelRef}
              items={yearList?.map((name, index) => ({
                label: name,
                value: index,
              }))}
              height={360}
              width="100%"
              initialSelectedIndex={yearId}
              onChange={undefined}
              renderItem={(props) => (
                <SkinMbtiPickerItem
                  activeOpacity={1}
                  selected={yearId === props.value}
                  onPress={() => {
                    handleBirthYearValue(props.label, props.value);
                  }}
                >
                  <SkinMbtiPickerItemText>
                    {props.label + "년"}
                  </SkinMbtiPickerItemText>
                </SkinMbtiPickerItem>
              )}
            />
          </SkinMbtiDatePicker>
        )}
      </>
    );
  };

  return (
    <>
      <StatusBar barStyle="light-content" backgroundColor="#6432e9" />
      <Helmet>
        <title>피부 MBTI | 촉촉박사</title>
        <meta
          name="description"
          content="촉촉한 피부, 나만 모르고 있던 시술 정보가 궁금하다면? | 촉촉박사"
          data-react-helmet="true"
        />
      </Helmet>
      <Layouts logo hamburgerMode>
        <SkinMbtiContainer>
          <SkinMbtiImg resizeMode="cover" source={mbtiBg} />
          <SwitchCase
            tests={[
              {
                test: isLoading,
                component: (
                  <>
                    <View style={styles.slideWrap}>
                      <Swiper
                        modules={[Autoplay, EffectCreative]}
                        autoplay={{ delay: 700, disableOnInteraction: false }}
                        slidesPerView={1}
                        effect={"creative"}
                        creativeEffect={{
                          prev: {
                            translate: [0, 0, -800],
                            rotate: [180, 0, 0],
                          },
                          next: {
                            translate: [0, 0, -800],
                            rotate: [-180, 0, 0],
                          },
                        }}
                        loop
                        style={styles.slider}
                      >
                        {mbtiImages.map((item, index) => (
                          <SwiperSlide key={index}>
                            <Image source={item} style={styles.slideImg} />
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </View>
                    {isCompleted ? (
                      <Text style={styles.completedText}>찾았다!</Text>
                    ) : (
                      <Typed
                        style={styles.slideText}
                        strings={["촉촉박사 AI가 꼼꼼하게 분석하는 중..."]}
                        typeSpeed={80}
                      />
                    )}
                  </>
                ),
              },
            ]}
            defaultComponent={
              <SkinMbtiContents>
                <ProgressBar
                  onClickPrev={onClickPrev}
                  questions={questions}
                  totalQuestions={totalQuestions}
                  activeQuestionNum={activeQuestionNum}
                  id={activeQuestionNum + 1}
                />
                <SkinMbtiQuestionContents>
                  <SkinMbtiQuestionNumText>
                    Q{activeQuestionNum + 1}.
                  </SkinMbtiQuestionNumText>
                  <View
                    dataSet={{ media: ids.question }}
                    style={styles.question}
                  >
                    {questions[activeQuestionNum].question
                      .split("\n")
                      .map((item, index) => (
                        <SkinMbtiQuestionTitle key={index}>
                          {item}
                        </SkinMbtiQuestionTitle>
                      ))}
                  </View>
                  <Maybe
                    test={type === "birthYear" && yearList}
                    children={renderYearPicker()}
                  />
                </SkinMbtiQuestionContents>
                <View dataSet={{ media: ids.footer }} style={styles.footer}>
                  <Maybe
                    test={type === "gender"}
                    children={renderGenderData()}
                  />
                  <FlatList
                    data={lists}
                    renderItem={renderDefaultData}
                    style={styles.list}
                  />
                  <Maybe
                    test={type === "birthYear"}
                    children={
                      <SkinMbtiPickerNextBtn
                        style={styles.btn}
                        activeOpacity={1}
                        onPress={() => (birthYear ? onClickNext() : undefined)}
                      >
                        <Text style={styles.text}>다음 문제</Text>
                        <SkinMbtiNextIcon>
                          <Image
                            source={nextIcon}
                            resizeMode="contain"
                            style={styles.icon}
                          />
                        </SkinMbtiNextIcon>
                      </SkinMbtiPickerNextBtn>
                    }
                  />
                </View>
              </SkinMbtiContents>
            }
          />
        </SkinMbtiContainer>
      </Layouts>
    </>
  );
};

export default SkinMbtiDetailScreen;
