import { Color } from "@src/constants";
import styled from "styled-components/native";
import { StyleSheet } from "react-native";
import MediaStyle from "react-native-media-query";

export const Dimmed = styled.View`
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  position: ${({ isOpen }: { isOpen: boolean }) => (isOpen ? "fixed" : "")};
  background-color: ${({ isOpen }: { isOpen: boolean }) =>
    isOpen ? "rgba(0, 0, 0, 0.54)" : ""};
  z-index: ${({ isOpen }: { isOpen: boolean }) => (isOpen ? "1001" : "")};
`;

export const DialogPopupContents = styled.View`
  position: relative;
  height: 100%;
  min-height: 100%;
  padding: 40px 0 20px 0;
`;

export const Title = styled.Text`
  text-align: center;
  line-height: 28px;
`;

export const Info = styled.Text`
  margin-top: 20px;
  text-align: center;
`;

export const BtnContents = styled.View`
  width: 100%;
  margin-top: 40px;
  padding: 0 16px;
  flex-direction: row;
  justify-content: space-between;
`;

export const SingleBtn = styled.View``;

export const Btn = styled.TouchableOpacity`
  width: 100%;
`;

export const LeftBtn = styled.TouchableOpacity`
  width: calc(100% / 2 - 4px);
  margin-right: 4px;
  background-color: ${Color.WHITE};
  border: 1px solid ${Color.PUPPLE};
`;

export const RightBtn = styled.TouchableOpacity`
  width: calc(100% / 2 - 4px);
  margin-left: 4px;
  background-color: ${Color.PUPPLE};
`;

export const BtnText = styled.Text`
  font-size: 16px;
  font-family: "fontB";
`;

export const itemStyles = StyleSheet.create({
  title: {
    fontSize: 20,
    fontFamily: "fontB",
    color: Color.BLACK_TWO,
  },
  info: {
    fontSize: 16,
    fontFamily: "fontM",
    color: Color.BLACK_TWO,
  },
  subInfo: {
    fontSize: 12,
    fontFamily: "fontM",
    color: Color.LIGHT_GREY_FOUR,
  },
  btn: {
    height: 48,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 24,
    paddingTop: 11,
    paddingBottom: 13,
  },
  leftBtnText: {
    color: Color.PUPPLE,
  },
  rightBtnText: {
    color: Color.WHITE,
  },
  btnBorder: {
    borderLeftColor: Color.LIGHT_GREY_FIVE,
    borderLeftWidth: 1,
  },
});

export const { ids, styles } = MediaStyle.create({
  popup: {
    maxWidth: 480,
    marginInline: "calc(50vw - 220px)",
    top: "50%",
    marginTop: -100,
    backgroundColor: Color.WHITE,
    borderRadius: 16,
    transition: "top 0.6s cubic-bezier(0.165, 0.84, 0.44, 1)",
    zIndex: 1001,
    "@media (max-width: 480px)": {
      marginInline: 20,
    },
  },
});
