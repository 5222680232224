import React, { useEffect, useRef, useState } from "react";
import { View, StatusBar, Text, Image } from "react-native";
import { Helmet } from "react-helmet-async";
import { useNavigation, useRoute } from "@react-navigation/native";
import { Tabs } from "antd";

import { Layouts } from "@src/components/container";
import { CommunityList } from "@src/components/presentational";
import { ids, styles } from "@src/styles/modules/global";
import {
  ContentsWrap,
  itemStyles,
  ProfileContents,
  ProfileHeader,
  ProfileImage,
  ProfileMbti,
  ProfileNickname,
  ProfileSetting,
} from "./styled";
import settingIcon from "@assets/icons/icon-setting.svg";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  CommunityCommentUserId,
  CommunityProfile,
  userState,
} from "@src/store";
import { CommunityService } from "@src/services";
import { IForumPostList, ProfileType } from "@src/types";
import profileIcon from "@assets/icons/icon-profile.png";
import { useAuth } from "@src/hooks/auth";

/**
 * 프로필 페이지
 */

const ProfileScreen = () => {
  const navigation = useNavigation<any>();
  const route = useRoute<any>();
  const fileInputRef = useRef<any>(null);

  const { user } = useAuth();

  const setUser = useSetRecoilState(userState);
  const [commentUserId, setCommentUserId] = useRecoilState(
    CommunityCommentUserId
  );

  const [pageIdx, setPageIdx] = useState<number>(0);
  const [myProfilepageIdx, setMyProfilepageIdx] = useState<number>(0);
  const [postTotalCount, setPostTotalCount] = useState<number>(0);
  const [commentTotalCount, setCommentTotalCount] = useState<number>(0);

  const [myProfileData, setMyProfileData] =
    useRecoilState<ProfileType>(CommunityProfile);
  const [myProfilePostData, setMyProfilePostData] =
    useState<IForumPostList[]>();
  const [myProfileCommentData, setMyProfileCommentData] =
    useState<IForumPostList[]>();

  const [userProfileData, setUserProfileData] = useState<ProfileType>();
  const [profilePostData, setProfilePostData] = useState<IForumPostList[]>();
  const [profileCommentData, setProfileCommentData] =
    useState<IForumPostList[]>();
  const [postLast, setPostLast] = useState<boolean>(false);
  const [commentLast, setCommentLast] = useState<boolean>(false);

  const getUserProfile = async () => {
    try {
      const { data } = await CommunityService.getProfile(commentUserId);
      setUserProfileData(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getMyProfile = async () => {
    try {
      const data = await CommunityService.getMyProfile();
      setMyProfileData(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getMyProfilePost = async () => {
    try {
      const conditions = {
        pageIdx: myProfilepageIdx,
      };

      const data = await CommunityService.getMyProfilePost(conditions);

      if (myProfilepageIdx > 0) {
        setMyProfilePostData((prev) => prev.concat(data.content));
      } else {
        setMyProfilePostData(data.content);
      }

      setPostLast(data.last);
      setPostTotalCount(data.totalElements);
    } catch (error) {
      console.log(error);
    }
  };

  const getMyProfileComment = async () => {
    try {
      const conditions = {
        pageIdx: myProfilepageIdx,
      };

      const data = await CommunityService.getMyProfileComment(conditions);
      if (myProfilepageIdx > 0) {
        setMyProfileCommentData((prev) => prev.concat(data.content));
      } else {
        setMyProfileCommentData(data.content);
      }

      setCommentLast(data.last);
      setCommentTotalCount(data.totalElements);
    } catch (error) {
      console.log(error);
    }
  };

  const getProfilePost = async () => {
    try {
      const conditions = {
        userId: commentUserId,
        pageIdx,
      };

      const data = await CommunityService.getProfilePost(conditions);

      if (pageIdx > 0) {
        setProfilePostData((prev) => prev.concat(data.content));
      } else {
        setProfilePostData(data.content);
      }

      setPostLast(data.last);
      setPostTotalCount(data.totalElements);
    } catch (error) {
      console.log(error);
    }
  };

  const getProfileComment = async () => {
    try {
      const conditions = {
        userId: commentUserId,
        pageIdx,
      };

      const data = await CommunityService.getProfileComment(conditions);
      if (pageIdx > 0) {
        setProfileCommentData((prev) => prev.concat(data.content));
      } else {
        setProfileCommentData(data.content);
      }

      setCommentLast(data.last);
      setCommentTotalCount(data.totalElements);
    } catch (error) {
      console.log(error);
    }
  };

  const patchProfileImage = async (img) => {
    try {
      const conditions = {
        base64Encode: img,
      };

      await CommunityService.patchMyProfile(conditions);

      if (img) {
        getMyProfile();
        setUser({
          ...user,
          profileImgUrl: img,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleMoreData = () => {
    setPageIdx((prev) => prev + 1);
  };

  const handleMyProfileMoreData = () => {
    setMyProfilepageIdx((prev) => prev + 1);
  };

  const onChangeProfileImg = (e) => {
    const file = fileInputRef.current.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      patchProfileImage(reader.result);
    };
  };

  const tabs = [
    {
      key: "1",
      label: `작성한 토크 ${
        route.name === "myprofile"
          ? myProfileData?.countOfPosting
          : userProfileData?.countOfPosting
      }`,
      children: (
        <View
          dataSet={{ media: ids.paddingS }}
          style={[{ flex: 1 }, styles.paddingS]}
        >
          <CommunityList
            data={
              route.name === "myprofile" ? myProfilePostData : profilePostData
            }
            onClickMore={
              route.name === "myprofile"
                ? handleMyProfileMoreData
                : handleMoreData
            }
            totalCount={postTotalCount}
            last={postLast}
          />
        </View>
      ),
    },
    {
      key: "2",
      label: `댓글 단 토크 ${
        route.name === "myprofile"
          ? myProfileData?.countOfComment
          : userProfileData?.countOfComment
      }`,
      children: (
        <View
          dataSet={{ media: ids.paddingS }}
          style={[{ flex: 1 }, styles.paddingS]}
        >
          <CommunityList
            data={
              route.name === "myprofile"
                ? myProfileCommentData
                : profileCommentData
            }
            onClickMore={
              route.name === "myprofile"
                ? handleMyProfileMoreData
                : handleMoreData
            }
            totalCount={
              route.name === "myprofile" ? commentTotalCount : postTotalCount
            }
            last={commentLast}
          />
        </View>
      ),
    },
  ];

  const handleChangeTabId = (id) => {
    if (id === "2" && commentUserId) {
      getProfileComment();
    } else if (id === "2" && myProfileData) {
      getMyProfileComment();
    }
  };

  useEffect(() => {
    if (route.name === "myprofile") {
      getMyProfile();
    } else {
      getUserProfile();
    }
  }, [route]);

  useEffect(() => {
    if (route.name === "profile" && commentUserId) {
      getProfilePost();
      getProfileComment();
    }
  }, [route, commentUserId, pageIdx]);

  useEffect(() => {
    if (route.name === "myprofile") {
      getMyProfilePost();
      getMyProfileComment();
    }
  }, [route, myProfilepageIdx]);

  return (
    <>
      <StatusBar barStyle="light-content" backgroundColor="#6432e9" />
      <Helmet>
        <title>프로필 | 촉촉박사</title>
      </Helmet>
      <Layouts
        transparent
        cancelMode
        handleCancel={() =>
          navigation.canGoBack()
            ? navigation.goBack()
            : navigation.navigate("community")
        }
        titleMode
        title="프로필"
        hiddenRight
      >
        <ContentsWrap>
          <ProfileHeader>
            <ProfileContents>
              <label>
                <ProfileImage
                  source={{
                    uri:
                      route?.name === "myprofile"
                        ? myProfileData?.profileImageUrl
                        : route?.name === "profile"
                        ? userProfileData?.profileImageUrl
                        : profileIcon,
                  }}
                  resizeMode="cover"
                />
                {myProfileData && (
                  <ProfileSetting>
                    <Image
                      source={settingIcon}
                      resizeMode="contain"
                      style={itemStyles.icon}
                    />
                  </ProfileSetting>
                )}
              </label>
              {myProfileData && (
                <input
                  type="file"
                  style={itemStyles.fileInput}
                  onChange={onChangeProfileImg}
                  ref={fileInputRef}
                  accept=".png, .jpg, .jpeg"
                />
              )}
            </ProfileContents>
            {myProfileData?.baumannType || userProfileData?.baumannType ? (
              <ProfileMbti>
                <Text style={[itemStyles.whiteText, itemStyles.xxsText]}>
                  {route?.name === "myprofile"
                    ? myProfileData?.baumannType
                    : userProfileData?.baumannType}
                </Text>
              </ProfileMbti>
            ) : (
              <></>
            )}
            <ProfileNickname>
              {route?.name === "myprofile"
                ? myProfileData?.nickname
                : userProfileData?.nickname}
            </ProfileNickname>
          </ProfileHeader>
          <Tabs
            defaultActiveKey="1"
            items={tabs}
            onChange={handleChangeTabId}
            className="profile-tabs"
          />
        </ContentsWrap>
      </Layouts>
    </>
  );
};

export default ProfileScreen;
