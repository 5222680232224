import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { View, Text, ScrollView, Image, Dimensions } from "react-native";
import { useNavigation, useRoute } from "@react-navigation/native";
import { Steps } from "antd";
import { Rating } from "react-simple-star-rating";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import Toast from "react-native-fast-toast";
import { useRecoilState } from "recoil";

import {
  itemStyles,
  Category,
  ListName,
  MyPlanContainer,
  Summary,
  SummaryItem,
  SummaryItemTitle,
  SummaryItemInfo,
  SummaryItemTitleContents,
  MyPlanDetailHeader,
  MyPlanDetailItem,
  MyPlanDetailContents,
  SummaryItemInfoIcon,
  ProgressContents,
  Notice,
  NoticeHighlight,
  NoticeHighlightIcon,
  NoticeHighlightText,
  HighlightBg,
  CommentInfo,
  Dot,
  CommentInfoText,
  MyPlanProgress,
  MyPlanProgressList,
  ListHeader,
  ListFooter,
  ListContents,
  StepNum,
  CheckBoxText,
  ListTitle,
  ProgressTitle,
  MyPlanProgressSlider,
  CommonInput,
  CommonInputWrap,
  CommonMultiInput,
  RatingWrap,
  HeaderContainer,
  DateText,
  DeleteBtn,
  ChangeBtn,
  MyPlanSlideAddItem,
  SummaryItemPrice,
  ListCheckbox,
  CreateBtn,
  CreateBtnText,
  BottomBtn,
} from "./styled";
import {
  CircleCheckbox,
  Maybe,
  TooltipItem,
} from "@src/components/presentational";
import { Color, REGEX_NUMBER_COMMA } from "@src/constants";
import { globalStyles } from "@src/styles/modules/global";
import { DateUtil } from "@src/utils";
import { SkinPlanProcedureListData } from "@src/store";
import { planUpdateToast } from "@src/screens/sampleDatas";
import { analytics } from "@src/hooks/analytics";
import { SkinPlanService } from "@src/services";
import infoIcon from "@assets/icons/icon-info.svg";
import tooltipXlargeIcon from "@assets/icons/icon-tooltip-xlarge.png";
import tooltipXlargeIcon2 from "@assets/icons/icon-tooltip-xlarge2.png";
import tooltipXlargeIcon3 from "@assets/icons/icon-tooltip-xlarge3.png";
import commentIcon from "@assets/icons/icon-comment.png";
import deleteIcon from "@assets/icons/icon-close-black.svg";
import addIcon from "@assets/icons/icon-add.svg";
import emptyStarIcon from "@assets/icons/icon-star-unchecked.svg";
import fillStarIcon from "@assets/icons/icon-star-checked.svg";

interface MySkinPlanDetailItemProps {
  data?: any;
  comment?: any;
  edit?: any;
}

const MySkinPlanDetailItem = ({
  data,
  comment,
  edit,
}: MySkinPlanDetailItemProps): ReactElement => {
  const navigation = useNavigation<any>();
  const route = useRoute<any>();
  const toastRef = useRef(null);

  const plannerId = Number(route.params?.id);
  const pageId = Number(route.params?.pageId);
  const newArray = Number(route.params?.newArray);

  const { height } = Dimensions.get("window");
  const { Step } = Steps;

  const [expectPriceTooltip, setExpectPriceTooltip] = useState<boolean>(false);
  const [realPriceTooltip, setRealPriceTooltip] = useState<boolean>(false);
  const [progressTooltip, setProgressTooltip] = useState<boolean>(false);

  const [actualPriceSum, setActualPriceSum] = useState<number>();
  const [actualPrice, setActualPrice] = useState<string>();
  const [clinicName, setClinicName] = useState<string>();
  const [memo, setMemo] = useState<string>();
  const [rating, setRating] = useState<number>(0);

  const [totalPriceForPlanner, setTotalPriceForPlanner] = useState<number>();
  const [hasAllOfActualPrice, setHasAllOfActualPrice] =
    useState<boolean>(false);
  const [planItemDatas, setPlanItemDatas] = useRecoilState(
    SkinPlanProcedureListData
  );

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [stepId, setStepId] = useState<number>(0);
  const [checkedId, setCheckedId] = useState<any>([]);
  const [sortNums, setSortNums] = useState<any>([]);

  const [inputCount, setInputCount] = useState<number>(0);

  const handleExpectPriceTooltip = () => {
    setExpectPriceTooltip(true);
    setTimeout(() => {
      setExpectPriceTooltip(false);
    }, 3000);
  };

  const handleRealPriceTooltip = () => {
    setRealPriceTooltip(true);
    setTimeout(() => {
      setRealPriceTooltip(false);
    }, 3000);
  };

  const handleProgressTooltip = () => {
    setProgressTooltip(true);
    setTimeout(() => {
      setProgressTooltip(false);
    }, 3000);
  };

  const handleChecked = useCallback(
    (id, checked, sortNo) => {
      {
        /** 코드 리팩토링 예정 */
      }
      if (!checked) {
        if (id !== 0) {
          if (planItemDatas[id - 1].isCompleted === false) {
            setPlanItemDatas(
              planItemDatas.map((item) =>
                item.sortNo === sortNo ? { ...item, isCompleted: false } : item
              )
            );
          } else {
            setPlanItemDatas(
              planItemDatas.map((item) =>
                item.sortNo === sortNo ? { ...item, isCompleted: true } : item
              )
            );
            setIsEdit(true);
            setCheckedId([...checkedId, sortNo]);
          }
        } else {
          setPlanItemDatas(
            planItemDatas.map((item) =>
              item.sortNo === sortNo ? { ...item, isCompleted: true } : item
            )
          );
          setIsEdit(true);
          setCheckedId([...checkedId, sortNo]);
        }
      } else {
        setIsEdit(true);
        if (id !== 0) {
          if (planItemDatas[id].isCompleted) {
            setPlanItemDatas(
              planItemDatas.map((item) =>
                item.sortNo === sortNo ? { ...item, isCompleted: false } : item
              )
            );
            setIsEdit(true);
            const checkedItem = checkedId.filter((item) => item !== sortNo);
            setCheckedId(checkedItem);
          } else {
            setPlanItemDatas(
              planItemDatas.map((item) =>
                item.sortNo === sortNo ? { ...item, isCompleted: true } : item
              )
            );
          }
        } else {
          setPlanItemDatas(
            planItemDatas.map((item) =>
              item.sortNo === sortNo ? { ...item, isCompleted: false } : item
            )
          );
          setIsEdit(true);
          const checkedItem = checkedId.filter((item) => item !== sortNo);
          setCheckedId(checkedItem);
        }
      }
    },
    [checkedId, planItemDatas]
  );

  const handleCheckedId = (id) => {
    const checkedItem = id.sort((a, b) => b - a);
    setStepId(checkedItem[0]);
  };

  const handleInputCount = (e) => {
    setInputCount(e.target.value.length);
  };

  const handleActualPrice = (value, id, sortNo) => {
    setIsEdit(true);
    const commaValue = Number(value.replaceAll(",", ""));

    setPlanItemDatas(
      planItemDatas.map((item) =>
        item.sortNo === sortNo
          ? { ...item, actualPrice: commaValue ? commaValue : null }
          : item
      )
    );
  };

  const updatePlanItem = async () => {
    const datas = {
      plannerId,
      clinicName,
      memo,
      rating,
      totalPriceForPlanner,
      hasAllOfActualPrice,
      plannerItemEditList: planItemDatas,
    };

    try {
      const data = await SkinPlanService.putPlanItem(datas);
      if (data === "OK") {
        analytics("My SKin Plan Update", { my_skin_plan_update: datas });
        toastRef.current.show(planUpdateToast);

        setTimeout(() => {
          navigation.push("mySkinPlan");
        }, 2000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteList = (id) => {
    setIsEdit(true);

    const newData = planItemDatas.filter((item) => item.sortNo !== id);
    setPlanItemDatas(newData);
  };

  const handleClinicInput = (value) => {
    if (value.trim().length !== 0) {
      setIsEdit(true);
    } else {
      setIsEdit(false);
    }
    setClinicName(value);
  };

  const handleMemoInput = (value) => {
    if (value.trim().length !== 0) {
      setIsEdit(true);
    } else {
      setIsEdit(false);
    }
    setMemo(value);
  };

  useEffect(() => {
    if (data) {
      const sum = data.plannerItemDtoList
        .map((item) => item.actualPrice)
        .reduce((acc, val) => acc + val);
      setActualPriceSum(sum);

      if (data.totalActualPrice) {
        const commaValue = data.totalActualPrice
          .toString()
          .replace(REGEX_NUMBER_COMMA, ",");
        setActualPrice(commaValue);
      }

      const sortNums = data.plannerItemDtoList.map((item) => item.sortNo);
      setSortNums(sortNums);

      setClinicName(data.clinicName);
      setMemo(data.memo);
      setStepId(
        data.plannerItemDtoList.filter((item) => item.isCompleted === true)
          .length
      );
      setRating(data.rating);
    }
  }, [data]);

  useEffect(() => {
    if (data && !pageId) {
      setPlanItemDatas(data.plannerItemDtoList);
    }
  }, [data]);

  useEffect(() => {
    setIsEdit(false);
  }, []);

  useEffect(() => {
    edit(isEdit);
  }, [isEdit]);

  const renderContent = (item) => {
    return (
      <ScrollView
        bounces={false}
        scrollEventThrottle={1}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ flexGrow: 1 }}
      >
        <MyPlanContainer>
          <HeaderContainer>
            <MyPlanDetailContents>
              <MyPlanDetailItem>
                <MyPlanDetailHeader>
                  <Text style={[itemStyles.whiteText, itemStyles.lgText]}>
                    {item.subject}
                  </Text>
                </MyPlanDetailHeader>

                <Summary>
                  <SummaryItem>
                    <SummaryItemTitleContents>
                      <SummaryItemTitle>예상 가격</SummaryItemTitle>
                      <SummaryItemInfoIcon
                        onPress={handleExpectPriceTooltip}
                        activeOpacity={1}
                      >
                        <Image
                          source={infoIcon}
                          resizeMode="contain"
                          style={itemStyles.icon}
                        />
                      </SummaryItemInfoIcon>
                    </SummaryItemTitleContents>
                    <SummaryItemInfo>
                      {item.totalPriceForPlanner
                        ?.toString()
                        .replace(REGEX_NUMBER_COMMA, ",")}{" "}
                      원
                    </SummaryItemInfo>
                  </SummaryItem>
                  <SummaryItem>
                    <SummaryItemTitleContents>
                      <SummaryItemTitle>실제 가격</SummaryItemTitle>
                      {!item.hasAllOfActualPrice && (
                        <SummaryItemInfoIcon
                          onPress={handleRealPriceTooltip}
                          activeOpacity={1}
                        >
                          <Image
                            source={infoIcon}
                            resizeMode="contain"
                            style={itemStyles.icon}
                          />
                        </SummaryItemInfoIcon>
                      )}
                    </SummaryItemTitleContents>
                    <SummaryItemInfo>
                      {actualPriceSum === 0 || null
                        ? "-"
                        : actualPriceSum
                            ?.toString()
                            .replace(REGEX_NUMBER_COMMA, ",")}{" "}
                    </SummaryItemInfo>
                  </SummaryItem>
                  <SummaryItem>
                    <SummaryItemTitleContents>
                      <SummaryItemTitle>진행 현황</SummaryItemTitle>
                      <SummaryItemInfoIcon
                        onPress={handleProgressTooltip}
                        activeOpacity={1}
                      >
                        <Image
                          source={infoIcon}
                          resizeMode="contain"
                          style={itemStyles.icon}
                        />
                      </SummaryItemInfoIcon>
                    </SummaryItemTitleContents>
                    <ProgressContents>
                      <Steps
                        size="small"
                        current={stepId}
                        direction="horizontal"
                        labelPlacement="horizontal"
                      >
                        {item.plannerItemDtoList.map((item, index) => (
                          <Step key={index} title={item.categoryName} />
                        ))}
                      </Steps>
                    </ProgressContents>
                  </SummaryItem>
                  <Maybe
                    test={expectPriceTooltip}
                    children={
                      <TooltipItem
                        xLarge
                        positionTop={-45}
                        positionLeft={27}
                        opacity={1}
                        xLargeIcon={tooltipXlargeIcon}
                        transform
                      />
                    }
                  />
                  <Maybe
                    test={realPriceTooltip}
                    children={
                      <TooltipItem
                        xLarge
                        positionTop={75}
                        positionLeft={23}
                        opacity={1}
                        xLargeIcon={tooltipXlargeIcon2}
                        transform
                      />
                    }
                  />
                  <Maybe
                    test={progressTooltip}
                    children={
                      <TooltipItem
                        xxLarge
                        positionTop={105}
                        positionLeft={23}
                        opacity={1}
                        xLargeIcon={tooltipXlargeIcon3}
                        transform
                      />
                    }
                  />
                </Summary>
              </MyPlanDetailItem>
            </MyPlanDetailContents>

            <Notice>
              <NoticeHighlight>
                <NoticeHighlightIcon
                  source={commentIcon}
                  resizeMode="contain"
                />
                <HighlightBg style={globalStyles.highlight} />
                <NoticeHighlightText style={globalStyles.fontB}>
                  잠깐!
                </NoticeHighlightText>
              </NoticeHighlight>
              {comment.split("\n").map((item, index) => (
                <CommentInfo key={index}>
                  <Dot style={[globalStyles.dot, itemStyles.commentDot]} />
                  <CommentInfoText key={index} style={globalStyles.fontM}>
                    {item}
                  </CommentInfoText>
                </CommentInfo>
              ))}
            </Notice>
          </HeaderContainer>

          <MyPlanProgress>
            <ProgressTitle>시술 별 현황</ProgressTitle>
            <MyPlanProgressSlider>
              <Swiper
                slidesPerView={1}
                slideToClickedSlide
                spaceBetween={10}
                allowTouchMove
                style={itemStyles.slider}
                breakpoints={{
                  480: {
                    slidesPerView: 1.35,
                  },
                }}
              >
                {planItemDatas?.map((plannerItem, index) => (
                  <SwiperSlide style={itemStyles.slide} key={index}>
                    <MyPlanProgressList>
                      <ListHeader>
                        <StepNum>
                          {index + 1}/{planItemDatas.length}
                        </StepNum>
                        {planItemDatas.length > 1 && (
                          <DeleteBtn
                            activeOpacity={1}
                            onPress={() => handleDeleteList(plannerItem.sortNo)}
                          >
                            <Image
                              source={deleteIcon}
                              resizeMode="contain"
                              style={itemStyles.mdIcon}
                            />
                          </DeleteBtn>
                        )}
                      </ListHeader>
                      <ListContents>
                        <ListTitle>
                          {plannerItem.categoryName && (
                            <Category>
                              <Text
                                style={[
                                  itemStyles.whiteText,
                                  itemStyles.xxsText,
                                ]}
                              >
                                {plannerItem.categoryName}
                              </Text>
                            </Category>
                          )}
                          <ListName>
                            <Text
                              style={[itemStyles.puppleText, itemStyles.lgText]}
                            >
                              {plannerItem.dermatologyProcedureName}
                            </Text>
                          </ListName>
                          <ChangeBtn
                            onPress={() =>
                              navigation.push("search", {
                                id: plannerId,
                                type: "planUpdate",
                                sortNo: plannerItem.sortNo,
                              })
                            }
                          >
                            <Text style={itemStyles.xsText}>변경</Text>
                          </ChangeBtn>
                        </ListTitle>

                        <SummaryItem>
                          <SummaryItemTitleContents
                            style={itemStyles.summaryTitle}
                          >
                            <SummaryItemTitle
                              style={itemStyles.summaryTitleText}
                            >
                              평균 가격
                            </SummaryItemTitle>
                          </SummaryItemTitleContents>
                          <SummaryItemInfo>
                            {!plannerItem.priceForPlanner ||
                            plannerItem.priceForPlanner === 0
                              ? "-"
                              : plannerItem.priceForPlanner
                                  .toString()
                                  .replace(REGEX_NUMBER_COMMA, ",") ||
                                planItemDatas.priceForPlanner
                                  .toString()
                                  .replace(REGEX_NUMBER_COMMA, ",")}
                            원
                            {plannerItem.baseDateOfPrice && (
                              <DateText>
                                {" "}
                                (기준일{" "}
                                {DateUtil.dateFormat(
                                  plannerItem.baseDateOfPrice,
                                  DateUtil.DATE_FORMAT
                                )}
                                )
                              </DateText>
                            )}
                          </SummaryItemInfo>
                        </SummaryItem>
                        <SummaryItem>
                          <SummaryItemTitleContents
                            style={[
                              itemStyles.summaryTitle,
                              itemStyles.priceTitle,
                            ]}
                          >
                            <SummaryItemTitle
                              style={itemStyles.summaryTitleText}
                            >
                              실제 가격
                            </SummaryItemTitle>
                          </SummaryItemTitleContents>
                          <SummaryItemPrice>
                            <input
                              placeholder={
                                plannerItem.actualPrice
                                  ? plannerItem.actualPrice
                                      .toString()
                                      .replace(REGEX_NUMBER_COMMA, ",")
                                  : "실제 시술가를 입력해 주세요"
                              }
                              type="string"
                              value={
                                plannerItem.actualPrice
                                  ? plannerItem.actualPrice
                                      .toString()
                                      .replace(REGEX_NUMBER_COMMA, ",")
                                  : ""
                              }
                              onChange={(e) => {
                                handleActualPrice(
                                  e.target.value,
                                  plannerItem.plannerItemId,
                                  plannerItem.sortNo
                                );
                              }}
                              className={
                                plannerItem.actualPrice
                                  ? "input price-input-active"
                                  : "input price-input"
                              }
                            />
                            <span className="unit">원</span>
                          </SummaryItemPrice>
                        </SummaryItem>
                      </ListContents>
                      <ListFooter checked={plannerItem.isCompleted}>
                        <ListCheckbox>
                          <CircleCheckbox
                            isChecked={checkedId.includes(plannerItem.sortNo)}
                            onChangeCheck={(checked) =>
                              handleChecked(
                                index,
                                plannerItem.isCompleted,
                                plannerItem.sortNo
                              )
                            }
                            white
                          />
                          <CheckBoxText>시술받음</CheckBoxText>
                        </ListCheckbox>
                      </ListFooter>
                    </MyPlanProgressList>
                  </SwiperSlide>
                ))}
                );
                {planItemDatas?.length === 1 || planItemDatas?.length === 2 ? (
                  <SwiperSlide style={itemStyles.slide}>
                    <MyPlanSlideAddItem
                      onPress={() =>
                        navigation.push("search", {
                          id: plannerId,
                          type: "planAdd",
                          sortNo: item.plannerItemDtoList.length,
                        })
                      }
                      activeOpacity={1}
                    >
                      <Image
                        source={addIcon}
                        resizeMode="contain"
                        style={itemStyles.mdIcon}
                      />
                    </MyPlanSlideAddItem>
                  </SwiperSlide>
                ) : (
                  <></>
                )}
              </Swiper>
            </MyPlanProgressSlider>
          </MyPlanProgress>

          <View>
            <ProgressTitle>병원</ProgressTitle>
            <CommonInputWrap>
              <CommonInput
                placeholder="시술 받은 병원명을 기록해요"
                placeholderTextColor={Color.LIGHT_GREY_FOUR}
                value={clinicName || ""}
                onChangeText={(value) => handleClinicInput(value)}
                maxLength={20}
              />
            </CommonInputWrap>
          </View>

          <View>
            <ProgressTitle>메모</ProgressTitle>
            <CommonInputWrap>
              <CommonMultiInput
                placeholder="내용을 입력해 주세요."
                placeholderTextColor={Color.LIGHT_GREY_FOUR}
                onChange={handleInputCount}
                onChangeText={(value) => handleMemoInput(value)}
                maxLength={50}
                multiline
                value={memo || ""}
              />
              <View style={itemStyles.inputCount}>
                <Text style={itemStyles.inputCountText}>{inputCount}/50</Text>
              </View>
            </CommonInputWrap>
          </View>

          <View>
            <ProgressTitle>플랜 평가</ProgressTitle>
            <RatingWrap>
              <Text style={itemStyles.smText}>
                이 플랜이 얼마나 마음에 드시나요?
              </Text>
              <Rating
                onClick={(value) => {
                  setRating(value);
                  setIsEdit(true);
                }}
                initialValue={rating}
                fillIcon={<img src={fillStarIcon} />}
                emptyIcon={<img src={emptyStarIcon} />}
                iconsCount={5}
                style={itemStyles.rating}
              />
            </RatingWrap>
          </View>

          <BottomBtn>
            <CreateBtn
              activeOpacity={1}
              onPress={updatePlanItem}
              disabled={!isEdit && newArray !== 1 && checkedId.length === 0}
            >
              <CreateBtnText
                disabled={!isEdit && newArray !== 1 && checkedId.length === 0}
              >
                저장하기
              </CreateBtnText>
            </CreateBtn>
          </BottomBtn>
        </MyPlanContainer>
      </ScrollView>
    );
  };

  return (
    <>
      <MyPlanContainer>{data && renderContent(data)}</MyPlanContainer>
      <Toast
        ref={toastRef}
        offset={height / 2}
        duration={3000}
        style={globalStyles.centerToast}
        textStyle={globalStyles.centerToastText}
      />
    </>
  );
};

export default MySkinPlanDetailItem;
