import styled from "styled-components/native";

export const CheckBoxWrap = styled.TouchableOpacity`
  flex-direction: row;
  z-index: 9;
`;

export const CheckBox = styled.View`
  width: 28px;
  height: 28px;
`;

export const CheckBoxIcon = styled.Image`
  width: 28px;
  height: 28px;
`;
