import React, { ReactElement, useState } from "react";
import Swiper from "react-native-web-swiper";
import {
  PopupButtonContents,
  PopupButtonText,
  PopupCloseBtn,
  PopupCloseBtnText,
  PopupContainer,
  PopupContents,
  PopupSlideImg,
  PopupSlideInfo,
  PopupSlideTextContents,
  PopupSlideTitle,
  styles,
} from "./styled";
import { View } from "react-native";
import { ids } from "@src/styles/modules/global";

interface SlidePopupProps {
  images?: Array<any>;
  btnText?: string;
  onClickBtn?: () => void;
  clostBtnText?: string;
  onClickCloseBtn?: () => void;
}

const SlidePopup = ({
  images,
  btnText,
  onClickBtn,
  clostBtnText,
  onClickCloseBtn,
}: SlidePopupProps): ReactElement => {
  const [id, setId] = useState<number>(0);

  return (
    <PopupContainer>
      <View style={styles.popupInner} dataSet={{ media: ids.popup }}>
        <Swiper
          from={0}
          controlsProps={{
            dotsTouchable: true,
            dotActiveStyle: {
              backgroundColor: "#fff",
              width: 24,
            },
            dotsWrapperStyle: {
              bottom: 20,
            },
            prevTitle: "",
            nextTitle: "",
          }}
          onIndexChanged={(index) => setId(index)}
        >
          {images?.map((item, index) => (
            <View key={index} style={styles.slide}>
              <PopupContents>
                <PopupSlideImg source={item.url} />
                <PopupSlideTextContents>
                  <PopupSlideTitle style={styles.text}>
                    {item.title}
                  </PopupSlideTitle>
                  <PopupSlideInfo style={styles.text}>
                    {item.info}
                  </PopupSlideInfo>
                </PopupSlideTextContents>
              </PopupContents>
            </View>
          ))}
        </Swiper>
        <PopupContents>
          <PopupButtonContents
            index={id}
            underlayColor="#6432e9"
            onPress={id === 2 ? onClickBtn : undefined}
          >
            <PopupButtonText style={styles.text}>{btnText}</PopupButtonText>
          </PopupButtonContents>
        </PopupContents>
        <PopupCloseBtn underlayColor="transparent" onPress={onClickCloseBtn}>
          <PopupCloseBtnText>{clostBtnText}</PopupCloseBtnText>
        </PopupCloseBtn>
      </View>
    </PopupContainer>
  );
};

export default SlidePopup;
