import { Color } from "@src/constants";
import styled from "styled-components/native";
import { Dimensions, StyleSheet } from "react-native";
import MediaStyle from "react-native-media-query";

const { height } = Dimensions.get("window");

export const PlanItemContainer = styled.View`
  padding: 0 20px;
`;

export const SkinPlanSmTitle = styled.Text`
  margin-bottom: 10px;
`;

export const SkinPlanLgTitle = styled.Text`
  margin-bottom: 10px;
`;

export const SkinPlanMdTitle = styled.Text``;

export const PlanItemQuestion = styled.View`
  height: calc(100% - 200px);
  margin-top: 60px;
`;

export const PlanItemQuestionTitle = styled.Text`
  margin-bottom: 8px;
  font-size: 20px;
  font-family: "fontB";
`;

export const PlanItemQuestionList = styled.View`
  margin-top: 16px;
`;

export const PlanItemQuestionItem = styled.TouchableOpacity`
  width: calc(100% / 2 - 8px);
  flex-wrap: wrap;
`;

export const PlanItemQuestionText = styled.Text`
  font-size: 16px;
  font-family: "fontB";
  line-height: 1.4em;
  text-align: center;
  color: ${({ selected }: { selected: boolean }) =>
    selected ? Color.WHITE : Color.BLACK};
`;

export const PlanItemQuestionTextContents = styled.View`
  height: ${({ wide }: { wide: boolean }) => (wide ? "48px" : "24px")};
`;

export const PlanItemMultiSelect = styled.View`
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 8px;
`;

export const PlanItemMultiSelectItem = styled.TouchableOpacity`
  padding: 7px 16px 9px 16px;
  margin-right: 10px;
  margin-bottom: 10px;
  background-color: ${({ selected }: { selected: boolean }) =>
    selected ? Color.PUPPLE : Color.WHITE};
`;

export const PlanItemMultiSelectItemText = styled.Text`
  font-size: 16px;
`;

export const PlanItemRangeContainer = styled.View`
  margin-top: 60px;
`;

export const PlanItemRangeNum = styled.View`
  align-items: center;
`;

export const PlanItemRangeNumInner = styled.View`
  position: relative;
`;

export const PlanItemRangeNumText = styled.Text`
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  font-size: 14px;
  font-family: "fontM";
`;

export const PlanTextQuestionContents = styled.View`
  margin-top: 60px;
`;

export const PlanTextQuestionItem = styled.TouchableOpacity`
  height: 52px;
  align-items: center;
  justify-content: center;
  border-radius: 26px;
  margin-bottom: 16px;
  background-color: ${({ selected }: { selected: boolean }) =>
    selected ? Color.PUPPLE : Color.WHITE};
`;

export const PlanTextQuestionItemTitle = styled.Text`
  font-size: 16px;
  font-family: "fontB";
  color: ${({ selected }: { selected: boolean }) =>
    selected ? Color.WHITE : Color.BLACK_TWO};
`;

export const PlanSelectButton = styled.TouchableOpacity`
  height: 40px;
  padding: 7px 16px 9px 16px;
  margin-right: 10px;
  margin-bottom: 10px;
  align-items: center;
  justify-content: center;
  background-color: ${({ selected }: { selected: boolean }) =>
    selected ? Color.PUPPLE : Color.WHITE};
  border-radius: 20px;
  box-shadow: rgba(100, 50, 233, 0.08) 0 0 16px;
`;

export const PlanSelectButtonText = styled.Text`
  font-size: 16px;
  font-family: "fontM";
  color: ${({ selected }: { selected: boolean }) =>
    selected ? Color.WHITE : Color.BLACK_TWO};
`;

export const CheckBoxIcon = styled.Image`
  width: 28px;
  height: 28px;
`;

export const itemStyles = StyleSheet.create({
  smText: {
    lineHeight: 23,
    fontSize: 14,
  },
  lgText: {
    lineHeight: 33,
    fontSize: 24,
    fontWeight: "bold",
  },
  mdText: {
    lineHeight: 23,
    fontSize: 16,
  },
  priceImg: {
    position: "relative",
    width: 131,
    height: 88,
  },
  textQuestion: {
    marginTop: 24,
  },
  lastQuestion: {
    height: height - 170,
  },
});

export const { ids, styles } = MediaStyle.create({
  list: {
    position: "relative",
    width: "100%",
    marginBottom: 16,
    paddingLeft: 10,
    paddingTop: 10,
    paddingRight: 10,
    paddingBottom: 24,
    shadowColor: "#6432e914",
    shadowRadius: 16,
    backgroundColor: Color.WHITE,
    borderRadius: 16,
  },
  selectedList: {
    backgroundColor: Color.PUPPLE,
  },
  listImg: {
    width: "100%",
    height: 170,
    marginBottom: 8,
    "@media (max-width: 480px)": {
      height: 120,
    },
  },
});
