import { Color } from "@src/constants";
import styled from "styled-components/native";

export const TabContainer = styled.View`
  position: relative;
`;

export const NoticeContainer = styled.View`
  position: relative;
  height: 100%;
`;

export const Notice = styled.View`
  height: 160px;
`;

export const NoticeTextContents = styled.View`
  width: calc(100% - 140px);
  padding: 20px 0 26px 20px;
`;

export const NoticeImageContents = styled.View`
  position: absolute;
  right: 0;
  bottom: 0;
`;

export const NoticeImage = styled.Image`
  width: 140px;
  height: 160px;
`;

export const NoticeText = styled.Text`
  max-height: 88px;
  font-size: 14px;
  font-family: "fontM";
  line-height: 20px;
  color: ${Color.LIGHT_PUPPLE_FIVE};
`;
