import React, { ReactElement } from "react";
import { Maybe } from "..";
import {
  BottomFixContentsWrap,
  BottomFixContentsInner,
  BottomFixButtonContents,
  styles,
  LeftButton,
  LeftButtonText,
  RightButton,
  RightButtonText,
  FullButton,
  FullButtonText,
  SubText,
} from "./styled";

interface BottomFixButtonProps {
  onPressLeftBtn?: () => void;
  onPressRightBtn?: () => void;
  onPressFullBtn?: () => void;
  fullBtnMode?: boolean;
  fullBtnText?: string;
  multipleBtnMode?: boolean;
  leftBtnText?: string;
  rightBtnText?: string;
  whiteBtn?: boolean;
  whiteBg?: boolean;
  disabled?: boolean;
  disabledBg?: boolean;
  planMode?: boolean;
  subText?: string;
}

const BottomFixButton = ({
  onPressLeftBtn,
  onPressRightBtn,
  onPressFullBtn,
  fullBtnMode,
  fullBtnText,
  multipleBtnMode,
  leftBtnText,
  rightBtnText,
  whiteBtn,
  whiteBg,
  disabled,
  disabledBg,
  planMode,
  subText,
}: BottomFixButtonProps): ReactElement => {
  return (
    <BottomFixContentsWrap
      bgColor={fullBtnMode && !whiteBg && !planMode && !disabled}
    >
      <BottomFixContentsInner planMode={planMode}>
        {subText && <SubText>{subText}</SubText>}
        <BottomFixButtonContents>
          <Maybe
            test={fullBtnMode}
            children={
              <FullButton
                style={styles.button}
                onPress={onPressFullBtn}
                whiteBtn={whiteBtn}
                disabledBg={disabledBg}
                activeOpacity={1}
                disabled={disabled}
              >
                <FullButtonText whiteBtn={whiteBtn}>
                  {fullBtnText}
                </FullButtonText>
              </FullButton>
            }
          />
          <Maybe
            test={multipleBtnMode}
            children={
              <>
                <LeftButton
                  style={styles.button}
                  onPress={onPressLeftBtn}
                  activeOpacity={1}
                >
                  <LeftButtonText>{leftBtnText}</LeftButtonText>
                </LeftButton>

                <RightButton
                  style={styles.button}
                  onPress={disabled ? undefined : onPressRightBtn}
                  disabled={disabled}
                  activeOpacity={1}
                >
                  <RightButtonText>{rightBtnText}</RightButtonText>
                </RightButton>
              </>
            }
          />
        </BottomFixButtonContents>
      </BottomFixContentsInner>
    </BottomFixContentsWrap>
  );
};

export default BottomFixButton;
