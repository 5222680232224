import {
  CommunityListScreen,
  EditTalkScreen,
  CommunityDetailScreen,
  CommunityReportScreen,
  ProfileScreen,
  UpdateTalkScreen,
} from "@src/screens";

/**
 * Community Configs
 */

export default [
  {
    name: "community",
    component: CommunityListScreen,
    options: {
      title: "",
    },
  },
  {
    name: "editTalk",
    component: EditTalkScreen,
    options: {
      title: "",
    },
  },
  {
    name: "updateTalk",
    component: UpdateTalkScreen,
    options: {
      title: "",
    },
  },
  {
    name: "communityDetail",
    component: CommunityDetailScreen,
    options: {
      title: "",
    },
  },
  {
    name: "communityReport",
    component: CommunityReportScreen,
    options: {
      title: "",
    },
  },
  {
    name: "profile",
    component: ProfileScreen,
    options: {
      title: "",
    },
  },
  {
    name: "myprofile",
    component: ProfileScreen,
    options: {
      title: "",
    },
  },
];
