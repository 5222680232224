import { atom } from "recoil";

/**
 * 피부 MBTI Atoms
 */

// MBTI 결과
export const mbtiResultState = atom<string>({
  key: "mbtiResultState",
  default: "",
});

// MBTI 결과 로딩
export const resultLoadingState = atom<boolean>({
  key: "resultLoadingState",
  default: false,
});
