import { Color } from "@src/constants";
import styled from "styled-components/native";
import { StyleSheet } from "react-native";
import MediaStyle from "react-native-media-query";

export const PlanMakingListContainer = styled.View``;

export const PlanMakingListItemContents = styled.View`
  padding: 16px;
  border-radius: 16px;
  box-shadow: rgba(100, 50, 233, 0.08) 0 0 16px;
  margin-bottom: 20px;
  background-color: ${Color.WHITE};
`;

export const PlanMakingListItem = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const PlanMakingListBtnContents = styled.View`
  margin-top: 30px;
  flex-direction: row;
  justify-content: space-between;
`;

export const PlanMakingListBtn = styled.TouchableOpacity`
  width: calc(100% / 2 - 4px);
  height: 32px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: ${Color.GREY};
`;

export const Category = styled.View`
  align-items: center;
  justify-content: center;
  height: 20px;
  border-radius: 10px;
  padding: 2px 8px 3px 8px;
  background-color: ${Color.PUPPLE};
`;

export const ListName = styled.View`
  margin-left: 10px;
`;

export const Summary = styled.View`
  margin-top: 10px;
`;

export const Drag = styled.TouchableOpacity`
  width: 24px;
  height: 24px;
`;

export const itemStyles = StyleSheet.create({
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  whiteText: {
    color: Color.WHITE,
  },
  puppleText: {
    color: Color.PUPPLE,
  },
  xxsText: {
    fontSize: 10,
    fontWeight: "500",
  },
  xsText: {
    fontSize: 12,
    fontWeight: "500",
  },
  sText: {
    fontSize: 13,
    fontWeight: "500",
  },
  smText: {
    fontSize: 14,
    fontWeight: "500",
  },
  mdText: {
    fontSize: 16,
    fontWeight: "bold",
  },
  lgText: {
    fontSize: 20,
    fontWeight: "bold",
  },
  xlText: {
    fontSize: 24,
    fontWeight: "bold",
  },
  lineHeight: {
    lineHeight: 20,
  },
  pupple: {
    color: Color.PUPPLE,
  },
  bold: {
    fontWeight: "bold",
  },
  grayText: {
    color: Color.LIGHT_GREY_TWO,
  },
  dragIcon: {
    width: 24,
    height: 24,
  },
  flexRow: {
    flexDirection: "row",
  },
  alignCenter: {
    alignItems: "center",
  },
});

export const { ids, styles } = MediaStyle.create({});
