import React, { useState } from "react";
import { View, StatusBar, Text, ScrollView } from "react-native";
import { Helmet } from "react-helmet-async";
import RangeSlider from "@react-native-community/slider";
import { useNavigation } from "@react-navigation/native";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { useRecoilState } from "recoil";

import { Layouts } from "@src/components/container";
import {
  BottomBtnContents,
  planStyles,
  RangeLevel,
  RangeList,
  RangeListItem,
  RangeListItemImg,
  RangePrice,
  RangeTitle,
  RangeTitleText,
  RecommendPlan,
  SkinPlanContainer,
  SkinPlanHeader,
} from "../styled";
import { useAuth } from "@src/hooks/auth";
import thumbImg from "@assets/images/plan/price-range-thumb.png";
import rangeLgImg from "@assets/images/plan/price-range-img_large.png";
import { SkinPlanResultList, SkinPlanSelectedList } from "@src/store";
import { REGEX_NUMBER_COMMA } from "@src/constants";
import { globalStyles } from "@src/styles/modules/global";
import { analytics } from "@src/hooks/analytics";

/**
 * 추천 피부 플랜(Range Slider)
 */

const RangeRecommendScreen = () => {
  const navigation = useNavigation<any>();

  const { user } = useAuth();

  const [planResultList, setPlanResultList] =
    useRecoilState(SkinPlanResultList);
  const [planSelectedList, setPlanSelectedList] =
    useRecoilState(SkinPlanSelectedList);
  const [rangeValue, setRangeValue] = useState<number>(
    planResultList.length - 1
  );

  const handleRangeValue = (index) => {
    if (index) {
      setRangeValue(index);
    } else {
      setRangeValue(0);
    }
  };

  const handleRecommendPlan = () => {
    setPlanSelectedList({
      plan: planResultList[rangeValue]?.subject,
      price: planResultList[rangeValue]?.totalPriceForPlanner,
    });

    analytics("Skin Plan View Recommend List", {
      view_recommend_list: {
        plan: planResultList[rangeValue]?.subject,
        price: planResultList[rangeValue]?.totalPriceForPlanner,
      },
    });

    navigation.push("skinPlanRecommend", {
      id: planResultList && planResultList[rangeValue]?.sortNo,
    });
  };

  const renderHeader = () => {
    return (
      <SkinPlanHeader>
        <View style={[planStyles.planHeader, planStyles.whiteBg]}>
          <Text style={planStyles.mLText}>추천 피부 플랜</Text>
        </View>
        <View style={planStyles.planIntro}>
          <Text style={planStyles.lgText}>추천 플랜이 준비 되었어요 💙</Text>
          <Text style={[planStyles.mdText, planStyles.marginTop10]}>
            피부 관리 입문자인
            <br />
            <Text style={planStyles.highlightText}>{user?.nickname}</Text>{" "}
            님만을 위해
            <br />
            여드름 문제를 개선해 줄 플랜들을 만들었어요!
          </Text>
        </View>
      </SkinPlanHeader>
    );
  };

  const renderContents = () => {
    return (
      <RangeList>
        <View style={planStyles.rangeImg}>
          <RangeListItemImg source={rangeLgImg} resizeMode="stretch" />
          <Swiper
            slidesPerView={1}
            centeredSlides
            allowTouchMove={false}
            style={planStyles.slider}
          >
            <SwiperSlide>
              <RangeListItem>
                <RangeLevel>
                  {planResultList && planResultList[rangeValue]?.sortNo === 0
                    ? "A"
                    : planResultList && planResultList[rangeValue]?.sortNo === 1
                    ? "B"
                    : "C"}
                  플랜
                </RangeLevel>
                <RangeTitle>
                  <RangeTitleText>
                    {planResultList && planResultList[rangeValue]?.subject}
                  </RangeTitleText>
                </RangeTitle>
                <RangePrice>
                  <Text style={[planStyles.smText, planStyles.highlightText]}>
                    총 예상 금액
                  </Text>
                  <Text
                    style={[
                      planStyles.xxlText,
                      planStyles.highlightText,
                      planStyles.marginTop6,
                    ]}
                  >
                    {planResultList &&
                      planResultList[rangeValue]?.totalPriceForPlanner
                        .toString()
                        .replace(REGEX_NUMBER_COMMA, ",")}
                    <Text
                      style={[planStyles.mmlText, planStyles.highlightText]}
                    >
                      원
                    </Text>
                  </Text>
                </RangePrice>
              </RangeListItem>
            </SwiperSlide>
          </Swiper>
        </View>
        <RangeSlider
          step={1}
          minimumValue={0}
          maximumValue={2}
          value={rangeValue}
          maximumTrackTintColor="#ffffff"
          thumbImage={{ uri: thumbImg }}
          thumbTintColor="transparent"
          onValueChange={(value) => handleRangeValue(value)}
          tapToSeek
          vertical
          style={planStyles.rangeStyle}
        />
      </RangeList>
    );
  };

  return (
    <>
      <StatusBar barStyle="light-content" backgroundColor="#6432e9" />
      <Helmet>
        <title>추천 피부 플랜 | 촉촉박사</title>
      </Helmet>
      <Layouts logo hamburgerMode transparent>
        <ScrollView
          bounces={false}
          scrollEventThrottle={1}
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{ flexGrow: 1, flex: 1 }}
        >
          <SkinPlanContainer
            style={[planStyles.planHeight, globalStyles.justifySpaceB]}
          >
            <RecommendPlan>
              <View style={planStyles.header}>{renderHeader()}</View>
              {renderContents()}
            </RecommendPlan>
            <View style={planStyles.bottomBtn}>
              <BottomBtnContents
                activeOpacity={1}
                style={globalStyles.fullBtn}
                onPress={handleRecommendPlan}
              >
                <Text style={planStyles.bottomBtnText}>이 플랜을 볼래요!</Text>
              </BottomBtnContents>
            </View>
          </SkinPlanContainer>
        </ScrollView>
      </Layouts>
    </>
  );
};

export default RangeRecommendScreen;
