import React, { ReactElement } from "react";
import { ScrollView, View } from "react-native";
import {
  Dimmed,
  styles,
  ids,
  BottomPopupContainer,
  BottomPopupContents,
  BottomPopupItem,
  BottomPopupItemText,
} from "./styled";
import { FlatList } from "react-native";

interface BottomSelectItemProps {
  isOpen?: any;
  onClose?: () => void;
  data?: any;
  onChangeId?: (item) => void;
}

const BottomSelectItem = ({
  isOpen,
  onClose,
  data,
  onChangeId,
}: BottomSelectItemProps): ReactElement => {
  const renderItem = ({ item }) => {
    return (
      <BottomPopupItem
        onPress={() => {
          onClose();
          onChangeId(item);
        }}
      >
        <BottomPopupItemText>{item.title}</BottomPopupItemText>
      </BottomPopupItem>
    );
  };

  return (
    <>
      <Dimmed
        isOpen={isOpen}
        onStartShouldSetResponder={() => true}
        onResponderGrant={() => onClose()}
      />
      <View dataSet={{ media: ids.popup }} style={styles.popup}>
        <ScrollView
          bounces={false}
          scrollEventThrottle={1}
          contentContainerStyle={{ flexGrow: 1, flex: 1 }}
          showsVerticalScrollIndicator={false}
        >
          <BottomPopupContainer>
            <BottomPopupContents>
              <FlatList
                data={data}
                renderItem={renderItem}
                style={{ width: "100%" }}
              />
            </BottomPopupContents>
          </BottomPopupContainer>
        </ScrollView>
      </View>
    </>
  );
};

export default BottomSelectItem;
