import styled from "styled-components/native";

export const HeaderButton = styled.TouchableOpacity`
  align-items: center;
  min-height: 66px;
  padding: 0;
  justify-content: center;
  margin-left: 15px;
`;

export const Hamburger = styled.View`
  width: 24px;
  height: 24px;
`;

export const Icon = styled.Image`
  width: 24px;
  height: 24px;
`;

export const backButtonStyle = {
  marginLeft: 15,
};
