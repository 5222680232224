import { CommunitySearchList, IForumPostDetail, ProfileType } from "@src/types";
import { atom } from "recoil";
import { localStorageEffect } from "..";

/**
 * 커뮤니티 State
 */

// 커뮤니티 검색
export const CommunitySearchListState = atom<CommunitySearchList[]>({
  key: "communitySearchListState",
  default: [],
  effects_UNSTABLE: [localStorageEffect("communitySearchListState")],
});

// 커뮤니티 상세
export const CommunityDetailListState = atom<IForumPostDetail[]>({
  key: "CommunityDetailListState",
  default: [],
});

// 커뮤니티 검색 키워드
export const CommunitySearchKeywordState = atom<string>({
  key: "CommunitySearchKeywordState",
  default: "",
});

// 글 id
export const CommunityPostId = atom<number>({
  key: "CommunityPostId",
  default: 0,
});

// 댓글 id
export const CommunityCommentId = atom<number>({
  key: "CommunityCommentId",
  default: 0,
});

// 댓글 등록 user id
export const CommunityCommentUserId = atom<number | null>({
  key: "CommunityCommentUserId",
  default: null,
});

// 커뮤니티 forum id
export const CommunityForumId = atom<number>({
  key: "CommunityForumId",
  default: 1,
});

// 프로필
export const CommunityProfile = atom<ProfileType>({
  key: "CommunityProfile",
  default: null,
});
