import { ServicePolicyScreen, PrivacyPolicyScreen } from "@src/screens";

/**
 * Policy Configs
 */

export default [
  {
    name: "servicePolicy",
    component: ServicePolicyScreen,
    options: {
      title: "",
    },
  },
  {
    name: "privacyPolicy",
    component: PrivacyPolicyScreen,
    options: {
      title: "",
    },
  },
];
