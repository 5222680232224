/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  entireUserState,
  simpleUserState,
  userState,
  userTypeState,
  withdrawalDateState,
} from "@src/store";
import { STRG_ACCESS_TOKEN_KEY } from "@src/constants";
import { AuthService, TokenService } from "@src/services";
import { analytics } from "@src/hooks/analytics";

const UserEffect: React.FC = (): any => {
  const token = TokenService.getAccessToken();

  const [user, setUser] = useRecoilState(userState);
  const setSimpleUser = useSetRecoilState(simpleUserState);
  const setEntireUser = useSetRecoilState(entireUserState);
  const [userType, setUserType] = useRecoilState(userTypeState);
  const setWithdrawalDate = useSetRecoilState(withdrawalDateState);

  const cleanUp = () => {
    setUser(null as any);
    setSimpleUser(null as any);
    setEntireUser(null as any);

    localStorage.removeItem(STRG_ACCESS_TOKEN_KEY);
  };

  const getUserInfo = async () => {
    if (token) {
      try {
        const data = await AuthService.getUserInfo();
        setUser(data);
        analytics("Login", {
          id: data.id,
          page_name: window.location.pathname,
        });
      } catch (e: any) {
        const errorState = e.data;
        if (errorState?.status) {
          setUserType(errorState.status);
        }
        if (errorState?.lastAppliedDate) {
          setWithdrawalDate(errorState.lastAppliedDate);
        }
      }
    }
  };

  const getEntireUserInfo = async () => {
    if (token) {
      const type = "ENTIRE";
      const data = await AuthService.getUserTypeInfo(type);
      setEntireUser(data);
    }
  };

  useEffect(() => {
    if (!token) {
      cleanUp();
    }
  }, [token]);

  useEffect(() => {
    getUserInfo();
    getEntireUserInfo();
  }, [token]);
};

export default UserEffect;
