import React, { ReactElement, useRef } from "react";
import "@toast-ui/editor/dist/toastui-editor.css";
import { Editor, Viewer } from "@toast-ui/react-editor";
import "@toast-ui/editor/dist/toastui-editor-viewer.css";
import "@toast-ui/editor/dist/i18n/ko-kr";

import { EditorContainer } from "./styled";
import playIcon from "@assets/icons/icon-play.svg";

interface CommunityEditorProps {
  content: string;
}

const CommunityEditor = ({ content }: CommunityEditorProps): ReactElement => {
  const editorRef = useRef<Editor>(null);

  const myCustomEl = document.createElement("div");
  myCustomEl.setAttribute("class", "play-icon");
  myCustomEl.style.cursor = "pointer";

  const icon = document.createElement("img");
  icon.setAttribute("src", playIcon);
  icon.setAttribute("width", "20");
  myCustomEl.appendChild(icon);

  const container = document.createElement("div");
  const description = document.createElement("p");
  container.setAttribute("class", "popup-container");
  description.setAttribute("class", "info-text");
  description.textContent =
    "https://youtu.be/[동영상코드] 형식의 링크를 입력해 주세요.";

  const urlInput = document.createElement("input");
  urlInput.setAttribute("class", "youtube-input");
  urlInput.style.width = "100%";

  const button = document.createElement("button");
  button.setAttribute("class", "input-btn");
  button.innerHTML = "추가하기";

  button.addEventListener("click", (e: any) => {
    if (
      /https:\/\/youtu.be\/.{11,}/.test(e.target.value) ||
      /https:\/\/www.youtube.com\/watch\?v=.{11,}/.test(e.target.value)
    ) {
      let str =
        '<iframe width="435" height="245" src="https://www.youtube-nocookie.com/embed/' +
        e.target.value.slice(-26) +
        '" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style="border: none"></iframe>';

      editorRef.current.getInstance().changeMode("markdown");
      editorRef.current.getInstance().insertText(str);
      editorRef.current.getInstance().eventEmitter.emit("closePopup");
      editorRef.current.getInstance().changeMode("wysiwyg");
    }
  });

  container.appendChild(description);
  container.appendChild(urlInput);
  container.appendChild(button);

  const toolbarConfigs = [
    ["image"],
    [
      {
        name: "Youtube",
        tooltip: "Youtube",
        el: myCustomEl,
        popup: {
          body: container,
          style: { width: "auto" },
        },
      },
    ],
  ];

  return (
    <EditorContainer>
      <Editor
        ref={editorRef}
        toolbarItems={toolbarConfigs}
        initialEditType="wysiwyg"
        hideModeSwitch
        height="calc(100vh - 300px)"
        placeholder="내용을 입력해 주세요."
        language="ko-kr"
        customHTMLRenderer={{
          htmlBlock: {
            iframe(node) {
              return [
                {
                  type: "openTag",
                  tagName: "iframe",
                  outerNewLine: true,
                  attributes: node.attrs,
                },
                { type: "html", content: node.childrenHTML },
                { type: "closeTag", tagName: "iframe", outerNewLine: true },
              ];
            },
          },
        }}
      />
      <Viewer
        initialValue={content || ""}
        customHTMLRenderer={{
          htmlBlock: {
            iframe(node) {
              return [
                {
                  type: "openTag",
                  tagName: "iframe",
                  outerNewLine: true,
                  attributes: node.attrs,
                },
                { type: "html", content: node.childrenHTML },
                { type: "closeTag", tagName: "iframe", outerNewLine: false },
              ];
            },
          },
        }}
      />
    </EditorContainer>
  );
};

export default CommunityEditor;
