import React, { ReactElement } from "react";
import { View, Text, Image } from "react-native";
import { RenderItemParams } from "react-native-draggable-flatlist";

import {
  itemStyles,
  Category,
  ListName,
  PlanMakingListContainer,
  PlanMakingListItem,
  PlanMakingListItemContents,
  PlanMakingListBtnContents,
  PlanMakingListBtn,
  Drag,
} from "./styled";
import dragIcon from "@assets/icons/icon-drag.svg";

interface PlanMakingListProps {
  data?: any;
  orderMode?: boolean;
  listUpdate?: () => void;
}

const PlanMakingList = ({
  data,
  orderMode,
  listUpdate,
}: PlanMakingListProps): ReactElement => {
  const renderContent = ({ item, drag }: RenderItemParams<any>) => {
    return (
      <PlanMakingListItemContents>
        <PlanMakingListItem>
          <View style={[itemStyles.flexRow, itemStyles.alignCenter]}>
            <Category>
              <Text style={[itemStyles.whiteText, itemStyles.xxsText]}>
                {item.category}
              </Text>
            </Category>
            <ListName>
              <Text style={[itemStyles.puppleText, itemStyles.lgText]}>
                {item.title}
              </Text>
            </ListName>
          </View>
          {orderMode && (
            <View>
              <Drag onLongPress={drag} activeOpacity={1}>
                <Image
                  source={dragIcon}
                  resizeMode="contain"
                  style={itemStyles.dragIcon}
                />
              </Drag>
            </View>
          )}
        </PlanMakingListItem>
        <PlanMakingListBtnContents>
          <PlanMakingListBtn onPress={listUpdate}>
            <Text style={[itemStyles.xsText, itemStyles.grayText]}>
              변경하기
            </Text>
          </PlanMakingListBtn>
          <PlanMakingListBtn>
            <Text style={[itemStyles.xsText, itemStyles.grayText]}>
              삭제하기
            </Text>
          </PlanMakingListBtn>
        </PlanMakingListBtnContents>
      </PlanMakingListItemContents>
    );
  };

  const renderFooter = (item) => {
    return <View></View>;
  };

  return (
    <PlanMakingListContainer>{renderContent(data)}</PlanMakingListContainer>
  );
};

export default PlanMakingList;
