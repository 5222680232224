import { Color } from "@src/constants";
import styled from "styled-components/native";

export const TabContainer = styled.View`
  position: relative;
`;

export const SlideContents = styled.View``;

export const TabItem = styled.TouchableOpacity`
  min-width: 60px;
  min-height: 36px;
  padding: 0 14px 0 14px;
  margin-right: 4px;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  border-width: ${({ isActive }: { isActive?: boolean }) =>
    isActive ? "0px" : "1px"};
  border-color: ${({ isActive }: { isActive?: boolean }) =>
    isActive ? "" : Color.LIGHT_PUPPLE_FIVE};
  background-color: ${({ isActive }: { isActive?: boolean }) =>
    isActive ? Color.WHITE : ""};
`;

export const TabText = styled.Text`
  font-family: ${({ isActive }: { isActive?: boolean }) =>
    isActive ? "fontB" : "fontM"};
  color: ${({ isActive }: { isActive?: boolean }) =>
    isActive ? Color.LIGHT_PUPPLE_THREE : Color.LIGHT_PUPPLE_FIVE};
  font-size: 14px;
`;
