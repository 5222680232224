/**
 * HTTP 상태 코드
 */
export const HTTP_OK = 200;
export const HTTP_BAD_REQUEST = 400;
export const HTTP_UNAUTHORIZED = 401;
export const HTTP_FORBIDDEN = 403;
export const HTTP_NOT_FOUND = 404;
export const HTTP_INTERNAL_SERVER_ERROR = 500;

/**
 * API 에러코드
 */

// TOKEN 관련 오류
export const ERR_EXPIRED_TOKEN = "expired_token";

// 탈퇴 요청/휴면 계정일 경우
export const DROPPED_USER = "dropped";
export const SLEEPER_USER = "sleeper";
