/**
 * Mixpanel Analytics
 */

import ExpoMixpanelAnalytics from "@benawad/expo-mixpanel-analytics";

export const analytics = (eventName, value) => {
  const analyticsId = "6b781e73e8fa4deb3bf55da79d2dc8fb";
  const analytics = new ExpoMixpanelAnalytics(analyticsId);

  analytics.track(eventName, value);

  return analytics;
};
