/**
 * 로그인 후 Redirect URL
 */

import env from "../../environments";

export const useRedirectUrl = () => {
  const BASE_URL = env.API_URL;
  const REDIRECT_URL = env.REDIRECT_URL;
  const LOGIN_URL = `${BASE_URL}/oauth2/authorization/kakao?redirect_uri=${REDIRECT_URL}&referrer=${
    window.location.pathname
  }${window.location.search && window.location.search}`;

  return {
    loginUrl: LOGIN_URL,
  };
};
