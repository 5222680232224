import { Color } from "@src/constants";
import styled from "styled-components/native";

export const SurgeryInfoItemWrap = styled.View``;

export const SurgeryInfoItemInner = styled.View`
  padding: 0 20px;
`;

export const SurgeryInfoList = styled.View`
  display: flex;
  flex-direction: ${({ exceptionView }: { exceptionView?: boolean }) =>
    exceptionView ? "" : "row"};
  justify-content: ${({ exceptionView }: { exceptionView?: boolean }) =>
    exceptionView ? "" : "space-between"};
  background-color: ${Color.WHITE};
  padding: 19px 16px 19px 16px;
  margin-bottom: 10px;
  border-radius: 16px;
  box-shadow: rgba(100, 50, 233, 0.08) 0 0 16px;
`;

export const SurgeryInfoLeftTitle = styled.View`
  display: flex;
  flex-direction: row;
`;

export const SurgeryInfoLeftTitleText = styled.Text`
  font-size: 14px;
  font-family: "fontB";
  letter-spacing: -0.35px;
  line-height: 24px;
`;

export const SurgeryInfoLeftTitleSubText = styled.Text`
  margin-left: 5px;
  font-size: 12px;
  color: ${Color.LIGHT_GREY_TWO};
`;

export const SurgeryInfoRightTitle = styled.View`
  max-width: 210px;
`;

export const SurgeryInfoRightTitleText = styled.Text`
  font-size: 16px;
  font-family: "fontB";
  letter-spacing: -0.4px;
  line-height: 24px;
  color: ${Color.LIGHT_PUPPLE_THREE};
  text-align: right;
`;

export const SurgeryInfoPrice = styled.View``;

export const SurgeryInfoPriceTitleText = styled.Text`
  width: 52px;
  padding: 3px 0;
  margin-top: 10px;
  border-radius: 9px;
  text-align: center;
  font-size: 10px;
  font-family: "fontB";
  font-weight: bold;
  letter-spacing: -0.25px;
  color: ${Color.PUPPLE};
  background-color: ${Color.LIGHT_PUPPLE};
`;

export const SurgeryInfoPriceContents = styled.View`
  margin: 10px 0 12px 0;
  flex-direction: row;
`;

export const SurgeryInfoPriceContentsInner = styled.View``;

export const SurgeryInfoPriceNumContents = styled.View`
  flex-direction: row;
`;

export const SurgeryInfoPriceNum = styled.View`
  width: calc(100% / 3);
  min-height: 40px;
  align-items: center;
  justify-content: center;
`;

export const SurgeryInfoPriceTitle = styled.Text`
  font-size: 10px;
  font-family: "fontM";
`;

export const SurgeryInfoPriceText = styled.Text`
  font-size: 14px;
  font-family: "fontB";
  font-weight: bold;
  color: ${Color.LIGHT_PUPPLE_THREE};
`;

export const SurgeryInfoPriceGraphContents = styled.View``;

export const SurgeryInfoPriceGraphImg = styled.Image`
  width: 100%;
  height: 100%;
`;

export const SurgeryInfoRecommend = styled.View`
  width: 100%;
`;

export const SurgeryInfoRecommendTextWrap = styled.View`
  flex-direction: row;
  align-items: center;
  margin: 10px 0;
`;

export const SurgeryInfoRecommendText = styled.Text`
  padding: 4px 11px;
  background-color: ${Color.LIGHT_PUPPLE_FOUR};
  border-radius: 14px;
  font-size: 16px;
  font-family: "fontB";
  font-weight: bold;
  letter-spacing: -0.4px;
  color: ${Color.WHITE};
`;

export const SurgeryInfoPlusText = styled.Text`
  margin: 0 4px;
  font-size: 16px;
  font-family: "fontB";
  letter-spacing: -0.4px;
  color: ${Color.LIGHT_PUPPLE_THREE};
`;

export const SurgeryInfoRecommendSummary = styled.View`
  padding: 16px 16px 20px 16px;
  border-radius: 16px;
  background-color: ${Color.LIGHT_PUPPLE_FIVE};
`;

export const SurgeryInfoRecommendSummaryText = styled.Text`
  font-size: 13px;
  font-family: "fontM";
  font-weight: 500;
  letter-spacing: -0.33px;
  line-height: 20px;
  color: ${Color.LIGHT_PUPPLE_TWO};
`;
