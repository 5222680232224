import React from "react";
import { View, StatusBar } from "react-native";
import { Helmet } from "react-helmet-async";

import { ids, styles } from "@src/styles/modules/global";
import { LoginBtn, LoginBtnText, LoginContainer } from "./styled";

/**
 * 로그인 페이지
 */

const LoginScreen = () => {
  return (
    <>
      <StatusBar barStyle="light-content" backgroundColor="#6432e9" />
      <Helmet>
        <title>로그인 | 촉촉박사</title>
        <meta
          name="description"
          content="로그인 | 촉촉박사"
          data-react-helmet="true"
        />
      </Helmet>
      <View style={styles.container} dataSet={{ media: ids.container }}>
        <LoginContainer>
          <LoginBtn>
            <LoginBtnText>카카오로 로그인</LoginBtnText>
          </LoginBtn>
        </LoginContainer>
      </View>
    </>
  );
};

export default LoginScreen;
