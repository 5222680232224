import React, { ReactElement } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Thumbs } from "swiper";
import "swiper/css";
import "swiper/css/thumbs";
import "swiper/css/free-mode";
import { TabContainer, TabItem, TabText, SlideContents } from "./styled";
import { globalStyles } from "@src/styles/modules/global";

interface TabSliderProps {
  tabId?: number;
  onPressTab?: (id, name) => void;
  tabItems?: Array<any>;
  thumbsSwiper?: any;
}

const TabSlider = ({
  tabId,
  onPressTab,
  tabItems,
  thumbsSwiper,
}: TabSliderProps): ReactElement => {
  return (
    <TabContainer>
      <Swiper
        slidesPerView="auto"
        style={globalStyles.swiper}
        onSwiper={thumbsSwiper}
        modules={[Thumbs]}
        slideToClickedSlide
        slidesOffsetBefore={20}
        slidesOffsetAfter={20}
      >
        <SlideContents>
          {tabItems.map((item, index) => (
            <SwiperSlide key={index} style={globalStyles.slide}>
              <TabItem
                onPress={() => onPressTab(item.id, item.title)}
                isActive={tabId === item.id}
              >
                <TabText isActive={tabId === item.id}>{item.title}</TabText>
              </TabItem>
            </SwiperSlide>
          ))}
        </SlideContents>
      </Swiper>
    </TabContainer>
  );
};

export default TabSlider;
