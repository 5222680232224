import React, { PropsWithChildren } from "react";
import { Switch } from "react-native-switch";
import { Color } from "@src/constants";

interface SwitchToggleProps {
  switchState: any;
  handleSwitchButton: (value: any) => void;
}

const SwitchToggle = ({
  switchState,
  handleSwitchButton,
}: PropsWithChildren<SwitchToggleProps>): React.ReactElement => {
  return (
    <Switch
      circleSize={26}
      activeText={""}
      inActiveText={""}
      switchBorderRadius={16}
      barHeight={32}
      backgroundActive={Color.PUPPLE}
      backgroundInactive={Color.LIGHT_GREY_FIVE}
      circleActiveColor={Color.WHITE}
      circleBorderInactiveColor={Color.LIGHT_GREY_FIVE}
      circleBorderActiveColor={Color.PUPPLE}
      circleBorderWidth={2}
      value={switchState}
      onValueChange={(value) => handleSwitchButton(value)}
      innerCircleStyle={{
        width: 30,
        height: 30,
        borderRadius: 50,
      }}
      switchLeftPx={3}
      switchRightPx={3}
    />
  );
};

export default SwitchToggle;
