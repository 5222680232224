import styled from "styled-components/native";
import { Dimensions, StyleSheet } from "react-native";
import MediaStyle from "react-native-media-query";
import { Color } from "@src/constants";

export const SkinMbtiContainer = styled.View`
  height: 100%;
`;

export const SkinMbtiImgContents = styled.View``;

export const SkinMbtiImg = styled.Image`
  width: 100%;
  height: 100%;
`;

export const SkinMbtiContents = styled.View`
  position: absolute;
  width: 100%;
  height: calc(100% - 66px);
  align-items: center;
  justify-content: space-between;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const SkinMbtiTitle = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
`;

export const SkinMbtiTitleText = styled.Text`
  padding: 0 15px;
  font-family: "fontB";
  font-size: 18px;
  color: ${Color.WHITE};
`;

export const SkinMbtiSubTitleText = styled.View`
  margin: 22px 0;
`;

export const SkinMbtiInfoText = styled.View``;

export const SkinMbtiBtnContents = styled.View`
  width: 100%;
`;

export const SkinMbtiBtn = styled.TouchableOpacity`
  width: 100%;
  padding: 10px 0 12px 0;
  border-radius: 38px;
  background-color: ${Color.PUPPLE_TWO};
  box-shadow: rgba(255, 255, 255, 0.2) 0 0 16px;
`;

export const SkinMbtiBtnText = styled.Text`
  font-size: 24px;
  font-family: "fontB";
  line-height: 1.5em;
  text-align: center;
  color: ${Color.WHITE};
`;

export const SkinMbtiCountText = styled.Text`
  margin-top: 2px;
  font-size: 12px;
  font-family: "fontM";
  letter-spacing: -0.3px;
  color: ${Color.WHITE};
  text-align: center;
`;

export const SkinMbtiSnsContents = styled.View`
  flex-direction: row;
  padding-top: 30px;
`;

export const SkinMbtiSnsBtn = styled.TouchableOpacity`
  margin: 0 10px;
`;

export const SkinMbtiIntroImage = styled.View``;

export const SkinMbtiFooter = styled.View`
  width: 100%;
  align-items: center;
  padding: 0 20px;
`;

// Detail Page
export const SkinMbtiDetailHeader = styled.View`
  width: 100%;
  padding: 0 20px;
`;

export const SkinMbtiAnswerContents = styled.View`
  width: 100%;
`;

export const SkinMbtiQuestionContents = styled.View`
  width: 100%;
  padding: 0 20px;
`;

export const SkinMbtiQuestionNav = styled.View`
  width: 100%;
  height: 56px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const SkinMbtiQuestionPrev = styled.TouchableHighlight`
  justify-content: flex-start;
`;

export const SkinMbtiQuestionPrevImg = styled.Image`
  width: 24px;
  height: 24px;
`;

export const SkinMbtiQuestionCount = styled.View`
  align-items: flex-end;
`;

export const SkinMbtiQuestionCountText = styled.Text`
  font-size: 14px;
  letter-spacing: -0.35px;
  color: ${Color.WHITE};
`;

export const SkinMbtiQuestionNumText = styled.Text`
  margin-bottom: 40px;
  font-size: 30px;
  font-family: "fontM";
  text-align: center;
  color: ${Color.WHITE};
`;

export const SkinMbtiQuestionTitle = styled.Text`
  font-family: "fontB";
  font-size: 20px;
  text-align: center;
  line-height: 1.5em;
  color: ${Color.WHITE};
`;

export const SkinMbtiAnswerItem = styled.TouchableOpacity`
  width: 100%;
  min-height: 52px;
  max-height: 76px;
  padding: 13px 10px 12px 10px;
  margin-top: 16px;
  border-radius: 38px;
  justify-content: center;
  line-height: 27px;
  border: 0;
  background-color: ${Color.WHITE};
`;

export const SkinMbtiAnswerText = styled.Text`
  font-size: 16px;
  font-family: "fontB";
  text-align: center;
  color: ${Color.PUPPLE};
`;

export const SkinMbtiDateInputContents = styled.TouchableOpacity`
  flex-direction: row;
  margin-top: 60px;
  height: 60px;
  padding: 0 16px;
  align-items: center;
  justify-content: space-between;
  background-color: ${Color.WHITE};
  border-radius: 16px;
`;

export const SkinMbtiDateInput = styled.TextInput`
  font-size: 16px;
  font-family: "fontB";
  color: ${Color.PUPPLE};
`;

export const SkinMbtiDatePickerIcon = styled.Image`
  width: 24px;
  height: 24px;
`;

export const SkinMbtiDatePicker = styled.View`
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
`;

export const SkinMbtiPickerItem = styled.TouchableOpacity`
  width: calc(100% - 20px);
  height: 52px;
  border-radius: 16px;
  justify-content: center;
  background-color: ${({ selected }: { selected?: boolean }) =>
    selected ? Color.LIGHT_PUPPLE_FIVE : ""};
`;

export const SkinMbtiPickerItemText = styled.Text`
  text-align: center;
  font-size: 16px;
  font-family: "fontM";
`;

export const SkinMbtiPickerNextBtn = styled.TouchableOpacity`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(255, 255, 255, 0.2) 0 0 16px;
`;

export const SkinMbtiProgressBar = styled.View``;

export const SkinMbtiNextIcon = styled.View`
  position: absolute;
  right: 24px;
`;

// Result Page
export const SkinMbtiResultContents = styled.View`
  height: 100%;
  padding: 8px;
  background-image: linear-gradient(to bottom, #6432e9, #825bec);
`;

export const SkinMbtiResultContentsInner = styled.View`
  height: 100%;
  padding: 40px 0 24px 0;
  background-color: ${Color.WHITE};
  border-radius: 16px;
`;

export const Dimmed = styled.View`
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  position: ${({ isOpen }: { isOpen: boolean }) => (isOpen ? "fixed" : "")};
  background-color: ${({ isOpen }: { isOpen: boolean }) =>
    isOpen ? "rgba(0, 0, 0, 0.54)" : ""};
`;

export const LoadingSlider = styled.View`
  width: 100%;
  height: 100%;
`;

export const mbtiStyles = StyleSheet.create({
  progress: {
    backgroundColor: "#825bec",
    borderWidth: 0,
    borderRadius: 4,
  },
});

export const { ids, styles, fullStyles } = MediaStyle.create({
  header: {
    paddingTop: 40,
    "@media (max-width: 480px)": {
      paddingTop: 20,
    },
  },
  titleContents: {
    marginTop: 30,
    marginBottom: 30,
    "@media (max-width: 480px)": {
      marginTop: 22,
      marginBottom: 22,
    },
  },
  title: {
    fontSize: 44,
    fontWeight: "bold",
    letterSpacing: -1.1,
    textAlign: "center",
    color: Color.WHITE,
    "@media (max-width: 480px)": {
      fontSize: 34,
      letterSpacing: -0.4,
    },
  },
  infoText: {
    maxWidth: 356,
    marginLeft: "auto",
    marginRight: "auto",
    lineHeight: 25,
    letterSpacing: -0.4,
    fontSize: 16,
    fontFamily: "fontM",
    textAlign: "center",
    color: Color.WHITE,
    "@media (max-width: 480px)": {
      maxWidth: 297,
    },
  },
  bar: {
    width: 132,
    height: 6,
    "@media (max-width: 480px)": {
      width: 72,
    },
  },
  infoImgContents: {
    width: "100%",
    justifyContent: "center",
  },
  infoImg: {
    width: "100%",
    height: 320,
    "@media (max-width: 480px)": {
      height: 240,
    },
    "@media (max-width: 390px)": {
      height: 180,
    },
  },
  snsIcon: {
    width: 40,
    height: 40,
  },
  footer: {
    width: "100%",
    paddingBottom: 40,
    paddingLeft: 20,
    paddingRight: 20,
    alignItems: "center",
    zIndex: -1,
    "@media (max-width: 480px)": {
      paddingBottom: 20,
    },
  },
  question: {
    maxWidth: 440,
  },
  list: {
    width: "100%",
  },
  btn: {
    width: "100%",
    height: 52,
    borderRadius: 26,
    alignItems: "center",
    backgroundColor: Color.PUPPLE,
  },
  text: {
    color: Color.WHITE,
    fontSize: 16,
    fontFamily: "fontM",
  },
  icon: {
    width: 24,
    height: 24,
  },
  slideWrap: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -180,
    marginLeft: -80,
  },
  slider: {
    width: 160,
    height: 160,
  },
  slideImg: {
    width: 160,
    height: 160,
  },
  slideText: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: 20,
    marginLeft: -120,
    color: Color.WHITE,
    fontSize: 16,
    fontFamily: "fontM",
  },
  completedText: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: 20,
    marginLeft: -25,
    color: Color.WHITE,
    fontSize: 16,
    fontFamily: "fontM",
  },
});
