import React, { Component, ReactNode } from "react";
import { Image } from "react-native";
import backIcon from "@assets/icons/icon-back.svg";
import hamburgerIcon from "@assets/icons/icon-hamburger.svg";

import { HeaderButton, backButtonStyle, Hamburger, Icon } from "./styled";
import { HeaderIconBtn } from "@src/constants";

type HeaderIconButtonProps = {
  type: HeaderIconBtn;
  onPress?: () => void;
  isScrolled?: boolean;
  isActive?: boolean;
  alarm?: number;
  isLight?: boolean;
};

class HeaderIconButton extends Component<HeaderIconButtonProps> {
  handlePress = (): void => {
    const { onPress } = this.props;

    if (onPress) {
      onPress();
    }
  };

  renderIcon = (): ReactNode => {
    const { type, isScrolled } = this.props;
    switch (type) {
      case HeaderIconBtn.HAMBURGER:
        return (
          <Hamburger>
            <Icon source={hamburgerIcon} resizeMode="contain" />
          </Hamburger>
        );

      case HeaderIconBtn.BACK:
        return <Image source={backIcon} resizeMode="contain" />;

      default:
        throw new Error("incorrect type");
    }
  };

  render(): ReactNode {
    const { type } = this.props;

    return (
      <HeaderButton
        style={[type === HeaderIconBtn.BACK && backButtonStyle]}
        activeOpacity={1}
        onPress={this.handlePress}
      >
        {this.renderIcon()}
      </HeaderButton>
    );
  }
}

export default HeaderIconButton;
