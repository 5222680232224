import React, { useEffect, useRef, useState } from "react";
import { StatusBar, Dimensions } from "react-native";
import { Helmet } from "react-helmet-async";
import { useNavigation, useRoute } from "@react-navigation/native";
import Toast from "react-native-fast-toast";

import { Layouts } from "@src/components/container";
import {
  BottomPopup,
  BottomSelectItem,
  CommunityDetail,
  ImagePopup,
  Maybe,
  ShareKakao,
  ShareSns,
} from "@src/components/presentational";
import {
  deletePopupBtn,
  deletePopupIcon,
  deletePopupInfo,
  deletePopupTitle,
  popupBtn,
  popupIcon,
  popupInfo,
  popupSubInfo,
  popupTitle,
  replyCategoryAnonymous,
  replyCategoryMe,
  replyCategoryUser,
  talkDetailMoreCategoryAnonymous,
  talkDetailMoreCategoryMe,
  talkDetailMoreCategoryUser,
} from "../configs";
import { CommunityService } from "@src/services";
import { useRecoilState } from "recoil";
import {
  CommunityCommentId,
  CommunityCommentUserId,
  CommunityDetailListState,
  CommunityForumId,
} from "@src/store";
import { useAuth } from "@src/hooks/auth";
import {
  clipboardCopyFailText,
  communityShareText,
} from "@src/screens/sampleDatas";
import { globalStyles } from "@src/styles/modules/global";
import { useRedirectUrl } from "@src/hooks/redirect";

/**
 * 커뮤니티 상세 페이지
 */

const CommunityDetailScreen = () => {
  const route = useRoute<any>();
  const navigation = useNavigation<any>();
  const id = route.params?.id;

  const { loginUrl } = useRedirectUrl();
  const { isLoggedIn } = useAuth();

  const shareToastRef = useRef(null);
  const { height } = Dimensions.get("window");

  const [category, setCategory] = useState<string>();

  const [data, setData] = useRecoilState(CommunityDetailListState);
  const [commentId, setCommentId] = useRecoilState(CommunityCommentId);
  const [forumId, setForumId] = useRecoilState(CommunityForumId);
  const [commentUserId, setCommentUserId] = useRecoilState(
    CommunityCommentUserId
  );

  const [isWriter, setIsWriter] = useState<boolean>(false);
  const [deleteCommentState, setDeleteCommentState] = useState<boolean>(false);
  const [detailUserId, setDetailUserId] = useState<number>();

  const [isVisibleMorePopup, setIsVisibleMorePopup] = useState<boolean>(false);
  const [isVisibleInfoPopup, setIsVisibleInfoPopup] = useState<boolean>(false);
  const [isVisibleDeletePopup, setIsVisibleDeletePopup] =
    useState<boolean>(false);
  const [isVisibleReplyPopup, setIsVisibleReplyPopup] =
    useState<boolean>(false);
  const [isVisibleImagePopup, setIsVisibleImagePopup] =
    useState<boolean>(false);

  const [popupImage, setPopupImage] = useState<any>();

  const getForumPostDetail = async (): Promise<void> => {
    try {
      const conditions = {
        postId: id,
      };

      const { data } = await CommunityService.getForumPostDetail(conditions);
      setData([data]);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteComment = async (): Promise<void> => {
    try {
      const { data } = await CommunityService.deleteComment(id, commentId);
      if (data === "OK") {
        setDeleteCommentState(true);
      } else {
        setDeleteCommentState(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deletePost = async (): Promise<void> => {
    try {
      const data = await CommunityService.deletePost(id);
      if (data === "OK") {
        navigation.push("community");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getForumPostDetail();
  }, []);

  const renderContents = () => (
    <CommunityDetail
      data={data}
      isVisibleReplyPopup={setIsVisibleReplyPopup}
      setIsMine={setIsWriter}
      deleteComment={deleteCommentState}
      isVisibleImagePopup={setIsVisibleImagePopup}
      imageUrl={setPopupImage}
      forumId={forumId}
      detailUserId={setDetailUserId}
    />
  );

  const handleCategory = (item) => {
    if (item.type === "delete") {
      deleteComment();
    } else if (item.type === "profile") {
      navigation.push("profile");
    } else if (item.url) {
      navigation.push(item.url);
    } else {
      setCategory(item.text);
    }
  };

  const dataToShare: ShareData = {
    title: communityShareText,
    url: window.location.href,
  };

  const handlePostCategory = async (item) => {
    if (item.url) {
      navigation.push(item.url);
    }
    switch (item.type) {
      case "update":
        navigation.push("updateTalk", { id: id });
        break;
      case "kakao":
        ShareKakao(
          window.location.href,
          communityShareText,
          "촉촉박사 | 피부 MBTI"
        );
        break;
      case "link":
        const result = await ShareSns(dataToShare);
        if (result === "copiedToClipboard") {
          shareToastRef.current.show(communityShareText);
        } else if (result === "failed") {
          shareToastRef.current.show(clipboardCopyFailText);
        }
        break;
      case "delete":
        deletePost();
        break;
    }
  };

  const onClickMoreView = () => {
    setIsVisibleMorePopup(!isVisibleMorePopup);
  };

  useEffect(() => {
    if (data[0]) {
      setIsWriter(data[0].isItMine);
    }
  }, [data]);

  useEffect(() => {
    setCommentUserId(detailUserId);
  }, [detailUserId]);

  return (
    <>
      <StatusBar barStyle="light-content" backgroundColor="#6432e9" />
      <Helmet>
        <title>커뮤니티 | 촉촉박사</title>
      </Helmet>
      <Layouts
        transparent
        cancelMode
        handleCancel={() => navigation.push("community")}
        titleMode
        title={forumId === 1 ? "촉촉 토크" : "MBTI 토크"}
        rightBtn
        rightBtnIcon={isLoggedIn}
        onClickRightBtn={onClickMoreView}
      >
        {renderContents()}
      </Layouts>
      <Maybe
        test={isVisibleInfoPopup}
        children={
          <BottomPopup
            isOpen={isVisibleInfoPopup}
            onClose={() => setIsVisibleInfoPopup(false)}
            title={popupTitle}
            info={popupInfo}
            highlightInfo={popupSubInfo}
            icon={popupIcon}
            btnText={popupBtn}
            onClick={() => (window.location.href = loginUrl)}
          />
        }
      />
      <Maybe
        test={isVisibleMorePopup}
        children={
          <BottomSelectItem
            isOpen={isVisibleMorePopup}
            data={
              (forumId === 1 && isWriter && talkDetailMoreCategoryMe) ||
              (forumId === 1 && !isWriter && talkDetailMoreCategoryUser) ||
              (forumId === 2 && isWriter && talkDetailMoreCategoryMe) ||
              (forumId === 2 && !isWriter && talkDetailMoreCategoryAnonymous)
            }
            onClose={() => setIsVisibleMorePopup(false)}
            onChangeId={(item) => handlePostCategory(item)}
          />
        }
      />
      <Maybe
        test={isVisibleReplyPopup}
        children={
          <BottomSelectItem
            isOpen={isVisibleReplyPopup}
            data={
              (forumId === 1 && isWriter && replyCategoryMe) ||
              (forumId === 1 && !isWriter && replyCategoryUser) ||
              (forumId === 2 && isWriter && replyCategoryMe) ||
              (forumId === 2 && !isWriter && replyCategoryAnonymous)
            }
            onClose={() => setIsVisibleReplyPopup(false)}
            onChangeId={(item) => handleCategory(item)}
          />
        }
      />
      <Maybe
        test={isVisibleDeletePopup}
        children={
          <BottomPopup
            isOpen={isVisibleDeletePopup}
            onClose={() => setIsVisibleDeletePopup(false)}
            title={deletePopupTitle}
            info={deletePopupInfo}
            icon={deletePopupIcon}
            btnText={deletePopupBtn}
          />
        }
      />
      <Maybe
        test={isVisibleImagePopup}
        children={
          <ImagePopup
            imageUrl={popupImage}
            isOpen={isVisibleImagePopup}
            onClose={() => setIsVisibleImagePopup(false)}
          />
        }
      />
      <Toast
        ref={shareToastRef}
        offset={height / 2}
        style={globalStyles.centerToast}
        textStyle={globalStyles.centerToastText}
      />
    </>
  );
};

export default CommunityDetailScreen;
