import styled from "styled-components/native";
import { Color } from "@src/constants";
import { StyleSheet } from "react-native";

export const HeaderBackground = styled.View`
  height: 66px;
  background-color: ${Color.PUPPLE};
`;

export const SurgeryListContents = styled.View`
  position: relative;
`;

export const SearchBarWrap = styled.View`
  position: relative;
`;

export const SearchBarInner = styled.View`
  width: 100%;
  height: 44px;
  position: absolute;
  bottom: -22px;
  padding: 0 20px;
`;

export const SearchBar = styled.View`
  border-radius: 22px;
  background-color: ${Color.WHITE};
  box-shadow: rgba(100, 50, 233, 0.08) 0 0 16px;
`;

export const ButtonContents = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  top: 6px;
  right: 6px;
`;

export const SearchButton = styled.TouchableHighlight`
  width: 32px;
  height: 32px;
`;

export const DeleteButton = styled.TouchableHighlight`
  width: 24px;
  height: 24px;
  margin-right: 8px;
`;

export const DeleteIcon = styled.Image`
  width: 24px;
  height: 24px;
`;

export const SearchInput = styled.TextInput`
  width: 100%;
  height: 42px;
  padding: 11px 0 13px 16px;
  outline-color: transparent;
  outline-width: 0;
  outline-style: none;
`;

export const SurgeryItemWrap = styled.View`
  margin: 45px 0;
  height: 100%;
`;

export const Icon = styled.View`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: ${Color.PUPPLE};
`;

export const IconImage = styled.Image`
  width: 24px;
  height: 24px;
`;

export const ComparisonWrap = styled.View`
  width: 100%;
  max-width: 480px;
  position: fixed;
  bottom: 0;
`;

export const ComparisonInfoContents = styled.View`
  width: 100%;
  height: 38px;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    #fff 14%,
    #fff
  );
`;

export const ComparisonInfoContentsText = styled.Text`
  font-size: 12px;
  font-family: "fontM";
  letter-spacing: -0.3px;
`;

export const Comparison = styled.View``;

export const ComparisonBtn = styled.TouchableOpacity`
  height: 52px;
  background-color: ${({ isActive }: { isActive?: boolean }) =>
    isActive ? Color.PUPPLE : Color.LIGHT_GREY_THREE};
  justify-content: center;
  text-align: center;
`;

export const ComparisonBtnText = styled.Text`
  color: ${Color.WHITE};
  font-size: 16px;
  font-family: "fontM";
  text-align: center;
`;

export const NoData = styled.View`
  margin-top: 30px;
`;

export const NoDataText = styled.Text`
  text-align: center;
`;

export const Dimmed = styled.View`
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  position: ${({ isOpen }: { isOpen: boolean }) => (isOpen ? "fixed" : "")};
  background-color: ${({ isOpen }: { isOpen: boolean }) =>
    isOpen ? "rgba(0, 0, 0, 0.54)" : ""};
  z-index: ${({ isOpen }: { isOpen: boolean }) => (isOpen ? "1000" : "")};
`;

export const listStyles = StyleSheet.create({
  onBlur: {},
});
