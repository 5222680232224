import React, { useEffect } from "react";
import { View, StatusBar, Image, ScrollView, FlatList } from "react-native";
import { Helmet } from "react-helmet-async";
import { useNavigation } from "@react-navigation/native";

import { Layouts } from "@src/components/container";
import {
  ids,
  planStyles,
  SkinPlanContainer,
  SkinPlanIntro,
  SkinPlanIntroItemTitle,
  SkinPlanSubTitleText,
  SkinPlanTitleText,
  styles,
} from "../styled";
import { BottomFixButton } from "@src/components/presentational";
import { useAuth } from "@src/hooks/auth";
import { skinPlanIntro } from "../configs";
import { useRecoilState } from "recoil";
import { SkinPlanResultList } from "@src/store";
import { globalStyles } from "@src/styles/modules/global";
import { useRedirectUrl } from "@src/hooks/redirect";
import { analytics } from "@src/hooks/analytics";

/**
 * 피부 플랜 만들기
 */

const SkinPlanScreen = () => {
  const navigation = useNavigation<any>();

  const { loginUrl } = useRedirectUrl();
  const { isLoggedIn } = useAuth();

  const [planResultList, setPlanResultList] =
    useRecoilState(SkinPlanResultList);

  const onClickStart = () => {
    analytics("Skin Plan Start", "");
    navigation.push("skinPlanQuestion");
  };

  const onClickLoginStart = () => {
    analytics("Skin Plan Login Start", "");
    window.location.href = loginUrl;
  };

  useEffect(() => {
    if (planResultList) {
      setPlanResultList(null);
    }
  }, []);

  const renderPlanIntro = ({ item }) => {
    return (
      <View dataSet={{ media: ids.list }} style={styles.list}>
        <SkinPlanIntroItemTitle>{item.title}</SkinPlanIntroItemTitle>
        <Image
          dataSet={{ media: ids.introImg }}
          style={styles.introImg}
          source={item.img}
          resizeMode="contain"
        />
      </View>
    );
  };

  return (
    <>
      <StatusBar barStyle="light-content" backgroundColor="#6432e9" />
      <Helmet>
        <title>피부 플랜 만들기 | 촉촉박사</title>
        <meta
          name="description"
          content="촉촉한 피부, 나만 모르고 있던 시술 정보가 궁금하다면? | 촉촉박사"
          data-react-helmet="true"
        />
      </Helmet>
      <Layouts logo hamburgerMode transparent>
        <ScrollView
          bounces={false}
          scrollEventThrottle={1}
          showsVerticalScrollIndicator={false}
          contentContainerStyle={globalStyles.dataList}
        >
          <SkinPlanContainer>
            <View style={planStyles.header}>
              <View dataSet={{ media: ids.title }} style={styles.title}>
                <SkinPlanTitleText style={planStyles.mLText}>
                  내게 꼭 맞는 시술 추천 받고
                </SkinPlanTitleText>
                <SkinPlanSubTitleText style={planStyles.xlText}>
                  나만의 피부 플랜 만들기
                </SkinPlanSubTitleText>
              </View>
            </View>
            <SkinPlanIntro>
              <FlatList
                renderItem={renderPlanIntro}
                data={skinPlanIntro}
                numColumns={2}
                style={planStyles.intro}
              />
            </SkinPlanIntro>
          </SkinPlanContainer>
        </ScrollView>
        <BottomFixButton
          whiteBg={false}
          fullBtnMode
          fullBtnText={isLoggedIn ? "시작하기" : "로그인하고 시작하기"}
          onPressFullBtn={() =>
            isLoggedIn ? onClickStart() : onClickLoginStart()
          }
        />
      </Layouts>
    </>
  );
};

export default SkinPlanScreen;
