import { atom, AtomEffect, DefaultValue } from "recoil";

/**
 * 공통 Atoms
 */

export const localStorageEffect: <T>(key: string) => AtomEffect<T> =
  (key: string) =>
  ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key);
    if (savedValue != null) {
      try {
        setSelf(JSON.parse(savedValue));
      } catch (e) {
        localStorage.removeItem(key);
        setSelf(new DefaultValue());
      }
    }
    onSet((newValue: any) => {
      if (newValue instanceof DefaultValue || newValue == null) {
        localStorage.removeItem(key);
      } else {
        if (key === "drmoist_refresh_token" || key === "drmoist_access_token") {
          localStorage.setItem(key, newValue);
        } else {
          localStorage.setItem(key, JSON.stringify(newValue));
        }
      }
    });
  };

// 시술 비교 popup
export const guidePopupState = atom<null | any>({
  key: "guidePopupState",
  default: null as any,
  effects_UNSTABLE: [localStorageEffect("guidePopup")],
});
