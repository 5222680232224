import React, { ReactElement } from "react";
import * as Progress from "react-native-progress";

import {
  progressStyles,
  ProgressBarHeader,
  ProgressBarNav,
  ProgressBarPrev,
  ProgressBarPrevImg,
  ProgressBarCount,
  ProgressBarCountText,
} from "./styled";
import backIcon from "@assets/icons/icon-left.svg";
import backIconB from "@assets/icons/icon-left-black.svg";

interface ProgressBarProps {
  onClickPrev?: () => void;
  activeQuestionNum?: number;
  questions?: any;
  totalQuestions?: number;
  id?: number;
  black?: boolean;
  barColor?: string;
  bgColor?: boolean;
}

const ProgressBar = ({
  onClickPrev,
  activeQuestionNum,
  questions,
  totalQuestions,
  id,
  black,
  barColor,
  bgColor,
}: ProgressBarProps): ReactElement => {
  return (
    <ProgressBarHeader>
      <ProgressBarNav>
        <ProgressBarPrev underlayColor="transparent" onPress={onClickPrev}>
          <ProgressBarPrevImg
            resizeMode="contain"
            source={bgColor ? backIconB : backIcon}
          />
        </ProgressBarPrev>
        <ProgressBarCount>
          <ProgressBarCountText black={black}>
            {activeQuestionNum + 1}/{totalQuestions}
          </ProgressBarCountText>
        </ProgressBarCount>
      </ProgressBarNav>
      <Progress.Bar
        width={null}
        height={8}
        progress={id / questions.length}
        color={barColor ? barColor : "#ffffff"}
        style={bgColor ? progressStyles.bgColor : progressStyles.progress}
      />
    </ProgressBarHeader>
  );
};

export default ProgressBar;
