import {
  InactiveMemberScreen,
  WithdrawalMemberScreen,
  MypageScreen,
  PrivacyNoticeScreen,
  ChangeNicknameScreen,
  PrivacySettingScreen,
  MyInfoScreen,
  WithdrawalScreen,
  WithdrawalConfirmScreen,
  MySkinPlanDetailScreen,
  MySkinPlanScreen,
  RangeRecommendScreen,
  SkinPlanMakingScreen,
  SkinPlanQuestionScreen,
  SkinPlanRecommendScreen,
} from "@src/screens";

/**
 * 로그인 필요한 Page Configs
 */

const authenticationConfigs = [
  {
    name: "inactiveMember",
    component: InactiveMemberScreen,
    options: {
      title: "",
    },
  },
  {
    name: "withdrawalMember",
    component: WithdrawalMemberScreen,
    options: {
      title: "",
    },
  },
  {
    name: "mypage",
    component: MypageScreen,
    options: {
      title: "",
    },
  },
  {
    name: "changeNickname",
    component: ChangeNicknameScreen,
    options: {
      title: "",
    },
  },
  {
    name: "privacyNotice",
    component: PrivacyNoticeScreen,
    options: {
      title: "",
    },
  },
  {
    name: "privacySetting",
    component: PrivacySettingScreen,
    options: {
      title: "",
    },
  },
  {
    name: "myinfo",
    component: MyInfoScreen,
    options: {
      title: "",
    },
  },
  {
    name: "withdrawal",
    component: WithdrawalScreen,
    options: {
      title: "",
    },
  },
  {
    name: "withdrawalConfirm",
    component: WithdrawalConfirmScreen,
    options: {
      title: "",
    },
  },
  {
    name: "mySkinPlan",
    component: MySkinPlanScreen,
    options: {
      title: "",
    },
  },
  {
    name: "mySkinPlanDetail",
    component: MySkinPlanDetailScreen,
    options: {
      title: "",
    },
  },
  {
    name: "skinPlanQuestion",
    component: SkinPlanQuestionScreen,
    options: {
      title: "",
    },
  },
  {
    name: "skinPlanRecommend",
    component: SkinPlanRecommendScreen,
    options: {
      title: "",
    },
  },
  {
    name: "rangeRecommend",
    component: RangeRecommendScreen,
    options: {
      title: "",
    },
  },
  {
    name: "skinPlanMaking",
    component: SkinPlanMakingScreen,
    options: {
      title: "",
    },
  },
];

export default authenticationConfigs;
