import styled from "styled-components/native";
import { StyleSheet } from "react-native";
import { Color } from "@src/constants";

export const SurgeryComparisonContents = styled.View`
  flex-direction: row;
  background-color: ${Color.GREY};
`;

export const Table = styled.View`
  width: 100%;
`;

export const TableCell = styled.View`
  flex-direction: row;
`;

export const TableCellTitle = styled.View`
  max-width: 57px;
  background-color: ${Color.GREY};
`;

export const TableCellTitleText = styled.Text`
  font-size: 13px;
  font-family: "fontB";
`;

export const TableCellContents = styled.View``;

export const TableCellContentsText = styled.Text``;

export const TableTitleWrap = styled.View``;

export const TableTitle = styled.View`
  height: ${({ id }: { id?: number }) =>
    id === 0 || id === 2 ? "80px" : "120px"};
  justify-content: center;
  align-items: center;
  border-top-width: 1px;
  border-top-color: #e2e2e2;
  border-right-width: 1px;
  border-right-color: #e2e2e2;
  border-bottom-width: ${({ id }: { id?: number }) => (id === 6 ? "1px" : "")};
  border-bottom-color: ${({ id }: { id?: number }) =>
    id === 6 ? "#e2e2e2" : ""};
  background-color: ${Color.GREY};
`;

export const TableTitleText = styled.Text`
  font-size: 13px;
  font-family: "fontB";
`;

export const TableContentsHeader = styled.View`
  height: 80px;
  justify-content: center;
  align-items: center;
  border-right-width: 1px;
  border-right-color: #e2e2e2;
  border-bottom-width: 1px;
  border-bottom-color: #e2e2e2;
  background-color: ${Color.LIGHT_PUPPLE_FOUR};
`;

export const TableContentsHeaderText = styled.Text`
  font-family: "fontB";
  font-size: 13px;
  color: ${Color.WHITE};
`;

export const TableContents = styled.View`
  width: calc(100% - 57px);
  flex-direction: row;
`;

export const TableContentsWrap = styled.View`
  width: calc(100% / 3);
  flex-direction: row;
  justify-content: center;
`;

export const TableContentsInner = styled.View`
  width: 100%;
`;

export const TableContentsData = styled.View`
  padding: 23px 14px;
  height: 120px;
  justify-content: center;
  align-items: center;
  border-right-width: ${({ id }: { id?: number }) => (id !== 2 ? "1px" : "")};
  border-right-color: ${({ id }: { id?: number }) =>
    id !== 2 ? "#e2e2e2" : ""};
  border-bottom-width: 1px;
  border-bottom-color: #e2e2e2;
  background-color: ${Color.WHITE};
`;

export const TableContentsCountData = styled.View`
  border-right-width: ${({ id }: { id?: number }) => (id !== 2 ? "1px" : "")};
  border-right-color: ${({ id }: { id?: number }) =>
    id !== 2 ? "#e2e2e2" : ""};
  border-bottom-width: 1px;
  border-bottom-color: #e2e2e2;
`;

export const TableContentIcon = styled.Image``;

export const comparisonStyles = StyleSheet.create({
  countData: {
    height: 80,
    paddingTop: 23,
    paddingBottom: 23,
    paddingLeft: 14,
    paddingRight: 14,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Color.WHITE,
  },
  text: {
    fontSize: 13,
    fontFamily: "fontM",
    textAlign: "center",
  },
});
