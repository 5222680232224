import { Color } from "@src/constants";
import styled from "styled-components/native";
import { Dimensions, StyleSheet } from "react-native";
import MediaStyle from "react-native-media-query";

const { height } = Dimensions.get("window");

export const Dimmed = styled.View`
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  position: ${({ isOpen }: { isOpen: boolean }) => (isOpen ? "fixed" : "")};
  background-color: ${({ isOpen }: { isOpen: boolean }) =>
    isOpen ? "rgba(0, 0, 0, 0.54)" : ""};
  z-index: ${({ isOpen }: { isOpen: boolean }) => (isOpen ? "1001" : "")};
`;

export const MbtiPopupContents = styled.View`
  position: relative;
  height: 100%;
  min-height: 100%;
  padding: 20px;
`;

export const MbtiPopupContentsHeader = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 14px;
`;

export const CloseBtn = styled.TouchableHighlight`
  align-items: flex-end;
`;

export const CloseBtnIcon = styled.Image`
  width: 24px;
  height: 24px;
`;

export const HeaderTitleText = styled.Text`
  font-size: 20px;
  font-family: "fontB";
  letter-spacing: -0.5px;
`;

export const MbtiPopupList = styled.View``;

export const MbtiPopupItem = styled.TouchableOpacity`
  margin: 10px 0;
  padding: 12px 10px;
  border-radius: 16px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${Color.LIGHT_PUPPLE_FIVE};
`;

export const MbtiPopupItemTextContents = styled.View`
  width: calc(100% - 136px);
`;

export const itemStyles = StyleSheet.create({
  image: {
    width: 96,
    height: 96,
    borderRadius: 12,
    marginRight: 12,
  },
  icon: {
    width: 24,
    height: 24,
  },
  title: {
    fontSize: 20,
    color: Color.PUPPLE,
    fontWeight: "bold",
    lineHeight: 32,
  },
  desc: {
    fontSize: 14,
    fontWeight: "500",
    letterSpacing: -0.35,
    lineHeight: 20,
  },
});

export const { ids, styles } = MediaStyle.create({
  popup: {
    height: height - 80,
    marginInline: "calc(50vw - 232px)",
    marginVertical: 40,
    backgroundColor: Color.WHITE,
    borderRadius: 16,
    bottom: 0,
    top: 0,
    transition: "top 0.6s cubic-bezier(0.165, 0.84, 0.44, 1)",
    zIndex: 1001,
    "@media (max-width: 480px)": {
      marginInline: 8,
    },
  },
});
