import React, { useEffect, useState } from "react";
import { View, StatusBar, Text, Image } from "react-native";
import { Helmet } from "react-helmet-async";
import { useNavigation, useRoute } from "@react-navigation/native";

import { Layouts } from "@src/components/container";
import { BackIcon, planStyles } from "../styled";
import { mySkinPlanDetailComment, popupInfo, popupTitle } from "../configs";
import {
  DialogPopup,
  Maybe,
  MySkinPlanDetailItem,
} from "@src/components/presentational";
import backIcon from "@assets/icons/icon-back2.svg";
import { SkinPlanService } from "@src/services";

/**
 * 내 피부 플랜 상세
 */

const MySkinPlanDetailScreen = () => {
  const navigation = useNavigation<any>();
  const route = useRoute<any>();
  const plannerId = Number(route.params?.id);

  const [data, setData] = useState<any>();
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const getPlanItemDetail = async () => {
    try {
      const conditions = {
        plannerId,
      };

      const data = await SkinPlanService.getPlanItemDetail(conditions);
      setData(data);
    } catch (error) {
      console.log(error);
    }
  };

  const onClickBack = () => {
    setShowPopup(true);
  };

  useEffect(() => {
    getPlanItemDetail();
  }, []);

  const renderItemList = () => {
    return (
      <MySkinPlanDetailItem
        data={data}
        comment={mySkinPlanDetailComment}
        edit={setIsEdit}
      />
    );
  };

  return (
    <>
      <StatusBar barStyle="light-content" backgroundColor="#6432e9" />
      <Helmet>
        <title>내 피부 플랜 상세 | 촉촉박사</title>
      </Helmet>
      <Layouts logo hamburgerMode transparent>
        <View style={[planStyles.planHeader, planStyles.header]}>
          <BackIcon
            onPress={() => (isEdit ? onClickBack() : navigation.goBack())}
          >
            <Image
              source={backIcon}
              resizeMode="contain"
              style={planStyles.icon}
            />
          </BackIcon>
          <Text style={planStyles.mLText}>내 피부 플랜 상세</Text>
        </View>
        {renderItemList()}
      </Layouts>
      <Maybe
        test={showPopup && isEdit}
        children={
          <DialogPopup
            isOpen={showPopup}
            onClose={() => setShowPopup(false)}
            title={popupTitle}
            info={popupInfo}
            multiple
            leftBtnText="취소하기"
            rightBtnText="돌아가기"
            onClickLeftBtn={() => setShowPopup(false)}
            onClickRightBtn={() => navigation.push("mySkinPlan")}
          />
        }
      />
    </>
  );
};

export default MySkinPlanDetailScreen;
