import { Color } from "@src/constants";
import styled from "styled-components/native";
import { StyleSheet } from "react-native";
import MediaStyle from "react-native-media-query";

export const MyPlanContainer = styled.View`
  width: 100%;
  height: 100%;
  padding-bottom: 50px;
`;

export const HeaderContainer = styled.View`
  width: 100%;
  padding: 0 20px;
  margin-top: 10px;
`;

export const MyPlanDetailContents = styled.View`
  position: relative;
  background-image: linear-gradient(to bottom, #6432e9, #825bec);
  padding: 21px 16px 20px 16px;
  border-radius: 24px;
`;

export const MyPlanDetailItem = styled.View`
  position: relative;
`;

export const MyPlanDetailHeader = styled.View``;

export const MyPlanDetailInfo = styled.View`
  border-radius: 16px;
  background-color: ${Color.WHITE};
`;

export const Category = styled.View`
  height: 20px;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  border-radius: 10px;
  padding: 2px 8px 3px 8px;
  background-color: ${Color.PUPPLE};
`;

export const ListName = styled.View``;

export const Summary = styled.View`
  position: relative;
  margin: 20px 0 8px 0;
  padding: 16px 16px 12px 16px;
  border-radius: 16px;
  background-color: ${Color.GREY};
`;

export const SummaryItem = styled.View`
  flex-direction: row;
  margin-bottom: 10px;
`;

export const SummaryItemTitleContents = styled.View`
  width: 87px;
  flex-direction: row;
  align-items: center;
`;

export const SummaryItemTitle = styled.Text`
  margin-right: 4px;
  font-size: 14px;
  font-family: "fontB";
`;

export const SummaryItemInfoIcon = styled.TouchableOpacity``;

export const SummaryItemInfo = styled.Text`
  width: calc(100% - 87px);
  font-size: 16px;
  font-family: "fontB";
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const SummaryItemPrice = styled.View`
  position: relative;
  flex-direction: row;
  width: calc(100% - 76px);
`;

export const SummaryItemInput = styled.TextInput`
  width: 100%;
  padding-bottom: 8px;
  border-bottom-width: 2px;
  border-bottom-color: ${Color.LIGHT_GREY_FIVE};
  font-size: 16px;
  outline-color: transparent;
  outline-width: 0;
  outline-style: none;
`;

export const SummaryItemScope = styled.View``;

export const SummaryMore = styled.View`
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

export const SummaryMoreBtn = styled.TouchableOpacity`
  flex-direction: row;
  align-items: center;
`;

export const SummaryMoreText = styled.Text`
  font-size: 14px;
  font-family: "fontM";
  color: ${Color.LIGHT_GREY_FOUR};
`;

export const ProgressContents = styled.View`
  width: calc(100% - 87px);
  flex-direction: row;
  align-items: center;
`;

export const Notice = styled.View`
  margin-top: 20px;
`;

export const NoticeHighlight = styled.View`
  position: relative;
  flex-direction: row;
  margin-bottom: 4px;
`;

export const NoticeHighlightIcon = styled.Image`
  width: 20px;
  height: 20px;
  margin-right: 4px;
`;

export const HighlightBg = styled.View`
  width: 30px;
  background-color: ${Color.LIGHT_PUPPLE_SIX};
`;

export const NoticeHighlightText = styled.Text`
  z-index: 1;
  font-size: 14px;
  font-family: "fontB";
  letter-spacing: -0.35px;
`;

export const CommentInfo = styled.View`
  flex-direction: row;
  margin-bottom: 4px;
`;

export const CommentInfoText = styled.Text`
  font-size: 13px;
  font-family: "fontM";
  letter-spacing: -0.33px;
  line-height: 18px;
`;

export const Dot = styled.View`
  background-color: ${Color.BLACK_TWO};
`;

export const MyPlanProgress = styled.View``;

export const ProgressTitle = styled.Text`
  padding: 0 20px;
  margin-top: 40px;
  font-size: 20px;
  font-family: "fontB";
  margin-bottom: 20px;
`;

export const MyPlanProgressSlider = styled.View`
  width: calc(100% - 40px);
`;

export const MyPlanProgressList = styled.View`
  position: relative;
  height: 200px;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: rgba(100, 50, 233, 0.08) 0 0 16px;
`;

export const MyPlanSlideAddItem = styled.TouchableOpacity`
  height: 200px;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: rgba(100, 50, 233, 0.08) 0 0 16px;
  align-items: center;
  justify-content: center;
`;

export const MyPlanProgressListItem = styled.View``;

export const ListHeader = styled.View`
  padding: 8px 16px 0 16px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
`;

export const ListFooter = styled.View`
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 8px 16px;
  height: 44px;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  background-color: ${({ checked }: { checked?: boolean }) =>
    checked ? Color.PUPPLE : Color.LIGHT_GREY_THREE};
`;

export const ListCheckbox = styled.View`
  align-items: center;
  flex-direction: row;
`;

export const StepNum = styled.Text`
  font-size: 14px;
`;

export const DeleteBtn = styled.TouchableOpacity``;

export const CheckBox = styled.TouchableOpacity`
  width: 28px;
  height: 28px;
`;

export const CheckBoxIcon = styled.Image`
  width: 28px;
  height: 28px;
`;

export const CheckBoxText = styled.Text`
  margin-left: 4px;
  font-size: 12px;
  color: ${Color.WHITE};
`;

export const ListContents = styled.View`
  padding: 7px 16px 20px 16px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  background-color: ${Color.WHITE};
`;

export const ListTitle = styled.View`
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
`;

export const CommonInputWrap = styled.View`
  position: relative;
  padding: 0 20px;
`;

export const CommonInput = styled.TextInput`
  padding: 17px 0 19px 16px;
  font-size: 16px;
  border: 1px solid ${Color.LIGHT_GREY_FIVE};
  background-color: ${Color.WHITE};
  border-radius: 16px;
  outline-color: transparent;
  outline-width: 0;
  outline-style: none;
`;

export const CommonMultiInput = styled.TextInput`
  padding: 20px 16px 0 16px;
  height: 136px;
  font-size: 16px;
  border: 1px solid ${Color.LIGHT_GREY_FIVE};
  background-color: ${Color.WHITE};
  border-radius: 16px;
  outline-color: transparent;
  outline-width: 0;
  outline-style: none;
`;

export const DateText = styled.Text`
  font-size: 12px;
  font-family: "fontM";
  color: ${Color.LIGHT_GREY_FOUR};
`;

export const RatingWrap = styled.View`
  height: 80px;
  padding: 0 20px;
  margin-bottom: 60px;
`;

export const Rating = styled.View``;

export const ChangeBtn = styled.TouchableOpacity`
  margin-left: 10px;
  padding: 2px 9px 3px 9px;
  border-radius: 12px;
  border: 1px solid ${Color.LIGHT_GREY_FIVE};
`;

export const BottomBtn = styled.View`
  padding: 0 20px;
  margin-bottom: 50px;
`;

export const CreateBtn = styled.TouchableOpacity`
  width: 100%;
  height: 52px;
  align-items: center;
  justify-content: center;
  border-radius: 26px;
  background-color: ${({ disabled }: { disabled?: boolean }) =>
    disabled ? Color.LIGHT_GREY_THREE : Color.PUPPLE};
`;

export const CreateBtnText = styled.Text`
  font-size: 16px;
  font-family: "fontM";
  letter-spacing: -0.4px;
  color: ${Color.WHITE};
`;

export const itemStyles = StyleSheet.create({
  title: {
    fontSize: 20,
    fontFamily: "fontB",
  },
  whiteText: {
    color: Color.WHITE,
  },
  puppleText: {
    color: Color.PUPPLE,
  },
  xxsText: {
    fontSize: 10,
    fontFamily: "fontM",
  },
  xsText: {
    fontSize: 12,
    fontFamily: "fontM",
  },
  sText: {
    fontSize: 13,
    fontFamily: "fontM",
  },
  smText: {
    fontSize: 14,
    fontFamily: "fontM",
  },
  mdText: {
    fontSize: 16,
    fontFamily: "fontB",
  },
  lgText: {
    fontSize: 20,
    fontFamily: "fontB",
  },
  xlText: {
    fontSize: 24,
    fontFamily: "fontB",
  },
  lineHeight: {
    lineHeight: 20,
  },
  pupple: {
    color: Color.PUPPLE,
  },
  bold: {
    fontFamily: "fontB",
  },
  grayText: {
    color: Color.LIGHT_GREY_TWO,
  },
  grayText2: {
    color: Color.LIGHT_GREY_FOUR,
  },
  flexRow: {
    flexDirection: "row",
  },
  alignCenter: {
    alignItems: "center",
  },
  icon: {
    width: 20,
    height: 20,
  },
  mdIcon: {
    width: 24,
    height: 24,
  },
  progress: {
    backgroundColor: Color.LIGHT_PUPPLE,
    borderWidth: 0,
    borderRadius: 4,
    marginTop: 8,
  },
  progressIcon: {
    width: 16,
    height: 16,
  },
  progressLine: {
    backgroundColor: Color.LIGHT_PUPPLE,
  },
  commentDot: {
    marginTop: 7,
  },
  slider: {
    width: "100%",
    paddingLeft: 20,
    paddingRight: 20,
  },
  slide: {
    height: 200,
  },
  padding: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  summaryTitle: {
    width: 73,
  },
  priceTitle: {
    marginBottom: 8,
  },
  summaryTitleText: {
    marginLeft: 0,
  },
  inputCount: {
    position: "absolute",
    right: 36,
    bottom: 13,
  },
  inputCountText: {
    fontSize: 12,
    fontWeight: "500",
    color: Color.LIGHT_GREY_FOUR,
  },
  rating: {
    position: "absolute",
    top: 40,
  },
  displayNone: {
    display: "none",
  },
  displayFlex: {
    display: "flex",
  },
});

export const { ids, styles } = MediaStyle.create({});
