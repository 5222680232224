import React, { useCallback, useEffect, useRef, useState } from "react";
import { View, StatusBar, Image, Text, ScrollView } from "react-native";
import { Helmet } from "react-helmet-async";
import { useNavigation } from "@react-navigation/native";
import { useRoute } from "@react-navigation/native";

import { Layouts } from "@src/components/container";
import {
  BottomBtn,
  ids,
  Loading,
  LoadingSubText,
  LoadingText,
  planStyles,
  QuestionConatiner,
  SkinMbtiContainer,
  SkinMbtiResultContents,
  SkinMbtiResultContentsInner,
  SkinMbtiTitle,
  SkinPlanCommonBtn,
  SkinPlanCommonBtnText,
  SkinPlanContentsInner,
  SkinPlanImg,
  SkinPlanLgTitle,
  SkinPlanMdTitle,
  SkinPlanSmTitle,
  styles,
} from "../styled";
import {
  BottomFixButton,
  Maybe,
  MbtiItem,
  PlanItem,
  ProgressBar,
  SwitchCase,
} from "@src/components/presentational";
import { MbtiService, SkinPlanService } from "@src/services";
import { skinPlanTest } from "../configs";
import { useAuth } from "@src/hooks/auth";
import { Color } from "@src/constants";
import mbtiImg from "@assets/images/plan/plan-mbti-img.png";
import loadingImg from "@assets/loading.gif";
import loadingCompleteImg from "@assets/icons/icon-loading-complete.png";
import { useRecoilState } from "recoil";
import { SkinPlanAnswerId, SkinPlanResultList } from "@src/store";
import { analytics } from "@src/hooks/analytics";

/**
 * 피부 플랜 문답
 */

const SkinPlanQuestionScreen = () => {
  const { user } = useAuth();

  const navigation = useNavigation<any>();
  const route = useRoute<any>();
  const pageId = route.params?.id;
  const scrollViewRef = useRef(null);

  const [answerId, setAnswerId] = useRecoilState(SkinPlanAnswerId);
  const [planResultList, setPlanResultList] =
    useRecoilState(SkinPlanResultList);
  const [mbtiResult, setMbtiResult] = useState<string>();

  const [activeQuestionNum, setActiveQuestionNum] = useState<number>(0);
  const [result, setResult] = useState<any>([]);
  const [showResult, setShowResult] = useState<boolean>(false);
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const [isSelectedLastImg, setIsSelectedLastImg] = useState<boolean>(false);
  const [isSelectedLastText, setIsSelectedLastText] = useState<boolean>(false);
  const [isToggleSelected, setIsToggleSelected] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingComplete, setIsLoadingComplete] = useState<boolean>(false);

  const { totalQuestions, questions } = skinPlanTest;
  const { type } = questions[activeQuestionNum];

  const getMbtiResult = async () => {
    try {
      analytics("Skin Plan Mbti Check", "");

      const data = await MbtiService.getMbtiResult();
      setMbtiResult(data.resultOfBaumannTest);

      if (data.resultOfBaumannTest) {
        setShowResult(true);
      } else {
        navigation.push("skinMbtiDetail", { state: 2 });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSkinPlanResultList = async () => {
    try {
      const conditions = {
        answerId,
      };

      const { plannerRetrievePlanList } =
        await SkinPlanService.getSkinPlanResultList(conditions);
      setPlanResultList(plannerRetrievePlanList);

      if (plannerRetrievePlanList) {
        setIsLoading(true);

        setTimeout(() => {
          setIsLoadingComplete(true);
        }, 5000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onClickPrev = () => {
    setIsSelected(false);

    if (activeQuestionNum !== 0) {
      setActiveQuestionNum((prev) => prev - 1);

      const resultValue = result.slice(0, result.length - 1);
      setResult(resultValue);
    } else {
      navigation.push("skinPlan");
    }
  };

  const onClickNext = useCallback(() => {
    setShowResult(false);
    setIsSelected(false);

    if (activeQuestionNum !== totalQuestions) {
      setActiveQuestionNum((prev) => prev + 1);
    } else {
      setActiveQuestionNum(0);
      setIsLoading(true);
    }
  }, [activeQuestionNum]);

  const handlePlanResultList = () => {
    getSkinPlanResultList();
  };

  useEffect(() => {
    if (isLoadingComplete) {
      setTimeout(() => {
        navigation.push("rangeRecommend");
      }, 2000);

      analytics("Skin Plan Recommend Entered", "");
    }
  }, [isLoadingComplete]);

  useEffect(() => {
    if (pageId) {
      setActiveQuestionNum(pageId);
    }
  }, []);

  useEffect(() => {
    setPlanResultList(null);
    setIsLoading(false);
    setIsLoadingComplete(false);
  }, []);

  useEffect(() => {
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollTo({ y: 0, animated: false });
    }
  }, [activeQuestionNum]);

  const renderMbtiConfirm = () => {
    return (
      <SkinPlanContentsInner>
        <SkinPlanSmTitle style={planStyles.mdTextB}>
          가장 중요한
        </SkinPlanSmTitle>
        <SkinPlanLgTitle style={planStyles.lgText}>
          첫 번째 단계에요!
          <br />내 피부 MBTI부터 확인해 볼까요?
        </SkinPlanLgTitle>
        <SkinPlanMdTitle style={planStyles.mdText}>
          <Text style={planStyles.highlightText}>{user?.nickname}</Text> 님의
          <br />
          피부 타입에 딱 맞는 플랜을 추천해 드릴게요!
        </SkinPlanMdTitle>
        <SkinPlanImg>
          <Image
            source={mbtiImg}
            resizeMode="contain"
            dataSet={{ media: ids.mbtiImg }}
            style={styles.mbtiImg}
          />
        </SkinPlanImg>
      </SkinPlanContentsInner>
    );
  };

  const renderUserMbtiConfirm = () => {
    return (
      <>
        <SkinMbtiContainer>
          <SkinMbtiTitle>
            <SkinPlanLgTitle
              style={[planStyles.lgText, planStyles.alignCenter]}
            >
              <Text style={planStyles.highlightText}>{user?.nickname}</Text>{" "}
              님은
              <br />
              <Text style={planStyles.highlightText}>{mbtiResult}</Text> 피부
              타입을 가지셨네요!
            </SkinPlanLgTitle>
          </SkinMbtiTitle>
          <SkinMbtiResultContents>
            <SkinMbtiResultContentsInner>
              <MbtiItem type={mbtiResult} planMode />
            </SkinMbtiResultContentsInner>
          </SkinMbtiResultContents>
          <SkinPlanMdTitle
            style={[planStyles.mdText, planStyles.textAlignCenter]}
          >
            <Text style={planStyles.highlightText}>{mbtiResult}</Text> 타입에
            맞는 시술들만 쏙쏙 골라 드릴게요!
            <br />
            <View style={planStyles.marginTop20}>
              <SkinPlanSmTitle style={planStyles.mdTextB}>
                설정을 계속해 볼까요?
              </SkinPlanSmTitle>
            </View>
          </SkinPlanMdTitle>

          <View dataSet={{ media: ids.bottomBtn }} style={styles.bottomBtn}>
            <SkinPlanCommonBtn
              onPress={() => {
                analytics("Skin Plan Mbti Result", "");
                onClickNext();
              }}
              disabled={activeQuestionNum !== 0 && !isSelected}
              activeOpacity={1}
            >
              <SkinPlanCommonBtnText>다음</SkinPlanCommonBtnText>
            </SkinPlanCommonBtn>
          </View>
        </SkinMbtiContainer>
      </>
    );
  };

  return (
    <>
      <StatusBar barStyle="light-content" backgroundColor="#6432e9" />
      <Helmet>
        <title>피부 플랜 만들기 | 촉촉박사</title>
        <meta
          name="description"
          content="촉촉한 피부, 나만 모르고 있던 시술 정보가 궁금하다면? | 촉촉박사"
          data-react-helmet="true"
        />
      </Helmet>
      <Layouts logo hamburgerMode transparent>
        <ScrollView
          ref={scrollViewRef}
          bounces={false}
          scrollEventThrottle={1}
          showsVerticalScrollIndicator={false}
        >
          <QuestionConatiner>
            <View style={planStyles.header}>
              <ProgressBar
                onClickPrev={onClickPrev}
                questions={questions}
                totalQuestions={totalQuestions}
                activeQuestionNum={activeQuestionNum}
                id={activeQuestionNum + 2}
                barColor={Color.PUPPLE}
                black
                bgColor
              />
            </View>

            <View
              dataSet={{ media: ids.planContents }}
              style={styles.planContents}
            >
              <SwitchCase
                tests={[
                  {
                    test: showResult,
                    component: renderUserMbtiConfirm(),
                  },
                  {
                    test: type === "mbtiConfirm",
                    component: renderMbtiConfirm(),
                  },
                ]}
                defaultComponent={
                  <>
                    <PlanItem
                      activeQuestionNum={activeQuestionNum}
                      selected={setIsSelected}
                      selectedLastImg={setIsSelectedLastImg}
                      selectedLastText={setIsSelectedLastText}
                    />
                    <SwitchCase
                      tests={[
                        {
                          test: activeQuestionNum === totalQuestions - 1,
                          component: (
                            <View
                              dataSet={{ media: ids.bottomBtn }}
                              style={styles.bottomBtn}
                            >
                              <Text style={planStyles.grayText}>
                                나만을 위한
                              </Text>
                              <SkinPlanCommonBtn
                                onPress={handlePlanResultList}
                                disabled={
                                  !isSelectedLastImg || !isSelectedLastText
                                }
                                activeOpacity={1}
                              >
                                <SkinPlanCommonBtnText>
                                  피부 플랜 보기
                                </SkinPlanCommonBtnText>
                              </SkinPlanCommonBtn>
                            </View>
                          ),
                        },
                        {
                          test: type === "textList",
                          component: (
                            <BottomBtn>
                              <SkinPlanCommonBtn
                                onPress={onClickNext}
                                disabled={
                                  activeQuestionNum !== 0 &&
                                  !isSelected &&
                                  !isToggleSelected
                                }
                                activeOpacity={1}
                              >
                                <SkinPlanCommonBtnText>
                                  다음
                                </SkinPlanCommonBtnText>
                              </SkinPlanCommonBtn>
                            </BottomBtn>
                          ),
                        },
                      ]}
                      defaultComponent={
                        <View
                          dataSet={{ media: ids.bottomBtn }}
                          style={styles.bottomBtn}
                        >
                          <SkinPlanCommonBtn
                            onPress={onClickNext}
                            disabled={
                              activeQuestionNum !== 0 &&
                              !isSelected &&
                              !isToggleSelected
                            }
                            activeOpacity={1}
                          >
                            <SkinPlanCommonBtnText>다음</SkinPlanCommonBtnText>
                          </SkinPlanCommonBtn>
                        </View>
                      }
                    />
                  </>
                }
              />
            </View>

            <Maybe
              test={activeQuestionNum === 0 && !showResult}
              children={
                <BottomFixButton
                  fullBtnMode
                  fullBtnText="내 MBTI 확인하기"
                  onPressFullBtn={getMbtiResult}
                  planMode
                  disabledBg={
                    activeQuestionNum !== 0 && !isSelected && !isToggleSelected
                  }
                />
              }
            />
          </QuestionConatiner>
        </ScrollView>
        <Maybe
          test={isLoading}
          children={
            <Loading>
              <View style={planStyles.loading}>
                <Image
                  source={isLoadingComplete ? loadingCompleteImg : loadingImg}
                  resizeMode="contain"
                  style={
                    isLoadingComplete
                      ? planStyles.loadingCompleteImg
                      : planStyles.loadingImg
                  }
                />
                {isLoadingComplete ? (
                  <LoadingText>맞춤 플랜 준비 완료!</LoadingText>
                ) : (
                  <LoadingText>
                    촉촉박사 AI가
                    <br />
                    <Text style={planStyles.highlightText}>
                      {user?.nickname}
                    </Text>{" "}
                    님을 위한
                    <br />
                    플랜을 만들고 있어요
                  </LoadingText>
                )}
                {isLoadingComplete ? (
                  ""
                ) : (
                  <LoadingSubText>잠시만 기다려 주세요!</LoadingSubText>
                )}
              </View>
            </Loading>
          }
        />
      </Layouts>
    </>
  );
};

export default SkinPlanQuestionScreen;
