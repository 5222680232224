import { Color } from "@src/constants";
import styled from "styled-components/native";
import { StyleSheet } from "react-native";
import MediaStyle from "react-native-media-query";

export const MbtiItemWrap = styled.View`
  height: 100%;
`;

export const MbtiItemContents = styled.View``;

export const MbtiType = styled.View`
  align-items: center;
`;

export const MbtiTypeContents = styled.View`
  margin: 20px 0 16px 0;
`;

export const HightlightContents = styled.View`
  position: relative;
`;

export const Hightlight = styled.View`
  position: absolute;
  left: 0;
  bottom: 0;
  height: 24px;
  width: 100%;
  background-color: ${Color.LIGHT_PUPPLE_SIX};
`;

export const MbtiTypeTitle = styled.Text`
  line-height: 1.5em;
  font-size: 18px;
  font-family: "fontB";
`;

export const MbtiTypeText = styled.Text`
  font-size: 52px;
  font-family: "fontJ";
  color: ${Color.PUPPLE};
`;

export const MbtiTypeDescText = styled.Text`
  font-size: 14px;
  font-family: "fontB";
  letter-spacing: -0.35px;
  color: ${Color.PUPPLE};
`;

export const MbtiTagText = styled.Text`
  margin-top: 20px;
  font-size: 20px;
  font-family: "fontB";
  letter-spacing: -0.5px;
  color: ${Color.PUPPLE};
`;

export const MbtiDescription = styled.View`
  margin: 30px 0 40px 0;
  padding: 20px 24px 14px 24px;
  background-color: ${Color.LIGHT_PUPPLE_FIVE};
`;

export const Dot = styled.View`
  margin-top: 7px;
  background-color: ${Color.PUPPLE};
`;

export const MbtiDescText = styled.Text`
  font-size: 16px;
  font-family: "fontM";
  letter-spacing: -0.4px;
  color: ${Color.PUPPLE};
`;

export const MbtiDesc = styled.View`
  margin-bottom: 16px;
  flex-direction: row;
  align-items: flex-start;
`;

export const MbtiRecommend = styled.View`
  align-items: center;
`;

export const MbtiRecommendItem = styled.TouchableOpacity``;

export const MbtiRecommendImg = styled.Image`
  width: 100%;
  height: 160px;
`;

export const MbtiRecommendImgContents = styled.View`
  height: 160px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  background-image: linear-gradient(to bottom, #6432e9, #825bec);
`;

export const MbtiRecommendSurgeryInner = styled.View`
  border-radius: 16px;
  box-shadow: rgba(100, 50, 233, 0.2) 0 0 16px;
`;

export const MbtiRecommendSurgeryTitleContents = styled.View`
  height: 48px;
  margin-top: -24px;
  margin-left: 12px;
  margin-right: 12px;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  box-shadow: rgba(100, 50, 233, 0.08) 0 0 16px;
  background-color: #fff;
`;

export const MbtiRecommendSurgeryTitle = styled.Text`
  font-size: 18px;
  font-family: "fontB";
  color: ${Color.PUPPLE};
`;

export const MbtiRecommendSurgeryInfo = styled.Text`
  padding: 20px 12px 30px 12px;
  font-size: 16px;
  font-family: "fontM";
  letter-spacing: -0.4px;
  line-height: 1.5em;
  text-align: center;
`;

export const MbtiShareContents = styled.View`
  margin-top: 60px;
  align-items: center;
`;

export const SkinMbtiSnsBtnContents = styled.View`
  flex-direction: row;
  margin: 30px 0 60px 0;
`;

export const SkinMbtiSnsBtn = styled.TouchableOpacity`
  margin: 0 10px;
`;

export const MbtiBottomBtnContents = styled.View`
  padding: 0 24px;
`;

export const MbtiBottomBtnText = styled.Text`
  color: ${Color.PUPPLE};
`;

export const MbtiBottomBtnAll = styled.TouchableOpacity`
  margin: 8px 0;
  border: 1px solid ${Color.PUPPLE};
`;

export const MbtiBottomBtnRestart = styled.TouchableOpacity`
  margin: 8px 0;
  background-color: ${Color.PUPPLE};
`;

export const MbtiBottomPlanContents = styled.View`
  margin-top: 20px;
  align-items: center;
`;

export const MbtiBottomTextContents = styled.View`
  margin-bottom: 60px;
  align-items: center;
`;

export const MbtiBottomPlanBtnContents = styled.View`
  width: 100%;
  padding: 0 32px;
`;

export const MbtiBottomPlanBtn = styled.TouchableOpacity`
  background-color: ${Color.PUPPLE};
`;

export const MbtiBottomPlanBtnText = styled.Text`
  font-size: 16px;
  font-family: "fontM";
  color: ${Color.WHITE};
`;

export const itemStyles = StyleSheet.create({
  image: {
    width: "100%",
    height: 320,
  },
  snsIcon: {
    width: 40,
    height: 40,
  },
  title: {
    fontSize: 20,
    fontFamily: "fontB",
    color: Color.PUPPLE,
  },
  btn: {
    width: "100%",
    height: 52,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    borderRadius: 26,
  },
  btnText: {
    fontSize: 16,
    fontFamily: "fontM",
  },
  icon: {
    width: 24,
    height: 24,
    marginRight: 8,
  },
  white: {
    color: Color.WHITE,
  },
  smText: {
    fontSize: 16,
    lineHeight: 23,
  },
  fontBold: {
    fontFamily: "fontB",
  },
  highlightText: {
    fontFamily: "fontB",
    color: Color.PUPPLE,
  },
  alignCenter: {
    textAlign: "center",
  },
  margin20: {
    marginTop: 20,
    marginBottom: 20,
  },
});

export const { ids, styles } = MediaStyle.create({
  listWrap: {
    width: "100%",
    gap: 16,
    paddingLeft: 24,
    paddingRight: 8,
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 30,
    "@media (max-width: 480px)": {
      paddingRight: 24,
      gap: 0,
    },
  },
  list: {
    position: "relative",
    height: 378,
    width: "46%",
    borderRadius: 16,
    boxShadow: "rgba(100, 50, 233, 0.2) 0 0 16px",
    "@media (max-width: 480px)": {
      width: "100%",
      height: "auto",
      marginBottom: 20,
    },
  },
});
