import React, { ReactElement, useRef } from "react";
import { Dimensions, ScrollView, StatusBar, Text } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { Helmet } from "react-helmet-async";
import Toast from "react-native-fast-toast";
import moment from "moment";
import { Layouts } from "@src/components/container";
import {
  AuthCommonContainer,
  AuthCommonInfoText,
  AuthCommonTitle,
  AuthCommonTitleText,
  styles,
} from "../styled";
import { BottomFixButton } from "@src/components/presentational";
import { globalStyles } from "@src/styles/modules/global";
import { withdrawalCancelText } from "@src/screens/sampleDatas";
import { MemberService } from "@src/services";
import { useRecoilState, useSetRecoilState } from "recoil";
import { tokenState, withdrawalDateState } from "@src/store";

/**
 * 탈퇴 요청 계정 안내
 */

const WithdrawalMemberScreen = (): ReactElement => {
  const navigation = useNavigation<any>();

  const { height } = Dimensions.get("window");
  const centerToastRef = useRef(null);

  const setToken = useSetRecoilState(tokenState);
  const [withdrawalDate, setWithdrawalDate] =
    useRecoilState(withdrawalDateState);

  const handleWithdrawalCancel = async () => {
    const type = "RESTORE";
    const conditions = {
      cause: "",
    };

    try {
      const data = await MemberService.deleteUser(type, conditions);
      setToken(data.accessToken);
    } catch (error) {
      console.log(error);
    }

    centerToastRef.current.show(withdrawalCancelText);

    setTimeout(() => {
      window.location.href = "surgeryList";
    }, 2500);
  };

  return (
    <>
      <StatusBar barStyle="light-content" backgroundColor="#6432e9" />
      <Helmet>
        <title>탈퇴 요청 계정 안내 | 촉촉박사</title>
        <meta
          name="description"
          content="촉촉한 피부, 나만 모르고 있던 시술 정보가 궁금하다면? | 촉촉박사"
          data-react-helmet="true"
        />
      </Helmet>
      <Layouts logo transparent hamburgerMode mainMode>
        <ScrollView
          bounces={false}
          scrollEventThrottle={1}
          contentContainerStyle={{ flexGrow: 1 }}
          showsVerticalScrollIndicator={false}
        >
          <AuthCommonContainer>
            <AuthCommonTitle>
              <AuthCommonTitleText>
                {moment(withdrawalDate).format("YYYY년 MM월 DD일")}에
                <br />
                <Text style={styles.highlight}>탈퇴 요청</Text>한 아이디에요.
              </AuthCommonTitleText>
            </AuthCommonTitle>
            <AuthCommonInfoText>
              지금 탈퇴 철회를 하시면 기존 아이디로 로그인하여 서비스를 이용할
              수 있어요.
              <br />
              <br />
            </AuthCommonInfoText>
            <AuthCommonInfoText style={styles.highlight}>
              탈퇴 철회를 원하시면 아래 ‘탈퇴 철회하기’를 눌러 주세요.
              <br />
              <br />
            </AuthCommonInfoText>
            <AuthCommonInfoText>
              탈퇴 신청 7일 후에는 탈퇴 철회가 불가하며, 서비스 이용을 위해서는
              재가입이 필요해요.
            </AuthCommonInfoText>
          </AuthCommonContainer>
        </ScrollView>
        <BottomFixButton
          multipleBtnMode
          leftBtnText="탈퇴 유지하기"
          rightBtnText="탈퇴 철회하기"
          onPressLeftBtn={() => navigation.navigate("surgeryList")}
          onPressRightBtn={handleWithdrawalCancel}
        />
      </Layouts>
      <Toast
        ref={centerToastRef}
        offset={height / 2}
        duration={2000}
        style={globalStyles.centerToast}
        textStyle={globalStyles.centerToastText}
      />
    </>
  );
};

export default WithdrawalMemberScreen;
