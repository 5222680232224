import styled from "styled-components/native";
import { Dimensions, StyleSheet } from "react-native";
import MediaStyle from "react-native-media-query";
import { Color } from "@src/constants";

const { width, height } = Dimensions.get("window");

export const ContentsWrap = styled.View`
  height: 100%;
  background-color: ${Color.WHITE};
`;

export const ContentsInner = styled.View`
  padding: 30px 20px;
`;

export const ReportSelectList = styled.View`
  margin-top: 30px;
`;

export const ReportSelectInput = styled.TextInput`
  width: 100%;
  height: 160px;
  padding: 20px 16px;
  font-size: 16px;
  border-radius: 16px;
  border: 1px solid ${Color.LIGHT_GREY_FIVE};
  outline-color: transparent;
  outline-width: 0;
  outline-style: none;
`;

export const itemStyles = StyleSheet.create({
  whiteBg: {
    backgroundColor: Color.WHITE,
  },
  smText: {
    fontSize: 14,
  },
  mdText: {
    lineHeight: 23,
    fontSize: 16,
  },
  mdTextB: {
    fontSize: 16,
    fontWeight: "bold",
  },
  mLText: {
    fontSize: 20,
    fontWeight: "bold",
  },
  lgText: {
    lineHeight: 33,
    fontSize: 24,
    fontWeight: "bold",
  },
  highlightText: {
    fontWeight: "bold",
    color: Color.PUPPLE,
  },
  marginTop10: {
    marginTop: 10,
  },
  marginTop20: {
    marginTop: 20,
  },
  alignCenter: {
    alignItems: "center",
  },
  spaceBetween: {
    justifyContent: "space-between",
  },
  grayText: {
    marginBottom: 10,
    fontSize: 14,
    fontWeight: "500",
    color: Color.LIGHT_GREY_TWO,
    textAlign: "center",
  },
  tab: {
    minWidth: 360,
    maxWidth: 480,
    backgroundColor: Color.WHITE,
  },
  tabLabel: {
    fontSize: 16,
    fontWeight: "500",
    color: Color.BLACK_TWO,
  },
  puppleText: {
    color: Color.PUPPLE,
  },
  icon: {
    width: 24,
    height: 24,
  },
});

export const { ids, styles, fullStyles } = MediaStyle.create({});
