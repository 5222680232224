import React, { ReactElement } from "react";
import { ScrollView, StatusBar, Text } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { Helmet } from "react-helmet-async";
import { Layouts } from "@src/components/container";
import {
  MypageCommonContainer,
  MypageCommonInfoText,
  MypageCommonTitle,
  MypageCommonTitleText,
  styles,
} from "./styled";
import { BottomFixButton } from "@src/components/presentational";

/**
 * 개인정보 보관 기간 설정
 */

const PrivacyNoticeScreen = (): ReactElement => {
  const navigation = useNavigation<any>();

  const handleCancel = () => {
    navigation.navigate("mypage");
  };

  const handleNext = () => {
    navigation.navigate("privacySetting");
  };

  return (
    <>
      <StatusBar barStyle="light-content" backgroundColor="#6432e9" />
      <Helmet>
        <title>개인정보 보관 기간 설정 | 촉촉박사</title>
        <meta
          name="description"
          content="촉촉한 피부, 나만 모르고 있던 시술 정보가 궁금하다면? | 촉촉박사"
          data-react-helmet="true"
        />
      </Helmet>
      <Layouts logo transparent>
        <ScrollView
          bounces={false}
          scrollEventThrottle={1}
          contentContainerStyle={{ flexGrow: 1 }}
          showsVerticalScrollIndicator={false}
        >
          <MypageCommonContainer>
            <MypageCommonTitle>
              <MypageCommonTitleText>
                개인정보 처리에 대한 안내
              </MypageCommonTitleText>
            </MypageCommonTitle>
            <MypageCommonInfoText>
              회원님의 휴면계정 전환 기간(개인정보를 분리 저장 및 관리하는
              개인정보 유효 기간)은 별도로 설정하실 수 있어요.
              <br />
              <br />
              회원님이 설정하신 기간 동안, 촉촉박사는 회원님의 회원정보를 분리
              보관하지 않아요.
              <br />
              <br />
              별도의 설정 요청이 없다면, 개인정보 보관 기간은 1년이에요.
              <br />
              <br />
              <Text style={styles.highlight}>
                설정을 원하시면 아래의 ‘다음으로’ 버튼을 클릭해 주세요.
              </Text>
            </MypageCommonInfoText>
          </MypageCommonContainer>
        </ScrollView>
        <BottomFixButton
          multipleBtnMode
          leftBtnText="취소하기"
          rightBtnText="다음으로"
          onPressLeftBtn={handleCancel}
          onPressRightBtn={handleNext}
        />
      </Layouts>
    </>
  );
};

export default PrivacyNoticeScreen;
