import { globalStyles, ids } from "@src/styles/modules/global";
import React, { ReactElement } from "react";
import { View } from "react-native";
import { Maybe } from "..";
import {
  SurgeryInfoItemWrap,
  SurgeryInfoItemInner,
  SurgeryInfoList,
  SurgeryInfoLeftTitle,
  SurgeryInfoLeftTitleText,
  SurgeryInfoRightTitle,
  SurgeryInfoRightTitleText,
  SurgeryInfoPrice,
  SurgeryInfoPriceTitleText,
  SurgeryInfoPriceContents,
  SurgeryInfoPriceNum,
  SurgeryInfoPriceTitle,
  SurgeryInfoPriceText,
  SurgeryInfoPriceGraphContents,
  SurgeryInfoPriceGraphImg,
  SurgeryInfoRecommend,
  SurgeryInfoRecommendText,
  SurgeryInfoRecommendSummary,
  SurgeryInfoRecommendSummaryText,
  SurgeryInfoPlusText,
  SurgeryInfoRecommendTextWrap,
  SurgeryInfoLeftTitleSubText,
} from "./styled";

interface SurgeryInfoItemProps {
  data: any;
}

const SurgeryInfoItem = ({ data }: SurgeryInfoItemProps): ReactElement => {
  return (
    <SurgeryInfoItemWrap>
      <SurgeryInfoItemInner>
        {data?.threadShape?.val && (
          <SurgeryInfoList>
            <SurgeryInfoLeftTitle>
              <SurgeryInfoLeftTitleText>
                {data?.threadShape?.title}
              </SurgeryInfoLeftTitleText>
            </SurgeryInfoLeftTitle>
            <SurgeryInfoRightTitle>
              <SurgeryInfoRightTitleText>
                {data?.threadShape?.val}
              </SurgeryInfoRightTitleText>
            </SurgeryInfoRightTitle>
          </SurgeryInfoList>
        )}
        {data?.treatUnit?.val && (
          <SurgeryInfoList>
            <SurgeryInfoLeftTitle>
              <SurgeryInfoLeftTitleText>
                {data?.treatUnit?.title}
              </SurgeryInfoLeftTitleText>
            </SurgeryInfoLeftTitle>
            <SurgeryInfoRightTitle>
              <SurgeryInfoRightTitleText>
                {data?.treatUnit?.val}
              </SurgeryInfoRightTitleText>
            </SurgeryInfoRightTitle>
          </SurgeryInfoList>
        )}
        {data?.recommendedNoTimes?.val && (
          <SurgeryInfoList>
            <SurgeryInfoLeftTitle>
              <SurgeryInfoLeftTitleText>
                {data?.recommendedNoTimes?.title}
              </SurgeryInfoLeftTitleText>
            </SurgeryInfoLeftTitle>
            <SurgeryInfoRightTitle>
              <SurgeryInfoRightTitleText>
                {data?.recommendedNoTimes?.val}
              </SurgeryInfoRightTitleText>
            </SurgeryInfoRightTitle>
          </SurgeryInfoList>
        )}
        {data?.surgerySite?.val && (
          <SurgeryInfoList>
            <SurgeryInfoLeftTitle>
              <SurgeryInfoLeftTitleText>
                {data?.surgerySite?.title}
              </SurgeryInfoLeftTitleText>
            </SurgeryInfoLeftTitle>
            <SurgeryInfoRightTitle>
              <SurgeryInfoRightTitleText>
                {data?.surgerySite?.val}
              </SurgeryInfoRightTitleText>
            </SurgeryInfoRightTitle>
          </SurgeryInfoList>
        )}
        {data?.provenance?.val && (
          <SurgeryInfoList>
            <SurgeryInfoLeftTitle>
              <SurgeryInfoLeftTitleText>
                {data?.provenance?.title}
              </SurgeryInfoLeftTitleText>
            </SurgeryInfoLeftTitle>
            <SurgeryInfoRightTitle>
              <SurgeryInfoRightTitleText>
                {data?.provenance?.val}
              </SurgeryInfoRightTitleText>
            </SurgeryInfoRightTitle>
          </SurgeryInfoList>
        )}
        {data?.duration?.val && (
          <SurgeryInfoList>
            <SurgeryInfoLeftTitle>
              <SurgeryInfoLeftTitleText>
                {data?.duration?.title}
              </SurgeryInfoLeftTitleText>
            </SurgeryInfoLeftTitle>
            <SurgeryInfoRightTitle>
              <SurgeryInfoRightTitleText>
                {data?.duration?.val}
              </SurgeryInfoRightTitleText>
            </SurgeryInfoRightTitle>
          </SurgeryInfoList>
        )}
        {data?.effect?.val && (
          <SurgeryInfoList>
            <SurgeryInfoLeftTitle>
              <SurgeryInfoLeftTitleText>
                {data?.effect?.title}
              </SurgeryInfoLeftTitleText>
            </SurgeryInfoLeftTitle>
            <SurgeryInfoRightTitle>
              <SurgeryInfoRightTitleText>
                {data?.effect?.val}
              </SurgeryInfoRightTitleText>
            </SurgeryInfoRightTitle>
          </SurgeryInfoList>
        )}
        {data?.levelOfPain?.val && (
          <SurgeryInfoList>
            <SurgeryInfoLeftTitle>
              <SurgeryInfoLeftTitleText>
                {data?.levelOfPain?.title}
              </SurgeryInfoLeftTitleText>
            </SurgeryInfoLeftTitle>
            <SurgeryInfoRightTitle>
              <SurgeryInfoRightTitleText>
                {data?.levelOfPain?.val}
              </SurgeryInfoRightTitleText>
            </SurgeryInfoRightTitle>
          </SurgeryInfoList>
        )}
        {data?.downtime?.val && (
          <SurgeryInfoList>
            <SurgeryInfoLeftTitle>
              <SurgeryInfoLeftTitleText>
                {data?.downtime?.title}
              </SurgeryInfoLeftTitleText>
            </SurgeryInfoLeftTitle>
            <SurgeryInfoRightTitle>
              <SurgeryInfoRightTitleText>
                {data?.downtime?.val}
              </SurgeryInfoRightTitleText>
            </SurgeryInfoRightTitle>
          </SurgeryInfoList>
        )}

        <Maybe
          test={data?.averagePrice}
          children={
            <SurgeryInfoList exceptionView>
              <SurgeryInfoLeftTitle>
                <SurgeryInfoLeftTitleText>평균 가격</SurgeryInfoLeftTitleText>
                {data?.averagePrice?.map(
                  (item, index) =>
                    index === 5 && (
                      <SurgeryInfoLeftTitleSubText key={index}>
                        ({item.val})
                      </SurgeryInfoLeftTitleSubText>
                    )
                )}
              </SurgeryInfoLeftTitle>
              <SurgeryInfoPrice>
                <SurgeryInfoPriceTitleText>전국 기준</SurgeryInfoPriceTitleText>
                <SurgeryInfoPriceContents>
                  {data?.averagePrice?.map(
                    (item, index) =>
                      index !== 0 &&
                      index !== 5 &&
                      item.val && (
                        <SurgeryInfoPriceNum key={index}>
                          <SurgeryInfoPriceTitle>
                            {item.title}
                          </SurgeryInfoPriceTitle>
                          <SurgeryInfoPriceText>
                            {item.val}
                          </SurgeryInfoPriceText>
                        </SurgeryInfoPriceNum>
                      )
                  )}
                </SurgeryInfoPriceContents>
                {data?.averagePrice?.map(
                  (item, index) =>
                    index === 4 && (
                      <SurgeryInfoPriceGraphContents key={index}>
                        <View
                          style={globalStyles.graph}
                          dataSet={{ media: ids.graph }}
                        >
                          <SurgeryInfoPriceGraphImg
                            source={item.icon}
                            resizeMode="contain"
                          />
                        </View>
                      </SurgeryInfoPriceGraphContents>
                    )
                )}
              </SurgeryInfoPrice>
            </SurgeryInfoList>
          }
        />

        <Maybe
          test={data?.surgeryItemCombination}
          children={
            <SurgeryInfoList exceptionView>
              <SurgeryInfoLeftTitle>
                <SurgeryInfoLeftTitleText>
                  {data?.surgeryItemCombination?.title}
                </SurgeryInfoLeftTitleText>
              </SurgeryInfoLeftTitle>
              <SurgeryInfoRecommend>
                <SurgeryInfoRecommendTextWrap>
                  <SurgeryInfoRecommendText>
                    {data?.surgeryItemCombination?.name}
                  </SurgeryInfoRecommendText>
                  <SurgeryInfoPlusText>+</SurgeryInfoPlusText>
                  <SurgeryInfoRecommendText>
                    {data?.surgeryItemCombination?.targetName}
                  </SurgeryInfoRecommendText>
                </SurgeryInfoRecommendTextWrap>
                <SurgeryInfoRecommendSummary>
                  <SurgeryInfoRecommendSummaryText>
                    {data?.surgeryItemCombination?.description}
                  </SurgeryInfoRecommendSummaryText>
                </SurgeryInfoRecommendSummary>
              </SurgeryInfoRecommend>
            </SurgeryInfoList>
          }
        />
      </SurgeryInfoItemInner>
    </SurgeryInfoItemWrap>
  );
};

export default SurgeryInfoItem;
