import styled from "styled-components/native";
import { StyleSheet } from "react-native";
import { Color } from "@src/constants";

export const PopupContainer = styled.View`
  position: relative;
  height: 100%;
  flex: 1;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
`;

export const PopupInner = styled.View`
  flex: 1;
  max-height: 640px;
`;

export const PopupContents = styled.View`
  padding: 0 20px;
  text-align: center;
  margin: 0 auto;
`;

export const PopupSlideImgContents = styled.View``;

export const PopupSlideImg = styled.Image`
  width: 320px;
  height: 320px;
`;

export const PopupSlideTextContents = styled.View`
  padding: 20px 0 0 0;
`;

export const PopupSlideTitle = styled.Text`
  font-size: 20px;
  font-family: "fontB";
  letter-spacing: -0.5px;
  text-align: center;
  color: ${Color.WHITE};
`;

export const PopupSlideInfo = styled.Text`
  margin: 16px 0 24px 0;
  font-size: 14px;
  line-height: 20px;
  font-family: "fontM";
  text-align: center;
  color: ${Color.WHITE};
`;

export const PopupButtonContents = styled.TouchableHighlight`
  width: 320px;
  height: 52px;
  border-radius: 26px;
  padding: 15px 0;
  background-color: ${({ index }: { index?: number }) =>
    index === 2 ? Color.LIGHT_PUPPLE_THREE : Color.LIGHT_GREY_THREE};
`;

export const PopupButtonText = styled.Text`
  font-size: 16px;
  font-family: "fontM";
  text-align: center;
  letter-spacing: -0.4px;
  color: ${Color.WHITE};
`;

export const PopupCloseBtn = styled.TouchableHighlight``;

export const PopupCloseBtnText = styled.Text`
  margin-top: 20px;
  font-size: 14px;
  font-family: "fontM";
  text-align: center;
  letter-spacing: -0.35px;
  color: ${Color.WHITE};
`;

export const styles = StyleSheet.create({
  popupInner: {
    flex: 1,
    maxHeight: 640,
    paddingBottom: 20,
  },
  slide: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  text: {
    fontFamily: "fontM",
  },
});
