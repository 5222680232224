import styled from "styled-components/native";
import { StyleSheet } from "react-native";
import { Color } from "@src/constants";

export const AccordionWrap = styled.View`
  position: relative;
`;

export const AccordionContainer = styled.View`
  width: 100%;
  background-image: linear-gradient(to bottom, #6432e9, #825bec);
  border-radius: 24px;
`;

export const AccordionInner = styled.View`
  padding: 10px 10px 24px 16px;
`;

export const AccordionHeader = styled.View`
  flex-direction: row;
  justify-content: space-between;
`;

export const AccordionHeaderText = styled.Text`
  padding-top: 6px;
  font-size: 20px;
  font-family: "fontB";
  line-height: 29px;
  letter-spacing: -0.5px;
  color: ${Color.WHITE};
`;

export const AccordionHeaderExpand = styled.TouchableHighlight`
  width: 24px;
  height: 24px;
`;

export const AccordionHeaderIcon = styled.Image`
  width: 24px;
  height: 24px;
`;

export const AccordionHeaderTagContents = styled.View`
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 4px;
`;

export const AccordionHeaderTag = styled.View`
  margin-right: 5px;
`;

export const AccordionHeaderTagText = styled.Text`
  font-size: 14px;
  font-family: "fontM";
  letter-spacing: -0.35px;
  line-height: 20px;
  color: ${Color.WHITE};
`;

export const AccordionContents = styled.View`
  margin-top: 20px;
`;

export const AccordionContentsHighlight = styled.View`
  position: relative;
  flex-direction: row;
  margin-bottom: 4px;
`;

export const AccordionContentsHighlightIcon = styled.Image`
  width: 20px;
  height: 20px;
  margin-right: 4px;
`;

export const HighlightBg = styled.View`
  width: 128px;
  background-color: ${Color.NAVY};
`;

export const AccordionContentsHighlightText = styled.Text`
  z-index: 1;
  font-size: 14px;
  font-family: "fontB";
  letter-spacing: -0.35px;
  color: ${Color.WHITE};
`;

export const AccordionContentsInfo = styled.View`
  flex-direction: row;
  align-items: center;
  margin-top: 2px;
`;

export const Dot = styled.View`
  background-color: ${Color.WHITE};
`;

export const AccordionContentsInfoText = styled.Text`
  font-size: 13px;
  font-family: "fontM";
  letter-spacing: -0.33px;
  line-height: 20px;
  color: ${Color.WHITE};
`;

export const AccordionInfoSummary = styled.View`
  padding: 0 16px 16px 16px;
`;

export const AccordionInfoSummaryInner = styled.View`
  width: 100%;
  padding: 16px 16px 20px 16px;
  border-radius: 16px;
  background-color: ${Color.LIGHT_PUPPLE_FIVE};
`;

export const AccordionInfoSummaryText = styled.Text`
  font-size: 13px;
  font-family: "fontM";
  line-height: 20px;
  letter-spacing: -0.33px;
  color: ${Color.LIGHT_PUPPLE_THREE};
`;

export const styles = StyleSheet.create({
  accordion: {
    overflow: "hidden",
  },
});
