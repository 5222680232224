import React, { ReactElement } from "react";
import { ScrollView, StatusBar, Text } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { Helmet } from "react-helmet-async";
import { tokenState } from "@src/store";
import { Layouts } from "@src/components/container";
import {
  AuthCommonContainer,
  AuthCommonInfoText,
  AuthCommonTitle,
  AuthCommonTitleText,
  styles,
} from "../styled";
import { BottomFixButton } from "@src/components/presentational";
import { MemberService } from "@src/services";
import { useSetRecoilState } from "recoil";

/**
 * 휴면 회원 안내
 */

const InactiveMemberScreen = (): ReactElement => {
  const navigation = useNavigation<any>();

  const setToken = useSetRecoilState(tokenState);

  const handleInactiveMember = async () => {
    const type = "RESTORE";
    const conditions = {
      cause: "",
    };

    try {
      const data = await MemberService.deleteUser(type, conditions);
      setToken(data.accessToken);
    } catch (error) {
      console.log(error);
    }

    setTimeout(() => {
      window.location.href = "privacyNotice";
    }, 2500);
  };

  return (
    <>
      <StatusBar barStyle="light-content" backgroundColor="#6432e9" />
      <Helmet>
        <title>휴면 회원 안내 | 촉촉박사</title>
        <meta
          name="description"
          content="촉촉한 피부, 나만 모르고 있던 시술 정보가 궁금하다면? | 촉촉박사"
          data-react-helmet="true"
        />
      </Helmet>
      <Layouts logo transparent hamburgerMode mainMode>
        <ScrollView
          bounces={false}
          scrollEventThrottle={1}
          contentContainerStyle={{ flexGrow: 1 }}
          showsVerticalScrollIndicator={false}
        >
          <AuthCommonContainer>
            <AuthCommonTitle>
              <AuthCommonTitleText>
                회원님의 아이디는
                <br />
                <Text style={styles.highlight}>휴면 중</Text>이에요.
              </AuthCommonTitleText>
            </AuthCommonTitle>
            <AuthCommonInfoText>
              마지막 로그인 후 개인정보 보관 기간이 지나 아이디가 휴면 상태로
              전환되었어요.
              <br />
              <br />
            </AuthCommonInfoText>
            <AuthCommonInfoText style={styles.highlight}>
              ‘다음으로’ 버튼을 누르면 휴면이 해제되고 서비스를 정상적으로
              이용할 수 있어요.
            </AuthCommonInfoText>
          </AuthCommonContainer>
        </ScrollView>
        <BottomFixButton
          multipleBtnMode
          leftBtnText="취소하기"
          rightBtnText="다음으로"
          onPressLeftBtn={() => navigation.navigate("surgeryList")}
          onPressRightBtn={handleInactiveMember}
        />
      </Layouts>
    </>
  );
};

export default InactiveMemberScreen;
