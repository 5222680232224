import {
  ISkinPlanList,
  PlanSubmitList,
  ResultList,
  SelectedKeyword,
} from "@src/types/api/skinPlanType";
import { atom } from "recoil";
import { localStorageEffect } from "..";

/**
 * 피부 플랜 Atoms
 */

// 질문지 조회
export const SkinPlanQuestionState = atom<ISkinPlanList>({
  key: "SkinPlanQuestionState",
  default: null,
});

// 질문지 답변
export const SkinPlanQuestionAnswerData = atom<any>({
  key: "SkinPlanQuestionAnswerData",
  default: [],
});

// 유저 응답 아이디
export const SkinPlanAnswerId = atom<number | null>({
  key: "SkinPlanAnswerId",
  default: null,
});

// 시술 추천 결과 조회
export const SkinPlanResultList = atom<ResultList[]>({
  key: "SkinPlanResultList",
  default: null,
  effects_UNSTABLE: [localStorageEffect("skinPlanResultList")],
});

// 이 플랜을 볼래요 클릭 리스트
export const SkinPlanSelectedList = atom<any>({
  key: "SkinPlanSelectedList",
  default: null,
});

// 피부플랜 생성
export const SkinPlanSubmitList = atom<PlanSubmitList>({
  key: "SkinPlanSubmitList",
  default: null,
});

// 내 피부 플랜 시술 별 현황
export const SkinPlanProcedureListData = atom<any>({
  key: "SkinPlanProcedureListData",
  default: null,
  effects_UNSTABLE: [localStorageEffect("planDetailProcedureList")],
});

// 시술명 검색
export const SkinPlanProcedureSearch = atom<SelectedKeyword[]>({
  key: "SkinPlanProcedureSearch",
  default: [],
  effects_UNSTABLE: [localStorageEffect("skinPlanProcedureSearch")],
});
