import React from "react";
import { Helmet } from "react-helmet-async";

/**
 * Meta Tag
 */

const SetMetaTag = (props) => {
  return (
    <Helmet>
      <title>{props.title}</title>

      <meta
        http-equiv="Content-Security-Policy"
        content="upgrade-insecure-requests"
      />

      <meta
        name="description"
        content={props.description}
        data-react-helmet="true"
      />
      <meta
        name="keywords"
        content="촉촉박사, 피부, 시술, 시술 정보, 시술 비교, 시술 가격"
      />

      <meta property="og:type" content="website" />
      <meta property="og:title" content={props.title} />
      <meta property="og:site_name" content={props.title} />
      <meta
        property="og:description"
        content={props.description}
        data-react-helmet="true"
      />
      <meta property="og:url" content={props.url} />

      <link rel="canonical" href={props.url} />
      <link
        rel="shortcut icon"
        type="image/x-icon"
        href="./assets/favicon.png"
      />
    </Helmet>
  );
};

export default SetMetaTag;
