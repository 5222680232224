import React, { ReactElement } from "react";
import { View } from "react-native";

import {
  CloseBtn,
  CloseBtnIcon,
  Dimmed,
  styles,
  PopupContents,
  ids,
} from "./styled";
import closeIcon from "@assets/icons/icon-close.png";

interface ImagePopupProps {
  isOpen?: any;
  onClose?: () => void;
  imageUrl?: any;
}

const ImagePopup = ({
  isOpen,
  onClose,
  imageUrl,
}: ImagePopupProps): ReactElement => {
  return (
    <>
      <Dimmed
        isOpen={isOpen}
        onStartShouldSetResponder={() => true}
        onResponderGrant={() => onClose()}
      />
      <View dataSet={{ media: ids.popup }} style={styles.popup}>
        <PopupContents>
          <View dataSet={{ media: ids.closeBtn }} style={styles.closeBtn}>
            <CloseBtn onPress={onClose} underlayColor="transparent">
              <CloseBtnIcon source={closeIcon} resizeMode="contain" />
            </CloseBtn>
          </View>
          <img src={imageUrl} />
        </PopupContents>
      </View>
    </>
  );
};

export default ImagePopup;
