import styled from "styled-components/native";
import { StyleSheet } from "react-native";
import { Color } from "@src/constants";

export const BottomFixContentsWrap = styled.View`
  width: 100%;
  position: fixed;
  bottom: 0;
  background-image: ${({ bgColor }: { bgColor?: boolean }) =>
    bgColor
      ? "linear-gradient(rgba(255, 255, 255, 0), rgb(255, 255, 255) 10%, rgb(255, 255, 255))"
      : ""};
`;

export const BottomFixContentsInner = styled.View`
  padding: ${({ planMode }: { planMode?: boolean }) =>
    planMode ? "0 20px 24px 20px" : "20px 20px 24px 20px"};
`;

export const BottomFixComment = styled.View`
  flex-direction: row;
  margin-bottom: 10px;
`;

export const BottomFixCommentIcon = styled.Image`
  width: 20px;
  height: 20px;
  margin-right: 4px;
`;

export const BottomFixCommentText = styled.Text`
  font-size: 12px;
  font-family: "fontM";
  line-height: 17px;
  letter-spacing: -0.3px;
`;

export const BottomFixButtonContents = styled.View`
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
`;

export const FullButton = styled.TouchableOpacity`
  width: 100%;
  background-color: ${({
    whiteBtn,
    disabledBg,
  }: {
    whiteBtn?: boolean;
    disabledBg?: boolean;
  }) =>
    whiteBtn
      ? Color.WHITE
      : Color.PUPPLE && disabledBg
      ? Color.LIGHT_GREY_THREE
      : Color.PUPPLE};
  border: ${({ whiteBtn }: { whiteBtn?: boolean }) =>
    whiteBtn ? `1px solid ${Color.PUPPLE}` : "0"};
`;

export const FullButtonText = styled.Text`
  font-size: 16px;
  font-family: "fontM";
  letter-spacing: -0.4px;
  color: ${({ whiteBtn }: { whiteBtn?: boolean }) =>
    whiteBtn ? Color.PUPPLE : Color.WHITE};
`;

export const LeftButton = styled.TouchableOpacity`
  width: calc(100% / 2.5 - 16px);
  margin-right: 16px;
  border: 1px solid ${Color.PUPPLE};
  background-color: ${Color.WHITE};
`;

export const LeftButtonText = styled.Text`
  font-size: 16px;
  font-family: "fontM";
  letter-spacing: -0.4px;
  color: ${Color.PUPPLE};
`;

export const RightButton = styled.TouchableOpacity`
  width: calc(100% / 2 + 45px);
  background-color: ${({ disabled }: { disabled?: boolean }) =>
    disabled ? Color.LIGHT_GREY_THREE : Color.PUPPLE};
`;

export const RightButtonText = styled.Text`
  font-size: 16px;
  font-family: "fontM";
  letter-spacing: -0.4px;
  color: ${Color.WHITE};
`;

export const SubText = styled.Text`
  margin-bottom: 10px;
  font-size: 14px;
  font-family: "fontM";
  text-align: center;
  color: ${Color.LIGHT_GREY_TWO};
`;

export const styles = StyleSheet.create({
  button: {
    height: 52,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 26,
  },
});
