import { Color } from "@src/constants";
import styled from "styled-components/native";
import { StyleSheet } from "react-native";
import MediaStyle from "react-native-media-query";

export const SearchContainer = styled.View`
  height: 100%;
  background-color: ${Color.WHITE};
`;

export const SearchBar = styled.View`
  height: 56px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  margin-bottom: 20px;
`;

export const SearchBack = styled.TouchableOpacity`
  width: 24px;
  height: 24px;
`;

export const SearchInputWrap = styled.View`
  width: calc(100% - 34px);
  margin-left: 10px;
`;

export const SearchInput = styled.TextInput`
  width: 100%;
  height: 44px;
  padding: 9px 0 11px 16px;
  background-color: ${Color.GREY};
  border-radius: 22px;
  outline-color: transparent;
  outline-width: 0;
  outline-style: none;
`;

export const BtnContents = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  top: 6px;
  right: 6px;
`;

export const DeleteBtn = styled.TouchableOpacity`
  width: 24px;
  height: 24px;
  margin-right: 8px;
`;

export const SearchBtn = styled.TouchableOpacity`
  width: 32px;
  height: 32px;
`;

export const SearchBtnIcon = styled.View`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: ${Color.PUPPLE};
`;

export const SearchKeyword = styled.View``;

export const SearchKeywordContents = styled.View`
  padding: 0 20px;
  margin-top: 10px;
`;

export const SearchKeywordTitleWrap = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const SearchKeywordTitle = styled.Text`
  font-size: 16px;
  font-family: "fontB";
`;

export const SearchKeywordAllDelete = styled.TouchableOpacity``;

export const SearchKeywordItem = styled.TouchableOpacity`
  height: 36px;
  padding: 9px 16px;
  margin-right: 10px;
  margin-bottom: 10px;
  background-color: ${({ isSelected }: { isSelected?: boolean }) =>
    isSelected ? Color.PUPPLE : Color.WHITE};
  border-radius: 18px;
  border: ${({ isSelected }: { isSelected?: boolean }) =>
    isSelected ? "transparent" : `1px solid ${Color.LIGHT_GREY_FIVE}`};
`;

export const CommunityKeywordItem = styled.TouchableOpacity`
  flex-direction: row;
  align-items: center;
  height: 36px;
  padding: 6px 16px;
  justify-content: center;
  margin-right: 10px;
  margin-bottom: 10px;
  background-color: ${Color.GREY};
  border-radius: 18px;
`;

export const CommunityKeywordDelete = styled.TouchableOpacity`
  margin-left: 8px;
`;

export const SearchKeywordItemText = styled.Text`
  padding-bottom: 2px;
  color: ${({ isSelected }: { isSelected?: boolean }) =>
    isSelected ? Color.WHITE : Color.BLACK_TWO};
`;

export const SearchTextItem = styled.View`
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
`;

export const SearchTextItemCheck = styled.TouchableOpacity``;

export const SearchTextItemText = styled.Text`
  margin-left: 10px;
  font-size: 16px;
`;

export const SearchNoData = styled.View`
  width: 100%;
  height: calc(100vh - 300px);
  padding: 0 60px;
  justify-content: center;
  align-items: center;
`;

export const SearchNoDataTitle = styled.Text`
  font-size: 20px;
  font-family: "fontB";
`;

export const SearchNoDataInfo = styled.Text`
  margin: 20px 0 40px 0;
  font-size: 16px;
  font-family: "fontM";
  text-align: center;
  line-height: 23px;
`;

export const SearchNoDataBtn = styled.TouchableOpacity`
  background-color: ${Color.PUPPLE};
`;

export const SearchNoDataBtnText = styled.Text`
  color: ${Color.WHITE};
`;

export const RecentSearchNoData = styled.View`
  width: 100%;
  height: calc(100vh - 250px);
  justify-content: center;
  align-items: center;
`;

export const SelectInputWrap = styled.View`
  position: fixed;
  width: 100%;
  background-color: #fff;
  z-index: 10;
  padding: 20px 0 10px 20px;
  margin-bottom: 10px;
`;

export const SelectInputBtn = styled.TouchableOpacity`
  flex-direction: row;
  align-items: center;
  width: fit-content;
`;

export const SelectInputBtnText = styled.Text`
  font-size: 14px;
  font-family: "fontM";
`;

export const itemStyles = StyleSheet.create({
  title: {
    fontSize: 20,
    fontFamily: "fontB",
  },
  whiteText: {
    color: Color.WHITE,
  },
  puppleText: {
    color: Color.PUPPLE,
  },
  xxsText: {
    fontSize: 10,
    fontFamily: "fontM",
  },
  xsText: {
    fontSize: 12,
    fontFamily: "fontM",
  },
  sText: {
    fontSize: 13,
    fontFamily: "fontM",
  },
  smText: {
    fontSize: 14,
    fontFamily: "fontM",
  },
  mdText: {
    fontSize: 16,
    fontFamily: "fontM",
  },
  lgText: {
    fontSize: 20,
    fontFamily: "fontB",
  },
  xlText: {
    fontSize: 24,
    fontFamily: "fontB",
  },
  lineHeight: {
    lineHeight: 20,
  },
  pupple: {
    color: Color.PUPPLE,
  },
  bold: {
    fontFamily: "fontB",
  },
  grayText: {
    color: Color.LIGHT_GREY_TWO,
  },
  dragIcon: {
    width: 24,
    height: 24,
  },
  flexRow: {
    flexDirection: "row",
  },
  alignCenter: {
    alignItems: "center",
  },
  textCenter: {
    textAlign: "center",
  },
  icon: {
    width: 24,
    height: 24,
  },
  noDataText: {
    fontSize: 14,
    fontFamily: "fontR",
  },
  communitySearch: {
    paddingTop: 54,
  },
});

export const { ids, styles } = MediaStyle.create({});
