import React, { ReactElement } from "react";
import { View, Image, Text, ScrollView, FlatList } from "react-native";
import { useNavigation } from "@react-navigation/native";

import {
  CloseBtn,
  CloseBtnIcon,
  Dimmed,
  styles,
  MbtiPopupContents,
  MbtiPopupContentsHeader,
  MbtiPopupList,
  HeaderTitleText,
  MbtiPopupItem,
  itemStyles,
  MbtiPopupItemTextContents,
  ids,
} from "./styled";
import { SkinMbtiResultType } from "@src/screens/mbti/SkinMbtiDetailScreen/configs";
import nextIcon from "@assets/icons/icon-next.svg";
import closeIcon from "@assets/icons/icon-close-black.svg";

interface MbtiPopupProps {
  isOpen?: any;
  onClose?: () => void;
}

const MbtiPopup = ({ isOpen, onClose }: MbtiPopupProps): ReactElement => {
  const navigation = useNavigation<any>();

  const renderMbtiAllType = ({ index, item }) => {
    return (
      <MbtiPopupItem
        onPress={() =>
          navigation.push("skinMbtiResult", {
            type: item.type,
            state: 3,
          })
        }
      >
        <Image
          source={item.img}
          resizeMode="contain"
          style={itemStyles.image}
        />
        <MbtiPopupItemTextContents>
          <Text style={itemStyles.title}>{item.type}</Text>
          <Text style={itemStyles.desc}>{item.title}</Text>
        </MbtiPopupItemTextContents>
        <View>
          <Image
            source={nextIcon}
            resizeMode="contain"
            style={itemStyles.icon}
          />
        </View>
      </MbtiPopupItem>
    );
  };

  return (
    <>
      <Dimmed
        isOpen={isOpen}
        onStartShouldSetResponder={() => true}
        onResponderGrant={() => onClose()}
      />
      <View dataSet={{ media: ids.popup }} style={styles.popup}>
        <MbtiPopupContents>
          <MbtiPopupContentsHeader>
            <HeaderTitleText>모든 결과 보기</HeaderTitleText>
            <CloseBtn onPress={onClose} underlayColor="transparent">
              <CloseBtnIcon source={closeIcon} resizeMode="contain" />
            </CloseBtn>
          </MbtiPopupContentsHeader>
          <ScrollView
            bounces={false}
            scrollEventThrottle={1}
            contentContainerStyle={{ flexGrow: 1 }}
            showsVerticalScrollIndicator={false}
          >
            <MbtiPopupList>
              <FlatList
                data={SkinMbtiResultType}
                renderItem={renderMbtiAllType}
              />
            </MbtiPopupList>
          </ScrollView>
        </MbtiPopupContents>
      </View>
    </>
  );
};

export default MbtiPopup;
