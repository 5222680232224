/* eslint-disable @typescript-eslint/no-explicit-any */
import RestService from "../RestService";

/**
 * Skin Plan API
 * @see https://
 */

const PREFIX_URL = `/v1/planner`;

class SkinPlanService {
  /**
   * 질문지 조회
   * @return {Promise<any>}
   */
  public getSkinPlanQuestion = async (): Promise<any> => {
    const url: string = `${PREFIX_URL}/retrieve/question`;

    try {
      const { data } = await RestService.get(url);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  /**
   * 유저 응답 수정
   * @return {Promise<any>}
   */
  public patchAnswer = async (payload): Promise<any> => {
    const url: string = `${PREFIX_URL}/retrieve/answer`;

    try {
      const { data } = await RestService.patch(url, payload);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  /**
   * 유저 아이디
   * @return {Promise<any>}
   */
  public postAnswerId = async (): Promise<any> => {
    const url: string = `${PREFIX_URL}/retrieve/answer`;

    try {
      const { data } = await RestService.post(url);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  /**
   * 시술 추천 결과 조회
   * @return {Promise<any>}
   */
  public getSkinPlanResultList = async ({ answerId }): Promise<any> => {
    const url: string = `${PREFIX_URL}/retrieve/result?plannerAnswerId=${answerId}`;

    try {
      const { data } = await RestService.get(url);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  /**
   * 피부플랜 생성
   * @return {Promise<any>}
   */
  public postSkinPlanList = async (payload): Promise<any> => {
    const url: string = `${PREFIX_URL}/`;

    try {
      const { data } = await RestService.post(url, payload);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  /**
   * 피부플랜 리스트 조회
   * @return {Promise<any>}
   */
  public getPlanList = async ({ pageIdx, pageSize }): Promise<any> => {
    const url: string = `${PREFIX_URL}/?page-idx=${pageIdx}&page-size=${pageSize}`;

    try {
      const { data } = await RestService.get(url);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  /**
   * 피부플랜 상세 조회
   * @return {Promise<any>}
   */
  public getPlanItemDetail = async ({ plannerId }): Promise<any> => {
    const url: string = `${PREFIX_URL}/${plannerId}`;

    try {
      const { data } = await RestService.get(url);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  /**
   * 피부플랜 수정
   * @return {Promise<any>}
   */
  public putPlanItem = async (payload): Promise<any> => {
    const url: string = `${PREFIX_URL}/`;

    try {
      const { data } = await RestService.put(url, payload);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  /**
   * 피부플랜 삭제
   * @return {Promise<any>}
   */
  public deletePlanList = async (plannerId): Promise<any> => {
    const url: string = `${PREFIX_URL}/${plannerId}`;

    try {
      const { data } = await RestService.delete(url);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  /**
   * 인기 검색어 조회
   * @return {Promise<any>}
   */
  public getPopularityKeyword = async ({ type }): Promise<any> => {
    const url: string = `${PREFIX_URL}/search/keyword?type=${type}`;

    try {
      const { data } = await RestService.get(url);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  /**
   * 시술명 검색
   * @return {Promise<any>}
   */
  public getProcedureSearch = async ({ keyword }): Promise<any> => {
    const url: string = `${PREFIX_URL}/search?keyword=${keyword}`;

    try {
      const { data } = await RestService.get(url);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

export default new SkinPlanService();
