import {
  SurgeryListScreen,
  SurgeryDetailScreen,
  SurgeryComparisonScreen,
} from "@src/screens";

/**
 * Surgery Configs
 */

export default [
  {
    name: "surgeryList",
    component: SurgeryListScreen,
    options: {
      title: "",
    },
  },
  {
    name: "surgeryDetail",
    component: SurgeryDetailScreen,
    options: {
      title: "",
    },
  },
  {
    name: "comparison",
    component: SurgeryComparisonScreen,
    options: {
      title: "",
    },
  },
];
