import styled from "styled-components/native";
import { StyleSheet } from "react-native";
import MediaStyle from "react-native-media-query";
import { Color } from "@src/constants";

export const ContentsWrap = styled.SafeAreaView`
  flex: 1;
  background-color: ${Color.WHITE};
`;

export const ProfileHeader = styled.View`
  padding: 30px 0 40px 0;
  align-items: center;
`;

export const ProfileContents = styled.View`
  margin-bottom: 16px;
  position: relative;
`;

export const ProfileImage = styled.Image`
  width: 100px;
  height: 100px;
  border-radius: 50%;
`;

export const ProfileSetting = styled.TouchableOpacity`
  position: absolute;
  right: 0;
  bottom: 0;
`;

export const ProfileMbtiInner = styled.View``;

export const ProfileMbti = styled.View`
  height: 20px;
  padding: 1px 14px 2px 14px;
  border-radius: 10px;
  background-color: ${Color.PUPPLE};
`;

export const ProfileNickname = styled.Text`
  margin-top: 8px;
  font-size: 20px;
  font-family: "fontB";
`;

export const itemStyles = StyleSheet.create({
  whiteBg: {
    backgroundColor: Color.WHITE,
  },
  xxsText: {
    fontSize: 12,
    fontFamily: "fontB",
  },
  smText: {
    fontSize: 14,
  },
  mdText: {
    lineHeight: 23,
    fontSize: 16,
  },
  mdTextB: {
    fontSize: 16,
    fontFamily: "fontB",
  },
  mLText: {
    fontSize: 18,
    fontFamily: "fontB",
  },
  lgText: {
    lineHeight: 33,
    fontSize: 24,
    fontFamily: "fontB",
  },
  highlightText: {
    fontFamily: "fontB",
    color: Color.PUPPLE,
  },
  marginTop10: {
    marginTop: 10,
  },
  marginTop20: {
    marginTop: 20,
  },
  alignCenter: {
    alignItems: "center",
  },
  spaceBetween: {
    justifyContent: "space-between",
  },
  grayText: {
    marginBottom: 10,
    fontSize: 14,
    fontFamily: "fontM",
    color: Color.LIGHT_GREY_TWO,
    textAlign: "center",
  },
  tab: {
    minWidth: 360,
    maxWidth: 480,
    backgroundColor: Color.WHITE,
  },
  tabLabel: {
    fontSize: 16,
    fontFamily: "fontM",
    color: Color.BLACK_TWO,
  },
  whiteText: {
    color: Color.WHITE,
  },
  puppleText: {
    color: Color.PUPPLE,
  },
  icon: {
    width: 28,
    height: 28,
  },
  fileInput: {
    position: "absolute",
    width: 28,
    height: 28,
    right: 0,
    bottom: 0,
    opacity: 0,
    padding: 0,
    margin: -1,
    overflow: "hidden",
    cursor: "pointer",
  },
});

export const { ids, styles, fullStyles } = MediaStyle.create({});
