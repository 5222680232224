import React, { ReactElement } from "react";
import { ScrollView, StatusBar, View } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { Helmet } from "react-helmet-async";
import { Layouts } from "@src/components/container";
import {
  MypageCommonBox,
  MypageCommonBoxInfo,
  MypageCommonBoxInner,
  MypageCommonBoxTitle,
  MypageCommonInfoText,
  MypageCommonTitle,
  MypageCommonTitleText,
  MypageCommonTopContainer,
  PolicyContents,
  styles,
} from "./styled";
import { BottomFixButton } from "@src/components/presentational";
import { globalStyles } from "@src/styles/modules/global";

/**
 * 서비스 이용약관
 */

const ServicePolicyScreen = (): ReactElement => {
  const navigation = useNavigation<any>();

  return (
    <>
      <StatusBar barStyle="light-content" backgroundColor="#6432e9" />
      <Helmet>
        <title>서비스 이용약관 | 촉촉박사</title>
        <meta
          name="description"
          content="촉촉한 피부, 나만 모르고 있던 시술 정보가 궁금하다면? | 촉촉박사"
          data-react-helmet="true"
        />
      </Helmet>
      <Layouts logo transparent hamburgerMode>
        <ScrollView
          bounces={false}
          scrollEventThrottle={1}
          showsVerticalScrollIndicator={false}
          style={{ backgroundColor: "#ffffff" }}
        >
          <MypageCommonTopContainer>
            <MypageCommonTitle>
              <MypageCommonTitleText>서비스 이용약관</MypageCommonTitleText>
            </MypageCommonTitle>
            <MypageCommonInfoText>
              촉촉박사와 관련된 서비스의 이용에 대한 규정을 확인할 수 있어요.
            </MypageCommonInfoText>
          </MypageCommonTopContainer>
          <PolicyContents>
            <MypageCommonBox>
              <MypageCommonBoxInner>
                <MypageCommonBoxTitle style={globalStyles.fontB}>
                  제1조(목적)
                </MypageCommonBoxTitle>
                <MypageCommonBoxInfo>
                  본 약관은 주식회사 에이아이투유(이하 “회사” 및
                  “에이아이투유”이라 함)가 제공하는 스킨닥터(스닥) 서비스(이하
                  “서비스” 및 “스닥”이라 함)의 이용과 관련하여 “회사”와
                  “회원”과의 권리, 의무 및 책임사항, 데이터 제공 및 활용,
                  이용조건, 절차 등 기본적인 사항을 규정함을 목적으로 합니다.
                </MypageCommonBoxInfo>
                <MypageCommonBoxTitle style={globalStyles.fontB}>
                  제2조(용어의 정의)
                </MypageCommonBoxTitle>
                <MypageCommonBoxInfo>
                  본 약관에서 사용하는 용어의 정의는 다음과 같습니다. <br />
                  (1) “서비스”는 “서비스”가 제공될 수 있는 단말기(태블릿,
                  휴대전화 등)와 상관없이 “회원”이 이용할 수 있는 주식회사
                  에이아이투유가 제공하는 서비스를 의미합니다. <br />
                  (2) “회원”은 “회사”의 “서비스”에 접속하여 본 약관에 따라
                  “회사”와 “이용계약”을 체결하고 “회사”가
                </MypageCommonBoxInfo>
                <MypageCommonBoxTitle style={globalStyles.fontB}>
                  제3조(목적)
                </MypageCommonBoxTitle>
                <MypageCommonBoxInfo>
                  본 약관은 주식회사 에이아이투유(이하 “회사” 및
                  “에이아이투유”이라 함)가 제공하는 스킨닥터(스닥) 서비스(이하
                  “서비스” 및 “스닥”이라 함)의 이용과 관련하여 “회사”와
                  “회원”과의 권리, 의무 및 책임사항, 데이터 제공 및 활용,
                  이용조건, 절차 등 기본적인 사항을 규정함을 목적으로 합니다.
                </MypageCommonBoxInfo>
                <MypageCommonBoxTitle style={globalStyles.fontB}>
                  제4조(목적)
                </MypageCommonBoxTitle>
                <MypageCommonBoxInfo>
                  본 약관은 주식회사 에이아이투유(이하 “회사” 및
                  “에이아이투유”이라 함)가 제공하는 스킨닥터(스닥) 서비스(이하
                  “서비스” 및 “스닥”이라 함)의 이용과 관련하여 “회사”와
                  “회원”과의 권리, 의무 및 책임사항, 데이터 제공 및 활용,
                  이용조건, 절차 등 기본적인 사항을 규정함을 목적으로 합니다.
                </MypageCommonBoxInfo>
                <MypageCommonBoxTitle style={globalStyles.fontB}>
                  제5조(목적)
                </MypageCommonBoxTitle>
                <MypageCommonBoxInfo>
                  본 약관은 주식회사 에이아이투유(이하 “회사” 및
                  “에이아이투유”이라 함)가 제공하는 스킨닥터(스닥) 서비스(이하
                  “서비스” 및 “스닥”이라 함)의 이용과 관련하여 “회사”와
                  “회원”과의 권리, 의무 및 책임사항, 데이터 제공 및 활용,
                  이용조건, 절차 등 기본적인 사항을 규정함을 목적으로 합니다.
                </MypageCommonBoxInfo>
                <MypageCommonBoxTitle style={globalStyles.fontB}>
                  제6조(목적)
                </MypageCommonBoxTitle>
                <MypageCommonBoxInfo>
                  본 약관은 주식회사 에이아이투유(이하 “회사” 및
                  “에이아이투유”이라 함)가 제공하는 스킨닥터(스닥) 서비스(이하
                  “서비스” 및 “스닥”이라 함)의 이용과 관련하여 “회사”와
                  “회원”과의 권리, 의무 및 책임사항, 데이터 제공 및 활용,
                  이용조건, 절차 등 기본적인 사항을 규정함을 목적으로 합니다.
                </MypageCommonBoxInfo>
                <MypageCommonBoxTitle style={globalStyles.fontB}>
                  제7조(목적)
                </MypageCommonBoxTitle>
                <MypageCommonBoxInfo>
                  본 약관은 주식회사 에이아이투유(이하 “회사” 및
                  “에이아이투유”이라 함)가 제공하는 스킨닥터(스닥) 서비스(이하
                  “서비스” 및 “스닥”이라 함)의 이용과 관련하여 “회사”와
                  “회원”과의 권리, 의무 및 책임사항, 데이터 제공 및 활용,
                  이용조건, 절차 등 기본적인 사항을 규정함을 목적으로 합니다.
                </MypageCommonBoxInfo>
                <MypageCommonBoxTitle style={globalStyles.fontB}>
                  제8조(목적)
                </MypageCommonBoxTitle>
                <MypageCommonBoxInfo>
                  본 약관은 주식회사 에이아이투유(이하 “회사” 및
                  “에이아이투유”이라 함)가 제공하는 스킨닥터(스닥) 서비스(이하
                  “서비스” 및 “스닥”이라 함)의 이용과 관련하여 “회사”와
                  “회원”과의 권리, 의무 및 책임사항, 데이터 제공 및 활용,
                  이용조건, 절차 등 기본적인 사항을 규정함을 목적으로 합니다.
                </MypageCommonBoxInfo>
              </MypageCommonBoxInner>
            </MypageCommonBox>
          </PolicyContents>
        </ScrollView>
        <View style={styles.bottomBtn}>
          <BottomFixButton
            fullBtnMode
            fullBtnText="확인"
            whiteBg
            onPressFullBtn={() =>
              navigation.canGoBack()
                ? navigation.goBack()
                : navigation.navigate("myinfo")
            }
          />
        </View>
      </Layouts>
    </>
  );
};

export default ServicePolicyScreen;
