import React, { ReactElement } from "react";
import { Image } from "react-native";
import {
  BottomFixContentsWrap,
  BottomFixContentsInner,
  BottomFixButtonContents,
  styles,
  LeftButton,
  LeftButtonText,
  RightButton,
  RightButtonText,
  Bar,
  ButtonContents,
} from "./styled";
import searchIcon from "@assets/icons/icon-search.svg";
import editIcon from "@assets/icons/icon-edit.svg";
import imageIcon from "@assets/icons/icon-image.svg";
import playIcon from "@assets/icons/icon-play.svg";

interface BottomFullButtonProps {
  onPressLeftBtn?: () => void;
  onPressRightBtn?: () => void;
  leftBtnText?: string;
  rightBtnText?: string;
  editor?: boolean;
  page?: string;
}

const BottomFullButton = ({
  onPressLeftBtn,
  onPressRightBtn,
  leftBtnText,
  rightBtnText,
  editor,
  page,
}: BottomFullButtonProps): ReactElement => {
  return (
    <BottomFixContentsWrap editor={editor}>
      <BottomFixContentsInner>
        <BottomFixButtonContents page={page}>
          <ButtonContents>
            <LeftButton
              style={editor ? styles.btnAlign : styles.button}
              onPress={onPressLeftBtn}
              activeOpacity={1}
            >
              <Image
                source={editor ? imageIcon : searchIcon}
                resizeMode="contain"
                style={styles.icon}
              />
              <LeftButtonText editor={editor}>{leftBtnText}</LeftButtonText>
            </LeftButton>
          </ButtonContents>
          {editor && <Bar />}

          <ButtonContents>
            <RightButton
              style={editor ? styles.btnAlign : styles.button}
              onPress={onPressRightBtn}
              activeOpacity={1}
            >
              <Image
                source={editor ? playIcon : editIcon}
                resizeMode="contain"
                style={styles.icon}
              />
              <RightButtonText editor={editor}>{rightBtnText}</RightButtonText>
            </RightButton>
          </ButtonContents>
        </BottomFixButtonContents>
      </BottomFixContentsInner>
    </BottomFixContentsWrap>
  );
};

export default BottomFullButton;
