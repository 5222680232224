import moment from "moment";

export const TIMEZONE_OFFSET = moment().utcOffset();
export const DATE_FORMAT = "YY.MM.DD";
export const DATETIME_FORMAT = "YYYY-MM-DD HH:mm";

/**
 * UTC 시간을 접속국가 기준 시간으로 변경
 * @param {String} utcTime
 */
export const parseUTCToLocalDatetime = (utcTime, format): string => {
  if (!utcTime) {
    return "-";
  }

  if (format) {
    return moment(utcTime)
      .utcOffset(TIMEZONE_OFFSET.toString())
      .format(format)
      .toString();
  }
  return moment(utcTime).utcOffset(TIMEZONE_OFFSET.toString()).toString();
};

export const dateFormat = (date, format): string => moment(date).format(format);
