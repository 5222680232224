import React, { ReactElement } from "react";
import { FlatList, ScrollView, StatusBar, View } from "react-native";
import { Helmet } from "react-helmet-async";
import { useNavigation } from "@react-navigation/native";
import {
  MenuList,
  MenuListItem,
  MenuListItemText,
  MenuMoveIcon,
  MenuSnsIcon,
  MyInfoContents,
  MypageCommonContents,
  MypageCommonInfoText,
  MypageCommonTitle,
  MypageCommonTitleText,
  MypageCommonTopContainer,
  styles,
} from "./styled";
import { Layouts } from "@src/components/container";
import { BottomFixButton } from "@src/components/presentational";
import { myinfoList } from "../sampleDatas";
import nextIcon from "@assets/icons/icon-next.svg";
import { useAuth } from "@src/hooks/auth";
import { globalStyles } from "@src/styles/modules/global";

/**
 * 내 정보 확인
 */

const MyInfoScreen = (): ReactElement => {
  const navigation = useNavigation<any>();

  const { user } = useAuth();

  return (
    <>
      <StatusBar barStyle="light-content" backgroundColor="#6432e9" />
      <Helmet>
        <title>내 정보 확인 | 촉촉박사</title>
        <meta
          name="description"
          content="촉촉한 피부, 나만 모르고 있던 시술 정보가 궁금하다면? | 촉촉박사"
          data-react-helmet="true"
        />
      </Helmet>
      <Layouts logo transparent hamburgerMode>
        <MypageCommonTopContainer>
          <MypageCommonTitle>
            <MypageCommonTitleText>내 정보 확인</MypageCommonTitleText>
          </MypageCommonTitle>
          <MypageCommonInfoText>
            현재 로그인한 계정에 대한 <br />
            SNS 연동 정보를 확인할 수 있어요.
          </MypageCommonInfoText>
        </MypageCommonTopContainer>
        <ScrollView
          bounces={false}
          scrollEventThrottle={1}
          contentContainerStyle={{ flexGrow: 1, flex: 1 }}
          showsVerticalScrollIndicator={false}
        >
          <MypageCommonContents>
            <View style={styles.infoContents}>
              <FlatList
                data={myinfoList}
                contentContainerStyle={globalStyles.listPaddingS}
                showsVerticalScrollIndicator={false}
                renderItem={({ index, item }) => {
                  return (
                    <MyInfoContents>
                      {item.icon && (
                        <MenuListItem>
                          <MenuListItemText>{item.name}</MenuListItemText>
                          <MenuSnsIcon
                            resizeMode="contain"
                            source={item.icon}
                          />
                        </MenuListItem>
                      )}
                      {item.type && (
                        <MenuListItem>
                          <MenuListItemText>{item.name}</MenuListItemText>
                          <MenuListItemText>{user?.email}</MenuListItemText>
                        </MenuListItem>
                      )}
                      {item.url && (
                        <MenuList
                          onPress={() => navigation.push(item.url)}
                          underlayColor="transparent"
                          link={item.url}
                        >
                          <MenuListItem>
                            <MenuListItemText>{item.name}</MenuListItemText>
                            {item.url && (
                              <MenuMoveIcon
                                resizeMode="contain"
                                source={nextIcon}
                              />
                            )}
                          </MenuListItem>
                        </MenuList>
                      )}
                    </MyInfoContents>
                  );
                }}
              />
            </View>
          </MypageCommonContents>
        </ScrollView>
        <View style={styles.bottomBtn}>
          <BottomFixButton
            fullBtnMode
            whiteBtn
            whiteBg
            fullBtnText="뒤로 가기"
            onPressFullBtn={() => navigation.navigate("mypage")}
          />
        </View>
      </Layouts>
    </>
  );
};

export default MyInfoScreen;
