import { Color } from "@src/constants";
import styled from "styled-components/native";
import { StyleSheet } from "react-native";
import MediaStyle from "react-native-media-query";

export const MyPlanContainer = styled.View``;

export const MyPlanListContents = styled.View`
  padding: 16px;
  border-radius: 16px;
  box-shadow: rgba(100, 50, 233, 0.08) 0 0 16px;
  margin-bottom: 20px;
  background-color: ${Color.WHITE};
`;

export const MyPlanListItem = styled.View``;

export const ListItemHeader = styled.View`
  flex-direction: row;
  justify-content: space-between;
`;

export const DeleteBtn = styled.TouchableOpacity``;

export const Category = styled.View`
  height: 24px;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  padding: 1px 13px 3px 13px;
  background-color: ${({ type }: { type?: string }) =>
    (type === "진행중" && Color.PUPPLE) ||
    (type === "시작 전" && "#ee80a6") ||
    (type === "완료" && Color.BLACK_TWO)};
`;

export const ListName = styled.View`
  margin-top: 10px;
`;

export const Summary = styled.View`
  position: relative;
  margin: 20px 0 8px 0;
  padding: 16px 16px 12px 16px;
  border-radius: 16px;
  background-color: ${Color.GREY};
`;

export const SummaryItem = styled.View`
  position: relative;
`;

export const SummaryItemInner = styled.View`
  flex-direction: row;
  margin-bottom: 12px;
`;

export const SummaryItemTitleContents = styled.View`
  width: 87px;
  flex-direction: row;
  align-items: center;
`;

export const SummaryItemTitle = styled.Text`
  margin-right: 4px;
  font-size: 14px;
  font-family: "fontB";
`;

export const SummaryItemInfo = styled.View`
  width: calc(100% - 87px);
  flex-direction: row;
`;

export const SummaryItemInfoText = styled.Text`
  font-size: ${({ smText }: { smText?: boolean }) =>
    smText ? "14px" : "16px"};
  font-family: ${({ smText }: { smText?: boolean }) =>
    smText ? "fontM" : "fontB"};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const SummaryItemScope = styled.View``;

export const SummaryMore = styled.View`
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

export const SummaryMoreBtn = styled.TouchableOpacity`
  flex-direction: row;
  align-items: center;
`;

export const SummaryMoreText = styled.Text`
  font-size: 14px;
  font-family: "fontM";
  color: ${Color.LIGHT_GREY_FOUR};
`;

export const SummaryTootip = styled.View`
  position: absolute;
`;

export const MoreContents = styled.View`
  width: 100%;
  align-items: center;
`;

export const MoreBtn = styled.TouchableOpacity`
  width: fit-content;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const itemStyles = StyleSheet.create({
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  whiteText: {
    color: Color.WHITE,
  },
  puppleText: {
    color: Color.PUPPLE,
  },
  xxsText: {
    fontSize: 10,
    fontWeight: "500",
  },
  xsText: {
    fontSize: 12,
    fontWeight: "500",
  },
  sText: {
    fontSize: 13,
    fontWeight: "500",
  },
  smText: {
    fontSize: 14,
    fontWeight: "500",
  },
  mdText: {
    fontSize: 16,
    fontWeight: "bold",
  },
  lgText: {
    fontSize: 20,
    fontWeight: "bold",
  },
  xlText: {
    fontSize: 24,
    fontWeight: "bold",
  },
  lineHeight: {
    lineHeight: 20,
  },
  pupple: {
    color: Color.PUPPLE,
  },
  bold: {
    fontWeight: "bold",
  },
  grayText: {
    color: Color.LIGHT_GREY_TWO,
  },
  flexRow: {
    flexDirection: "row",
  },
  alignCenter: {
    alignItems: "center",
  },
  icon: {
    width: 20,
    height: 20,
  },
  incompleteIcon: {
    width: 70,
    height: 20,
    marginLeft: 4,
  },
  deleteIcon: {
    width: 24,
    height: 24,
  },
  moreText: {
    fontSize: 14,
    fontWeight: "500",
    color: Color.LIGHT_GREY_FOUR,
  },
  redColor: {
    color: "#ff7473",
  },
});

export const { ids, styles } = MediaStyle.create({});
