import { Color } from "@src/constants";
import styled from "styled-components/native";
import { StyleSheet } from "react-native";
import MediaStyle from "react-native-media-query";

export const ContentsWrap = styled.View``;

export const CommunityListContainer = styled.View`
  min-height: 124px;
  max-height: 144px;
  padding: 16px 20px;
`;

export const CommunityListInner = styled.TouchableOpacity`
  flex-direction: row;
`;

export const Contents = styled.View`
  width: ${({ imgMode }: { imgMode?: string }) =>
    imgMode !== null ? "calc(100% - 76px)" : "100%"};
`;

export const AdminTag = styled.View`
  flex-direction: row;
  margin-right: 8px;
`;

export const Tag = styled.View`
  margin-right: 8px;
`;

export const Category = styled.View`
  width: fit-content;
  height: 18px;
  padding: 2px 8px;
  border-radius: 9px;
  background-color: ${Color.LIGHT_PUPPLE};
`;

export const CategoryText = styled.Text`
  font-size: 10px;
  font-family: "fontM";
  color: ${Color.PUPPLE};
`;

export const Title = styled.View`
  margin-top: 5px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const TitleText = styled.Text`
  width: calc(100% - 52px);
  text-overflow: ellipsis;
  max-height: 48px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 16px;
  font-family: "fontM";
  line-height: 24px;
  font-family: "fontM";
`;

export const Comment = styled.View`
  position: absolute;
  right: 0;
  top: 25px;
`;

export const CommentImage = styled.Image`
  width: 36px;
  height: 36px;
  position: relative;
`;

export const CommentText = styled.Text`
  position: absolute;
  width: 36px;
  height: 36px;
  align-items: center;
  justify-content: center;
  display: flex;
  text-align: center;
  font-size: 12px;
  color: ${Color.LIGHT_GREY_TWO};
`;

export const Info = styled.View`
  flex-direction: row;
  margin-top: 5px;
  width: ${({ imgMode }: { imgMode?: string }) =>
    imgMode !== null ? "calc(100% - 128px)" : "100%"};
  flex-wrap: wrap;
`;

export const CountText = styled.Text`
  margin: 0 8px;
`;

export const NicknameText = styled.Text`
  margin-right: 8px;
`;

export const ImgContents = styled.View`
  min-height: 88px;
  max-height: 108px;
  margin-right: 16px;
`;

export const LikeContents = styled.View`
  flex-direction: row;
  align-items: center;
`;

export const LikeImage = styled.Image`
  margin-right: 2px;
  width: 14px;
  height: 14px;
`;

export const SelectInputWrap = styled.View`
  padding: 20px 0 0 20px;
  margin-bottom: 10px;
`;

export const SelectInputBtn = styled.TouchableOpacity`
  flex-direction: row;
  align-items: center;
`;

export const SelectInputBtnText = styled.Text`
  font-size: 14px;
  font-family: "fontM";
`;

export const MoreContents = styled.View`
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 56px;
`;

export const MoreBtn = styled.TouchableOpacity`
  width: fit-content;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const NoData = styled.View`
  width: 100%;
  align-items: center;
`;

export const itemStyles = StyleSheet.create({
  title: {
    fontSize: 20,
    fontFamily: "fontB",
  },
  whiteText: {
    color: Color.WHITE,
  },
  puppleText: {
    color: Color.PUPPLE,
  },
  xxsText: {
    fontSize: 10,
    fontFamily: "fontM",
  },
  xsText: {
    fontSize: 12,
    fontFamily: "fontR",
  },
  sText: {
    fontSize: 13,
    fontFamily: "fontM",
  },
  smText: {
    fontSize: 14,
    fontFamily: "fontM",
  },
  mdText: {
    fontSize: 16,
    fontFamily: "fontB",
  },
  lgText: {
    fontSize: 20,
    fontFamily: "fontB",
  },
  xlText: {
    fontSize: 24,
    fontFamily: "fontB",
  },
  lineHeight: {
    lineHeight: 20,
  },
  pupple: {
    color: Color.PUPPLE,
  },
  bold: {
    fontFamily: "fontB",
  },
  gray: {
    color: Color.LIGHT_GREY_TWO,
  },
  gray2: {
    color: Color.LIGHT_GREY_FOUR,
  },
  flexRow: {
    flexDirection: "row",
  },
  alignCenter: {
    alignItems: "center",
  },
  image: {
    width: 60,
    height: 60,
    borderRadius: 8,
  },
  nickname: {
    marginBottom: 4,
  },
  icon: {
    width: 24,
    height: 24,
  },
  list: {
    flex: 1,
    marginTop: 54,
  },
  moreText: {
    fontSize: 14,
    fontFamily: "fontM",
    color: Color.LIGHT_GREY_FOUR,
  },
  noticeIcon: {
    width: 52,
    height: 18,
  },
  magazineIcon: {
    width: 60,
    height: 18,
  },
});

export const { ids, styles } = MediaStyle.create({});
