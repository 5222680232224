import { Color } from "@src/constants";
import styled from "styled-components/native";
import { StyleSheet } from "react-native";
import MediaStyle from "react-native-media-query";

export const Dimmed = styled.View`
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  position: ${({ isOpen }: { isOpen: boolean }) => (isOpen ? "fixed" : "")};
  background-color: ${({ isOpen }: { isOpen: boolean }) =>
    isOpen ? "rgba(0, 0, 0, 0.54)" : ""};
  z-index: ${({ isOpen }: { isOpen: boolean }) => (isOpen ? "1001" : "")};
`;

export const BottomPopupContainer = styled.View`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100%;
`;

export const BottomPopupContents = styled.View`
  width: 100%;
  padding: 16px 0 24px 0;
  align-items: center;
`;

export const BottomPopupItem = styled.TouchableOpacity`
  width: 100%;
  padding: 15px 0 17px 0;
  justify-content: center;
  align-items: center;
`;

export const BottomPopupItemText = styled.Text`
  font-size: 16px;
  font-family: "fontM";
`;

export const itemStyles = StyleSheet.create({});

export const { ids, styles } = MediaStyle.create({
  popup: {
    position: "absolute",
    width: "100%",
    maxWidth: 480,
    maxHeight: 376,
    marginInline: "calc(50vw - 240px)",
    backgroundColor: Color.WHITE,
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    bottom: 0,
    transition: "top 0.6s cubic-bezier(0.165, 0.84, 0.44, 1)",
    zIndex: 1001,
    "@media (max-width: 480px)": {
      marginInline: 0,
    },
  },
});
