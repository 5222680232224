import React, { ReactElement, useRef, useState } from "react";
import { Dimensions, ScrollView, StatusBar } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { Helmet } from "react-helmet-async";
import Toast from "react-native-fast-toast";
import { Layouts } from "@src/components/container";
import {
  CommonErrorMessage,
  MypageCommonContents,
  MypageCommonInfoText,
  MypageCommonInput,
  MypageCommonTitle,
  MypageCommonTitleText,
  MypageCommonTopContainer,
  NicknameBtn,
  NicknameBtnImg,
  NicknameBtnText,
} from "./styled";
import {
  ALERT_00_01,
  ALERT_00_02,
  ALERT_00_03,
  Color,
  REGEX_NICKNAME,
} from "@src/constants";
import { BottomFixButton } from "@src/components/presentational";
import { changeNicknameInput, nicknameSaveText } from "../sampleDatas";
import likeIcon from "@assets/icons/icon-like.svg";
import { globalStyles } from "@src/styles/modules/global";
import { MemberService } from "@src/services";
import { useRecoilState, useSetRecoilState } from "recoil";
import { CommunityProfile, tokenState } from "@src/store";
import { ProfileType } from "@src/types";

/**
 * 닉네임 변경
 */

const ChangeNicknameScreen = (): ReactElement => {
  const navigation = useNavigation<any>();

  const setToken = useSetRecoilState(tokenState);

  const { height } = Dimensions.get("window");
  const centerToastRef = useRef(null);

  const [nickname, setNickname] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>(null);
  const [myProfileData, setMyProfileData] =
    useRecoilState<ProfileType>(CommunityProfile);

  const handleSave = () => {
    if (!validate()) {
      return;
    }
    updateNickname();
    centerToastRef.current.show(nicknameSaveText);
  };

  const updateNickname = async () => {
    const conditions = {
      nickname: nickname,
    };

    try {
      const data = await MemberService.patchUserInfo(conditions);
      setToken(data.accessToken);
      setMyProfileData({
        ...myProfileData,
        nickname: nickname,
      });

      if (data) {
        setTimeout(() => {
          navigation.push("mypage");
        }, 1500);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRandomNickname = async () => {
    try {
      const data = await MemberService.getNickname();
      setNickname(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const validate = () => {
    if (!nickname) {
      setErrorMessage(ALERT_00_02);
      return false;
    }
    if (nickname.length === 1) {
      setErrorMessage(ALERT_00_01);
      return false;
    }
    if (nickname.length > 12) {
      setErrorMessage(ALERT_00_01);
      return false;
    }
    if (!REGEX_NICKNAME.test(nickname)) {
      setErrorMessage(ALERT_00_03);
      return false;
    }

    setErrorMessage(null);
    return true;
  };

  const handleCancel = () => {
    navigation.navigate("mypage");
  };

  return (
    <>
      <StatusBar barStyle="light-content" backgroundColor="#6432e9" />
      <Helmet>
        <title>닉네임 변경 | 촉촉박사</title>
        <meta
          name="description"
          content="촉촉한 피부, 나만 모르고 있던 시술 정보가 궁금하다면? | 촉촉박사"
          data-react-helmet="true"
        />
      </Helmet>
      <Layouts logo transparent hamburgerMode>
        <MypageCommonTopContainer>
          <MypageCommonTitle>
            <MypageCommonTitleText>닉네임을 입력하세요.</MypageCommonTitleText>
          </MypageCommonTitle>
          <MypageCommonInfoText>
            닉네임은 2~12글자까지 쓸 수 있고
            <br />
            한글, 영문, 숫자만 사용할 수 있어요.
          </MypageCommonInfoText>
        </MypageCommonTopContainer>
        <ScrollView
          bounces={false}
          scrollEventThrottle={1}
          contentContainerStyle={{ flexGrow: 1 }}
          showsVerticalScrollIndicator={false}
        >
          <MypageCommonContents>
            <MypageCommonInput
              value={nickname}
              placeholder={changeNicknameInput}
              placeholderTextColor={Color.LIGHT_GREY_FOUR}
              underlineColorAndroid="transparent"
              theme={{ underlineColor: "transparent" }}
              onChangeText={(value) => setNickname(value)}
              error={errorMessage}
            />
            {errorMessage && (
              <CommonErrorMessage>{errorMessage}</CommonErrorMessage>
            )}
            <NicknameBtn
              onPress={handleRandomNickname}
              underlayColor="transparent"
            >
              <NicknameBtnText>
                <NicknameBtnImg resizeMode="contain" source={likeIcon} />
                추천 닉네임 받기
              </NicknameBtnText>
            </NicknameBtn>
          </MypageCommonContents>
        </ScrollView>
        <BottomFixButton
          multipleBtnMode
          leftBtnText="취소하기"
          rightBtnText="변경하기"
          onPressLeftBtn={handleCancel}
          onPressRightBtn={handleSave}
        />
      </Layouts>
      <Toast
        ref={centerToastRef}
        offset={height / 2}
        duration={1000}
        style={globalStyles.centerToast}
        textStyle={globalStyles.centerToastText}
      />
    </>
  );
};

export default ChangeNicknameScreen;
