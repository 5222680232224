/* eslint-disable @typescript-eslint/no-explicit-any */
import RestService from "../RestService";

/**
 * MemberAPI
 * @see https://
 */
class MemberService {
  /**
   * 닉네임 변경
   * @return {Promise<any>}
   */
  public getNickname = async (): Promise<any> => {
    const url: string = "/v1/user/me/nickname";

    try {
      const data = await RestService.get(url);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  /**
   * 사용자 정보 업데이트
   * @return {Promise<any>}
   */
  public patchUserInfo = async (payload): Promise<any> => {
    const url: string = "/v1/user/me";

    try {
      const { data } = await RestService.patch(url, payload);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  /**
   * 사용자 탈퇴 신청/복원
   * @return {Promise<any>}
   */
  public deleteUser = async (type, payload): Promise<any> => {
    const url: string = `v1/user/me?req-type=${type}`;

    try {
      const data = await RestService.delete(url, payload);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

export default new MemberService();
