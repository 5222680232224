import { Color } from "@src/constants";
import styled from "styled-components/native";

export const TootipContainer = styled.View`
  width: 100px;
  height: 32px;
  z-index: 10;
`;

export const TootipContentsInner = styled.View`
  position: relative;
`;

export const TootipContentsImg = styled.Image`
  width: ${({
    small,
    large,
    xLarge,
  }: {
    small?: boolean;
    large?: boolean;
    xLarge?: boolean;
    xxLarge?: boolean;
  }) => (small ? "100px" : large ? "200px" : xLarge ? "200px" : "253px")};
  height: ${({
    small,
    xLarge,
    xxLarge,
  }: {
    xLarge?: boolean;
    xxLarge?: boolean;
    small?: boolean;
  }) => (small ? "32px" : xLarge || xxLarge ? "58px" : "32px")};
`;

export const TootipContentsTextWrap = styled.View`
  position: absolute;
  top: ${({ small, large }: { small?: boolean; large?: boolean }) =>
    small ? "4px" : large ? "2px" : "7px"};
  left: ${({ small, large }: { small?: boolean; large?: boolean }) =>
    small ? "32px" : large ? "11px" : "33px"};
`;

export const TootipContentsText = styled.Text`
  font-size: ${({
    small,
    large,
    xLarge,
  }: {
    small?: boolean;
    large?: boolean;
    xLarge?: boolean;
  }) => (small ? "10px" : large ? "12px" : xLarge && "12px")};
  font-family: "fontM";
  letter-spacing: -0.25px;
  color: ${Color.WHITE};
`;
