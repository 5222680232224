import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  StatusBar,
  FlatList,
  Image,
  Text,
  Dimensions,
  View,
} from "react-native";
import { Helmet } from "react-helmet-async";
import { useNavigation } from "@react-navigation/native";
import { useRoute } from "@react-navigation/native";
import Toast from "react-native-fast-toast";
import { Tabs } from "antd";

import { Layouts } from "@src/components/container";
import {
  BtnContents,
  CommunityKeywordDelete,
  CommunityKeywordItem,
  DeleteBtn,
  itemStyles,
  RecentSearchNoData,
  SearchBack,
  SearchBar,
  SearchBtn,
  SearchBtnIcon,
  SearchContainer,
  SearchInput,
  SearchInputWrap,
  SearchKeyword,
  SearchKeywordAllDelete,
  SearchKeywordContents,
  SearchKeywordItem,
  SearchKeywordItemText,
  SearchKeywordTitle,
  SearchKeywordTitleWrap,
  SearchNoData,
  SearchNoDataBtn,
  SearchNoDataBtnText,
  SearchNoDataInfo,
  SearchNoDataTitle,
  SearchTextItem,
  SearchTextItemText,
  SelectInputBtn,
  SelectInputBtnText,
  SelectInputWrap,
} from "./styled";
import {
  BottomSelectItem,
  CircleCheckbox,
  CommunityList,
  DialogPopup,
  Maybe,
  SwitchCase,
} from "@src/components/presentational";
import {
  communitySearchPlaceholder,
  keywordDeletePopupTitle,
  keywordToast,
  planSearchPlaceholder,
} from "@src/screens/sampleDatas";
import { Color } from "@src/constants";
import { globalStyles, ids, styles } from "@src/styles/modules/global";
import { useRecoilState } from "recoil";
import {
  CommunitySearchKeywordState,
  CommunitySearchListState,
  SkinPlanProcedureListData,
} from "@src/store";
import {
  CommunitySearchList,
  IForumPostList,
  SelectedKeyword,
  PostCategory,
} from "@src/types";
import { CommunityService, SkinPlanService } from "@src/services";
import searchIcon from "@assets/icons/icon-search.svg";
import backIcon from "@assets/icons/icon-back2.svg";
import cancelIcon from "@assets/icons/icon-cancel.svg";
import downIcon from "@assets/icons/icon-arrow-down.svg";
import { analytics } from "@src/hooks/analytics";

/**
 * 검색하기
 */

const GlobalSearchScreen = () => {
  const navigation = useNavigation<any>();
  const route = useRoute<any>();
  const type = route.params?.type;
  const plannerId = route.params?.id;
  const sortNo = Number(route.params?.sortNo);

  const { height } = Dimensions.get("window");
  const toastRef = useRef(null);

  // 커뮤니티
  const [data, setData] = useState<IForumPostList[]>();
  const [forumId, setForumId] = useState<number>(1);
  const [offset, setOffset] = useState<number>(0);
  const [postCategoryId, setPostCategoryId] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);

  // 커뮤니티
  const [categoryList, setCategoryList] = useState<PostCategory[]>();
  const [categoryTitle, setCategoryTitle] = useState<string>("");
  const [isVisiblePopup, setIsVisiblePopup] = useState<boolean>(false);
  const [last, setLast] = useState<boolean>(false);

  // 커뮤니티
  const [communityKeywordData, setCommunityKeywordData] = useRecoilState(
    CommunitySearchListState
  );
  const [communitySearchKeyword, setCommunitySearchKeyword] = useRecoilState(
    CommunitySearchKeywordState
  );
  const [communityKeyword, setCommunityKeyword] = useState<string>("");
  const [isVisibleDeletePopup, setIsVisibleDeletePopup] =
    useState<boolean>(false);
  const [showSearchList, setShowSearchList] = useState<boolean>(false);

  // 시술
  const [popularityKeywordData, setPopularityKeywordData] =
    useState<SelectedKeyword[]>();
  const [planItemDatas, setPlanItemDatas] = useRecoilState(
    SkinPlanProcedureListData
  );
  const [planKeyword, setPlanKeyword] = useState<string>();
  const [isNoPlanKeyword, setIsNoPlanKeyword] = useState<boolean>(false);
  const [keywordData, setKeywordData] = useState<any>([]);
  const [checkedId, setCheckedId] = useState<any>([]);

  const [selectedKeywordItem, setSelectedKeywordItem] = useState<any>([]);
  const [noData, setNoData] = useState<boolean>(false);

  const getForumPostList = async (keyword): Promise<void> => {
    try {
      const conditions = {
        forumId,
        postCategoryId,
        keyword: communitySearchKeyword ? communitySearchKeyword : keyword,
        offset,
      };

      const data = await CommunityService.getForumPostList(conditions);
      if (offset > 0) {
        setData((prev) => prev.concat(data.content));
      } else {
        setData(data?.content);
      }

      setLast(data?.last);
      setTotalCount(data?.totalElements);
    } catch (error) {
      console.log(error);
    }
  };

  const getForumCategory = async (): Promise<void> => {
    try {
      const conditions = {
        forumId,
      };

      const data = await CommunityService.getForumCategory(conditions);
      setCategoryList(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getPopularityKeyword = async (): Promise<void> => {
    try {
      const conditions = {
        type: "POPULARITY",
      };

      const data = await SkinPlanService.getPopularityKeyword(conditions);
      setPopularityKeywordData(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getProcedureSearch = async (keyword): Promise<void> => {
    try {
      const conditions = {
        keyword: keyword,
      };

      const data = await SkinPlanService.getProcedureSearch(conditions);
      setKeywordData(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearchValue = (value) => {
    setPlanKeyword(value);
    if (value) {
      getProcedureSearch(value);
      setIsNoPlanKeyword(true);
    } else {
      setIsNoPlanKeyword(false);
    }
  };

  const handleSearchValueSubmit = useCallback(() => {
    analytics("Skin Plan Search Keyword", { keyword: planKeyword });

    if (!keywordData) {
      setNoData(true);
    } else {
      setNoData(false);
    }
  }, [keywordData]);

  const handleCommunitySearchValue = (value) => {
    setCommunityKeyword(value);
  };

  const handleCommunitySearchSubmit = () => {
    if (communityKeyword.length < 2) {
      toastRef.current.show(keywordToast);
    } else {
      analytics("Community Search Keyword", { keyword: communityKeyword });
      setCommunitySearchKeyword(communityKeyword);
      setCommunityKeywordData((list: CommunitySearchList[]) => [
        { text: communityKeyword },
        ...list,
      ]);
    }

    setShowSearchList(true);
  };

  const handleSearchValueClear = () => {
    setPlanKeyword("");
    setKeywordData([]);
    setCommunityKeyword("");
  };

  const onClickkeywordItem = (id, value, price) => {
    setSelectedKeywordItem(id);
    setCheckedId(id);

    if (type === "planAdd") {
      setPlanItemDatas((prev) => [
        ...prev,
        {
          plannerItemId: null,
          dermatologyProcedureName: value,
          dermatologyProcedureId: id,
          sortNo: sortNo + 1,
          actualPrice: 0,
          priceForPlanner: price,
          isCompleted: false,
        },
      ]);
    } else {
      let datas = [];
      datas.push(
        {
          plannerItemId: null,
          dermatologyProcedureName: value,
          dermatologyProcedureId: id,
          sortNo: sortNo,
          actualPrice: 0,
          priceForPlanner: price,
          isCompleted: false,
        },
        ...planItemDatas
      );

      const newArray = datas.reduce(function (acc, current) {
        if (acc.findIndex(({ sortNo }) => sortNo === current.sortNo) === -1) {
          acc.push(current);
        }
        return acc;
      }, []);

      const newArraySort = newArray.sort((a, b) => a.sortNo - b.sortNo);
      setPlanItemDatas(newArraySort);
    }

    navigation.push("mySkinPlanDetail", {
      id: plannerId,
      pageId: 1,
      sortNo: sortNo,
      newArray: 1,
    });
  };

  const onClickSearchKeyword = () => {
    if (type === "planAdd") {
      setPlanItemDatas((prev) => [
        ...prev,
        {
          plannerItemId: null,
          dermatologyProcedureName: planKeyword,
          dermatologyProcedureId: null,
          sortNo: sortNo + 1,
          actualPrice: 0,
          isCompleted: false,
        },
      ]);
    } else {
      let datas = [];
      datas.push(
        {
          plannerItemId: null,
          dermatologyProcedureName: planKeyword,
          dermatologyProcedureId: null,
          sortNo: sortNo,
          actualPrice: 0,
          isCompleted: false,
        },
        ...planItemDatas
      );

      const newArray = datas.reduce(function (acc, current) {
        if (acc.findIndex(({ sortNo }) => sortNo === current.sortNo) === -1) {
          acc.push(current);
        }
        return acc;
      }, []);

      const newArraySort = newArray.sort((a, b) => a.sortNo - b.sortNo);
      setPlanItemDatas(newArraySort);
    }

    navigation.push("mySkinPlanDetail", {
      id: plannerId,
      pageId: 1,
      sortNo: sortNo,
      newArray: 1,
    });
  };

  const handleAllDeleteKeyword = () => {
    setCommunityKeywordData([]);
    setIsVisibleDeletePopup(false);
  };

  const handleDeleteKeywordItem = (text) => {
    const list = communityKeywordData.filter((item) => item.text !== text);
    setCommunityKeywordData(list);
  };

  const handleShowSearchList = (keyword) => {
    setShowSearchList(true);
    setCommunitySearchKeyword(keyword);
  };

  const handleChangeTabId = (id) => {
    setForumId(Number(id));
    setPostCategoryId(0);
    setCategoryTitle("");
  };

  const handleCategory = (item) => {
    setCategoryTitle(item.title);
    setPostCategoryId(item.id);
  };

  const handleOffset = () => {
    setOffset((prev) => prev + 1);
  };

  useEffect(() => {
    if (type === "community") {
      getForumCategory();
    }
  }, [type, forumId]);

  useEffect(() => {
    if (type === "community") {
      getForumPostList(communityKeyword);
    }
  }, [type, forumId, postCategoryId, communityKeyword, offset]);

  useEffect(() => {
    if (type === "community") {
      getForumPostList(communitySearchKeyword);
    }
  }, [type, communitySearchKeyword]);

  useEffect(() => {
    if (type !== "community") {
      getPopularityKeyword();
    }
  }, [type]);

  useEffect(() => {
    setKeywordData([]);
  }, []);

  const renderCateogry = () => {
    return (
      <SelectInputWrap>
        <SelectInputBtn
          onPress={() => setIsVisiblePopup(!isVisiblePopup)}
          activeOpacity={1}
        >
          <SelectInputBtnText>
            {categoryTitle ? categoryTitle : "말머리 선택"}
          </SelectInputBtnText>
          <Image
            source={downIcon}
            resizeMode="contain"
            style={itemStyles.icon}
          />
        </SelectInputBtn>
      </SelectInputWrap>
    );
  };

  const tabs = [
    {
      key: "1",
      label: "촉촉토크",
      children: (
        <View
          dataSet={{ media: ids.paddingxL }}
          style={[{ flex: 1 }, styles.paddingxL]}
        >
          {renderCateogry()}
          {data && (
            <SwitchCase
              tests={[
                {
                  test: data.length === 0,
                  component: (
                    <RecentSearchNoData>
                      <Text>검색된 토크가 없어요.</Text>
                    </RecentSearchNoData>
                  ),
                },
              ]}
              defaultComponent={
                <View style={itemStyles.communitySearch}>
                  <CommunityList
                    data={data}
                    onClickMore={handleOffset}
                    totalCount={totalCount}
                    last={last}
                  />
                </View>
              }
            />
          )}
        </View>
      ),
    },
    {
      key: "2",
      label: "MBTI 토크",
      children: (
        <View
          dataSet={{ media: ids.paddingxL }}
          style={[{ flex: 1 }, styles.paddingxL]}
        >
          {renderCateogry()}
          {data && (
            <SwitchCase
              tests={[
                {
                  test: data.length === 0,
                  component: (
                    <RecentSearchNoData>
                      <Text>검색된 토크가 없어요.</Text>
                    </RecentSearchNoData>
                  ),
                },
              ]}
              defaultComponent={
                <View style={itemStyles.communitySearch}>
                  <CommunityList
                    data={data}
                    onClickMore={handleOffset}
                    totalCount={totalCount}
                    last={last}
                  />
                </View>
              }
            />
          )}
        </View>
      ),
    },
  ];

  const renderKeywordItem = ({ item }) => {
    return (
      <SearchKeywordItem
        onPress={() =>
          onClickkeywordItem(
            item.dermatologyProcedureId,
            item.dermatologyProcedureName,
            item.priceForPlanner
          )
        }
        isSelected={selectedKeywordItem === item.dermatologyProcedureId}
        activeOpacity={1}
      >
        <SearchKeywordItemText
          isSelected={selectedKeywordItem === item.dermatologyProcedureId}
        >
          {item.dermatologyProcedureName}
        </SearchKeywordItemText>
      </SearchKeywordItem>
    );
  };

  const renderSearchTextItem = ({ item }) => {
    return (
      <SearchKeywordContents>
        <SearchTextItem>
          <CircleCheckbox
            isChecked={checkedId === item.dermatologyProcedureId}
            onChangeCheck={() =>
              onClickkeywordItem(
                item.dermatologyProcedureId,
                item.dermatologyProcedureName,
                item.priceForPlanner
              )
            }
          />
          <SearchTextItemText>
            {item.dermatologyProcedureName}
          </SearchTextItemText>
        </SearchTextItem>
      </SearchKeywordContents>
    );
  };

  const renderSearchNoData = () => {
    return (
      <SearchNoData>
        <SearchNoDataTitle>앗! 검색 결과가 없어요</SearchNoDataTitle>
        <SearchNoDataInfo>
          검색하신{" "}
          <Text style={[itemStyles.puppleText, itemStyles.bold]}>
            {planKeyword}
          </Text>
          <br />
          그대로 시술을 등록해 드릴까요?
        </SearchNoDataInfo>
        <SearchNoDataBtn
          activeOpacity={1}
          style={globalStyles.fullBtn}
          onPress={onClickSearchKeyword}
        >
          <SearchNoDataBtnText style={itemStyles.mdText}>
            검색어로 시술 등록하기
          </SearchNoDataBtnText>
        </SearchNoDataBtn>
      </SearchNoData>
    );
  };

  const renderCommunityKeywordItem = ({ item, index }) => {
    return (
      <CommunityKeywordItem
        activeOpacity={1}
        onPress={() => handleShowSearchList(item.text)}
      >
        <SearchKeywordItemText isSelected={selectedKeywordItem === index}>
          {item.text}
        </SearchKeywordItemText>
        <CommunityKeywordDelete
          onPress={() => handleDeleteKeywordItem(item.text)}
        >
          <Image
            resizeMode="contain"
            source={cancelIcon}
            style={itemStyles.icon}
          />
        </CommunityKeywordDelete>
      </CommunityKeywordItem>
    );
  };

  return (
    <>
      <StatusBar barStyle="light-content" backgroundColor="#6432e9" />
      <Helmet>
        <title>검색 | 촉촉박사</title>
      </Helmet>
      <Layouts logo hamburgerMode transparent>
        <SearchContainer>
          <SearchBar>
            <SearchBack onPress={() => navigation.goBack()}>
              <Image
                source={backIcon}
                resizeMode="contain"
                style={itemStyles.icon}
              />
            </SearchBack>
            <SearchInputWrap>
              <SearchInput
                value={
                  type === "community" ? communityKeyword : planKeyword || ""
                }
                onChangeText={(value) =>
                  type === "community"
                    ? handleCommunitySearchValue(value)
                    : handleSearchValue(value)
                }
                onSubmitEditing={
                  type === "community"
                    ? handleCommunitySearchSubmit
                    : handleSearchValueSubmit
                }
                placeholder={
                  type === "community"
                    ? communitySearchPlaceholder
                    : planSearchPlaceholder
                }
                placeholderTextColor={Color.LIGHT_GREY_FOUR}
                maxLength={10}
              />
              <BtnContents>
                {communityKeyword || planKeyword ? (
                  <DeleteBtn onPress={handleSearchValueClear} activeOpacity={1}>
                    <Image
                      resizeMode="contain"
                      source={cancelIcon}
                      style={itemStyles.icon}
                    />
                  </DeleteBtn>
                ) : (
                  <></>
                )}
                <SearchBtn
                  onPress={
                    type === "community"
                      ? handleCommunitySearchSubmit
                      : handleSearchValueSubmit
                  }
                  activeOpacity={1}
                >
                  <SearchBtnIcon>
                    <Image
                      resizeMode="contain"
                      source={searchIcon}
                      style={itemStyles.icon}
                    />
                  </SearchBtnIcon>
                </SearchBtn>
              </BtnContents>
            </SearchInputWrap>
          </SearchBar>
          <SearchKeyword>
            {!showSearchList && !isNoPlanKeyword && (
              <SearchKeywordContents>
                <SearchKeywordTitleWrap>
                  <SearchKeywordTitle>
                    {type === "community" &&
                      communityKeywordData.length !== 0 &&
                      !showSearchList &&
                      "최근 검색어"}
                    {type !== "community" && !isNoPlanKeyword && "인기 검색어"}
                  </SearchKeywordTitle>
                  {type === "community" &&
                    communityKeywordData.length !== 0 &&
                    !showSearchList && (
                      <SearchKeywordAllDelete
                        onPress={() => setIsVisibleDeletePopup(true)}
                        activeOpacity={1}
                      >
                        <Text style={itemStyles.xsText}>전체 삭제</Text>
                      </SearchKeywordAllDelete>
                    )}
                </SearchKeywordTitleWrap>
                {type === "community" && !showSearchList && (
                  <FlatList
                    data={communityKeywordData}
                    renderItem={renderCommunityKeywordItem}
                    contentContainerStyle={{
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                    numColumns={2}
                  />
                )}
                {type !== "community" && !isNoPlanKeyword && (
                  <FlatList
                    data={popularityKeywordData}
                    renderItem={renderKeywordItem}
                    contentContainerStyle={{
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                    numColumns={2}
                  />
                )}
              </SearchKeywordContents>
            )}

            <SwitchCase
              tests={[
                {
                  test: noData,
                  component: renderSearchNoData(),
                },
                {
                  test: isNoPlanKeyword && keywordData?.length !== 0,
                  component: (
                    <FlatList
                      data={keywordData}
                      renderItem={renderSearchTextItem}
                    />
                  ),
                },
              ]}
            />
          </SearchKeyword>
          <SwitchCase
            tests={[
              {
                test: communityKeywordData.length === 0 && type === "community",
                component: (
                  <SearchNoData>
                    <Text style={itemStyles.noDataText}>
                      최근 검색어 내역이 없어요.
                    </Text>
                  </SearchNoData>
                ),
              },
              {
                test: showSearchList,
                component: (
                  <Tabs
                    defaultActiveKey={String(forumId)}
                    items={tabs}
                    onChange={handleChangeTabId}
                  />
                ),
              },
            ]}
          />
        </SearchContainer>
      </Layouts>
      <Maybe
        test={isVisibleDeletePopup}
        children={
          <DialogPopup
            isOpen={isVisibleDeletePopup}
            onClose={() => setIsVisibleDeletePopup(false)}
            title={keywordDeletePopupTitle}
            multiple
            leftBtnText="취소하기"
            rightBtnText="삭제하기"
            onClickLeftBtn={() => setIsVisibleDeletePopup(false)}
            onClickRightBtn={handleAllDeleteKeyword}
          />
        }
      />
      <Toast
        ref={toastRef}
        offset={height / 2}
        duration={3000}
        style={globalStyles.centerToast}
        textStyle={globalStyles.centerToastText}
      />
      <Maybe
        test={isVisiblePopup}
        children={
          <BottomSelectItem
            isOpen={isVisiblePopup}
            data={categoryList}
            onClose={() => setIsVisiblePopup(false)}
            onChangeId={(item) => handleCategory(item)}
          />
        }
      />
    </>
  );
};

export default GlobalSearchScreen;
