import React, { ReactElement } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Thumbs } from "swiper";
import "swiper/css";
import "swiper/css/thumbs";
import {
  TabContainer,
  NoticeContainer,
  Notice,
  NoticeTextContents,
  NoticeImage,
  NoticeImageContents,
  NoticeText,
} from "./styled";
import { globalStyles } from "@src/styles/modules/global";

interface TabSubSliderProps {
  tabItems?: Array<any>;
  thumbsSwiper?: any;
}

const TabSubSlider = ({
  tabItems,
  thumbsSwiper,
}: TabSubSliderProps): ReactElement => {
  return (
    <TabContainer>
      <Swiper
        slidesPerView={1}
        style={globalStyles.swiperContents}
        thumbs={{ swiper: thumbsSwiper }}
        modules={[Thumbs]}
        allowTouchMove={false}
        speed={10}
      >
        {tabItems.map((item, index) => (
          <SwiperSlide key={index}>
            <NoticeContainer>
              <Notice>
                <NoticeTextContents>
                  <NoticeText>{item.summary}</NoticeText>
                </NoticeTextContents>
                <NoticeImageContents>
                  <NoticeImage source={item.decoImageUrl} />
                </NoticeImageContents>
              </Notice>
            </NoticeContainer>
          </SwiperSlide>
        ))}
      </Swiper>
    </TabContainer>
  );
};

export default TabSubSlider;
