import React, { ReactElement, useEffect, useState } from "react";
import { View, Image, Text, FlatList } from "react-native";
import { useRecoilState } from "recoil";

import {
  ids,
  itemStyles,
  PlanItemContainer,
  PlanItemMultiSelect,
  PlanItemQuestion,
  PlanItemQuestionItem,
  PlanItemQuestionList,
  PlanItemQuestionText,
  PlanItemQuestionTextContents,
  PlanItemQuestionTitle,
  SkinPlanLgTitle,
  SkinPlanMdTitle,
  styles,
  PlanTextQuestionContents,
  PlanTextQuestionItem,
  PlanTextQuestionItemTitle,
  PlanSelectButton,
  PlanSelectButtonText,
} from "./styled";
import { skinPlanTest } from "@src/screens/plan/configs";
import { SwitchCase } from "..";
import { SkinPlanAnswerId } from "@src/store";
import { SkinPlanService } from "@src/services";
import { analytics } from "@src/hooks/analytics";

interface PlanItemProps {
  activeQuestionNum?: number;
  selected?: any;
  selectedLastImg?: any;
  selectedLastText?: any;
}

const PlanItem = ({
  activeQuestionNum,
  selected,
  selectedLastImg,
  selectedLastText,
}: PlanItemProps): ReactElement => {
  const [answerId, setAnswerId] = useRecoilState(SkinPlanAnswerId);

  const [selectedNum1, setSelectedNum1] = useState<number>();
  const [selectedNum2, setSelectedNum2] = useState<any>();
  const [selectedNum3, setSelectedNum3] = useState<number>();
  const [selectedNum4, setSelectedNum4] = useState<number>();

  const { questions } = skinPlanTest;
  const { title, subTitle, info, list, list2, info2, type } =
    questions[activeQuestionNum];

  const getAnswerId = async (): Promise<void> => {
    try {
      const data = await SkinPlanService.postAnswerId();
      setAnswerId(data.plannerAnswerId);
    } catch (error) {
      console.log(error);
    }
  };

  const patchAnswer = async (answer) => {
    try {
      await SkinPlanService.patchAnswer(answer);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelectedFirstItem = (choiceId, text) => {
    selected(true);
    setSelectedNum1(choiceId);

    const answerData = {
      plannerAnswerId: answerId,
      questionId: 1,
      choiceId: choiceId,
    };

    analytics("Skin Plan Answer Category", { answer_category: text });
    patchAnswer(answerData);
  };

  const handleSelectedSecondItem = (choiceId, text) => {
    selected(true);
    setSelectedNum2(choiceId);

    const answerData = {
      plannerAnswerId: answerId,
      questionId: 2,
      choiceId: choiceId,
    };

    analytics("Skin Plan Answer Concerns", { answer_concerns: text });
    patchAnswer(answerData);
  };

  const handleSelectedLastImgItem = (choiceId, text) => {
    selectedLastImg(true);
    setSelectedNum3(choiceId);

    const answerData = {
      plannerAnswerId: answerId,
      questionId: 3,
      choiceId: choiceId,
    };

    analytics("Skin Plan Answer Personal", { answer_personal_state: text });
    patchAnswer(answerData);
  };

  const handleSelectedLastTextItem = (choiceId) => {
    selectedLastText(true);
    setSelectedNum4(choiceId);

    const answerData = {
      plannerAnswerId: answerId,
      questionId: 4,
      choiceId: choiceId,
    };
    patchAnswer(answerData);
  };

  useEffect(() => {
    getAnswerId();
  }, []);

  const renderPlanQuestion = ({ item }) => {
    return (
      <PlanItemQuestionItem
        onPress={() => handleSelectedFirstItem(item.choiceId, item.text)}
        activeOpacity={1}
      >
        <View
          dataSet={{ media: ids.list }}
          style={[
            styles.list,
            item.choiceId === selectedNum1 && styles.selectedList,
          ]}
        >
          <Image
            dataSet={{ media: ids.listImg }}
            style={styles.listImg}
            source={item.img}
            resizeMode="contain"
          />
          <PlanItemQuestionTextContents
            wide={item.text.includes("\n") ? true : false}
          >
            <PlanItemQuestionText selected={item.choiceId === selectedNum1}>
              {item.text}
            </PlanItemQuestionText>
          </PlanItemQuestionTextContents>
        </View>
      </PlanItemQuestionItem>
    );
  };

  const renderSelectQuestion = (list) => {
    return (
      <PlanItemMultiSelect>
        {list.map((item, index) => (
          <PlanSelectButton
            key={index}
            activeOpacity={1}
            selected={item.choiceId === selectedNum2}
            onPress={() => handleSelectedSecondItem(item.choiceId, item.text)}
          >
            <PlanSelectButtonText selected={item.choiceId === selectedNum2}>
              {item.text}
            </PlanSelectButtonText>
          </PlanSelectButton>
        ))}
      </PlanItemMultiSelect>
    );
  };

  const renderPlanQuestion2 = ({ item }) => {
    return (
      <>
        <PlanItemQuestionItem
          onPress={() => handleSelectedLastImgItem(item.choiceId, item.text)}
          activeOpacity={1}
        >
          <View
            dataSet={{ media: ids.list }}
            style={[
              styles.list,
              item.choiceId === selectedNum3 && styles.selectedList,
            ]}
          >
            <Image
              dataSet={{ media: ids.listImg }}
              style={styles.listImg}
              source={item.img}
              resizeMode="contain"
            />
            <PlanItemQuestionTextContents
              wide={item.text.includes("\n") ? true : false}
            >
              <PlanItemQuestionText selected={item.choiceId === selectedNum3}>
                {item.text}
              </PlanItemQuestionText>
            </PlanItemQuestionTextContents>
          </View>
        </PlanItemQuestionItem>
      </>
    );
  };

  const renderPlanTextQuestion = (list) => {
    return (
      <PlanTextQuestionContents>
        <PlanItemQuestionTitle>{info2}</PlanItemQuestionTitle>
        <View style={itemStyles.textQuestion}>
          {list.map((item, index) => (
            <PlanTextQuestionItem
              key={index}
              selected={selectedNum4 === item.choiceId}
              onPress={() => handleSelectedLastTextItem(item.choiceId)}
              activeOpacity={1}
            >
              <PlanTextQuestionItemTitle
                selected={selectedNum4 === item.choiceId}
              >
                {item.text}
              </PlanTextQuestionItemTitle>
            </PlanTextQuestionItem>
          ))}
        </View>
      </PlanTextQuestionContents>
    );
  };

  return (
    <>
      <PlanItemContainer>
        <SkinPlanLgTitle style={itemStyles.lgText}>
          <Text>{title}</Text>
        </SkinPlanLgTitle>
        <SkinPlanMdTitle style={itemStyles.mdText}>
          <Text>{subTitle}</Text>
        </SkinPlanMdTitle>
        <PlanItemQuestion>
          <PlanItemQuestionTitle>{info}</PlanItemQuestionTitle>
          <PlanItemQuestionList>
            <SwitchCase
              tests={[
                {
                  test: type === "imgList",
                  component: (
                    <FlatList
                      keyExtractor={(item, index) => `key${index}`}
                      renderItem={renderPlanQuestion}
                      data={list}
                      numColumns={type === "imgList" ? 2 : undefined}
                      columnWrapperStyle={{ justifyContent: "space-between" }}
                    />
                  ),
                },
                {
                  test: type === "imgList2",
                  component: (
                    <View>
                      <FlatList
                        keyExtractor={(item, index) => `key${index}`}
                        renderItem={renderPlanQuestion2}
                        data={list}
                        numColumns={type === "imgList2" ? 2 : undefined}
                        columnWrapperStyle={{ justifyContent: "space-between" }}
                      />
                      {list2 && renderPlanTextQuestion(list2)}
                    </View>
                  ),
                },
                {
                  test: type === "textList",
                  component: renderSelectQuestion(list),
                },
              ]}
            />
          </PlanItemQuestionList>
        </PlanItemQuestion>
      </PlanItemContainer>
    </>
  );
};

export default PlanItem;
