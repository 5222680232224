import SurgeryConfigs from "./SurgeryConfigs";
import AuthConfigs from "./AuthConfigs";
import MypageConfigs from "./MypageConfigs";
import SkinMbtiConfigs from "./SkinMbtiConfigs";
import SkinPlanConfigs from "./SkinPlanConfigs";
import CommunityConfigs from "./CommunityConfigs";
import SearchConfigs from "./SearchConfigs";

export default [
  ...SurgeryConfigs,
  ...AuthConfigs,
  ...MypageConfigs,
  ...SkinMbtiConfigs,
  ...SkinPlanConfigs,
  ...CommunityConfigs,
  ...SearchConfigs,
];
