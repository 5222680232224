import styled from "styled-components/native";
import { StyleSheet } from "react-native";
import MediaStyle from "react-native-media-query";
import { Color } from "@src/constants";

export const SelectInputWrap = styled.View`
  position: fixed;
  width: 100%;
  background-color: #fff;
  z-index: 10;
  padding: 20px 0 10px 20px;
  margin-bottom: 10px;
`;

export const SelectInputBtn = styled.TouchableOpacity`
  flex-direction: row;
  align-items: center;
  width: fit-content;
`;

export const SelectInputBtnText = styled.Text`
  font-size: 14px;
  font-family: "fontM";
`;

export const itemStyles = StyleSheet.create({
  whiteBg: {
    backgroundColor: Color.WHITE,
  },
  smText: {
    fontSize: 14,
  },
  mdText: {
    lineHeight: 23,
    fontSize: 16,
  },
  mdTextB: {
    fontSize: 16,
    fontFamily: "fontB",
  },
  mLText: {
    fontSize: 18,
    fontFamily: "fontB",
  },
  lgText: {
    lineHeight: 33,
    fontSize: 24,
    fontFamily: "fontB",
  },
  highlightText: {
    fontFamily: "fontB",
    color: Color.PUPPLE,
  },
  marginTop10: {
    marginTop: 10,
  },
  marginTop20: {
    marginTop: 20,
  },
  alignCenter: {
    alignItems: "center",
  },
  spaceBetween: {
    justifyContent: "space-between",
  },
  grayText: {
    marginBottom: 10,
    fontSize: 14,
    fontFamily: "fontM",
    color: Color.LIGHT_GREY_TWO,
    textAlign: "center",
  },
  tab: {
    minWidth: 360,
    maxWidth: 480,
    backgroundColor: Color.WHITE,
  },
  tabLabel: {
    fontSize: 16,
    fontFamily: "fontM",
    color: Color.BLACK_TWO,
    textAlign: "center",
  },
  puppleText: {
    color: Color.PUPPLE,
  },
  icon: {
    width: 24,
    height: 24,
  },
  contents: {
    marginTop: 54,
  },
});

export const { ids, styles, fullStyles } = MediaStyle.create({
  paddingB: {
    paddingBottom: 30,
    "@media (max-width: 480px)": {
      paddingBottom: 60,
    },
  },
});
