import React, { ReactElement, useEffect, useState } from "react";
import { Text, Image, FlatList } from "react-native";
import { useNavigation } from "@react-navigation/native";

import {
  itemStyles,
  Category,
  ListName,
  MyPlanContainer,
  MyPlanListContents,
  MyPlanListItem,
  Summary,
  SummaryItem,
  SummaryItemTitle,
  SummaryItemInfo,
  SummaryItemScope,
  SummaryMore,
  SummaryMoreText,
  SummaryItemTitleContents,
  SummaryMoreBtn,
  SummaryItemInfoText,
  SummaryItemInner,
  DeleteBtn,
  ListItemHeader,
  MoreContents,
  MoreBtn,
} from "./styled";
import { SummaryItemInfoIcon } from "../MySkinPlanDetailItem/styled";
import { Maybe, TooltipItem } from "@src/components/presentational";
import { REGEX_NUMBER_COMMA } from "@src/constants";
import { SkinPlanService } from "@src/services";
import { analytics } from "@src/hooks/analytics";
import starIcon from "@assets/icons/icon-star.svg";
import nextGrayIcon from "@assets/icons/icon-next-gray.svg";
import infoIcon from "@assets/icons/icon-info.svg";
import tooltipXlargeIcon from "@assets/icons/icon-tooltip-xlarge.png";
import tooltipXlargeIcon2 from "@assets/icons/icon-tooltip-xlarge2.png";
import incompleteIcon from "@assets/icons/icon-incomplete.png";
import deleteIcon from "@assets/icons/icon-close-black.svg";
import downGrayIcon from "@assets/icons/icon-down-gray.svg";

interface MySkinPlanListItemProps {
  data?: any;
  deleteMode?: boolean;
  deleteItem?: any;
  showPopup?: any;
  getPlanList?: any;
  setDeleteMode?: any;
  totalCount?: number;
  last?: boolean;
  onClickMore?: () => void;
}

const MySkinPlanListItem = ({
  data,
  deleteMode,
  deleteItem,
  showPopup,
  getPlanList,
  setDeleteMode,
  totalCount,
  last,
  onClickMore,
}: MySkinPlanListItemProps): ReactElement => {
  const navigation = useNavigation<any>();

  const [expectPriceTooltip, setExpectPriceTooltip] = useState<boolean>(false);
  const [realPriceTooltip, setRealPriceTooltip] = useState<boolean>(false);

  const [listId, setListId] = useState<number>();
  const [deleteList, setDeleteList] = useState<any>();

  const handleExpectPriceTooltip = (id) => {
    const selectedId = data.filter((item) => item.plannerId === id);
    selectedId[0].isExpectPriceSelected = true;
    setExpectPriceTooltip(true);

    setTimeout(() => {
      selectedId[0].isExpectPriceSelected = false;
      setExpectPriceTooltip(false);
    }, 2000);
  };

  const handleRealPriceTooltip = (id) => {
    const selectedId = data.filter((item) => item.plannerId === id);
    selectedId[0].isRealPriceSelected = true;
    setRealPriceTooltip(true);

    setTimeout(() => {
      selectedId[0].isRealPriceSelected = false;
      setRealPriceTooltip(false);
    }, 2000);
  };

  const handleShowPopup = (item) => {
    showPopup(true);
    setDeleteMode(false);
    setListId(item.plannerId);
    setDeleteList(item);
  };

  const handleDeleteItem = async (id, list) => {
    try {
      const data = await SkinPlanService.deletePlanList(id);
      if (data === "OK") {
        analytics("Skin Plan Delete List", { skin_plan_delete_list: list });
        getPlanList();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (deleteItem) {
      handleDeleteItem(listId, deleteList);
    }
  }, [deleteItem, listId, deleteList]);

  const renderContent = ({ item }) => {
    return (
      <MyPlanListContents>
        <MyPlanListItem>
          <ListItemHeader>
            <Category type={item.processStatus}>
              <Text style={[itemStyles.whiteText, itemStyles.smText]}>
                {item.processStatus}
              </Text>
            </Category>
            {deleteMode && (
              <DeleteBtn
                onPress={() => handleShowPopup(item)}
                activeOpacity={1}
              >
                <Image
                  source={deleteIcon}
                  resizeMode="contain"
                  style={itemStyles.deleteIcon}
                />
              </DeleteBtn>
            )}
          </ListItemHeader>
          <ListName>
            <Text style={itemStyles.lgText}>{item.subject}</Text>
          </ListName>
          <Summary>
            <SummaryItem>
              <SummaryItemInner>
                <SummaryItemTitleContents>
                  <SummaryItemTitle>예상 가격</SummaryItemTitle>
                  <SummaryItemInfoIcon
                    onPress={() => handleExpectPriceTooltip(item.plannerId)}
                    activeOpacity={1}
                  >
                    <Image
                      source={infoIcon}
                      resizeMode="contain"
                      style={itemStyles.icon}
                    />
                  </SummaryItemInfoIcon>
                </SummaryItemTitleContents>
                <SummaryItemInfo>
                  <SummaryItemInfoText>
                    {item.totalPriceForPlanner
                      ? item.totalPriceForPlanner
                          ?.toString()
                          .replace(REGEX_NUMBER_COMMA, ",")
                      : "???"}
                    {item.hasUnofficialItem && !item.hasAllOfActualPrice && (
                      <Text style={itemStyles.redColor}> ↑</Text>
                    )}
                  </SummaryItemInfoText>
                </SummaryItemInfo>
              </SummaryItemInner>
            </SummaryItem>
            <SummaryItem>
              <SummaryItemInner>
                <SummaryItemTitleContents>
                  <SummaryItemTitle>실제 가격</SummaryItemTitle>
                  {!item.hasAllOfActualPrice && (
                    <SummaryItemInfoIcon
                      onPress={() => handleRealPriceTooltip(item.plannerId)}
                      activeOpacity={1}
                    >
                      <Image
                        source={infoIcon}
                        resizeMode="contain"
                        style={itemStyles.icon}
                      />
                    </SummaryItemInfoIcon>
                  )}
                </SummaryItemTitleContents>
                <SummaryItemInfo>
                  <SummaryItemInfoText>
                    {item.totalActualPrice === 0 ||
                    item.totalActualPrice === null
                      ? "-"
                      : item.totalActualPrice &&
                        item.totalActualPrice
                          .toString()
                          .replace(REGEX_NUMBER_COMMA, ",")}
                  </SummaryItemInfoText>
                  {!item.hasAllOfActualPrice && item.totalActualPrice ? (
                    <Image
                      source={incompleteIcon}
                      resizeMode="contain"
                      style={itemStyles.incompleteIcon}
                    />
                  ) : (
                    <></>
                  )}
                </SummaryItemInfo>
              </SummaryItemInner>
            </SummaryItem>
            <SummaryItem>
              <SummaryItemInner>
                <SummaryItemTitleContents>
                  <SummaryItemTitle>한줄 메모</SummaryItemTitle>
                </SummaryItemTitleContents>
                <SummaryItemInfo>
                  <SummaryItemInfoText>
                    {item.memo ? item.memo : "-"}
                  </SummaryItemInfoText>
                </SummaryItemInfo>
              </SummaryItemInner>
            </SummaryItem>
            <SummaryItem>
              <SummaryItemInner>
                <SummaryItemTitleContents>
                  <SummaryItemTitle>플랜 평가</SummaryItemTitle>
                </SummaryItemTitleContents>
                <SummaryItemInfo>
                  {item.rating ? (
                    <>
                      {Array.from(Array(item.rating), (_, i) => (
                        <SummaryItemScope key={i}>
                          <Image
                            source={starIcon}
                            resizeMode="contain"
                            style={itemStyles.icon}
                          />
                        </SummaryItemScope>
                      ))}
                    </>
                  ) : (
                    <SummaryItemInfoText>-</SummaryItemInfoText>
                  )}
                </SummaryItemInfo>
              </SummaryItemInner>
            </SummaryItem>

            <Maybe
              test={item.isExpectPriceSelected}
              children={
                <TooltipItem
                  xLarge
                  positionTop={-45}
                  positionLeft={29}
                  opacity={1}
                  xLargeIcon={tooltipXlargeIcon}
                  transform
                />
              }
            />
            <Maybe
              test={item.isRealPriceSelected}
              children={
                <TooltipItem
                  xLarge
                  positionTop={75}
                  positionLeft={22}
                  opacity={1}
                  xLargeIcon={tooltipXlargeIcon2}
                  transform
                />
              }
            />
          </Summary>
          {!deleteMode && (
            <SummaryMore>
              <SummaryMoreBtn
                activeOpacity={1}
                onPress={() =>
                  navigation.push("mySkinPlanDetail", { id: item.plannerId })
                }
              >
                <SummaryMoreText>자세히 보기</SummaryMoreText>
                <Image
                  source={nextGrayIcon}
                  resizeMode="contain"
                  style={itemStyles.icon}
                />
              </SummaryMoreBtn>
            </SummaryMore>
          )}
        </MyPlanListItem>
      </MyPlanListContents>
    );
  };

  return (
    <MyPlanContainer>
      <FlatList
        data={data}
        renderItem={renderContent}
        showsVerticalScrollIndicator={false}
        keyExtractor={(item, index) => index.toString()}
      />
      <Maybe
        test={totalCount > 10 && !last}
        children={
          <MoreContents>
            <MoreBtn onPress={onClickMore} activeOpacity={1}>
              <Text style={itemStyles.moreText}>더보기</Text>
              <Image
                source={downGrayIcon}
                resizeMode="contain"
                style={itemStyles.icon}
              />
            </MoreBtn>
          </MoreContents>
        }
      />
    </MyPlanContainer>
  );
};

export default MySkinPlanListItem;
