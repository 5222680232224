import React, { ReactElement, useEffect, useRef, useState } from "react";
import { Dimensions, ScrollView, StatusBar, Text, View } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { Helmet } from "react-helmet-async";
import Toast from "react-native-fast-toast";
import { useRecoilState, useSetRecoilState } from "recoil";
import { Layouts } from "@src/components/container";
import {
  MypageCommonBox,
  MypageCommonBoxInfo,
  MypageCommonBoxInner,
  MypageCommonCheck,
  MypageCommonCheckbox,
  MypageCommonCheckText,
  MypageCommonContents,
  MypageCommonInfoText,
  MypageCommonTitle,
  MypageCommonTitleText,
  MypageCommonTopContainer,
  styles,
} from "./styled";
import {
  BottomFixButton,
  CircleCheckbox,
} from "@src/components/presentational";
import { globalStyles } from "@src/styles/modules/global";
import { withdrawalText } from "../sampleDatas";
import { MemberService, TokenService } from "@src/services";
import {
  simpleUserState,
  tokenState,
  withdrawalCauseInputState,
  withdrawalCauseSelectState,
} from "@src/store";
import { analytics } from "@src/hooks/analytics";
import { useAuth } from "@src/hooks/auth";

/**
 * 회원 탈퇴 확인
 */

const WithdrawalConfirmScreen = (): ReactElement => {
  const navigation = useNavigation<any>();

  const { height } = Dimensions.get("window");
  const centerToastRef = useRef(null);

  const { user } = useAuth();

  const setUser = useSetRecoilState(simpleUserState);
  const setToken = useSetRecoilState(tokenState);

  const [selectedValue, setSelectedValue] = useRecoilState(
    withdrawalCauseSelectState
  );
  const [inputText, setInputText] = useRecoilState(withdrawalCauseInputState);

  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(true);

  const withDrawalMember = async () => {
    const type = "DROP";
    const conditions = {
      cause: inputText ? inputText : selectedValue,
    };

    try {
      analytics("Withdrawal", {
        id: user.id,
        cause: inputText ? inputText : selectedValue,
      });

      await MemberService.deleteUser(type, conditions);
      setUser(null);
      TokenService.cleanTokens();
    } catch (error) {
      console.log(error);
    }

    centerToastRef.current.show(withdrawalText);

    setTimeout(() => {
      window.location.href = "surgeryList";
    }, 3000);
  };

  const handleChecked = () => {
    setIsChecked(!isChecked);
  };

  useEffect(() => {
    if (isChecked) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [isChecked]);

  return (
    <>
      <StatusBar barStyle="light-content" backgroundColor="#6432e9" />
      <Helmet>
        <title>회원 탈퇴 | 촉촉박사</title>
        <meta
          name="description"
          content="촉촉한 피부, 나만 모르고 있던 시술 정보가 궁금하다면? | 촉촉박사"
          data-react-helmet="true"
        />
      </Helmet>
      <Layouts logo transparent hamburgerMode>
        <MypageCommonTopContainer>
          <MypageCommonTitle>
            <MypageCommonTitleText>
              정말로 촉촉박사를
              <br />
              <Text style={styles.highlight}>탈퇴</Text>하시나요?
            </MypageCommonTitleText>
          </MypageCommonTitle>
          <MypageCommonInfoText>
            탈퇴하시면 촉촉박사를 더 이상 이용하실 수 없어요. 아래의 주의사항을
            반드시 읽어보시고 결정해 주세요.
          </MypageCommonInfoText>
        </MypageCommonTopContainer>
        <ScrollView
          bounces={false}
          scrollEventThrottle={1}
          contentContainerStyle={{ flexGrow: 1 }}
          showsVerticalScrollIndicator={false}
        >
          <MypageCommonContents>
            <MypageCommonBox>
              <MypageCommonBoxInner>
                <MypageCommonBoxInfo style={styles.info}>
                  <Text style={styles.infoText}>1. </Text>
                  <Text style={styles.infoText}>
                    회원 탈퇴를 신청하면 7일간 탈퇴 유예 상태로 변경됩니다.
                  </Text>
                </MypageCommonBoxInfo>
                <MypageCommonBoxInfo style={styles.info}>
                  <Text style={styles.infoText}>2. </Text>
                  <Text style={styles.infoText}>
                    7일 내에는 탈퇴 철회가 가능합니다.
                  </Text>
                </MypageCommonBoxInfo>
                <MypageCommonBoxInfo style={styles.info}>
                  <Text style={styles.infoText}>3. </Text>
                  <Text style={styles.infoText}>
                    탈퇴 유예 기간인 아이디의 닉네임은 다른 아이디에서 사용할 수
                    없습니다.
                  </Text>
                </MypageCommonBoxInfo>
                <MypageCommonBoxInfo style={styles.info}>
                  <Text style={styles.infoText}>4. </Text>
                  <Text style={styles.infoText}>
                    탈퇴 유예 기간인 아이디를 보유한 회원은 탈퇴 유예 기간 동안
                    다른 아이디로 재가입할 수 없습니다.
                  </Text>
                </MypageCommonBoxInfo>
                <MypageCommonBoxInfo style={styles.info}>
                  <Text style={styles.infoText}>5. </Text>
                  <Text style={styles.infoText}>
                    탈퇴 신청 후 7일이 지나면 탈퇴가 완료되며, 이후 재가입이
                    가능합니다.
                  </Text>
                </MypageCommonBoxInfo>
              </MypageCommonBoxInner>
            </MypageCommonBox>
            <MypageCommonCheck>
              <MypageCommonCheckbox
                onPress={handleChecked}
                underlayColor="transparent"
              >
                <>
                  <CircleCheckbox
                    isChecked={isChecked}
                    onChangeCheck={handleChecked}
                    outline
                  />
                  <MypageCommonCheckText>
                    모든 안내사항을 확인 했습니다.
                  </MypageCommonCheckText>
                </>
              </MypageCommonCheckbox>
            </MypageCommonCheck>
          </MypageCommonContents>
        </ScrollView>
        <View style={styles.bottomBtn}>
          <BottomFixButton
            multipleBtnMode
            leftBtnText="계속 이용하기"
            rightBtnText="탈퇴하기"
            disabled={disabled}
            onPressLeftBtn={() => navigation.navigate("myinfo")}
            onPressRightBtn={withDrawalMember}
          />
        </View>
      </Layouts>
      <Toast
        ref={centerToastRef}
        offset={height / 2}
        duration={2500}
        style={globalStyles.centerToast}
        textStyle={globalStyles.centerToastText}
      />
    </>
  );
};

export default WithdrawalConfirmScreen;
