import { Color } from "@src/constants";
import styled from "styled-components/native";

export const ToggleButtonContainer = styled.TouchableOpacity`
  height: 40px;
  padding: 7px 16px 9px 16px;
  margin-right: 10px;
  margin-bottom: 10px;
  align-items: center;
  justify-content: center;
  background-color: ${({ selected }: { selected: boolean }) =>
    selected ? Color.PUPPLE : Color.WHITE};
  border-radius: 20px;
  box-shadow: rgba(100, 50, 233, 0.08) 0 0 16px;
`;

export const ToggleButtonItemText = styled.Text`
  font-size: 16px;
  font-family: "fontM";
  color: ${({ selected }: { selected: boolean }) =>
    selected ? Color.WHITE : Color.BLACK_TWO};
`;

export const CheckBoxIcon = styled.Image`
  width: 28px;
  height: 28px;
`;
