import { globalStyles } from "@src/styles/modules/global";
import React, { ReactElement, useState, useRef } from "react";
import { View, TouchableWithoutFeedback, Animated, Easing } from "react-native";
import {
  AccordionContainer,
  AccordionContents,
  AccordionContentsHighlight,
  AccordionContentsHighlightIcon,
  AccordionContentsHighlightText,
  AccordionContentsInfo,
  AccordionContentsInfoText,
  AccordionHeader,
  AccordionHeaderExpand,
  AccordionHeaderIcon,
  AccordionHeaderTag,
  AccordionHeaderTagContents,
  AccordionHeaderTagText,
  AccordionHeaderText,
  AccordionInfoSummary,
  AccordionInfoSummaryInner,
  AccordionInfoSummaryText,
  AccordionInner,
  AccordionWrap,
  Dot,
  HighlightBg,
  styles,
} from "./styled";
import expandDownIcon from "@assets/icons/icon-expand-down.svg";
import noticeIcon from "@assets/icons/icon-notice.png";

interface AccordionListProps {
  data?: Array<any>;
  highlightText?: string;
  toggleMoreInfo?: () => void;
}

const AccordionList = ({
  data,
  highlightText,
  toggleMoreInfo,
}: AccordionListProps): ReactElement => {
  const [open, setOpen] = useState(false);
  const animatedController = useRef(new Animated.Value(0)).current;
  const [bodySectionHeight, setBodySectionHeight] = useState<any>();

  const bodyHeight = animatedController.interpolate({
    inputRange: [0, 1],
    outputRange: [0, bodySectionHeight],
  });

  const arrowAngle = animatedController.interpolate({
    inputRange: [0, 1],
    outputRange: ["0rad", `${Math.PI}rad`],
  });

  const toggleListItem = () => {
    if (open) {
      Animated.timing(animatedController, {
        duration: 300,
        toValue: 0,
        useNativeDriver: true,
        easing: Easing.bezier(0.4, 0.0, 0.2, 1),
      }).start();
    } else {
      Animated.timing(animatedController, {
        duration: 300,
        toValue: 1,
        useNativeDriver: true,
        easing: Easing.bezier(0.4, 0.0, 0.2, 1),
      }).start();
    }
    setOpen(!open);
  };

  return (
    <AccordionWrap>
      {data.map((item, index) => (
        <AccordionContainer key={index}>
          <AccordionInner>
            <TouchableWithoutFeedback>
              <AccordionHeader>
                <AccordionHeaderText>{item.title}</AccordionHeaderText>
                <Animated.View
                  style={{
                    transform: [{ rotateZ: arrowAngle }],
                    width: 24,
                    height: 24,
                  }}
                >
                  <AccordionHeaderExpand
                    onPress={() => {
                      toggleListItem();
                      toggleMoreInfo();
                    }}
                    underlayColor="transparent"
                  >
                    <AccordionHeaderIcon
                      source={expandDownIcon}
                      resizeMode="contain"
                    />
                  </AccordionHeaderExpand>
                </Animated.View>
              </AccordionHeader>
            </TouchableWithoutFeedback>

            <AccordionHeaderTagContents>
              {item.keyword.map((item, index) => (
                <AccordionHeaderTag key={index}>
                  <AccordionHeaderTagText style={globalStyles.fontM}>
                    {item}
                  </AccordionHeaderTagText>
                </AccordionHeaderTag>
              ))}
            </AccordionHeaderTagContents>

            <AccordionContents>
              <AccordionContentsHighlight>
                <AccordionContentsHighlightIcon
                  source={noticeIcon}
                  resizeMode="contain"
                />
                <HighlightBg style={globalStyles.highlight} />
                <AccordionContentsHighlightText style={globalStyles.fontB}>
                  {highlightText}
                </AccordionContentsHighlightText>
              </AccordionContentsHighlight>
              {item.summary.split("\n").map((item, index) => (
                <AccordionContentsInfo key={index}>
                  <Dot style={globalStyles.dot} />
                  <AccordionContentsInfoText
                    key={index}
                    style={globalStyles.fontM}
                  >
                    {item}
                  </AccordionContentsInfoText>
                </AccordionContentsInfo>
              ))}
            </AccordionContents>
          </AccordionInner>
          <Animated.View style={[styles.accordion, { height: bodyHeight }]}>
            <AccordionInfoSummary
              onLayout={(event) =>
                setBodySectionHeight(event.nativeEvent.layout.height)
              }
            >
              <AccordionInfoSummaryInner>
                <AccordionInfoSummaryText style={globalStyles.fontM}>
                  {item.description}
                </AccordionInfoSummaryText>
              </AccordionInfoSummaryInner>
            </AccordionInfoSummary>
          </Animated.View>
        </AccordionContainer>
      ))}
    </AccordionWrap>
  );
};

export default AccordionList;
