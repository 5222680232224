import React, { useEffect, useRef, useState } from "react";
import { View, StatusBar, Text, ScrollView, Dimensions } from "react-native";
import { Helmet } from "react-helmet-async";
import { useNavigation } from "@react-navigation/native";
import Toast from "react-native-fast-toast";

import { Layouts } from "@src/components/container";
import {
  MySkinPlanContents,
  MySkinPlanEditText,
  NoData,
  NoDataInfo,
  NoDataTitle,
  planStyles,
  SkinPlanContainer,
  SkinPlanEdit,
  SkinPlanEditContents,
} from "../styled";
import { deletePopupInfo, deletePopupTitle } from "../configs";
import {
  BottomFixButton,
  DialogPopup,
  Maybe,
  MySkinPlanListItem,
} from "@src/components/presentational";
import { SkinPlanService } from "@src/services";
import { MyPlanList } from "@src/types";
import { planDeleteToast } from "@src/screens/sampleDatas";
import { globalStyles } from "@src/styles/modules/global";
import { analytics } from "@src/hooks/analytics";

/**
 * 내 피부 플랜 목록
 */

const MySkinPlanScreen = () => {
  const navigation = useNavigation<any>();
  const toastRef = useRef(null);

  const { height } = Dimensions.get("window");

  const [data, setData] = useState<MyPlanList[]>();
  const [pageIdx, setPageIdx] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);

  const [listManagement, setListManagement] = useState<boolean>(false);
  const [noData, setNoData] = useState<boolean>(false);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [deleteMode, setDeleteMode] = useState<boolean>(false);

  const [totalCount, setTotalCount] = useState<number>(0);
  const [last, setLast] = useState<boolean>(false);

  const getPlanList = async () => {
    try {
      const conditions = {
        pageIdx,
        pageSize,
      };

      const data = await SkinPlanService.getPlanList(conditions);
      setNoData(data.content.length === 0);

      if (pageIdx > 0) {
        setData((prev) => prev.concat(data.content));
      } else {
        setData(data.content);
      }

      if (deleteMode) {
        toastRef.current.show(planDeleteToast);
      }

      setTotalCount(data.totalElements);
      setLast(data.last);
    } catch (error) {
      console.log(error);
    }
  };

  const onClickCancelBtn = () => {
    setShowPopup(false);
    setDeleteMode(false);
  };

  const onClickDeleteBtn = () => {
    setDeleteMode(true);
    setShowPopup(false);
  };

  const handleMoreData = () => {
    setPageIdx((prev) => prev + 1);
  };

  useEffect(() => {
    getPlanList();
  }, [pageIdx]);

  return (
    <>
      <StatusBar barStyle="light-content" backgroundColor="#6432e9" />
      <Helmet>
        <title>내 피부 플랜 | 촉촉박사</title>
      </Helmet>
      <Layouts logo hamburgerMode transparent>
        <View
          style={
            noData
              ? [planStyles.headerWrap, planStyles.header]
              : [planStyles.planHeader, planStyles.header]
          }
        >
          <Text style={planStyles.mLText}>내 피부 플랜</Text>
        </View>
        <ScrollView
          bounces={false}
          scrollEventThrottle={1}
          showsVerticalScrollIndicator={false}
          contentContainerStyle={[{ flexGrow: 1 }, globalStyles.dataList]}
        >
          <SkinPlanContainer>
            {noData ? (
              <NoData>
                <NoDataTitle>내 피부 플랜이 비었어요!</NoDataTitle>
                <NoDataInfo>
                  아래 버튼을 눌러 <br />
                  새로운 피부 플랜을 만들어 보세요
                </NoDataInfo>
              </NoData>
            ) : (
              <MySkinPlanContents>
                <SkinPlanEditContents>
                  <SkinPlanEdit
                    onPress={() => setListManagement(!listManagement)}
                    activeOpacity={1}
                  >
                    <MySkinPlanEditText>
                      {listManagement ? "완료" : "리스트 관리"}
                    </MySkinPlanEditText>
                  </SkinPlanEdit>
                </SkinPlanEditContents>
                <MySkinPlanListItem
                  data={data}
                  deleteMode={listManagement}
                  deleteItem={deleteMode}
                  getPlanList={getPlanList}
                  showPopup={setShowPopup}
                  setDeleteMode={setDeleteMode}
                  totalCount={totalCount}
                  onClickMore={handleMoreData}
                  last={last}
                />
              </MySkinPlanContents>
            )}
          </SkinPlanContainer>
        </ScrollView>
        <BottomFixButton
          fullBtnMode
          fullBtnText="피부 플랜 만들기"
          onPressFullBtn={() => {
            analytics("Skin Plan Making", noData ? { state: "empty" } : "");
            navigation.push("skinPlan");
          }}
        />
      </Layouts>
      <Maybe
        test={showPopup}
        children={
          <DialogPopup
            isOpen={showPopup}
            onClose={() => setShowPopup(false)}
            title={deletePopupTitle}
            info={deletePopupInfo}
            multiple
            leftBtnText="취소하기"
            rightBtnText="삭제하기"
            onClickLeftBtn={onClickCancelBtn}
            onClickRightBtn={onClickDeleteBtn}
          />
        }
      />
      <Toast
        ref={toastRef}
        offset={height / 2}
        duration={3000}
        style={globalStyles.centerToast}
        textStyle={globalStyles.centerToastText}
      />
    </>
  );
};

export default MySkinPlanScreen;
