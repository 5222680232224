import styled from "styled-components/native";
import { Dimensions, StyleSheet } from "react-native";
import MediaStyle from "react-native-media-query";
import { Color } from "@src/constants";

const { width, height } = Dimensions.get("window");

export const SkinPlanContainer = styled.View`
  width: 100%;
  align-items: center;
`;

export const SkinPlanHeader = styled.View`
  width: 100%;
  margin-bottom: 30px;
`;

export const SkinPlanTitle = styled.View``;

export const SkinPlanTitleText = styled.Text`
  margin-bottom: 8px;
  text-align: center;
`;

export const SkinPlanSubTitleText = styled.Text``;

export const SkinMbtiContainer = styled.View`
  height: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const SkinMbtiTitle = styled.View``;

export const SkinMbtiResultContents = styled.View`
  width: 100%;
  margin: 30px 0 60px 0;
  padding: 8px;
  background-image: linear-gradient(to bottom, #6432e9, #825bec);
  border-radius: 16px;
`;

export const SkinMbtiResultContentsInner = styled.View`
  height: 100%;
  padding: 40px 0 30px 0;
  border-radius: 16px;
  background-color: ${Color.WHITE};
`;

export const SkinPlanIntro = styled.View`
  width: 100%;
  padding-bottom: 40px;
`;

export const SkinPlanIntroItem = styled.View`
  position: relative;
  width: calc(100% / 2 - 8px);
  min-height: 225px;
  height: 279px;
  margin-right: 16px;
  margin-bottom: 16px;
  padding: 16px 16px 0 16px;
  background-color: ${Color.WHITE};
  box-shadow: rgba(100, 50, 233, 0.2) 0 0 16px;
  border-radius: 16px;
`;

export const SkinPlanIntroItemTitle = styled.Text`
  font-size: 16px;
  font-family: "fontB";
`;

export const SkinPlanIntroItemImg = styled.Image`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  min-height: 125px;
  height: 180px;
`;

export const SkinPlanContents = styled.View`
  position: relative;
  width: 100%;
  height: 100%;
  margin-top: 40px;
`;

export const RecommendPlan = styled.View`
  width: 100%;
`;

export const SkinPlanQuestion = styled.View``;

export const SkinPlanContentsInner = styled.View`
  padding: 0 20px;
`;

export const SkinPlanSmTitle = styled.Text`
  margin-bottom: 10px;
`;

export const SkinPlanLgTitle = styled.Text`
  margin-bottom: 20px;
`;

export const SkinPlanMdTitle = styled.Text``;

export const SkinPlanImg = styled.View`
  position: fixed;
  bottom: 76px;
  width: 100%;
  left: 0;
`;

export const SkinPlanTabContainer = styled.View``;

export const SkinPlanCommonBtnContainer = styled.View`
  width: 100%;
  padding: 0 20px;
  margin: 60px 0 24px 0;
`;

export const SkinPlanCommonBtn = styled.TouchableOpacity`
  width: 100%;
  height: 52px;
  border-radius: 26px;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(100, 50, 233, 0.08) 0 0 16px;
  background-color: ${({ disabled }: { disabled?: boolean }) =>
    disabled ? Color.LIGHT_GREY_THREE : Color.PUPPLE};
`;

export const SkinPlanCommonBtnText = styled.Text`
  font-size: 16px;
  font-family: "fontM";
  color: ${Color.WHITE};
`;

export const SkinPlanMakingHeader = styled.View`
  padding: 0 20px;
  margin-top: 10px;
`;

export const SkinPlanMakingContents = styled.View`
  width: 100%;
  padding: 0 20px 136px 20px;
`;

export const SkinPlanMakingComment = styled.View`
  padding: 16px;
  border-radius: 16px;
  background-color: ${Color.LIGHT_PUPPLE_SEVEN};
`;

export const SkinPlanEditContents = styled.View`
  margin-top: 20px;
  margin-bottom: 16px;
  align-items: flex-end;
`;

export const SkinPlanEdit = styled.TouchableOpacity``;

export const SkinPlanEditText = styled.Text`
  font-size: 14px;
  font-family: "fontM";
  color: ${({ orderMode }: { orderMode?: boolean }) =>
    orderMode ? Color.PUPPLE : Color.LIGHT_GREY_FOUR};
`;

export const MySkinPlanEditText = styled.Text`
  font-size: 14px;
  font-family: "fontM";
  color: ${Color.PUPPLE};
`;

export const PlanMakingListAddBtnContents = styled.View``;

export const PlanMakingListAddBtn = styled.TouchableOpacity`
  height: 60px;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  background-color: ${Color.WHITE};
`;

export const NoticeHighlight = styled.View`
  position: relative;
  flex-direction: row;
  margin-bottom: 10px;
`;

export const NoticeHighlightIcon = styled.Image`
  width: 20px;
  height: 20px;
  margin-right: 4px;
`;

export const HighlightBg = styled.View`
  width: 120px;
  background-color: ${Color.LIGHT_PUPPLE_SIX};
`;

export const NoticeHighlightText = styled.Text`
  z-index: 1;
  font-size: 16px;
  font-family: "fontB";
  letter-spacing: -0.35px;
`;

export const CommentInfo = styled.View`
  flex-direction: row;
  margin-bottom: 8px;
`;

export const CommentInfoText = styled.Text`
  font-size: 14px;
  letter-spacing: -0.35px;
  line-height: 20px;
`;

export const Dot = styled.View`
  background-color: ${Color.BLACK_TWO};
`;

export const NoData = styled.View`
  width: 100%;
  height: calc(100vh - 600px);
  padding: 0 60px;
  justify-content: center;
  align-items: center;
`;

export const NoDataTitle = styled.Text`
  font-size: 20;
  font-family: "fontB";
`;

export const NoDataInfo = styled.Text`
  margin: 20px 0 40px 0;
  font-size: 16px;
  font-family: "fontM";
  text-align: center;
  line-height: 23px;
`;

export const MySkinPlanContents = styled.View`
  width: 100%;
  padding: 0 20px 40px 20px;
`;

export const BackIcon = styled.TouchableOpacity`
  position: absolute;
  left: 20px;
`;

export const Loading = styled.View`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  background: ${Color.WHITE};
`;

export const LoadingText = styled.Text`
  padding: 40px 0;
  font-size: 20px;
  font-family: "fontB";
  line-height: 27px;
  text-align: center;
  font-family: "fontB";
`;

export const LoadingSubText = styled.Text`
  font-size: 16px;
  font-family: "fontM";
`;

export const RangeList = styled.View`
  position: relative;
  width: 100%;
  height: 315px;
  padding: 0 20px;
  margin-bottom: 90px;
  margin-top: 30px;
`;

export const RangeListItem = styled.View`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  align-items: center;
`;

export const RangeListItemImg = styled.Image`
  width: 100%;
  height: 100%;
`;

export const RangeLevel = styled.Text`
  font-size: 16px;
  font-family: "fontB";
  color: ${Color.WHITE};
`;

export const RangeTitle = styled.View`
  max-width: 280px;
  margin-top: 25px;
  margin-bottom: 15px;
  height: 70px;
`;

export const RangeTitleText = styled.Text`
  height: 70px;
  font-size: 24px;
  font-family: "fontB";
  text-align: center;
  line-height: 2rem;
  color: ${Color.PUPPLE};
`;

export const RangePrice = styled.View`
  width: calc(100% - 72px);
  height: 100px;
  justify-content: center;
  padding: 16px 0;
  align-items: center;
  border-radius: 16px;
  background-color: ${Color.LIGHT_PUPPLE};
`;

export const BottomBtn = styled.View`
  position: fixed;
  bottom: 0;
  margin-bottom: 24px;
  padding: 0 20px;
  width: 100%;
`;

export const BottomBtnContents = styled.TouchableOpacity`
  background-color: ${Color.PUPPLE};
`;

export const QuestionConatiner = styled.View`
  height: 100%;
`;

export const planStyles = StyleSheet.create({
  planHeight: {
    height: "100%",
  },
  header: {
    width: "100%",
    alignItems: "center",
    backgroundImage:
      "linear-gradient(to bottom, #fff, #fff 51%, rgba(255, 255, 255, 0))",
  },
  headerWrap: {
    width: "100%",
    height: 250,
    alignItems: "center",
  },
  planHeader: {
    height: 56,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  intro: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  planIntro: {
    width: "100%",
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 20,
  },
  whiteBg: {
    backgroundColor: Color.WHITE,
  },
  smText: {
    fontSize: 14,
  },
  mdText: {
    lineHeight: 23,
    fontSize: 16,
  },
  mmdText: {
    fontSize: 24,
  },
  mdTextB: {
    fontSize: 16,
    fontFamily: "fontB",
  },
  mLText: {
    fontSize: 18,
    fontFamily: "fontB",
  },
  mmlText: {
    fontSize: 16,
  },
  lgText: {
    lineHeight: 33,
    fontSize: 24,
    fontFamily: "fontB",
  },
  xlText: {
    lineHeight: 33,
    fontSize: 28,
    fontFamily: "fontB",
  },
  xxlText: {
    fontSize: 34,
  },
  highlightText: {
    fontFamily: "fontB",
    color: Color.PUPPLE,
  },
  fontBold: {
    fontFamily: "fontB",
  },
  marginTop6: {
    marginTop: 6,
  },
  marginTop10: {
    marginTop: 10,
  },
  marginTop20: {
    marginTop: 20,
  },
  marginTop8: {
    marginTop: 8,
  },
  alignCenter: {
    alignItems: "center",
    textAlign: "center",
  },
  grayText: {
    marginBottom: 10,
    fontSize: 14,
    fontFamily: "fontM",
    color: Color.LIGHT_GREY_TWO,
    textAlign: "center",
  },
  tab: {
    minWidth: 360,
    maxWidth: 480,
    backgroundColor: Color.WHITE,
  },
  tabLabel: {
    fontSize: 16,
    fontFamily: "fontM",
    color: Color.BLACK_TWO,
  },
  commentDot: {
    marginTop: 7,
  },
  puppleText: {
    color: Color.PUPPLE,
  },
  icon: {
    width: 24,
    height: 24,
  },
  loadingImg: {
    width: 120,
    height: 120,
  },
  loadingCompleteImg: {
    width: 80,
    height: 80,
  },
  loading: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  rangeImg: {
    position: "relative",
    width: "100%",
    height: 266,
  },
  rangeStyle: {
    width: "100%",
    height: 12,
    marginTop: 28,
    shadowColor: "#6432e914",
    shadowRadius: 6,
  },
  slider: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 4,
    left: 0,
  },
  bottomBtn: {
    width: "100%",
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 24,
  },
  bottomBtnText: {
    fontSize: 16,
    fontFamily: "fontM",
    color: Color.WHITE,
  },
  textAlignCenter: {
    textAlign: "center",
  },
});

export const { ids, styles, fullStyles } = MediaStyle.create({
  container: {
    "@media (max-width: 480px)": {},
  },
  planContents: {
    position: "relative",
    width: "100%",
    height: height - 170,
    marginTop: 40,
    justifyContent: "space-between",
    "@media (max-width: 480px)": {
      height: "auto",
    },
  },
  title: {
    paddingTop: 80,
    paddingBottom: 80,
    "@media (max-width: 480px)": {
      paddingTop: 40,
      paddingBottom: 40,
    },
  },
  list: {
    position: "relative",
    height: 279,
    width: "48%",
    marginRight: 16,
    marginBottom: 16,
    paddingLeft: 16,
    paddingTop: 16,
    paddingRight: 16,
    shadowColor: "#6432e914",
    shadowRadius: 16,
    backgroundColor: Color.WHITE,
    borderRadius: 16,
    "@media (max-width: 480px)": {
      height: 225,
    },
  },
  introImg: {
    position: "absolute",
    left: 0,
    bottom: 0,
    width: "100%",
    height: 180,
    "@media (max-width: 480px)": {
      height: 125,
    },
  },
  mbtiImg: {
    width: "100%",
    height: 266,
    "@media (max-width: 480px)": {
      height: 247,
    },
  },
  bottomBtn: {
    width: "100%",
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 24,
    marginTop: 60,
  },
  recommendBtn: {
    bottom: 0,
    width: "100%",
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 24,
    "@media (min-width: 320px) and (max-width: 414px)": {
      position: "initial",
    },
  },
});
