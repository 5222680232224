/* eslint-disable no-useless-escape */
/**
 * 공통 정규식
 */

/**
 * 숫자 정규식
 */
export const REGEX_NUMBER = /[0-12]/g;

/**
 * 공백체크 정규식
 */
export const REGEX_SPACE = /\s/g;

/**
 * 천단위 콤마 정규식
 */
export const REGEX_NUMBER_COMMA = /\B(?=(\d{3})+(?!\d))/g;

/**
 * 10만 이상 단위 입력 방지 정규식
 */
export const REGEX_SIX_HUNDRED_THOUSAND = /^\d{6}$/g;

/**
 * 소수점 두 자리 이상 입력방지 정규식
 */
export const REGEX_HUNDREDS_PLACE_VALUE = /(^\d+$)|(^\d+\.\d{0,2}$)/g;

/**
 * 소수점이 두 번 이상 중복 입력 방지 정규식
 */
export const REGEX_IGNORE_DUPLICATE_DOTS = /^\d*[.]\d*$/g;

/**
 * Email 정규식
 */
export const REGEX_EMAIL =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/**
 * 닉네임 정규식
 */
export const REGEX_NICKNAME =
  /^(?=.*[ㄱ-ㅎ|가-힣|a-z|A-Z|0-9|])[ㄱ-ㅎ|가-힣|a-z|A-Z|0-9|]{2,12}$/;

/**
 * PASSWORD
 */
// 비밀번호 정규식 1 : 숫자, 영문 조합 6~16
export const REGEX_PASSWORD_NUM_ENG = /^(?=.*[a-zA-Z])(?=.*[0-9]).{6,16}$/;

// 비밀번호 정규식 2 : 숫자, 특수문자 조합 8~16
export const REGEX_PASSWORD_NUM_SPE =
  /^(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,16}$/;

// 비밀번호 정규식 3 : 영문, 특수문자 조합 8~16
export const REGEX_PASSWORD_ENG_SPE = /^(?=.*[^a-zA-Z0-9])(?=.*[0-9]).{8,16}$/;
