import { Color } from "@src/constants";
import MediaStyle from "react-native-media-query";
import { StyleSheet, Dimensions } from "react-native";
import styled from "styled-components/native";
import { getBottomSpace } from "react-native-iphone-x-helper";

const { width } = Dimensions.get("window");

/**
 * Global Styles
 */

export const globalStyles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Color.PUPPLE,
  },
  header: {
    width: 480,
    height: 66,
    maxWidth: 480,
  },
  fontM: {
    fontFamily: "fontM",
  },
  fontL: {
    fontFamily: "fontL",
  },
  fontR: {
    fontFamily: "fontR",
  },
  fontB: {
    fontFamily: "fontB",
  },

  dataList: {
    paddingBottom: getBottomSpace() + 65,
  },
  listPaddingM: {
    paddingBottom: getBottomSpace() + 45,
  },
  listPaddingL: {
    paddingBottom: getBottomSpace() + 96,
  },
  listPaddingS: {
    paddingBottom: getBottomSpace() + 30,
  },

  justifySpaceB: {
    justifyContent: "space-between",
  },
  /** Toast */
  centerToast: {
    maxWidth: 440,
    width: width - 40,
    bottom: "50%",
    paddingTop: 12,
    paddingBottom: 13,
    borderRadius: 8,
    justifyContent: "center",
    opacity: 0.8,
    backgroundColor: Color.BLACK,
  },
  bottomToast: {
    position: "relative",
    maxWidth: 480,
    marginTop: 0,
    marginBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    borderRadius: 0,
    width: "100%",
    height: 44,
    justifyContent: "center",
    flexDirection: "row-reverse",
    opacity: 0.8,
    zIndex: 100,
    transform: [
      {
        translateX: 0,
        translateY: 0,
      },
    ],
  },
  toastClose: {
    width: 25,
    height: 25,
  },
  centerToastText: {
    minWidth: "87%",
    fontSize: 15,
    textAlign: "center",
    lineHeight: 22,
    fontFamily: "Noto Sans KR,sans-serif",
  },
  bottomToastText: {
    position: "relative",
    minWidth: "87%",
    fontSize: 14,
    textAlign: "left",
    fontFamily: "Noto Sans KR,sans-serif",
  },

  /** Data List */
  list: {
    flexDirection: "row",
    alignContent: "center",
  },
  box: {
    shadowColor: "rgba(0, 0, 0, 0.12)",
    shadowOffset: { width: 6, height: 4 },
    shadowOpacity: 0.8,
    shadowRadius: 8,
    backgroundColor: Color.WHITE,
  },

  /** swiper */
  swiper: {
    backgroundColor: Color.PUPPLE,
    paddingTop: 6,
    paddingBottom: 10,
    maxWidth: "100%",
  },
  swiperContents: {
    width: "100%",
    maxHeight: 160,
    backgroundColor: Color.PUPPLE,
    borderBottomLeftRadius: 24,
    borderBottomRightRadius: 24,
  },
  slide: {
    width: "auto",
    minWidth: 60,
  },

  highlight: {
    position: "absolute",
    left: 24,
    bottom: 0,
    height: 8,
    opacity: 0.38,
  },
  dot: {
    width: 3,
    height: 3,
    marginRight: 5,
    borderRadius: 50,
  },

  /** graph */
  graph: {
    height: 250,
  },

  fullBtn: {
    width: "100%",
    height: 52,
    borderRadius: 26,
    alignItems: "center",
    justifyContent: "center",
  },
});

// media query
export const { ids, styles } = MediaStyle.create({
  container: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    top: 0,
    minHeight: "100%",
    overflow: "hidden",
    contain: "layout size style",
    marginInline: "calc(50vw - 240px)",
    "@media (max-width: 480px)": {
      marginInline: 0,
      paddingInline: "calc(50vw - 240px)",
    },
    backgroundColor: Color.GREY,
  },
  bg: {
    backgroundColor: Color.GREY,
  },
  popup: {
    "@media (max-width: 480px)": {
      top: 0,
    },
  },
  graph: {
    "@media (max-width: 480px)": {
      height: 200,
    },
    "@media (max-width: 320px)": {
      height: 180,
    },
  },
  paddingS: {
    paddingBottom: getBottomSpace() + 30,
    "@media (max-width: 480px)": {
      paddingBottom: getBottomSpace() + 70,
    },
    "@media screen and (min-width:768px) and (max-width: 1023px)": {
      paddingBottom: getBottomSpace() + 70,
    },
  },
  paddingL: {
    paddingBottom: getBottomSpace() + 30,
    "@media (max-width: 480px)": {
      paddingBottom: getBottomSpace() + 80,
    },
  },
  paddingxL: {
    paddingBottom: getBottomSpace() + 30,
    "@media (max-width: 480px)": {
      paddingBottom: getBottomSpace() + 100,
    },
  },
});

export const ToastCloseBtn = styled.TouchableOpacity`
  position: absolute;
  top: -12px;
  height: 25px;
`;

export const ToastCloseBtnImage = styled.Image`
  width: 25px;
  height: 25px;
`;
