import React, { useEffect, useRef, useState } from "react";
import {
  View,
  StatusBar,
  Text,
  ScrollView,
  Image,
  Dimensions,
} from "react-native";
import { Helmet } from "react-helmet-async";
import { useNavigation, useRoute } from "@react-navigation/native";
import YouTube from "react-youtube";
import getYouTubeId from "get-youtube-id";
import Toast from "react-native-fast-toast";

import { Layouts } from "@src/components/container";
import {
  EditorContainer,
  EditorTitle,
  EditTalkContainer,
  itemStyles,
  SelectHeadInputBtn,
  SelectInputBtn,
  SelectInputBtnText,
  SelectInputContainer,
  BottomContents,
  BottomLeftBtn,
  BottomRightBtn,
  DeleteBtn,
  EditorTextInput,
  EditorTextInputWrap,
  AdminSelectList,
  AdminSelectListItem,
  CheckBoxText,
} from "./styled";
import {
  BottomPopup,
  BottomSelectItem,
  FileAttacher,
  Maybe,
  CircleCheckbox,
} from "@src/components/presentational";
import {
  cancelPopupBtn,
  cancelPopupIcon,
  cancelPopupInfo,
  cancelPopupTitle,
  fileCountToastText,
  youtubeInput,
  youtubePopupBtn,
  youtubePopupInfo,
  youtubePopupTitle,
} from "../configs";
import { Color } from "@src/constants";
import downIcon from "@assets/icons/icon-arrow-down.svg";
import imgIcon from "@assets/icons/icon-image.svg";
import playIcon from "@assets/icons/icon-play.svg";
import deleteIcon from "@assets/icons/icon-delete-gray.png";
import { globalStyles } from "@src/styles/modules/global";
import { CommunityService } from "@src/services";
import { PostCategory } from "@src/types";
import { useRecoilState } from "recoil";
import { CommunityDetailListState } from "@src/store";
import { useAuth } from "@src/hooks/auth";

/**
 * 토크 수정 페이지
 */

const UpdateTalkScreen = () => {
  const { user } = useAuth();
  const userType = String(user?.authorities);

  const navigation = useNavigation<any>();
  const route = useRoute<any>();
  const id = route.params?.id;

  const { height } = Dimensions.get("window");
  const toastRef = useRef(null);
  const textareaRef = useRef(null);

  const [isVisibleTalkPopup, setIsVisibleTalkPopup] = useState<boolean>(false);
  const [isVisibleHeadPopup, setIsVisibleHeadPopup] = useState<boolean>(false);
  const [isFocusedTitle, setIsFocusedTitle] = useState<boolean>(false);

  const [isVisibleYoutubePopup, setIsVisibleYoutubePopup] =
    useState<boolean>(false);
  const [isVisibleCancelPopup, setIsVisibleCancelPopup] =
    useState<boolean>(false);

  const [data, setData] = useRecoilState(CommunityDetailListState);

  const [talkCategory, setTalkCategory] = useState<string>();
  const [headCategory, setHeadCategory] = useState<string>();

  const [forumList, setForumList] = useState<any>([]);
  const [categoryList, setCategoryList] = useState<PostCategory[]>();
  const [forumId, setForumId] = useState<number>(0);
  const [postCategoryId, setPostCategoryId] = useState<number>(0);

  const [adminCategoryList, setAdminCategoryList] = useState<any>([]);
  const [checkedAdminType, setCheckedAdminType] = useState<any>();

  const [title, setTitle] = useState<string>("");
  const [contentsText, setContentsText] = useState<string>("");

  const [youtubeLink, setYoutubeLink] = useState<string>();
  const [addYoutubeLink, setAddYoutubeLink] = useState<string>();
  const [showVideo, setShowVideo] = useState<boolean>(false);
  const [showToast, setShowToast] = useState<boolean>(false);

  const [attachFiles, setAttachFiles] = useState<any>([]);
  const [deleteFileList, setDeleteFileList] = useState<any>([]);
  const [fileData, setFileData] = useState<any>([]);
  const [fileCount, setFileCount] = useState<number>(10);
  const [fileCountLimit, setFileCountLimit] = useState<boolean>(false);

  const [inputHeight, setInputHeight] = useState<number>(0);

  const getForumList = async () => {
    try {
      const data = await CommunityService.getForumList();
      setForumList(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getForumCategory = async (): Promise<void> => {
    try {
      const conditions = {
        forumId,
      };

      const data = await CommunityService.getForumCategory(conditions);
      setCategoryList(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getAdminCategory = async () => {
    try {
      const data = await CommunityService.getAdminCategory();
      setAdminCategoryList(data);
    } catch (error) {
      console.log(error);
    }
  };

  const toDataURL = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
      reader.onerror = reject;
    });
  };

  const putPost = async () => {
    const fileDatas = [];
    const images = fileData.map((item) => item.base64Encode);

    for (let i = 0; i < fileData.length; i++) {
      fileDatas.push({
        base64Encode: await toDataURL(images[i]),
      });
    }

    fileDatas.forEach((element, index) => {
      element["sortNo"] = index + 1;
    });

    try {
      const payload = {
        title,
        content: contentsText,
        youtubeUrl: youtubeLink,
        attachedImageList: fileDatas,
      };

      const data = await CommunityService.putPost(id, payload);
      if (data) {
        navigation.push("community");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleTalkCategory = (item) => {
    setForumId(item.id);
    setTalkCategory(item.title);
  };

  const handleHeadCategory = (item) => {
    setPostCategoryId(item.id);
    setHeadCategory(item.title);
  };

  const handleCancel = () => {
    setIsVisibleCancelPopup(true);
  };

  const handleChangeFile = (files) => {
    const uploadFiles = [...fileData];

    files.forEach((file) => {
      if (uploadFiles.length === 3) {
        setFileCountLimit(true);
      }

      if (uploadFiles.length > 2) {
        toastRef.current.show(fileCountToastText);
        return;
      }

      if (!fileCountLimit) uploadFiles.push(file);
    });

    setFileData(uploadFiles);
  };

  const handleChangeNewFile = (files) => {
    setAttachFiles((prev) => ({
      attachFiles: files,
    }));
  };

  const handleDeleteFileItem = (id) => {
    const newFile = [...Array.from(fileData)];
    newFile.splice(id, 1);
    setFileData(newFile);
  };

  const handleDeleteVideoItem = (id) => {
    setShowVideo(false);
    setYoutubeLink("");
  };

  const handleInputValue = (value) => {
    setYoutubeLink(value);
    setShowVideo(true);
    setIsVisibleYoutubePopup(false);
  };

  const handleAddYoutubeValue = () => {
    if (youtubeLink) {
      setAddYoutubeLink(youtubeLink);
    }

    setShowVideo(true);
    setIsVisibleYoutubePopup(false);
  };

  const onChangeCheck = (value) => {
    setCheckedAdminType(value);
  };

  useEffect(() => {
    if (showToast) {
      toastRef.current.show(fileCountToastText);
    }
  }, [showToast]);

  useEffect(() => {
    getForumList();
  }, []);

  useEffect(() => {
    getForumCategory();
  }, [forumId]);

  useEffect(() => {
    if (userType === "ROLE_ADMIN") {
      getAdminCategory();
    }
  }, [user]);

  useEffect(() => {
    switch (data[0].postCategory.forumId) {
      case 1:
        setForumId(1);
        setTalkCategory("촉촉토크");
        break;
      case 2:
        setForumId(2);
        setTalkCategory("MBTI토크");
        break;
    }

    if (data[0]) {
      setHeadCategory(data[0].postCategory.title);
    }

    setTitle(data[0].title);
    setContentsText(data[0].content);

    const files = [];

    if (data[0].attachedImageList.length !== 0) {
      const imageFiles = data[0].attachedImageList;
      const images = imageFiles.map((item) => item.imageUrl);

      for (let i = 0; i < images.length; i++) {
        files.push({ base64Encode: images[i] });
      }
      setFileData(files);
    }

    setYoutubeLink(data[0].youtubeUrl);
  }, [data]);

  useEffect(() => {
    if (fileData.length === 3) {
      setFileCountLimit(true);
    }
    if (fileData.length > 3) {
      toastRef.current.show(fileCountToastText);
    }
  }, [fileData]);

  const renderSelectTalkItem = () => {
    return (
      <SelectInputBtn
        onPress={() => setIsVisibleTalkPopup(!isVisibleTalkPopup)}
        activeOpacity={1}
        style={itemStyles.selectInput}
      >
        <SelectInputBtnText>{talkCategory}</SelectInputBtnText>
        <Image source={downIcon} resizeMode="contain" style={itemStyles.icon} />
      </SelectInputBtn>
    );
  };

  const renderSelectHeadItem = () => {
    return (
      <SelectHeadInputBtn
        onPress={() => setIsVisibleHeadPopup(!isVisibleHeadPopup)}
        activeOpacity={1}
        style={itemStyles.selectInput}
      >
        <SelectInputBtnText>{headCategory}</SelectInputBtnText>
        <Image source={downIcon} resizeMode="contain" style={itemStyles.icon} />
      </SelectHeadInputBtn>
    );
  };

  const renderAdminSelectItem = () => {
    return (
      <AdminSelectList>
        {adminCategoryList.map((item, index) => (
          <AdminSelectListItem key={index}>
            <CircleCheckbox
              isChecked={checkedAdminType === item.type}
              onChangeCheck={() => onChangeCheck(item.type)}
              outline
            />
            <CheckBoxText>
              {item.type === "NOTICE" ? "공지" : "매거진"}
            </CheckBoxText>
          </AdminSelectListItem>
        ))}
      </AdminSelectList>
    );
  };

  const renderEditor = () => {
    return (
      <ScrollView
        bounces={false}
        scrollEventThrottle={1}
        contentContainerStyle={{ flexGrow: 1 }}
        showsVerticalScrollIndicator={false}
      >
        <EditorContainer>
          <EditorTitle
            value={title}
            placeholder="제목을 입력해 주세요. (최대20자)"
            placeholderTextColor={Color.LIGHT_GREY_FOUR}
            style={[
              isFocusedTitle && {
                borderBottomColor: Color.PUPPLE,
              },
              title && { fontWeight: "bold" },
            ]}
            onChangeText={(value) => setTitle(value)}
            onFocus={() => setIsFocusedTitle(true)}
            onBlur={() => setIsFocusedTitle(false)}
          />
          <EditorTextInputWrap>
            {showVideo && (
              <View style={itemStyles.video}>
                <YouTube
                  videoId={getYouTubeId(addYoutubeLink)}
                  opts={{
                    width: "100%",
                    height: "200px",
                  }}
                />
                <DeleteBtn onPress={handleDeleteVideoItem} activeOpacity={1}>
                  <Image
                    source={deleteIcon}
                    resizeMode="contain"
                    style={itemStyles.deleteIcon}
                  />
                </DeleteBtn>
              </View>
            )}
            {(fileData || []).map((file, index) => (
              <div key={index} style={itemStyles.editItem} className="file">
                <img src={file.base64Encode} style={itemStyles.image} />
                <DeleteBtn
                  onPress={() => handleDeleteFileItem(index)}
                  activeOpacity={1}
                >
                  <Image
                    source={deleteIcon}
                    resizeMode="contain"
                    style={itemStyles.deleteIcon}
                  />
                </DeleteBtn>
              </div>
            ))}
            <EditorTextInput
              ref={textareaRef}
              value={contentsText}
              placeholder={
                fileData.length === 0 && !showVideo
                  ? "내용을 입력해 주세요."
                  : ""
              }
              placeholderTextColor={Color.LIGHT_GREY_FOUR}
              onChangeText={(value) => setContentsText(value)}
              onContentSizeChange={(e) => {
                setInputHeight(e.nativeEvent.contentSize.height);
              }}
              style={{ height: Math.max(35, inputHeight) }}
              maxLength={5000}
              multiline
            />
          </EditorTextInputWrap>
        </EditorContainer>
      </ScrollView>
    );
  };

  const renderFooterContents = () => {
    return (
      <BottomContents>
        <View style={[itemStyles.bottomBtn, itemStyles.bar]}>
          <BottomLeftBtn>
            <Image
              source={imgIcon}
              resizeMode="contain"
              style={itemStyles.icon}
            />
            <Text style={itemStyles.smText}>이미지 추가</Text>
            <FileAttacher
              files={fileData}
              meta={{ accept: ".png, .jpg, .jpeg" }}
              attachedFiles={attachFiles}
              onChangeFile={handleChangeFile}
              onChangeAttachedFile={handleChangeNewFile}
              showToast={setShowToast}
            />
          </BottomLeftBtn>
        </View>
        <View style={itemStyles.bottomBtn}>
          <BottomRightBtn onPress={() => setIsVisibleYoutubePopup(true)}>
            <Image
              source={playIcon}
              resizeMode="contain"
              style={itemStyles.icon}
            />
            <Text style={itemStyles.smText}>YouTube 추가</Text>
          </BottomRightBtn>
        </View>
      </BottomContents>
    );
  };

  return (
    <>
      <StatusBar barStyle="light-content" backgroundColor="#6432e9" />
      <Helmet>
        <title>토크 수정 | 촉촉박사</title>
      </Helmet>
      <Layouts
        transparent
        cancelMode
        handleCancel={handleCancel}
        titleMode
        title="토크 수정"
        rightBtn
        rightText="저장"
        onClickRightBtn={putPost}
      >
        <EditTalkContainer>
          <SelectInputContainer>
            {renderSelectTalkItem()}
            {renderSelectHeadItem()}
          </SelectInputContainer>
          <>
            {userType === "ROLE_ADMIN" && {
              renderAdminSelectItem,
            }}
          </>
          {renderEditor()}
          {renderFooterContents()}
        </EditTalkContainer>
      </Layouts>
      <Maybe
        test={isVisibleTalkPopup}
        children={
          <BottomSelectItem
            isOpen={isVisibleTalkPopup}
            data={forumList}
            onClose={() => setIsVisibleTalkPopup(false)}
            onChangeId={(item) => handleTalkCategory(item)}
          />
        }
      />
      <Maybe
        test={isVisibleHeadPopup}
        children={
          <BottomSelectItem
            isOpen={isVisibleHeadPopup}
            data={categoryList}
            onClose={() => setIsVisibleHeadPopup(false)}
            onChangeId={(item) => handleHeadCategory(item)}
          />
        }
      />
      <Maybe
        test={isVisibleYoutubePopup}
        children={
          <BottomPopup
            isOpen={isVisibleYoutubePopup}
            onClose={() => setIsVisibleYoutubePopup(false)}
            title={youtubePopupTitle}
            info={youtubePopupInfo}
            btnText={youtubePopupBtn}
            input
            placeholder={youtubeInput}
            inputValue={youtubeLink}
            handleInputValue={(value) => setYoutubeLink(value)}
            onClick={() => handleAddYoutubeValue()}
          />
        }
      />
      <Maybe
        test={isVisibleCancelPopup}
        children={
          <BottomPopup
            isOpen={isVisibleCancelPopup}
            onClose={() => setIsVisibleCancelPopup(false)}
            title={cancelPopupTitle}
            info={cancelPopupInfo}
            icon={cancelPopupIcon}
            btnText={cancelPopupBtn}
            onClick={() => navigation.push("community")}
          />
        }
      />
      <Toast
        ref={toastRef}
        offset={height / 2}
        style={globalStyles.centerToast}
        textStyle={globalStyles.centerToastText}
      />
    </>
  );
};

export default UpdateTalkScreen;
