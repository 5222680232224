import styled from "styled-components/native";
import { Color } from "@src/constants";
import { StyleSheet } from "react-native";

export const CommonHeaderWrap = styled.View`
  height: 66px;
  contain: layout size style;
`;

export const CommonHeaderInner = styled.View`
  height: 66px;
  flex-direction: row;
  align-items: center;
  justify-content: ${({
    bgColor,
    hiddenRight,
  }: {
    bgColor?: boolean;
    hiddenRight?: boolean;
  }) => (hiddenRight ? "center" : "space-between")};
  padding: 20px;
  background-color: ${({ bgColor }: { bgColor?: boolean }) =>
    bgColor ? Color.WHITE : Color.PUPPLE};
`;

export const CommonHeaderLogo = styled.View`
  width: 112px;
  height: 26px;
`;

export const CommonHeaderLogoBtn = styled.TouchableHighlight``;

export const CommonHeaderLogoImage = styled.Image`
  width: 112px;
  height: 26px;
`;

export const BackBtn = styled.TouchableHighlight`
  margin-right: 10px;
  position: ${({ hiddenRight }: { hiddenRight?: boolean }) =>
    hiddenRight ? "absolute" : ""};
  top: ${({ hiddenRight }: { hiddenRight?: boolean }) =>
    hiddenRight ? "20px" : ""};
  left: ${({ hiddenRight }: { hiddenRight?: boolean }) =>
    hiddenRight ? "20px" : ""};
  z-index: 1;
`;

export const BackImage = styled.Image`
  width: 24px;
  height: 24px;
`;

export const HeaderTitle = styled.View``;

export const HeaderTitleText = styled.Text`
  text-align: center;
  font-size: 18px;
  font-family: "fontB";
  letter-spacing: -0.45px;
`;

export const HomeBtn = styled.TouchableHighlight``;

export const HomeImage = styled.Image`
  width: 24px;
  height: 24px;
`;

export const MoreImage = styled.Image`
  width: 24px;
  height: 24px;
`;

export const HeaderRightContents = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const HamburgerBtn = styled.TouchableHighlight``;

export const HamburgerImage = styled.Image`
  width: 24px;
  height: 24px;
`;

export const LoginBtn = styled.TouchableHighlight`
  margin-right: 16px;
`;

export const LoginBtnText = styled.Text`
  color: ${Color.WHITE};
`;

export const RightBtn = styled.TouchableOpacity``;

export const styles = StyleSheet.create({
  text: {
    fontSize: 14,
    fontWeight: "500",
    color: Color.PUPPLE,
  },
});
