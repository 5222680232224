import styled from "styled-components/native";
import { StyleSheet } from "react-native";
import { Color } from "@src/constants";

export const Table = styled.View`
  width: 100%;
  border-bottom-width: 1px;
  border-bottom-color: #e2e2e2;
`;

export const TableCell = styled.View`
  flex-direction: row;
  justify-content: space-between;
  border-top-width: 1px;
  border-top-color: #e2e2e2;
`;

export const TableCellTitle = styled.View`
  width: 60px;
  align-items: center;
  justify-content: center;
  border-right-width: 1px;
  border-right-color: #e2e2e2;
  background-color: ${Color.GREY};
`;

export const TableCellTopContents = styled.View`
  min-height: 80px;
  max-height: 120px;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: ${({ length }: { length?: number }) =>
    length === 3 ? "calc(100% / 3 - 20px)" : "calc(100% / 2 - 30px)"};
  border-right-width: ${({ id, length }: { id?: number; length?: number }) =>
    (length === 2 && id === 0) ||
    (length === 3 && id === 0) ||
    (length === 3 && id === 1)
      ? "1px"
      : ""};
  border-right-color: ${({ id, length }: { id?: number; length?: number }) =>
    (length === 2 && id === 0) ||
    (length === 3 && id === 0) ||
    (length === 3 && id === 1)
      ? "#e2e2e2"
      : ""};
  background-color: ${Color.LIGHT_PUPPLE_FOUR};
`;

export const TableCellTopContentsText = styled.Text`
  font-size: 13px;
  font-family: "fontB";
  text-align: center;
  color: ${Color.WHITE};
`;

export const TableCellTitleText = styled.Text`
  width: 25px;
  font-size: 13px;
  font-family: "fontB";
`;

export const TableCellContents = styled.View`
  position: relative;
  min-height: 80px;
  max-height: 130px;
  padding: 23px 0;
  width: ${({ length }: { length?: number }) =>
    length === 3 ? "calc(100% / 3 - 20px)" : "calc(100% / 2 - 30px)"};
  border-right-width: ${({ id, length }: { id?: number; length?: number }) =>
    (length === 2 && id === 0) ||
    (length === 3 && id === 0) ||
    (length === 3 && id === 1)
      ? "1px"
      : ""};
  border-right-color: ${({ id, length }: { id?: number; length?: number }) =>
    (length === 2 && id === 0) ||
    (length === 3 && id === 0) ||
    (length === 3 && id === 1)
      ? "#e2e2e2"
      : ""};
  align-items: center;
  justify-content: center;
  background-color: ${Color.WHITE};
`;

export const TableCellContentsText = styled.Text`
  font-family: "fontR";
`;

export const TableCellContentsSubText = styled.Text`
  position: absolute;
  right: 9px;
  bottom: 8px;
  font-size: 12px;
  font-family: "fontR";
  color: ${Color.LIGHT_GREY_TWO};
`;

export const TableCellUnitContentsText = styled.Text`
  width: calc(100% - 30px);
  text-align: center;
  font-family: "fontR";
`;

export const TableContentIcon = styled.Image`
  width: 48px;
  height: 48px;
  margin-bottom: 10px;
`;

export const styles = StyleSheet.create({
  text: {
    fontSize: 13,
    fontWeight: "500",
    textAlign: "center",
  },
  provenance: {
    paddingLeft: 10,
    paddingRight: 10,
  },
});
