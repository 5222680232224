/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from "react";

interface MaybeProps {
  test: any;
  children: JSX.Element;
}

const Maybe = ({ test, children }: MaybeProps): JSX.Element => {
  return <>{test ? children : null}</>;
};

export default Maybe;
