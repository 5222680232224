import styled from "styled-components/native";
import { Dimensions, StyleSheet } from "react-native";
import MediaStyle from "react-native-media-query";

const { height } = Dimensions.get("window");

export const Dimmed = styled.View`
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  position: ${({ isOpen }: { isOpen: boolean }) => (isOpen ? "fixed" : "")};
  background-color: ${({ isOpen }: { isOpen: boolean }) =>
    isOpen ? "#000" : ""};
  z-index: ${({ isOpen }: { isOpen: boolean }) => (isOpen ? "1001" : "")};
`;

export const PopupContents = styled.View`
  position: relative;
  height: 100%;
  min-height: 100%;
`;

export const CloseBtn = styled.TouchableHighlight`
  align-items: flex-end;
`;

export const CloseBtnIcon = styled.Image`
  width: 24px;
  height: 24px;
`;

export const itemStyles = StyleSheet.create({
  icon: {
    width: 24,
    height: 24,
  },
});

export const { ids, styles } = MediaStyle.create({
  popup: {
    height: height - 200,
    marginInline: "calc(50vw - 232px)",
    marginVertical: 100,
    bottom: 0,
    top: 0,
    transition: "top 0.6s cubic-bezier(0.165, 0.84, 0.44, 1)",
    zIndex: 1001,
    "@media (max-width: 480px)": {
      margin: 0,
    },
  },
  closeBtn: {
    paddingBottom: 60,
    "@media (max-width: 480px)": {
      paddingBottom: 10,
      paddingTop: 16,
      paddingRight: 20,
    },
  },
});
