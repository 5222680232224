import React, { useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { refreshTokenState, tokenState } from "@src/store";
import ReactDOM from "react-dom";

interface LoginAsSocialProps {
  children?: any;
}

/**
 * 소셜 로그인
 */

const LoginAsSocial: React.FC<LoginAsSocialProps> = ({ children }) => {
  const container = useRef(document.createElement("div"));

  const [token, setToken] = useRecoilState(tokenState);
  const [refreshToken, setRefreshToken] = useRecoilState(refreshTokenState);
  const [redirectUrl, setRedirectUrl] = useState<string>();

  const getParams = (str, val) => {
    let start = 0;
    let end = 0;
    let ans;
    let text = val + "=";

    start = str.indexOf(text) + text.length;
    end = str.indexOf("&", start);

    if (end === -1) {
      ans = str.substring(start);
    } else {
      end = str.indexOf("&", start);
      ans = str.substring(start, end);
    }
    return ans;
  };

  const getRedirectParams = (name) => {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");

    var results = regex.exec(location.search);
    setRedirectUrl(getParams(results["input"], "referrer"));

    return results === null
      ? ""
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  };

  const doLogin = () => {
    const accessToken = getRedirectParams("access-token");
    const refreshToken = getRedirectParams("refresh-token");
    setToken(accessToken);
    setRefreshToken(refreshToken);
  };

  useEffect(() => {
    doLogin();
  }, []);

  useEffect(() => {
    if (token) {
      location.href = redirectUrl;
    }
  }, [redirectUrl, token]);

  return ReactDOM.createPortal(children, container.current);
};

export default LoginAsSocial;
