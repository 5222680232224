import React, { ReactElement, useEffect, useRef, useState } from "react";
import {
  FlatList,
  Text,
  Animated,
  Easing,
  View,
  ScrollView,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import {
  CloseBtn,
  CloseBtnIcon,
  Dimmed,
  HamburgerContainer,
  HamburgerContents,
  HamburgerContentsHeader,
  HeaderLogo,
  HeaderLogoIcon,
  HeaderInfoText,
  HeaderTitleText,
  LoginBtn,
  LoginBtnText,
  HamburgerContentsMenu,
  MenuList,
  MenuListItem,
  MenuListItemText,
  HamburgerContentsFooter,
  SnsBtnContainer,
  SnsBtn,
  SnsBtnIcon,
  styles,
  MenuListIcon,
  TermsContainer,
  TermsBtn,
  TermsBtnText,
  MenuListMoveIcon,
  MenuListContents,
  MenuListMoveIconContents,
  DropdownMenu,
  DropdownMenuText,
  MenuListDropdown,
  MenuListDropdownContents,
  DropdownMenuList,
  Logout,
  ids,
  menuStyles,
} from "./styled";
import { SwitchCase } from "..";
import { snsList, termsList } from "@src/screens/sampleDatas";
import { useAuth } from "@src/hooks/auth";
import closeIcon from "@assets/icons/icon-close-black.svg";
import logoIcon from "@assets/logo-icon.svg";
import dropdownIcon from "@assets/icons/icon-dropdown.svg";
import { useRecoilState } from "recoil";
import { ProfileType } from "@src/types";
import { CommunityProfile } from "@src/store";
import { CommunityService } from "@src/services";

interface HamburgerMenuProps {
  isOpen?: any;
  onClose?: () => void;
  menuList?: any;
  handleLogin?: () => void;
  handleLogout?: () => void;
  loginPopup?: () => void;
}

const HamburgerMenu = ({
  isOpen,
  onClose,
  menuList,
  handleLogin,
  handleLogout,
}: HamburgerMenuProps): ReactElement => {
  const navigation = useNavigation<any>();
  const { isLoggedIn, user } = useAuth();

  const [open, setOpen] = useState<boolean>(false);
  const animatedController = useRef(new Animated.Value(0)).current;
  const [bodySectionHeight, setBodySectionHeight] = useState<number>(52);

  const [myProfileData, setMyProfileData] =
    useRecoilState<ProfileType>(CommunityProfile);

  const arrowAngle = animatedController.interpolate({
    inputRange: [0, 1],
    outputRange: ["0rad", `${Math.PI}rad`],
  });

  const bodyHeight = animatedController.interpolate({
    inputRange: [0, 1],
    outputRange: [0, bodySectionHeight],
  });

  const toggleListItem = () => {
    if (open) {
      Animated.timing(animatedController, {
        duration: 300,
        toValue: 0,
        useNativeDriver: true,
        easing: Easing.bezier(0.4, 0.0, 0.2, 1),
      }).start();
    } else {
      Animated.timing(animatedController, {
        duration: 300,
        toValue: 1,
        useNativeDriver: true,
        easing: Easing.bezier(0.4, 0.0, 0.2, 1),
      }).start();
    }
    setOpen(!open);
  };

  const getMyProfile = async () => {
    try {
      const data = await CommunityService.getMyProfile();
      setMyProfileData(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      getMyProfile();
    }
  }, [isLoggedIn]);

  return (
    <>
      <Dimmed
        isOpen={isOpen}
        onStartShouldSetResponder={() => true}
        onResponderGrant={() => onClose()}
      />
      <HamburgerContainer isOpen={isOpen}>
        <HamburgerContents>
          <CloseBtn onPress={onClose} underlayColor="transparent">
            <CloseBtnIcon source={closeIcon} resizeMode="contain" />
          </CloseBtn>
          <HamburgerContentsHeader>
            <SwitchCase
              tests={[
                {
                  test: isLoggedIn,
                  component: (
                    <>
                      <HeaderLogo>
                        <HeaderLogoIcon
                          source={{
                            uri: myProfileData?.profileImageUrl
                              ? myProfileData?.profileImageUrl
                              : logoIcon,
                          }}
                          resizeMode="cover"
                        />
                      </HeaderLogo>
                      <HeaderTitleText>
                        오늘도 촉촉함의 비밀, 파헤쳐 볼까요?
                      </HeaderTitleText>
                      <HeaderInfoText>
                        {myProfileData?.nickname}{" "}
                        <Text style={menuStyles.text}>님</Text>
                      </HeaderInfoText>
                    </>
                  ),
                },
              ]}
              defaultComponent={
                <>
                  <HeaderTitleText>깨끗하게, 맑게, 촉촉하게~</HeaderTitleText>
                  <HeaderInfoText>촉촉박사가 되어볼까요?</HeaderInfoText>
                  <LoginBtn onPress={handleLogin} activeOpacity={1}>
                    <LoginBtnText>1초 만에 로그인하기</LoginBtnText>
                  </LoginBtn>
                </>
              }
            />
          </HamburgerContentsHeader>
          <ScrollView
            bounces={false}
            scrollEventThrottle={1}
            contentContainerStyle={[{ flexGrow: 1, flex: 1 }]}
            showsVerticalScrollIndicator={false}
          >
            <HamburgerContentsMenu>
              <View dataSet={{ media: ids.menuList }} style={styles.menuList}>
                <FlatList
                  data={menuList}
                  renderItem={({ index, item }) => {
                    return (
                      <MenuList>
                        <SwitchCase
                          tests={[
                            {
                              test: item.type === 0,
                              component: (
                                <MenuListContents
                                  onPress={() => navigation.push(item.url)}
                                  activeOpacity={1}
                                >
                                  <MenuListItem>
                                    <MenuListItemText>
                                      {item.name}
                                    </MenuListItemText>
                                    {item.icon && (
                                      <MenuListIcon source={item.icon} />
                                    )}
                                  </MenuListItem>
                                  {item.state === 0 && (
                                    <MenuListMoveIconContents>
                                      <MenuListMoveIcon source={dropdownIcon} />
                                    </MenuListMoveIconContents>
                                  )}
                                </MenuListContents>
                              ),
                            },
                            {
                              test: item.state === 0,
                              component: (
                                <MenuListDropdownContents>
                                  <MenuListDropdown
                                    onPress={() => toggleListItem()}
                                    activeOpacity={1}
                                  >
                                    <MenuListItem>
                                      <MenuListItemText>
                                        {item.name}
                                      </MenuListItemText>
                                    </MenuListItem>
                                    <Animated.View
                                      style={{
                                        transform: [{ rotateZ: arrowAngle }],
                                      }}
                                    >
                                      <MenuListMoveIconContents>
                                        <MenuListMoveIcon
                                          source={dropdownIcon}
                                        />
                                      </MenuListMoveIconContents>
                                    </Animated.View>
                                  </MenuListDropdown>
                                  <Animated.View
                                    style={[
                                      menuStyles.accordion,
                                      { height: bodyHeight },
                                    ]}
                                  >
                                    <DropdownMenuList
                                      onLayout={() =>
                                        isLoggedIn
                                          ? setBodySectionHeight(107)
                                          : setBodySectionHeight(52)
                                      }
                                    >
                                      {isLoggedIn ? (
                                        <View>
                                          {item.subMenu?.map(
                                            (subItem, index) => (
                                              <DropdownMenu
                                                key={index}
                                                onPress={() =>
                                                  navigation.push(subItem.url)
                                                }
                                                activeOpacity={1}
                                              >
                                                <DropdownMenuText>
                                                  {subItem.name}
                                                </DropdownMenuText>
                                              </DropdownMenu>
                                            )
                                          )}
                                        </View>
                                      ) : (
                                        <DropdownMenu
                                          key={index}
                                          onPress={() =>
                                            navigation.push("skinPlan")
                                          }
                                          activeOpacity={1}
                                        >
                                          <DropdownMenuText>
                                            피부 플랜 만들기
                                          </DropdownMenuText>
                                        </DropdownMenu>
                                      )}
                                    </DropdownMenuList>
                                  </Animated.View>
                                </MenuListDropdownContents>
                              ),
                            },
                            {
                              test: user && !item.value,
                              component: (
                                <MenuListContents
                                  onPress={() => navigation.push(item.url)}
                                  activeOpacity={1}
                                >
                                  <MenuListItem>
                                    <MenuListItemText>
                                      {item.name}
                                    </MenuListItemText>
                                  </MenuListItem>
                                </MenuListContents>
                              ),
                            },
                            {
                              test: user && item.value,
                              component: (
                                <Logout
                                  onPress={handleLogout}
                                  activeOpacity={1}
                                >
                                  <MenuListItemText>
                                    {item.name}
                                  </MenuListItemText>
                                </Logout>
                              ),
                            },
                          ]}
                        />
                      </MenuList>
                    );
                  }}
                />

                <HamburgerContentsFooter>
                  <SnsBtnContainer>
                    {snsList.map((item, index) => (
                      <SnsBtn
                        key={index}
                        onPress={() => window.open(item.link)}
                        activeOpacity={1}
                      >
                        <SnsBtnIcon source={item.icon} resizeMode="contain" />
                      </SnsBtn>
                    ))}
                  </SnsBtnContainer>
                  <TermsContainer>
                    {termsList.map((item, index) => (
                      <TermsBtn
                        key={index}
                        index={index}
                        onPress={() => navigation.push(item.url)}
                        activeOpacity={1}
                      >
                        <TermsBtnText>{item.name}</TermsBtnText>
                      </TermsBtn>
                    ))}
                  </TermsContainer>
                </HamburgerContentsFooter>
              </View>
            </HamburgerContentsMenu>
          </ScrollView>
        </HamburgerContents>
      </HamburgerContainer>
    </>
  );
};

export default HamburgerMenu;
