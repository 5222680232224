/* eslint-disable @typescript-eslint/no-explicit-any */
import RestService from "../RestService";

/**
 * Community API
 * @see https://
 */

const PREFIX_URL = `/v1/community`;

class CommunityService {
  /**
   * 게시글 주제 리스트
   * @return {Promise<any>}
   */
  public getForumList = async (): Promise<any> => {
    const url: string = `${PREFIX_URL}/forum`;

    try {
      const { data } = await RestService.get(url);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  /**
   * 게시글 조회
   * @return {Promise<any>}
   */
  public getForumPostList = async ({
    forumId,
    postCategoryId,
    keyword,
    offset,
  }): Promise<any> => {
    const url: string = `${PREFIX_URL}/forum/${forumId}/post?post-category-id=${
      postCategoryId + "&"
    }keyword=${keyword + "&"}offset=${offset}`;
    try {
      const { data } = await RestService.get(url);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  /**
   * 게시글 주제별 말머리(카테고리) 리스트
   * @return {Promise<any>}
   */
  public getForumCategory = async ({ forumId }): Promise<any> => {
    const url: string = `${PREFIX_URL}/forum/${forumId}/category`;

    try {
      const { data } = await RestService.get(url);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  /**
   * 게시글 상세 보기
   * @return {Promise<any>}
   */
  public getForumPostDetail = async ({ postId }): Promise<any> => {
    const url: string = `${PREFIX_URL}/post/${postId}`;

    try {
      const data = await RestService.get(url);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  /**
   * 게시글 좋아요
   * @return {Promise<any>}
   */
  public getPostLike = async ({ postId, action }): Promise<any> => {
    const url: string = `${PREFIX_URL}/post/${postId}/like?action=${action}`;

    try {
      const data = await RestService.get(url);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  /**
   * 게시글 등록
   * @return {Promise<any>}
   */
  public postPost = async (forumId, postCategoryId, payload): Promise<any> => {
    const url: string = `${PREFIX_URL}/forum/${forumId}/post-category/${postCategoryId}/post`;

    try {
      const data = await RestService.post(url, payload);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  /**
   * 게시글 댓글 리스트
   * @return {Promise<any>}
   */
  public getPostComment = async ({ postId, pageIdx }): Promise<any> => {
    const url: string = `${PREFIX_URL}/post/${postId}/comment?page-idx=${pageIdx}`;

    try {
      const data = await RestService.get(url);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  /**
   * 게시글 댓글 등록
   * @return {Promise<any>}
   */
  public postComment = async (postId, payload): Promise<any> => {
    const url: string = `${PREFIX_URL}/post/${postId}/comment`;

    try {
      const data = await RestService.post(url, payload);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  /**
   * 게시글 대댓글 등록
   * @return {Promise<any>}
   */
  public postReplyComment = async (
    postId,
    commentId,
    payload
  ): Promise<any> => {
    const url: string = `${PREFIX_URL}/post/${postId}/comment/${commentId}/comment`;

    try {
      const data = await RestService.post(url, payload);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  /**
   * 게시글 수정
   * @return {Promise<any>}
   */
  public putPost = async (postId, payload): Promise<any> => {
    const url: string = `${PREFIX_URL}/post/${postId}`;

    try {
      const { data } = await RestService.put(url, payload);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  /**
   * 게시글 삭제
   * @return {Promise<any>}
   */
  public deletePost = async (postId): Promise<any> => {
    const url: string = `${PREFIX_URL}/post/${postId}`;

    try {
      const { data } = await RestService.delete(url);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  /**
   * 댓글 신고
   * @return {Promise<any>}
   */
  public postCommentReport = async (postId, commentId, cause): Promise<any> => {
    const url: string = `${PREFIX_URL}/post/${postId}/comment/${commentId}/complaint?cause=${cause}`;

    try {
      const data = await RestService.post(url);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  /**
   * 댓글 삭제
   * @return {Promise<any>}
   */
  public deleteComment = async (postId, commentId): Promise<any> => {
    const url: string = `${PREFIX_URL}/post/${postId}/comment/${commentId}`;

    try {
      const data = await RestService.delete(url);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  /**
   * 프로필 조회
   * @return {Promise<any>}
   */
  public getProfile = async (userId): Promise<any> => {
    const url: string = `v1/user/${userId}/profile/community`;

    try {
      const data = await RestService.get(url);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  /**
   * 프로필 게시글 모아보기
   * @return {Promise<any>}
   */
  public getProfilePost = async ({ userId, pageIdx }): Promise<any> => {
    const url: string = `${PREFIX_URL}/user/${userId}/post?page-idx=${pageIdx}`;

    try {
      const { data } = await RestService.get(url);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  /**
   * 프로필 댓글 모아보기
   * @return {Promise<any>}
   */
  public getProfileComment = async ({ userId, pageIdx }): Promise<any> => {
    const url: string = `${PREFIX_URL}/user/${userId}/comment?page-idx=${pageIdx}`;

    try {
      const { data } = await RestService.get(url);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  /**
   * 내 프로필 게시글 모아보기
   * @return {Promise<any>}
   */
  public getMyProfilePost = async ({ pageIdx }): Promise<any> => {
    const url: string = `${PREFIX_URL}/user/me/post?page-idx=${pageIdx}`;

    try {
      const { data } = await RestService.get(url);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  /**
   * 내 프로필 댓글 모아보기
   * @return {Promise<any>}
   */
  public getMyProfileComment = async ({ pageIdx }): Promise<any> => {
    const url: string = `${PREFIX_URL}/user/me/comment?page-idx=${pageIdx}`;

    try {
      const { data } = await RestService.get(url);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  /**
   * 내 프로필 이미지 교체
   * @return {Promise<any>}
   */
  public patchMyProfile = async (payload): Promise<any> => {
    const url: string = `v1/user/me/profile`;

    try {
      const { data } = await RestService.patch(url, payload);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  /**
   * 내 프로필 조회
   * @return {Promise<any>}
   */
  public getMyProfile = async (): Promise<any> => {
    const url: string = `v1/user/me/profile/community`;

    try {
      const { data } = await RestService.get(url);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  /**
   * 어드민 게시글 카테고리
   * @return {Promise<any>}
   */
  public getAdminCategory = async (): Promise<any> => {
    const url: string = `${PREFIX_URL}/post/admin-posting-type`;

    try {
      const { data } = await RestService.get(url);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

export default new CommunityService();
