import React, { useEffect } from "react";
import { ThemeProvider } from "styled-components";
import * as Font from "expo-font";
import { HelmetProvider } from "react-helmet-async";
import { RecoilRoot } from "recoil";
import theme from "@src/utils/theme";
import RootNavigator from "@src/navigations";
import { UserEffect } from "@src/components/presentational";

export default function App() {
  const getFonts = async () => {
    await Font.loadAsync({
      fontM: require("./assets/fonts/NotoSansKR-Medium.ttf"),
      fontR: require("./assets/fonts/NotoSansKR-Regular.ttf"),
      fontB: require("./assets/fonts/NotoSansKR-Bold.ttf"),
      fontJ: require("./assets/fonts/Jalnan.ttf"),
    });
  };

  useEffect(() => {
    getFonts();
  }, []);

  return (
    <RecoilRoot>
      <UserEffect />
      <ThemeProvider theme={theme}>
        <HelmetProvider>
          <RootNavigator />
        </HelmetProvider>
      </ThemeProvider>
    </RecoilRoot>
  );
}
