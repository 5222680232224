import React, { ReactElement, useRef } from "react";
import { View, Image, Text, Dimensions } from "react-native";
import { useNavigation } from "@react-navigation/native";
import Toast from "react-native-fast-toast";
import { useRoute } from "@react-navigation/native";

import { SkinMbtiResultType } from "@src/screens/mbti/SkinMbtiDetailScreen/configs";
import {
  Hightlight,
  HightlightContents,
  MbtiItemContents,
  MbtiItemWrap,
  MbtiRecommend,
  MbtiRecommendImg,
  MbtiDescription,
  MbtiTagText,
  MbtiType,
  MbtiTypeContents,
  MbtiDesc,
  MbtiDescText,
  MbtiTypeDescText,
  MbtiTypeText,
  MbtiTypeTitle,
  styles,
  Dot,
  MbtiRecommendSurgeryTitle,
  MbtiRecommendImgContents,
  MbtiRecommendSurgeryTitleContents,
  MbtiRecommendSurgeryInfo,
  itemStyles,
  ids,
  MbtiShareContents,
  SkinMbtiSnsBtn,
  SkinMbtiSnsBtnContents,
  MbtiBottomBtnContents,
  MbtiBottomBtnAll,
  MbtiBottomBtnRestart,
  MbtiBottomBtnText,
  MbtiRecommendItem,
  MbtiBottomPlanContents,
  MbtiBottomPlanBtn,
  MbtiBottomPlanBtnText,
  MbtiBottomPlanBtnContents,
  MbtiBottomTextContents,
} from "./styled";
import { Maybe, ShareKakao, ShareSns, SwitchCase } from "..";
import { globalStyles } from "@src/styles/modules/global";
import surgeryImg01 from "@assets/images/mbti/mbti-surgery01.png";
import surgeryImg02 from "@assets/images/mbti/mbti-surgery02.png";
import mbtiKakaoIcon from "@assets/images/mbti/mbti-sns-kakao.png";
import mbtiShareIcon from "@assets/images/mbti/mbti-sns-share.png";
import mbtiListIcon from "@assets/icons/icon-list.svg";
import mbtiRestartIcon from "@assets/icons/icon-restart.svg";
import {
  clipboardCopyFailText,
  mbtiLinkCopyText,
  mbtiResultShareText,
  mbtiShareText,
} from "@src/screens/sampleDatas";
import { useAuth } from "@src/hooks/auth";
import { useRecoilState } from "recoil";
import { mbtiResultState, resultLoadingState } from "@src/store";
import { analytics } from "@src/hooks/analytics";

interface MbtiItemProps {
  type: any;
  onClick?: () => void;
  planMode?: boolean;
  onClickNext?: () => void;
}

const MbtiItem = ({
  type,
  onClick,
  planMode,
  onClickNext,
}: MbtiItemProps): ReactElement => {
  const { height } = Dimensions.get("window");

  const { isLoggedIn, user } = useAuth();

  const route = useRoute<any>();
  const state = Number(route.params?.state);

  const navigation = useNavigation<any>();
  const shareToastRef = useRef(null);

  const [mbtiResult, setMbtiResult] = useRecoilState(mbtiResultState);
  const [isLoading, setIsLoading] = useRecoilState(resultLoadingState);

  const dataToShare: ShareData = {
    title: mbtiShareText,
    url: window.location.href,
  };

  const handleShareButton = async () => {
    analytics("Skin Mbti Share", { share_type: "kakao" });

    const result = await ShareSns(dataToShare);

    if (result === "copiedToClipboard") {
      shareToastRef.current.show(mbtiLinkCopyText);
    } else if (result === "failed") {
      shareToastRef.current.show(clipboardCopyFailText);
    }
  };

  const handleMbtiRestart = () => {
    analytics("Skin Mbti Restart", "");
    navigation.push("skinMbti");
    setMbtiResult(null);
    setIsLoading(false);
  };

  return (
    <>
      <MbtiItemWrap>
        {SkinMbtiResultType.map((item, index) => (
          <Maybe
            key={index}
            test={item.type === type}
            children={
              <MbtiItemContents key={index}>
                <MbtiType>
                  {item.title.split("\n").map((item, index) => (
                    <MbtiTypeTitle key={index}>{item}</MbtiTypeTitle>
                  ))}
                  <MbtiTypeContents>
                    <HightlightContents>
                      <Hightlight />
                      <MbtiTypeText>{item.type}</MbtiTypeText>
                    </HightlightContents>
                  </MbtiTypeContents>
                  <MbtiTypeDescText>{item.typeDesc}</MbtiTypeDescText>
                  <Image
                    source={item.img}
                    resizeMode="contain"
                    style={itemStyles.image}
                  />
                  <MbtiTagText>{item.tag}</MbtiTagText>
                </MbtiType>

                {!planMode && (
                  <>
                    <MbtiDescription>
                      {item.description.split("\n").map((item, index) => (
                        <MbtiDesc key={index}>
                          <Dot style={globalStyles.dot} />
                          <MbtiDescText style={globalStyles.fontM}>
                            {item}
                          </MbtiDescText>
                        </MbtiDesc>
                      ))}
                    </MbtiDescription>
                    {state !== 2 && (
                      <MbtiRecommend>
                        <Text style={itemStyles.title}>추천하는 시술</Text>
                        <View
                          dataSet={{ media: ids.listWrap }}
                          style={styles.listWrap}
                        >
                          {item.recommend.map((item, index) => (
                            <View
                              key={index}
                              dataSet={{ media: ids.list }}
                              style={styles.list}
                            >
                              <MbtiRecommendItem
                                activeOpacity={1}
                                onPress={() => {
                                  analytics("Skin Mbti Recommend List", {
                                    surgery_name: item.title,
                                  });
                                  navigation.push("surgeryDetail", {
                                    id: item.id,
                                  });
                                }}
                              >
                                <MbtiRecommendImgContents>
                                  <MbtiRecommendImg
                                    source={
                                      index === 0 ? surgeryImg01 : surgeryImg02
                                    }
                                    resizeMode="contain"
                                  />
                                </MbtiRecommendImgContents>
                                <MbtiRecommendSurgeryTitleContents>
                                  <MbtiRecommendSurgeryTitle>
                                    {item.title}
                                  </MbtiRecommendSurgeryTitle>
                                </MbtiRecommendSurgeryTitleContents>
                                <MbtiRecommendSurgeryInfo>
                                  {item.description}
                                </MbtiRecommendSurgeryInfo>
                              </MbtiRecommendItem>
                            </View>
                          ))}
                        </View>
                      </MbtiRecommend>
                    )}
                  </>
                )}
              </MbtiItemContents>
            }
          />
        ))}

        {!planMode && state !== 2 && (
          <MbtiShareContents>
            <Maybe
              test={!isLoggedIn || state === 1 || state === 2}
              children={
                <>
                  <Text style={itemStyles.title}>친구에게 공유하기</Text>
                  <SkinMbtiSnsBtnContents>
                    <SkinMbtiSnsBtn
                      onPress={() => {
                        analytics("Skin Mbti Share", { share_type: "link" });
                        ShareKakao(
                          window.location.href,
                          mbtiResultShareText,
                          "촉촉박사 | 피부 MBTI"
                        );
                      }}
                    >
                      <Image
                        source={mbtiKakaoIcon}
                        resizeMode="contain"
                        style={itemStyles.snsIcon}
                      />
                    </SkinMbtiSnsBtn>
                    <SkinMbtiSnsBtn onPress={handleShareButton}>
                      <Image
                        source={mbtiShareIcon}
                        resizeMode="contain"
                        style={itemStyles.snsIcon}
                      />
                    </SkinMbtiSnsBtn>
                  </SkinMbtiSnsBtnContents>
                </>
              }
            />
          </MbtiShareContents>
        )}

        {!planMode && state !== 2 && (
          <MbtiBottomBtnContents>
            <SwitchCase
              tests={[
                {
                  test: isLoggedIn && state === 0,
                  component: (
                    <>
                      <MbtiBottomBtnRestart
                        style={itemStyles.btn}
                        activeOpacity={1}
                        onPress={handleMbtiRestart}
                      >
                        <Image
                          source={mbtiRestartIcon}
                          style={itemStyles.icon}
                        />
                        <Text style={[itemStyles.btnText, itemStyles.white]}>
                          테스트 다시 하기
                        </Text>
                      </MbtiBottomBtnRestart>
                      <MbtiBottomBtnAll
                        style={itemStyles.btn}
                        onPress={() => navigation.goBack()}
                        activeOpacity={1}
                      >
                        <MbtiBottomBtnText style={itemStyles.btnText}>
                          뒤로 가기
                        </MbtiBottomBtnText>
                      </MbtiBottomBtnAll>
                    </>
                  ),
                },
                {
                  test: state !== 2,
                  component: (
                    <>
                      <MbtiBottomBtnAll
                        style={itemStyles.btn}
                        onPress={onClick}
                        activeOpacity={1}
                      >
                        <Image source={mbtiListIcon} style={itemStyles.icon} />
                        <MbtiBottomBtnText style={itemStyles.btnText}>
                          모든 결과 보기
                        </MbtiBottomBtnText>
                      </MbtiBottomBtnAll>
                      <MbtiBottomBtnRestart
                        style={itemStyles.btn}
                        activeOpacity={1}
                        onPress={() => navigation.push("skinMbti")}
                      >
                        <Image
                          source={mbtiRestartIcon}
                          style={itemStyles.icon}
                        />
                        <Text style={[itemStyles.btnText, itemStyles.white]}>
                          테스트 다시 하기
                        </Text>
                      </MbtiBottomBtnRestart>
                    </>
                  ),
                },
              ]}
              defaultComponent={
                <>
                  <MbtiBottomBtnAll
                    style={itemStyles.btn}
                    onPress={onClick}
                    activeOpacity={1}
                  >
                    <Image source={mbtiListIcon} style={itemStyles.icon} />
                    <MbtiBottomBtnText style={itemStyles.btnText}>
                      모든 결과 보기
                    </MbtiBottomBtnText>
                  </MbtiBottomBtnAll>
                  <MbtiBottomBtnRestart
                    style={itemStyles.btn}
                    activeOpacity={1}
                    onPress={() => navigation.push("skinMbti")}
                  >
                    <Image source={mbtiRestartIcon} style={itemStyles.icon} />
                    <Text style={[itemStyles.btnText, itemStyles.white]}>
                      테스트 다시 하기
                    </Text>
                  </MbtiBottomBtnRestart>
                </>
              }
            />
          </MbtiBottomBtnContents>
        )}

        {state === 2 && (
          <MbtiBottomPlanContents>
            <MbtiBottomTextContents>
              <Text style={[itemStyles.smText, itemStyles.alignCenter]}>
                <Text style={itemStyles.highlightText}>{user?.nickname}</Text>{" "}
                님을 위해
                <br />
                <Text style={itemStyles.highlightText}>{type}</Text> 타입에 맞는
                시술들을 쏙쏙 골라 드릴게요.
              </Text>
              <Text style={[itemStyles.smText, itemStyles.margin20]}>
                이제 간단한 단계들만 남아 있어요!
              </Text>
              <Text style={[itemStyles.smText, itemStyles.fontBold]}>
                아래 다음 버튼을 눌러주세요.
              </Text>
            </MbtiBottomTextContents>
            <MbtiBottomPlanBtnContents>
              <MbtiBottomPlanBtn
                activeOpacity={1}
                style={globalStyles.fullBtn}
                onPress={onClickNext}
              >
                <MbtiBottomPlanBtnText>다음</MbtiBottomPlanBtnText>
              </MbtiBottomPlanBtn>
            </MbtiBottomPlanBtnContents>
          </MbtiBottomPlanContents>
        )}
      </MbtiItemWrap>
      <Toast
        ref={shareToastRef}
        offset={height / 2}
        duration={3000}
        style={globalStyles.centerToast}
        textStyle={globalStyles.centerToastText}
      />
    </>
  );
};

export default MbtiItem;
