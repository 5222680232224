/**
 * Storage
 */

import env from "../../environments";

export const STRG_LOCALE_KEY = `${env.PROJECT_SYMBOL}_locale`;
export const STRG_USERNAME_KEY = `${env.PROJECT_SYMBOL}_username`;
export const STRG_USER_ROLE_KEY = `${env.PROJECT_SYMBOL}_user_role`;
export const STRG_ACCESS_TOKEN_KEY = `${env.PROJECT_SYMBOL}_access_token`;
export const STRG_REFRESH_TOKEN_KEY = `${env.PROJECT_SYMBOL}_refresh_token`;

export const COOKIE_VALUE = "never-show-up-today";
