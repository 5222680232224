import CommunityListScreen from "./CommunityListScreen";
import EditTalkScreen from "./EditTalkScreen";
import CommunityDetailScreen from "./CommunityDetailScreen";
import CommunityReportScreen from "./CommunityReportScreen";
import UpdateTalkScreen from "./UpdateTalkScreen";
import ProfileScreen from "./ProfileScreen";

export {
  CommunityListScreen,
  EditTalkScreen,
  CommunityDetailScreen,
  CommunityReportScreen,
  UpdateTalkScreen,
  ProfileScreen,
};
