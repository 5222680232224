import React from "react";
import RadioGroup, { RadioButtonProps } from "react-native-radio-buttons-group";
import {
  RadioButtonItem,
  RadioButtonItems,
  RadioButtonText,
  SelectInput,
  styles,
} from "./styled";

export interface RadioProps {
  value?: any;
  options?: any;
  onChangeValue?: any;
  items?: any;
  input?: any;
  inputPlaceholder?: string;
  onChangeInputText?: (value) => void;
  inputValue?: any;
  showInputText?: any;
}

const Radio = (props: RadioProps): JSX.Element => {
  const {
    value,
    options,
    onChangeValue,
    items,
    inputPlaceholder,
    onChangeInputText,
    inputValue,
    showInputText,
  } = props;

  const radioButtons: RadioButtonProps[] = items;

  return (
    <>
      <RadioButtonItem last={radioButtons.length - 1}>
        <RadioButtonItems style={styles.radioStyle}>
          <RadioGroup
            radioButtons={radioButtons}
            selectedId={value}
            onPress={onChangeValue}
            containerStyle={styles.radioStyle}
          />
        </RadioButtonItems>
        <RadioButtonText>{options}</RadioButtonText>
      </RadioButtonItem>
      {showInputText && (
        <SelectInput
          placeholder={inputPlaceholder}
          placeholderTextColor="#c6c6c6"
          onChangeText={onChangeInputText}
          value={inputValue}
          maxLength={500}
          multiline
        />
      )}
    </>
  );
};

export default Radio;
