import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { View, Text, ScrollView, Image, FlatList } from "react-native";

import {
  Category,
  CategoryText,
  CommentContents,
  Comment,
  CommunityListContainer,
  HeaderContents,
  ContentsWrap,
  CountText,
  DetailContents,
  Info,
  itemStyles,
  LikeContents,
  LikeImage,
  NicknameText,
  Title,
  TitleHeader,
  TitleText,
  CommentItem,
  CommentItemInner,
  CommentWriter,
  CommentNickname,
  CommentMore,
  CommentItems,
  Reply,
  ReplyComment,
  ReplyContents,
  ReplyInner,
  ReplyBtn,
  ReplyBtnText,
  LikeBtn,
  BottomCommentContents,
  BottomCommentInput,
  NoData,
  NoDataText,
  LikeCount,
  BottomCommentNickname,
  CommentInputImg,
  BottomCommentInner,
  CommentImgView,
  ImgDelete,
  ImgDeleteIcon,
  DetailContentsText,
  MoreContents,
  MoreBtn,
  BottomCommentBtn,
  BottomCommentBtnText,
  AttachIcon,
  GrayBox,
  GrayBoxLogin,
  CommentNicknameContents,
} from "./styled";
import { Maybe } from "..";
import { CommunityService } from "@src/services";
import { useRoute } from "@react-navigation/core";
import { useAuth } from "@src/hooks/auth";
import likeIcon from "@assets/icons/icon-like.png";
import activeLikeIcon from "@assets/icons/icon-like-active.png";
import moreIcon from "@assets/icons/icon-more.svg";
import photoIcon from "@assets/icons/icon-image.svg";
import deleteIcon from "@assets/icons/icon-delete.png";
import downGrayIcon from "@assets/icons/icon-down-gray.svg";
import profileIcon from "@assets/icons/icon-profile.png";
import getYouTubeID from "get-youtube-id";
import { useRecoilState } from "recoil";
import {
  CommunityCommentId,
  CommunityCommentUserId,
  CommunityPostId,
} from "@src/store";
import { useRedirectUrl } from "@src/hooks/redirect";
import { globalStyles } from "@src/styles/modules/global";
import { analytics } from "@src/hooks/analytics";

interface CommunityDetailProps {
  data: any;
  isVisibleReplyPopup?: any;
  setIsMine?: any;
  deleteComment?: any;
  isVisibleImagePopup?: any;
  imageUrl?: any;
  forumId?: number;
  detailUserId?: any;
}

const CommunityDetail = ({
  data,
  isVisibleReplyPopup,
  setIsMine,
  deleteComment,
  isVisibleImagePopup,
  imageUrl,
  forumId,
  detailUserId,
}: CommunityDetailProps): ReactElement => {
  const route = useRoute<any>();
  const id = route.params?.id;

  const { isLoggedIn } = useAuth();
  const { loginUrl } = useRedirectUrl();

  const fileInputRef = useRef<any>(null);
  const textareaRef = useRef(null);

  const [replyMode, setReplyMode] = useState<boolean>(false);
  const [likeMode, setLikeMode] = useState<boolean>(false);
  const [likeCount, setLikeCount] = useState<number>();
  const [pageIndex, setPageIndex] = useState<number>(0);

  const [commentCount, setCommentCount] = useState<number>(0);
  const [comment, setComment] = useState<any>([]);
  const [commentLast, setCommentLast] = useState<boolean>(false);
  const [nickname, setNickname] = useState<string>();
  const [postId, setPostId] = useRecoilState(CommunityPostId);
  const [commentId, setCommentId] = useRecoilState(CommunityCommentId);
  const [commentUserId, setCommentUserId] = useRecoilState(
    CommunityCommentUserId
  );
  const [commentText, setCommentText] = useState<string>("");

  const [commentImgFile, setCommentImgFile] = useState<any>([]);

  const onChangeCommentImg = (e) => {
    const file = fileInputRef.current.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setCommentImgFile(reader.result);
    };
  };

  const onChangeTextInput = () => {
    if (textareaRef && textareaRef.current) {
      textareaRef.current.style.height = "0px";
      const scrollHeight = textareaRef.current.scrollHeight;
      textareaRef.current.style.height = scrollHeight + "px";
    }
  };

  const postLike = async () => {
    try {
      const conditions = {
        postId: postId,
        action: "TOGGLE",
      };

      await CommunityService.getPostLike(conditions);
    } catch (error) {
      console.log(error);
    }

    handleLikeCount();
  };

  const getComment = async () => {
    try {
      const conditions = {
        postId: id,
        pageIdx: pageIndex,
      };

      const { data } = await CommunityService.getPostComment(conditions);
      if (pageIndex > 0) {
        setComment((prev) => prev.concat(data.content));
      } else {
        setComment(data.content);
      }

      setCommentLast(data.last);
      setCommentCount(data.totalElements);
    } catch (error) {
      console.log(error);
    }
  };

  const postComment = useCallback(async () => {
    try {
      const payload = {
        content: commentText,
        base64Encode: String(commentImgFile),
      };

      const { data } = await CommunityService.postComment(id, payload);

      if (data === "OK") {
        analytics("Community Comment", {
          board_name: forumId === 1 ? "촉촉 토크" : "MBTI 토크",
        });

        getComment();
        setCommentText("");
        setCommentImgFile([]);
      }
    } catch (error) {
      console.log(error);
    }
  }, [commentText, commentImgFile]);

  const postReplyComment = useCallback(async () => {
    try {
      const payload = {
        content: commentText,
        base64Encode: String(commentImgFile),
      };

      const { data } = await CommunityService.postReplyComment(
        id,
        commentId,
        payload
      );

      if (data === "OK") {
        getComment();
        setCommentText("");
        setNickname("");
        setReplyMode(false);
        setCommentImgFile([]);
      }
    } catch (error) {
      console.log(error);
    }
  }, [commentText, commentImgFile]);

  const handleLikeCount = () => {
    if (!likeMode) {
      setLikeMode(true);
      setLikeCount((prev) => prev + 1);
    } else if (likeMode && likeCount > 0) {
      setLikeMode(false);
      setLikeCount((prev) => prev - 1);
    }
  };

  const handleMoreData = () => {
    setPageIndex((prev) => prev + 1);
  };

  const handleMakingReply = (name, id) => {
    setReplyMode(true);
    setNickname(name);
    setCommentId(id);
  };

  const handleMoreBtn = (isMine, id, userId) => {
    isVisibleReplyPopup(true);
    setIsMine(isMine);
    setCommentId(id);
    setCommentUserId(userId);
  };

  const handleLogin = () => {
    window.location.href = loginUrl;
  };

  useEffect(() => {
    if (data[0]) {
      if (data[0].isItLike) {
        setLikeMode(true);
      } else {
        setLikeMode(false);
      }
      setLikeCount(data[0].countOfLike);
      detailUserId(data[0].user.id);
    }
  }, [data]);

  useEffect(() => {
    getComment();
  }, [pageIndex]);

  useEffect(() => {
    setPostId(id);
  }, [id]);

  useEffect(() => {
    if (deleteComment === true) {
      getComment();
    }
  }, [deleteComment]);

  const renderDetailItem = ({ item }) => {
    return (
      <ScrollView
        bounces={false}
        scrollEventThrottle={1}
        contentContainerStyle={{ flexGrow: 1, flex: 1 }}
        showsVerticalScrollIndicator={false}
      >
        <CommunityListContainer>
          <HeaderContents>
            <TitleHeader>
              <Category>
                <CategoryText>{item.postCategory.title}</CategoryText>
              </Category>
              <Title>
                <TitleText>{item.title}</TitleText>
              </Title>
              <Info>
                <View>
                  <Image
                    source={
                      item.user?.profile ? item.user?.profile : profileIcon
                    }
                    style={itemStyles.profileImg}
                  />
                </View>
                <View style={itemStyles.nickname}>
                  <NicknameText style={[itemStyles.xsText, itemStyles.gray]}>
                    {item.user.nickname}
                  </NicknameText>
                  <View style={itemStyles.flexRow}>
                    <Text style={[itemStyles.xsText, itemStyles.gray2]}>
                      {item.createDate}
                    </Text>
                    <CountText style={[itemStyles.xsText, itemStyles.gray2]}>
                      조회 {item.countOfView}
                    </CountText>
                  </View>
                </View>
              </Info>
            </TitleHeader>
          </HeaderContents>
          <DetailContents>
            {item.youtubeUrl && (
              <iframe
                width="100%"
                height="250px"
                frameBorder="0"
                allowFullScreen
                src={`https://www.youtube.com/embed/${getYouTubeID(
                  item.youtubeUrl
                )}`}
              />
            )}
            {item.attachedImageList.map((item, index) => (
              <img
                key={index}
                src={item.imageUrl}
                style={itemStyles.contentsImage}
                onClick={() => {
                  isVisibleImagePopup(true);
                  imageUrl(item.imageUrl);
                }}
              />
            ))}
            <DetailContentsText>{item.content}</DetailContentsText>
            {isLoggedIn && (
              <LikeContents>
                <LikeBtn activeOpacity={1} onPress={postLike}>
                  <LikeImage
                    source={likeMode ? activeLikeIcon : likeIcon}
                    resizeMode="contain"
                  />
                </LikeBtn>
                <LikeCount like={likeMode} style={itemStyles.xsText}>
                  {likeCount}
                </LikeCount>
              </LikeContents>
            )}
            <CommentContents>
              <Text style={itemStyles.mdText}>댓글 {comment.length}</Text>
              <CommentItems>
                {comment.length === 0 ? (
                  <NoData>
                    <NoDataText>
                      댓글이 없어요.
                      <br />첫 댓글을 작성해 보세요.
                    </NoDataText>
                  </NoData>
                ) : (
                  <>
                    <FlatList
                      data={comment}
                      renderItem={({ item }) => (
                        <CommentItem comment={item.parentId}>
                          <CommentItemInner>
                            <View style={itemStyles.flexRow}>
                              <Image
                                source={
                                  item.user?.profile
                                    ? item.user?.profile
                                    : profileIcon
                                }
                                resizeMode="cover"
                                style={itemStyles.smProfileImg}
                              />
                              <View style={itemStyles.nickname}>
                                <View style={itemStyles.flexRow}>
                                  {item.user?.isPostOwner ? (
                                    <CommentWriter>
                                      <Text
                                        style={[
                                          itemStyles.whiteText,
                                          itemStyles.xxsText,
                                        ]}
                                      >
                                        작성자
                                      </Text>
                                    </CommentWriter>
                                  ) : (
                                    <CommentNicknameContents type={forumId}>
                                      <CommentNickname
                                        type={forumId}
                                        style={[
                                          itemStyles.xsText,
                                          itemStyles.gray,
                                        ]}
                                      >
                                        {item.user?.nickname}
                                      </CommentNickname>
                                    </CommentNicknameContents>
                                  )}
                                </View>
                                <View
                                  style={[itemStyles.flexRow, itemStyles.time]}
                                >
                                  <Text
                                    style={[
                                      itemStyles.xsText,
                                      itemStyles.gray2,
                                    ]}
                                  >
                                    {item.createDate}
                                  </Text>
                                </View>
                              </View>
                            </View>
                            {isLoggedIn && (
                              <CommentMore
                                activeOpacity={1}
                                onPress={() =>
                                  handleMoreBtn(
                                    item.isItMine,
                                    item.id,
                                    item.user?.id
                                  )
                                }
                              >
                                <Image
                                  source={moreIcon}
                                  resizeMode="contain"
                                  style={itemStyles.icon}
                                />
                              </CommentMore>
                            )}
                          </CommentItemInner>
                          <Comment>
                            {item.imageUrl && (
                              <img
                                src={item.imageUrl}
                                style={itemStyles.commentImage}
                                onClick={() => {
                                  isVisibleImagePopup(true);
                                  imageUrl(item.imageUrl);
                                }}
                              />
                            )}
                            {!isLoggedIn && item.isBlindForNeedLogin ? (
                              <GrayBox>
                                <Text style={itemStyles.grayBoxText}>
                                  1시간 이내에 작성된 댓글을 확인 하시려면
                                  <br />
                                  <GrayBoxLogin onPress={handleLogin}>
                                    <Text style={itemStyles.bold}>로그인</Text>
                                  </GrayBoxLogin>
                                  하세요.
                                </Text>
                              </GrayBox>
                            ) : (
                              <Text style={itemStyles.text}>
                                {item.content}
                              </Text>
                            )}
                            {(isLoggedIn && forumId === 1) ||
                            (forumId === 2 &&
                              !item.parentId &&
                              !item.isBlindForNeedLogin) ? (
                              <ReplyBtn
                                activeOpacity={1}
                                onPress={() =>
                                  handleMakingReply(
                                    item.user?.nickname,
                                    item.id
                                  )
                                }
                              >
                                <ReplyBtnText>답글달기</ReplyBtnText>
                              </ReplyBtn>
                            ) : (
                              <></>
                            )}
                          </Comment>
                        </CommentItem>
                      )}
                    />
                    <Maybe
                      test={commentCount > 10 && comment !== [] && !commentLast}
                      children={
                        <MoreContents>
                          <MoreBtn onPress={handleMoreData} activeOpacity={1}>
                            <Text style={itemStyles.moreText}>더보기</Text>
                            <Image
                              source={downGrayIcon}
                              resizeMode="contain"
                              style={itemStyles.icon}
                            />
                          </MoreBtn>
                        </MoreContents>
                      }
                    />
                  </>
                )}
              </CommentItems>
            </CommentContents>
          </DetailContents>
        </CommunityListContainer>
      </ScrollView>
    );
  };

  const renderCommentInput = () => (
    <BottomCommentContents reply={replyMode} img={commentImgFile[0]}>
      {commentImgFile[0] && (
        <CommentImgView>
          <CommentInputImg source={{ uri: commentImgFile }} />
          <ImgDelete onPress={() => setCommentImgFile([])}>
            <ImgDeleteIcon source={deleteIcon} resizeMode="contain" />
          </ImgDelete>
        </CommentImgView>
      )}
      <BottomCommentInner>
        <AttachIcon
          source={photoIcon}
          resizeMode="contain"
          style={itemStyles.icon}
        />
        <input
          type="file"
          style={itemStyles.fileInput}
          onChange={onChangeCommentImg}
          ref={fileInputRef}
          accept="image/*"
        />
        <BottomCommentInput
          ref={textareaRef}
          placeholder="댓글을 입력하세요"
          placeholderTextColor="#c6c6c6"
          value={commentText}
          multiline
          reply={replyMode}
          onChange={onChangeTextInput}
          onChangeText={(value) => setCommentText(value)}
          numberOfLines={5}
          style={{
            height: nickname ? 72 : 48,
          }}
        />
        {replyMode && (
          <BottomCommentNickname>
            {nickname ? `@${nickname}` : ""}
          </BottomCommentNickname>
        )}
        <BottomCommentBtn activeOpacity={1}>
          <BottomCommentBtnText
            active={commentText.length > 0}
            onPress={replyMode ? postReplyComment : postComment}
          >
            등록
          </BottomCommentBtnText>
        </BottomCommentBtn>
      </BottomCommentInner>
    </BottomCommentContents>
  );

  return (
    <>
      <ContentsWrap
        img={commentImgFile[0]}
        reply={replyMode}
        isLoggedIn={isLoggedIn}
      >
        <FlatList
          data={data}
          renderItem={renderDetailItem}
          showsVerticalScrollIndicator={false}
          contentContainerStyle={globalStyles.listPaddingS}
        />
      </ContentsWrap>
      <Maybe test={isLoggedIn} children={renderCommentInput()} />
    </>
  );
};

export default CommunityDetail;
