/**
 * 로그인/User 정보
 */

import { useEffect, useRef } from "react";
import { useRecoilState } from "recoil";
import { simpleUserState, entireUserState, userState } from "@src/store";

export const useAuth = () => {
  const [user] = useRecoilState(userState);
  const [simpleUser] = useRecoilState(simpleUserState);
  const [entireUser] = useRecoilState(entireUserState);
  const latestUser = useRef<any>();

  useEffect(() => {
    latestUser.current = simpleUser;
  }, [simpleUser]);

  return {
    isLoggedIn: !!user,
    user: user,
    simpleUser: simpleUser,
    entireUser: entireUser,
  };
};
