import { Color } from "@src/constants";
import styled from "styled-components/native";
import { Dimensions, StyleSheet } from "react-native";
import MediaStyle from "react-native-media-query";

const { height } = Dimensions.get("window");

export const ProgressBarHeader = styled.View`
  width: 100%;
  padding: 0 20px;
`;

export const ProgressBarNav = styled.View`
  width: 100%;
  height: 56px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const ProgressBarPrev = styled.TouchableHighlight`
  justify-content: flex-start;
`;

export const ProgressBarPrevImg = styled.Image`
  width: 24px;
  height: 24px;
`;

export const ProgressBarCount = styled.View`
  align-items: flex-end;
`;

export const ProgressBarCountText = styled.Text`
  font-size: 14px;
  letter-spacing: -0.35px;
  color: ${({ black }: { black?: boolean }) =>
    black ? Color.BLACK : Color.WHITE};
`;

export const progressStyles = StyleSheet.create({
  progress: {
    backgroundColor: "#825bec",
    borderWidth: 0,
    borderRadius: 4,
  },
  bgColor: {
    backgroundColor: "#efeefe",
    borderWidth: 0,
  },
});

export const { ids, styles } = MediaStyle.create({
  popup: {
    height: height - 80,
    marginInline: "calc(50vw - 232px)",
    marginVertical: 40,
    backgroundColor: Color.WHITE,
    borderRadius: 16,
    bottom: 0,
    top: 0,
    transition: "top 0.6s cubic-bezier(0.165, 0.84, 0.44, 1)",
    zIndex: 1001,
    "@media (max-width: 480px)": {
      marginInline: 8,
    },
  },
});
